<div class="container">
    <div class="container" fxLayout="row" fxLayoutGap="5px">
        <div class="item item-1" fxFlex="35px">
            <mat-icon>delete</mat-icon>
        </div>
        <div class="item item-2" fxFlex="100%">
            <h3 mat-dialog-title>Are you sure you want to Delete ?</h3>
        </div>
    </div>
    <button mat-raised-button color="warn" (click)="confirmDelete()">Delete</button>
    <button mat-raised-button (click)="onNoClick()" tabindex="-1">Cancel</button>
</div>