import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';

@Component({
  selector: 'app-sys-user-roll',
  templateUrl: './sys-user-roll.component.html',
  styleUrls: ['./sys-user-roll.component.scss']
})
export class SysUserRollComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowSysUserRoll: boolean = false;
  dataSourceSysuserRoll = new MatTableDataSource();
  displayedColumnsSysuserRoll = ['email', 'admin', 'management', 'hightlevelmanagement', 'inactive'];
  filter: string = "";
  dataSysuserRoll: any = [];
  isAdmin: boolean;
  pageNumber: number = 0;
  ngAfterViewInit() {
    this.dataSourceSysuserRoll.paginator = this.paginator;

  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    public sdUtilityService: SDUtilityService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.isShowSysUserRoll = true;
    this.getGetUserList()


  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceSysuserRoll.filter = filterValue;
    // this.capturePageParameter();
  }


  //    Getข้อมูล
  getGetUserList() {
    let callMethod: string = '/Auth/GetUserList';
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        console.log(values);

        // let i = 0;
        values.forEach(objData => {

          objData.sysUserRoles.forEach(element => {

            if (element.userSysRoleId == 3) {
              objData.isAdmin = true;
            }

            if (element.userSysRoleId == 4) {
              objData.isManagement = true;
            }

            if (element.userSysRoleId == 5) {
              objData.isHightLevelManagement = true;
            }
          });

          this.dataSysuserRoll.push(objData)

        });
        this.dataSourceSysuserRoll.data = this.dataSysuserRoll
        console.log(this.dataSourceSysuserRoll.data);
      });
  }

  btnSave(data: any) {
    console.log(data);

  }

  rowClicked(row) {
    this.router.navigate(['sysuserrolldetail', JSON.stringify(row)]);

  }

  onPaginateChange(datatest: any) {
    if (datatest['pageIndex'] == 0) {
      this.pageNumber = 0;
    } else {
      this.pageNumber = datatest['pageIndex'] * datatest['pageSize'];
    }
  }



}
