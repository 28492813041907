<div class="table-container">

    <table style="width: 100%; font-size: 14px;" class="font-light">
        <tr>
            <td colspan="2" style="width: 100%; text-align: center;margin-top: 30px;">
                <h3>รายงานแสดงโครงการที่ใกล้ถึงกำหนดประชุมแจ้งความคืบหน้า</h3>
            </td>
        </tr>
        <tr>
            <td colspan="2">
                <table style="width: 100%;padding: 0px;margin: 0px;">
                    <tr>
                        <td colspan="4 " style="width: 100%; ">
                            <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                <tr>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ลำดับ
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        หน่วยงาน
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ปีงบประมาณ
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        รหัสโครงการ
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ชื่อโครงการ
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ชื่อหัวหน้าโครงการ
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        วันที่เริ่มโครงการ
                                    </td>

                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        วันที่ติดตามความก้าวหน้าครั้งถัดไป
                                    </td>


                                </tr>
                                <tr *ngFor="let objDataProjectMeeting of dataProjectTracking let i = index">
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ i + 1 }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.organizationName }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.fiscalYear }}
                                    </td>

                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.projectCode }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.projectNameTH }}
                                    </td>

                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.researcherName }}

                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.projectStartDate | date : 'dd/MM/yyyy' }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objDataProjectMeeting.trackingDate | date : 'dd/MM/yyyy' }}
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

    </table>
</div>