import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { projectproposal } from 'src/app/datamodel/projectproposal';
import { researcher } from 'src/app/datamodel/researcher';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { CurrencyPipe, formatDate } from '@angular/common';
// import { PDFDocumentFactory, PDFDocumentWriter } from 'pdf-lib';
// import fs from 'fs';
import { Injectable } from '@angular/core';
// import { PDFDocumentFactory, PDFDocumentWriter } from 'pdf-lib';
// declare function addPageNumbers(): any;

@Component({
  selector: 'app-report-proposal',
  templateUrl: './report-proposal.component.html',
  styleUrls: ['./report-proposal.component.scss']
})
export class ReportProposalComponent implements OnInit {
  Reportdatas: any = [];
  projectProposal: projectproposal;
  getHight: number = 0;
  objPageNumber: any;
  totalPages: number = 0;
  sum: number = 0;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    // public sdUtilityService: SDUtilityService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>,
    // private PDFDocument: PDFDocument
  ) {
    this.projectProposal = JSON.parse(this.route.snapshot.paramMap.get('dataobj'));
    this.dateAdapter.setLocale('th-TH');
  }

  ngOnInit() {
    console.log(this.projectProposal);
    this.Reportdatas = this.projectProposal;
    console.log("<<=====", JSON.stringify(document.getElementById("getHight").scrollHeight), "=====>>");
    //เรียกใช้งานฟังช์คริปต์
    // addPageNumbers();
    // this.setPageNumber();
  }

  CloseClick() {

    let dataToSend: any = { 'mode': 'edit', 'projectid': this.projectProposal.id };
    this.router.navigate(['/projectsearchdetail', JSON.stringify(dataToSend)]);

  }

  btnTestPrint() {
    console.log(document);


    //
    // let url = "https://172.16.40.52/reportproposalviewver/" + JSON.stringify(this.projectProposal);
    // window.open(url)

    // this.router.navigate([
    //   '/reportproposalviewver',
    //   JSON.stringify(this.projectProposal),
    // ]);
    // let myWindow = window.open("", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=900,height=500");

    // let htmlContent:string =   this.sanitizer.bypassSecurityTrustHtml(document.getElementById("contentToConvert").innerHTML)['changingThisBreaksApplicationSecurity'];

    // myWindow.document.write('<html><head><title>Print it!</title><style>@font-face {font-family: chulabhorn;src: url(/assets/fonts/chulabhornLikit/chulabhornlikittext-light.woff);} p {font-family: chulabhorn;}</style></head>aaaaa ทดสอบ นนนนน llllll<p> aaaaa ทดสอบ นนนนน </p>  <p>' + htmlContent + '</p></html>');


  }
  setPageNumber() {

    console.log("<<=====", JSON.stringify(document.getElementById("getHight").scrollHeight), "=====>>");
    let now = formatDate(new Date(), 'dd/MM/yyyy', 'en');
    const datasplit = now.split('/')
    console.log(datasplit);
    let num = +datasplit[2] + 543;
    now = datasplit[0] + "/" + datasplit[1] + "/" + num;

    var content = document.getElementById("content");
    // if (document.getElementById("getHight").scrollHeight <= 10000) {
    //    var totalPages = Math.round(document.getElementById("getHight").scrollHeight / 1050);  //เดิม 1100 //1072
    //   console.log("<= 10000",totalPages);
    // } else if (document.getElementById("getHight").scrollHeight >= 10000) {
    // var totalPages = Math.round(document.getElementById("getHight").scrollHeight / 1123);  //เดิม 1100 //1072
    // console.log(">= 10000",totalPages);
    // }
      if (document.getElementById("getHight").scrollHeight > 1000 && document.getElementById("getHight").scrollHeight < 2324) {
        this.totalPages = 2;

      } else if  (document.getElementById("getHight").scrollHeight > 2348 && document.getElementById("getHight").scrollHeight < 3499) {
        this.totalPages = 3;

      } else if  (document.getElementById("getHight").scrollHeight > 3536 && document.getElementById("getHight").scrollHeight < 4318) { //เดิม 4619 พบว่า มันมีหน้าสุดท้ายว่าง เพราะ 4394
        this.totalPages = 4;

      } else if  (document.getElementById("getHight").scrollHeight > 4318 && document.getElementById("getHight").scrollHeight < 5532) {
        this.totalPages = 5;

      } else if  (document.getElementById("getHight").scrollHeight > 5563 && document.getElementById("getHight").scrollHeight < 6640) {
        this.totalPages = 6;

      } else if (document.getElementById("getHight").scrollHeight > 6671 && document.getElementById("getHight").scrollHeight < 7704) {
         this.totalPages = 7;

      }  else if (document.getElementById("getHight").scrollHeight > 7828 && document.getElementById("getHight").scrollHeight < 8740) {
        this.totalPages = 8;

      }  else if (document.getElementById("getHight").scrollHeight > 8766 && document.getElementById("getHight").scrollHeight < 9838) {
        this.totalPages = 9;

      }  else if (document.getElementById("getHight").scrollHeight > 9865 && document.getElementById("getHight").scrollHeight < 10995) {
        this.totalPages = 10;

      }  else if (document.getElementById("getHight").scrollHeight > 11020 && document.getElementById("getHight").scrollHeight < 12081) {
        this.totalPages = 11;

      }  else if (document.getElementById("getHight").scrollHeight > 12108 && document.getElementById("getHight").scrollHeight < 13198) {
        this.totalPages = 12;

      }  else if (document.getElementById("getHight").scrollHeight > 13223 && document.getElementById("getHight").scrollHeight < 14295) {
        this.totalPages = 13;

      }  else if (document.getElementById("getHight").scrollHeight > 14352 && document.getElementById("getHight").scrollHeight < 15361) {
        this.totalPages = 14;

      }  else if (document.getElementById("getHight").scrollHeight > 15388 && document.getElementById("getHight").scrollHeight < 16470) {
        this.totalPages = 15;

      }  else if (document.getElementById("getHight").scrollHeight > 16497 && document.getElementById("getHight").scrollHeight < 17542) {
        this.totalPages = 16;

      }  else if (document.getElementById("getHight").scrollHeight > 17569 && document.getElementById("getHight").scrollHeight < 19313) {
        this.totalPages = 17;

      }  else if (document.getElementById("getHight").scrollHeight > 19339 && document.getElementById("getHight").scrollHeight < 20463) {
        this.totalPages = 18;

      }  else if (document.getElementById("getHight").scrollHeight > 20490 && document.getElementById("getHight").scrollHeight < 21650) {
        this.totalPages = 19;

      }  else if (document.getElementById("getHight").scrollHeight > 21677 && document.getElementById("getHight").scrollHeight < 22785) {
        this.totalPages = 20;

      }  else if (document.getElementById("getHight").scrollHeight > 22811 && document.getElementById("getHight").scrollHeight < 23952) {
        this.totalPages = 21;

      }  else if (document.getElementById("getHight").scrollHeight > 23978 && document.getElementById("getHight").scrollHeight < 25125) {
        this.totalPages = 22;

      }  else if (document.getElementById("getHight").scrollHeight > 25130 && document.getElementById("getHight").scrollHeight < 26352) {
        this.totalPages = 23;

      }  else if (document.getElementById("getHight").scrollHeight > 26378 && document.getElementById("getHight").scrollHeight < 27420) {
        this.totalPages = 24;
        alert("24หน้า")
        }   else if (document.getElementById("getHight").scrollHeight >= 27420) {
         this.totalPages = Math.round(document.getElementById("getHight").scrollHeight / 1100);  //เดิม 1100 //1072

       }

    console.log(document.getElementById("getHight").scrollHeight);


    if (content != null) {
      for (var i = 1; i <= this.totalPages; i++) {
        var pageNumberDiv = document.createElement("div");
        var pageNumber = document.createTextNode("หน้า " + i + " / " + this.totalPages + " พิมพ์จากระบบวันที่ " + now );
        pageNumberDiv.style.position = "absolute";
        document.getElementById("content").style.fontFamily = "chulabhorn";
        // document.getElementById("getHight").style.fontFamily = "tahoma";

        document.getElementById("content").style.fontSize = "13px";

        if (i == 1) {
          pageNumberDiv.style.top = "calc(" + i + " * 298.5mm)";
        } else if (i > 1 && i < 10) {
          pageNumberDiv.style.top = "calc((" + i + " * 300.7mm) + ((" + i + " - 1) * 4.2mm))";
        } else if (i >= 10) {
          pageNumberDiv.style.top = "calc((" + i + " * 301.2mm) + ((" + i + " - 1) * 4.2mm))";
        } else if (i >= 19) {
          pageNumberDiv.style.top = "calc((" + i + " * 301.7mm) + ((" + i + " - 1) * 4.2mm))";
        }

        pageNumberDiv.appendChild(pageNumber);
        content.appendChild(pageNumberDiv);
        pageNumberDiv.style.left = "calc(100% - (" + 280 + "px + 5px))";
      }
    }
  }

  captureScreen() {

    console.log("<<=====", JSON.stringify(document.getElementById("getHight").scrollHeight), "=====>>");
    this.setPageNumber();


    let WindowPrt = window.open('', 'PRINT', 'height=600,width=900');

    //  let htmlContent:string =   this.sanitizer.bypassSecurityTrustHtml(document.getElementById("contentToConvert").innerHTML)['changingThisBreaksApplicationSecurity'];

    // WindowPrt.document.write('<html><head><title>' + "" +'</title>');
    WindowPrt.document.write('<html><head><title>' + document.title + '</title>');
    WindowPrt.document.write('<style> .Mat-lbl-text {font-size: 15px;padding-bottom: 10px;padding-top: 10px;color: black;font-style: normal;width: 100%;}');
     WindowPrt.document.write('@font-face {font-family: chulabhorn;src: url(/assets/fonts/chulabhornLikit/chulabhornlikittext-light.woff);} table {font-family: chulabhorn;}');
     //WindowPrt.document.write('@font-face {font-family: tahoma;} table {font-family: tahoma;}');

    WindowPrt.document.write(' .Mat-lbl-data {font-size: 13px;padding-bottom: 10px;padding-top: 10px;color: rgb(2, 2, 2);font-style: normal;}');
    WindowPrt.document.write(' @page {size: A4;margin-top: 10mm; margin-left: 10mm; margin-right: 10mm; border-bottom: 20mm;  @bottom-right {content: counter(page) "/" counter(pages); }} ');
    // WindowPrt.document.write(' @page {size: A4;margin-top: 10mm; margin-left: 10mm; margin-right: 10mm; border-bottom: 20mm;  @bottom-right {content: counter(page) "/" counter(pages); }} ');
    WindowPrt.document.write(' @media print { html, body {width: 210mm;height: 297mm; bottom: 20px} .page {  margin: 0; border: initial;  border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; margin-bottom: 10px }}');

    WindowPrt.document.write(' body {width: 100%;height: 100%;margin: 0mm;padding: 0;} ');
    WindowPrt.document.write(' .page {width: 210mm; min-height: 297mm;padding-top: 5mm; }');
    WindowPrt.document.write(' .subpage {height: 297mm; padding-top: 0.3cm; padding-left: 1cm; padding-right: 1cm; padding-bottom: 10cm; } ');

    // WindowPrt.document.write(' .report-container { page-break-after:always; }');
    // WindowPrt.document.write(' .report-header { display:table-header-group; margin-left: 1cm; margin-right: 1cm; margin-bottom: 0.5cm; }');
    // WindowPrt.document.write(' .report-footer { display:table-footer-group; text-align: right; display: block; position: fixed; left: 430pt; bottom: 0pt;  }');

    WindowPrt.document.write(' table.report-container { page-break-after:always;} ');
    WindowPrt.document.write(' thead.report-header { display:table-header-group;} ');
    WindowPrt.document.write(' tfoot.report-footer { display:table-footer-group; text-align: right; height: 15px; bottom: 5pt;} ');
    WindowPrt.document.write(' .HideContent{ display: block;}');
    WindowPrt.document.write(' </style>');
    WindowPrt.document.write('</head><body>');

    WindowPrt.document.write(document.getElementById("contentToConvert").innerHTML);
     console.log(document.getElementById("contentToConvert").innerHTML);
    console.log("<<=====", JSON.stringify(document.getElementById("getHight").scrollHeight), "=====>>");

    WindowPrt.document.write('</body></html>');

    setTimeout(() => {
      WindowPrt.print();
      WindowPrt.close();
    }, 2000);
    // WindowPrt.print();
  }



  tranformHTML(htmlContent: string) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

}
