export class expectedimpact{
    id: number;
    projectId: number;
    orderNo: number;
    expectedImpact: string;
    canBeUtilized: boolean;
    comment: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: true;
}