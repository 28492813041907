export class expectedresult{
    id: number;
    projectId: number;
    orderNo: number;
    fromMonth: number;
    toMonth: number;
    activityName: string;
    expectedResult: string;
    percentOfActivity: number;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}