<div class="table-container">
    <div fxFlexFill fxLayout="row">
        <table style="width: 100%;">
            <tr>
                <TD style="width: 87%; text-align: left; padding-right: 15px; padding-top: 10px;">
                    <!-- <button *ngIf="showProjectProposeBtn" (click)="refreshList()" style="margin-left: 10px; margin-top: 0px;" mat-raised-button class="btn-send">{{'เรียกข้อมูล'}}</button> -->
                    <button *ngIf="showProjectProposeBtn" (click)="gotoProjectDetail()" style="margin-left: 10px; margin-top: 0px; font-family: chulabhornlikittext-light; font-size: small;" mat-raised-button class="btn-Menu">{{'เสนอโครงการวิจัย'}}</button>
                </TD>

                <!-- <TD style="width: 2%;"></TD> -->
            </tr>
        </table>
    </div>
</div>
<div fxLayout="row" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
    <div fxFlex="86%" style="padding-left: 5px;">
        <mat-form-field class="font-light">
            <div class="font-light">
                <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{'ค้นหา'}}">
            </div>
        </mat-form-field>
    </div>
    <div fxFlex="2%" style="margin-right: 30px;">
        <img *ngIf="showProjectProposeBtn" (click)="refreshList()" style="width: 24px; height: 28px;max-height: 20px; margin-top: 25px;" src="assets\Icon\refresh.png">
    </div>

    <div fxFlex="12%" style="padding-right: 5px;">
        <mat-form-field class="example-full-width">
            <mat-select class="font-light" [(ngModel)]="selectedStatus" (selectionChange)="progressStatusSelectionChanged()">
                <div class="font-light-small">
                    <mat-option *ngFor="let statusList of projectStatusList" [value]=statusList.id>
                        {{ statusList.statusNameAdmin }}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- <div fxFlex="2%">

    </div> -->
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
    <mat-card style="border-radius: 10px; margin: 10px;  border-style: solid; border-color: #d9d9d9;">

        <mat-table class="gridrow" [dataSource]="dataSource" matSort matSortDisableClear (matSortChange)="sortData($event)">
            <ng-container matColumnDef="lineNo">
                <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'ลำดับ'}}</mat-header-cell>
                <mat-cell class="font-light" fxFlex="4" style="color: #002F87;" *matCellDef="let element; let i = index">{{ element.rowNo }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="organizationName">
                <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'หน่วยงาน' }}</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let objjob">
                    <div style="color: #002F87; text-align: center;" class="font-light">{{ objjob.organizationName }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="fiscalYear">
                <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'ปีงบประมาณ' }}</mat-header-cell>
                <mat-cell fxFlex="7" *matCellDef="let objjob">
                    <div *ngIf="objjob.fiscalYear!=0" style="color: #002F87;" class="font-light">{{ objjob.fiscalYear+543 }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="projectCode">
                <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-bold" *matHeaderCellDef>{{'รหัสโครงการ' }}</mat-header-cell>
                <mat-cell fxFlex="7" *matCellDef="let objjob">
                    <div style="color: #002F87; width: 100%; text-align: center;" class="font-light">{{ objjob.projectCode }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="projectName">
                <mat-header-cell style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'ชื่อโครงการ' }}</mat-header-cell>
                <mat-cell *matCellDef="let objjob">
                    <div style="color: #002F87;" class="font-light">{{ objjob.projectName }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="researcherFullName">
                <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'นักวิจัย' }}</mat-header-cell>
                <mat-cell fxFlex="10" *matCellDef="let objjob">
                    <div style="color: #002F87;" class="font-light">{{ objjob.researcherFullName }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="projectStatus">
                <mat-header-cell style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'สถานะโครงการ' }}</mat-header-cell>
                <mat-cell *matCellDef="let objjob">
                    <div style="color: #002F87;" class="font-light">{{ objjob.projectStatus }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="projectStartDate">
                <mat-header-cell fxFlex="9.5" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'วันที่อนุมัติโครงการ'}}</mat-header-cell>
                <mat-cell fxFlex="9.5" *matCellDef="let objjob">
                    <div style="color: #002F87; width: 100%; text-align: center;" class="font-light">{{ transformDate(objjob.projectStartDate)}}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="nextProjectProgressReportDate">
                <mat-header-cell fxFlex="12" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'วันที่ติดตามความก้าวหน้าครั้งถัดไป'}}</mat-header-cell>
                <mat-cell fxFlex="12" *matCellDef="let objjob">
                    <div style="color: #002F87;" class="font-light">{{ objjob.trackingTime }} {{objjob.trackingTime?'วันที่':''}} {{transformDate(objjob.nextProjectProgressReportDate)}}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="projectRequestDate">
                <mat-header-cell fxFlex="9.5" style="color: #002F87;" class="font-bold" *matHeaderCellDef mat-sort-header>{{'วันที่ยื่นคำขอ'}}</mat-header-cell>
                <mat-cell fxFlex="9.5" *matCellDef="let objjob">
                    <div style="color: #002F87;" class="font-light">{{ transformDate(objjob.projectRequestDate)}}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="isNewMessage">
                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef></mat-header-cell>
                <mat-cell fxFlex="3" *matCellDef="let objjob" style="text-align: center;">
                    <!-- <div style="color: #002F87;" class="font-light">{{ objjob.isNewMessage }}</div> -->
                    <img *ngIf="objjob.isNewMessage" style="max-height: 20px;   " src="assets\Icon\unread.png">
                    <!-- <img *ngIf="!objjob.isNewMessage" style="max-height: 20px;  " src="assets\Icon\read.png"> -->

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="istimer">
                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef></mat-header-cell>
                <mat-cell fxFlex="3" *matCellDef="let objjob" style="text-align: center;">

                    <img *ngIf="objjob.isShowTimerGreen" style="max-height: 20px;" src="assets\Icon\timerGreen.png">
                    <img *ngIf="objjob.isShowTimerYello" style="max-height: 20px;" src="assets\Icon\timerYello.png">
                    <img *ngIf="objjob.isShowTimerRed" style="max-height: 20px;" src="assets\Icon\timerRed.png">


                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></mat-row>
        </mat-table>
        <!-- (page)="onPaginateChange($event)" -->
        <mat-paginator class="font-light" [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list">
        </mat-paginator>
    </mat-card>

</div>
