import { projectstatusnext } from "./projectstatusnext";

export class projectstatus{
    id: number;
    orderNo: number;
    statusNameAdmin: string;
    statusDesc: string;
    nextStatus:projectstatusnext;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}