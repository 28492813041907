export class teammember{
    id: number;
    projectId: number;
    referResearcherId: number;
    orderNo:number;
    isIntenalResearch: boolean;
    researcherType: number;
    researcherFullName: string;
    nationalId:string;
    organizationId: number;
    organizationName: string;
    percentAssociate: number;
    eMail: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}