import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-project-number-by-org',
  templateUrl: './project-number-by-org.component.html',
  styleUrls: ['./project-number-by-org.component.scss']
})
export class ProjectNumberByOrgComponent implements OnInit {
lineChart: any = []; //ประกาศตัวแปรเก็บค่า
  constructor() { }

  ngOnInit(): void {
   
  }

}
