export class budgetdetail{
    id: number;
    projectBudgetHeadId: number;
    orderNo: number;
    budgetGroupId:number;
    budgetGroup: string;
    budgetCategoryId:number;
    budgetCategory: string;
    budgetDescription: string;
    budget: number;
    fiscalYear: number;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}