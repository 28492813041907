import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { organization } from 'src/app/datamodel/organization';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { IAPIResponse } from 'src/app/interfaces/apiResponse';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  displayName?:string,
  givenName?: string,
  surname?: string,
  jobTitle?:string,
  mobilePhone?:string,
  mail?:string,
  userPrincipalName?: string,
  id?: string
};
@Component({
  selector: 'app-main-data-detail',
  templateUrl: './main-data-detail.component.html',
  styleUrls: ['./main-data-detail.component.scss']
})
export class MainDataDetailComponent implements OnInit {

  dataRow: any = {}
  result: IAPIResponse;
  organizationAuthorizations: organizationAuthorization[];
  dataAffiation: any[];
  saveInProgress: boolean;
  closeSnackbarMessage: string = 'Close';
  isShowCreate: boolean = false;
  isDisabledOrderNo: boolean = false;
  profile!: ProfileType;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    private snackBar: MatSnackBar,
    public sdUtilityService: SDUtilityService,
    public dialog: MatDialog) {
    this.dataRow = JSON.parse(this.route.snapshot.paramMap.get('dataobj'));
    console.log("DataRow", this.dataRow);
    this.dataRow.updateDate = moment(this.dataRow.updateDate, "YYYY-MM-DDTHH:mm:ss").add('543','years').format("DD/MM/YYYY HH:mm");
    console.log("datetime", this.dataRow.updateDate);


  }


  ngOnInit(): void {
    if (this.dataRow.isNewForm == true) {
      this.isShowCreate = true;
    } else {
      this.isShowCreate = false;
      this.isDisabledOrderNo = true;

    }

    this.getUpdateBy();

    this.getOrganization();
  }

  getUpdateBy(){

    this.brokerAPIService.getProfile(GRAPH_ENDPOINT).subscribe(values=>{
      this.profile = values;
      console.log("this.profile",this.profile);
      if (this.dataRow.updateBy == this.profile.mail) {
        console.log("findData");
      this.dataRow.updateBy = this.profile.displayName;
      }
    });

  }

  getOrganization() {

    let callMethod: string = '/Master/GetOrganizationList';
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        console.log(values);

        this.dataAffiation = values;
        console.log("dataAffiation", this.dataAffiation);

      });
  }

  btnSave() {
    if (this.validate()) {
      this.setOrganization();
    }

  }

  getAffiation() {
    var dataAffiation = this.dataAffiation.find(x => x.id == this.dataRow.id);
    console.log("strAffiation", dataAffiation);
    return dataAffiation;
  }

  onSelectAffiation() {
    var data = this.getAffiation();
    console.log("dataAffiation", data);
    // this.getOrganization()
  }

  setOrganization() {

    var strDateTime = this.datetimeToday();
    var strAffiation = this.getAffiation();

    if (this.isShowCreate == true) {
      var strOrderNo = this.dataAffiation.find(x => x.orderNo == this.dataRow.orderNo);
      console.log("strOrderNo", strOrderNo);
      if (strOrderNo == undefined || strOrderNo.length == 0) {
        let objNewOrganization = {
          'orderNo': this.dataRow.orderNo,
          'organizationName': this.dataRow.organizationName,
          'affiliation': strAffiation.affiliation,
          'organizationShortName': this.dataRow.organizationShortName,
          'inActivated': this.dataRow.inActivated,
          'updateBy': 'admin',
          'updateDate': strDateTime

        }
        console.log("objOrganization", objNewOrganization);
        this.postDaaToAPI(objNewOrganization);
      } else {
        this.openDialog("ไม่สามารถบันทึกข้อมูล", "โปรดเช็ค OrderNoนี้มีในระบบแล้ว");
      }

    } else {

      let objOrganization = {
        'id': this.dataRow.id,
        'orderNo': this.dataRow.orderNo,
        'organizationName': this.dataRow.organizationName,
        'affiliation': this.dataRow.affiliation,
        'organizationShortName': this.dataRow.organizationShortName,
        'inActivated': this.dataRow.inActivated,
        'updateBy': 'admin',
        'updateDate': strDateTime
      }
      console.log("objOrganization", objOrganization);
      this.postDaaToAPI(objOrganization);


    }
  }

  postDaaToAPI(objOrg: any) {
    this.blockScreen();
    this.brokerAPIService.post('/Master/SaveOrganization', objOrg).subscribe(
      values => {
        this.result = values;
        this.unBlockScreen();
        if (this.result.success) {
          this.openSnackBar(this.result.message, this.closeSnackbarMessage);
        } else {
          this.openDialog("ไม่สามารถบันทึกข้อมูล", JSON.stringify(this.result.message));
        }
      },
      errors => {
        this.unBlockScreen();
        this.openDialog("ไม่สามารถบันทึกข้อมูล", JSON.stringify(errors));
      });
  }


  blockScreen() {
    this.saveInProgress = true;
    document.getElementById('overlay').style.display = 'block';
  }

  unBlockScreen() {
    this.saveInProgress = false;
    document.getElementById('overlay').style.display = 'none';
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  openDialog(dialogTitle: string, dialogMessage: string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      data: { title: dialogTitle, message: dialogMessage },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }


  btnClose() {
    let link = ['maindatalisting'];
    this.router.navigate(link);
  }

  // btnRemve() {

  //   var strDate = this.datetimeToday();

  //   if (this.isShowCreate == false) {

  //     let objDeleteOrganization = {
  //       'id': this.dataRow.id,
  //       'orderNo': this.dataRow.orderNo,
  //       'organizationName': this.dataRow.organizationName,
  //       'affiliation': this.dataRow.affiliation,
  //       'organizationShortName': this.dataRow.organizationShortName,
  //       'inActivated': this.dataRow.inActivated,
  //       'updateBy': 'admin',
  //       'updateDate': strDate
  //     }
  //     console.log("objDeleteOrganization", objDeleteOrganization);

  //     this.deleteData(objDeleteOrganization);
  //   }
  // }

  validate() {
    console.log("varlidate");
    var strAffiation = this.getAffiation();
    if (this.dataRow.organizationName == "") {
      this.openDialog("โปรดกรอกข้อมูล", "OrganizationName");
      console.log("VAROrganizationName");
      return false


    } else if (this.dataRow.orderNo == null || this.dataRow.orderNo == "") {
      this.openDialog("โปรดกรอกข้อมูล", "OrderNo");
      console.log("VAROrderNo");
      return false

    } else if (this.dataRow.organizationShortName == "") {
      this.openDialog("โปรดกรอกข้อมูล", "OrganizationShortName");
      console.log("VAOrganizationShortName");
      return false

    } else if (strAffiation.affiliation == "") {
      this.openDialog("โปรดกรอกข้อมูล", "Affiliation");
      console.log("VARAffiliation");
      return false

    } else {
      console.log("VRA Else");

    }
    return true;
  }

  datetimeToday() {
    const currentTime: Date = new Date();
    const year: number = currentTime.getUTCFullYear();
    const month: number = currentTime.getUTCMonth() + 1; // Month starts from 0
    const day: number = currentTime.getUTCDate();
    const hours: number = currentTime.getUTCHours();
    const minutes: number = currentTime.getUTCMinutes();
    const seconds: number = currentTime.getUTCSeconds();

    const pad = (num: number): string => (num < 10 ? `0${num}` : num.toString());
    const formattedTime: string = `${year}-${pad(month)}-${pad(day)}T${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;

    return formattedTime;
  }

  deleteData(objOrg: any) {
    this.blockScreen();
    this.brokerAPIService.post('/Master/DeleteOrganization', objOrg).subscribe(
      values => {
        this.result = values;
        this.unBlockScreen();
        if (this.result.success) {
          this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          let link = ['maindatalisting'];
          this.router.navigate(link);
        } else {
          this.openDialog("ไม่สามารถบันทึกข้อมูล", JSON.stringify(this.result.message));
        }
      },
      errors => {
        this.unBlockScreen();
        this.openDialog("ไม่สามารถบันทึกข้อมูล", JSON.stringify(errors));
      });
  }



}

export class organizationAuthorization extends organization {
  isSelected: boolean;
}
