import { attachdocument } from "./attachdocument";

export class projectattachdocument{
    id: number;
    projectId: number;
    orderNo: number;
    attachDocumentId: number;
    attachDocument: attachdocument;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}