import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';

@Component({
  selector: 'app-report-project-meeting-progress',
  templateUrl: './report-project-meeting-progress.component.html',
  styleUrls: ['./report-project-meeting-progress.component.scss']
})
export class ReportProjectMeetingProgressComponent implements OnInit {

  dataProjectTracking: any = [];
  errorMessage:string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService:BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    public sdUtilityService: SDUtilityService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.GetProjectTracking()
  }

  GetProjectTracking() {

    let callMethod:string='/Report/GetProjectTrackingCloseToDueDateReport' ;
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        console.log(values);
        this.dataProjectTracking = values;
        // this.dataSourceSysuserRoll.data = values;
        // values.forEach(objGetUser => {
        //   console.log(objGetUser);
  
        //   // this.dataSysuserRoll.push({
        //   //   email:objGetUser.email
        //   })
          
        // });
  
        // this.dataSourceSysuserRoll.data = this.dataSysuserRoll;
  
  
        // console.log(this.dataSourceSysuserRoll.data);
        
      },
      error =>{
         this.errorMessage = <any>error;
         console.log('ProjectList:'+JSON.stringify(this.errorMessage));
      });
  }

}
