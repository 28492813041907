import { attachdocument } from "./attachdocument";

export class revieweddocumentdecision{
    id: number;
    projectId: number;
    messageDate: Date;
    messageFromId: number;
    messageToId: number;
    decisionDate:Date;
    subject: string;
    message: string;
    isRead: boolean;
    isReply: boolean;
    attachment1Id: number;
    attachment1: attachdocument;
    attachment2Id: number;
    attachment2: attachdocument;
    attachment3Id: number;
    attachment3: attachdocument;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
    optionNo: number;
    conclusionMessage: string;
}
