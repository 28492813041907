<div class="container">
    <mat-toolbar color="primary">
        <div [ngSwitch]="data.Messagetype">
            <mat-icon *ngSwitchCase="'Error'" class="iconsize">info</mat-icon>
            <mat-icon *ngSwitchCase="'Complete'" class="iconsize">info</mat-icon>
            <mat-icon *ngSwitchCase="'Validate'" class="iconsize">info</mat-icon>
        </div>
        {{ data.Messagetitle | translate}}
    </mat-toolbar>
    <br />

    <h3 class="textbody" mat-dialog-title>{{ data.Messagebody | translate }}</h3>

    <table style="width: 100%;">
        <tr>
            <td style="width: 20%"></td>
            <td>
                <button mat-raised-button color="warn" (click)="onClickConfirm()">
          {{'Confirm' | translate}}
        </button>
            </td>
            <td style="width: 10%"></td>
            <td>
                <button mat-raised-button (click)="onClickCancle()" tabindex="-1">
          {{'Cancel' | translate}}
        </button>
            </td>
            <td style="width: 20%"></td>
        </tr>
    </table>
</div>