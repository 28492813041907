import { Component, OnInit, ViewChild } from '@angular/core';
// import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { attachdocument } from 'src/app/datamodel/attachdocument';
import { researcher } from 'src/app/datamodel/researcher';
import { IAPIResponse } from 'src/app/interfaces/apiResponse';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateAdapter } from '@angular/material/core';
// import { FormControl } from '@angular/forms';
import {educationhistory } from 'src/app/datamodel/educationhistory';
import { workexperiencehistory } from 'src/app/datamodel/workexperiencehistory';
import { seminarhistory } from 'src/app/datamodel/seminarhistory';
import { presentwork } from 'src/app/datamodel/presentwork';
import { researcherattachdocument } from 'src/app/datamodel/researcherattachdocument';
import { enumdata } from 'src/app/datamodel/enumdata';
import { publication } from 'src/app/datamodel/publication';
import { base64ToBlob,  sortingArrayList } from 'src/app/services/general-function';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@Component({
  selector: 'app-researcher-edit',
  templateUrl: './researcher-edit.component.html',
  styleUrls: ['./researcher-edit.component.scss'],

})
export class ResearcherEditComponent implements OnInit {
  @ViewChild('hBSort') hBSort: MatSort;
  @ViewChild('sBSort') sBSort: MatSort;

  emailError:string="รูปแบบอีเมล์ไม่ถูกต้อง";
  
  private dialog: MatDialog;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15',
    minHeight: '15',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    // fonts: [
    //   {class: 'arial', name: 'Arial'},
    //   {class: 'times-new-roman', name: 'Times New Roman'},
    //   {class: 'calibri', name: 'Calibri'},
    //   {class: 'comic-sans-ms', name: 'Comic Sans MS'},
    //   {class: 'chulabhornlikittext-regular', name: 'chulabhornlikittext'}
    // ],
    defaultFontName: 'Arial',
    sanitize:false,
    toolbarHiddenButtons: [
      [],[
      'backgroundColor',
      
      'link',
      'unlink',
      'insertVideo',]
      ],
  };
  // private data:[researcher];
  isEditable: boolean;
  isNotEditable:boolean;
  isShowbtnSave: boolean;
  isShowTabeducation: boolean;
  ishowtabattach: boolean;

  ishowtabSeminar: boolean;
  ishowtabWorkExp: boolean;
  isShowFieldInput: boolean;
  i: string;
  keeporderNo: any = [];

  researcherID:string;

  // date1 = new FormControl(new Date());
  locationType = [{ 'desc': 'ภายในประเทศ', 'id': 1 }, { 'desc': 'ต่างประเทศ', 'id': 2 }];

  disabledTab: boolean;
  dataBase64: any;
  researcherData: researcher = new researcher;
  objUpdatefile: any = {};
 
  closeSnackbarMessage: string = 'Close';
  attachedFileName:string;
  attachedFileType:string;
  attachedFileMimeType:string;
  // publishedWork: string;
  Reward: string;
  organizationMember: string;
  private errorMessage: string;
  datarResult02: any = {};
  keepResult: number;
  result: IAPIResponse;
  result02: IAPIResponse;
  id: number;
  Filename: string;
  arrfileName: string[];
  startYear: string;
  endYear: string;
  position: string;
  agency: string;
  country: string;
  KeepFileName: any = [];
  fileUpload: any = [];
  lineNo: string;
  saveInProgress:boolean;

  
  //educationHists
  ishowtabeducationHists: boolean = false;
  beginEduYear: number;
  endEduYear: number;
  eduQualification: string;
  fieldOfStudy: string;
  eduInstitution: string;
  eduCountry: string;
  arreducation = [];
  dataeducation = [];
  dataOrderNoeducation = [];

  

  //workExpHists
  // ishowtabWorkExpHists: boolean = false;
  // beginWorkYear: number;
  // endWorkYear: number;
  // workPosition: string;
  // workPlace: string;
  // workCountry: string
  // arrworkExpHists: any = [];
  // dataOrderNoworkExpHists = [];

  //seminarHists
  ishowtabSeminarHists: boolean = false;
  year: number;
  yearpresent: number;
  seminarSubject: string;
  organizerName: string;
  // arrseminarHists: any = []; //
  arrdataTestSemi: any = [];
  dataOrderNoseminarHists = [];

  //presentWorks

  presentWorkName: string
  eventName: string;
  presentLocation: string;
  arrpresentWorks: any = [];
  dataOrderNopresentWorks = [];

  //attachDocuments
  ishowtabattachDocuments: boolean = false;
  documentType: string;
  documentName: string;
  fileTypeList: [enumdata];
  fileTypeListToShow: [enumdata];
  degreeList:[enumdata];
  // arrattachDocuments: any = [];

  objAPIResponse: any = {};
  selectedTabIndex: any;
  eventselectedTabIndex: any;
  isShowPresentations: boolean = false;
  isShowAttachment: boolean = false;
  isShowTrainingHistory: boolean = false;
  isshowWorkExperience: boolean = false;
  isShowEducationalRecord: boolean = false;
  isshowEditorRewordStatus: boolean;
  isshowEditorOverayOrganizationMemberStatus: boolean;
  editorPublishedWorks: string;
  editorProfessionalOrganizationMember: string
  editorReward
  LengthReworg: number;
  lengOverayReword: number;
  LengthOrganizationMember: number
  lengOverayOrganizationMember: number

  dataSourceEducationalRecord = new MatTableDataSource();
  displayedColumnsEducationalRecord = ['Control', 'lineNo', 'beginEduYear', 'endEduYear', 'eduQualification','qualification', 'fieldOfStudy', 'eduInstitution', 'eduCountry'];


  dataSourceWorkExperience = new MatTableDataSource();
  displayedColumnsWorkExperience = ['Control', 'lineNo', 'isStillWorking','strBeginWorkYear', 'strEndWorkYear', 'workPosition', 'workPlace', 'workCountry'];


  dataSourceSeminar = new MatTableDataSource();
  displayedColumnsSeminarHists = ['Control', 'lineNo', 'year', 'seminarSubject', 'organizerName'];


  dataSourcePresentations = new MatTableDataSource();
  displayedColumnsPresentations = ['Control', 'lineNo', 'presentDate', 'presentWorkName', 'eventName','presentLevel', 'presentLocation','attachDocument'];

  dataSourcePublications = new MatTableDataSource();
  displayedColumnsPublications = ['Control', 'lineNo', 'publishYear', 'publishWorkName', 'journalName','journalLevel', 'impactFactor','quartileTCI_Index','paticipation','attachDocument'];
  
  dataSourceAttachment = new MatTableDataSource();
  displayedColumnsAttachment = ['Control', 'lineNo', 'documentType', 'documentName',];

  journalLeveList = [{"id":1,"description":"ระดับชาติ"},{"id":2,"description":"ระดับนานาชาติ"}];
  participationList = [{"id":1,"description":"First Authur"},{"id":2,"description":"Corresponding Authur"},{"id":3,"description":"Co-Investigator"}];
  quartileList = [{"id":0,"description":"ไม่ระบุ"},{"id":1,"description":"Q1"},{"id":2,"description":"Q2"},{"id":3,"description":"Q3"},{"id":4,"description":"Q4"}];
  tciTierList = [{"id":0,"description":"ไม่ระบุ"},{"id":1,"description":"TCI Tier 1"},{"id":2,"description":"TCI Tier 2"},{"id":3,"description":"TCI Tier 3"}];



  // dataSource = new MatTableDataSource();
  // displayedColumns = ['Control', 'lineNo', 'year', 'seminarSubject', 'organizerName'];

  isEducationHistsValid: boolean = false;
  isWorkExpHistsValid: boolean = false;
  isSeminarHistsValid: boolean = false;
  isRewardValid: boolean = false;
  isOrganizationMemberValid: boolean = false;
  isPresentWorksValid: boolean = false;
  isexternalPublishedWorksValid: boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    // public sdUtilityService: SDUtilityService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.getDegreeList();
    this.getFileTypeList();
    this.researcherID=JSON.parse(this.route.snapshot.paramMap.get('dataobj')).param;
    console.log("showresearcher",this.researcherID);
    this.dateAdapter.setLocale('th-TH');  
    let callMethod: string = '/Auth/IsEditableResearch/' + this.researcherID;
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        this.isEditable = values.isEditable;
        this.isNotEditable = !this.isEditable;
        if (this.isEditable == true) {
          this.isShowbtnSave = true;
          this.isShowTabeducation = true;
          this.ishowtabattach = true;
          this.ishowtabSeminar = true;
          this.ishowtabWorkExp = true;
          // this.disabledTab = false;
          this.isShowFieldInput = true;

          // console.log("Aa");

        } else if (this.isEditable == false) {

          this.isShowbtnSave = false;
          this.isShowTabeducation = false;
          this.ishowtabattach = false;
          this.ishowtabSeminar = false;
          this.ishowtabWorkExp = false;
          // this.disabledTab = true;
          this.isShowFieldInput = false;
          // console.log("BB");
        }
      },
      error => this.errorMessage = <any>error
    );

  }


  ngOnInit(): void {
    this.selectedTabIndex = 0;
    if (this.selectedTabIndex == 0) {
      this.isShowEducationalRecord = true;
      this.isshowWorkExperience = false;
      this.isShowTrainingHistory = false;
      this.isShowAttachment = false;
      this.isShowPresentations = false;
    }
    this.getResearcher();
  }

  getResearcher() {
    this.brokerAPIService.get('/Researcher/GetResearcherProfile/' + this.researcherID).subscribe(
      values => {
        this.researcherData = values;
        console.log("getResearcher",JSON.stringify( this.researcherData));
        this.dataSourceEducationalRecord.data = this.researcherData.educationHists;
        this.dataSourceWorkExperience.data = this.researcherData.workExpHists;
        this.addIsStillWorking();
        this.dataSourceSeminar.data = this.researcherData.seminarHists;     
        
        if( this.researcherData.presentWorks.length && this.researcherData.presentWorks.length > 0){
          
        this.dataSourcePresentations.data = sortingArrayList( this.researcherData.presentWorks,'attachDocumentId','ASC');
        } else {
          
          this.dataSourcePresentations.data = this.researcherData.presentWorks;
        }
        this.dataSourceAttachment.data = this.researcherData.attachDocuments;
        this.Reward = this.researcherData.reward;
        this.organizationMember = this.researcherData.organizationMember;
        this.checkDataInTab()
        if(this.researcherData.birthDate.toString()=='0001-01-01T00:00:00'||!this.researcherData.birthDate){
          this.researcherData.birthDate = new Date();
        }
        // this.publishedWork = this.researcherData.publishedWork;
        // if(!this.researcherData.birthDate){
        //   this.researcherData.birthDate = new Date;
        // }
        if(this.researcherData.externalPublishedWorks){
          this.dataSourcePublications.data = this.researcherData.externalPublishedWorks;
        }
      },
      error => {

      }
    )
  }

  async getDegreeList(){
    let url:string = "/Master/GetEducationList";
    this.brokerAPIService.getAwait(url,null ,null).then(
      values=>{
        this.degreeList = values;
      },
      error=>{
        console.log("eror:",error);
      }
    )
  }

  getFileTypeList(){
    let url:string  = "/Master/GetResearcherAttachFileTypeList";
    this.brokerAPIService.get(url).subscribe(
      values=>{
        this.fileTypeList = values;
        this.fileTypeListToShow = this.fileTypeList;
        // this.attachedFileType = this.fileTypeList[0].description;
      },
      error=>{
        console.log("eror:",error);
      }
    )
  }



  btnBlack() {

  }

  btnPrint() {
    // let link = ['reportcv'];researcherData

    // this.router.navigate(link);
    let researchID = {'param':this.researcherData.id}
    this.router.navigate(['/reportcv',JSON.stringify(researchID)]);
    
    
  }

  validate() {
    if (this.LengthReworg > 1000) {
      this.isshowEditorRewordStatus = true;
      return false;
    }
    

    if (this.LengthOrganizationMember > 1000) {
      this.isshowEditorOverayOrganizationMemberStatus = true;
      return false;
    }
    return true;
  }

  async btnSave() {
    
    console.log(this.researcherData);
    if(this.validate()){
      console.log("Save");
      
    this.blockScreen();
    this.checkRequiredFields(this.researcherData.faxNo);

    this.researcherData.reward = this.sanitizer.bypassSecurityTrustHtml(this.Reward)['changingThisBreaksApplicationSecurity'];
    // this.researcherData.reward = this.Reward;
    this.researcherData.organizationMember = this.sanitizer.bypassSecurityTrustStyle(this.organizationMember)['changingThisBreaksApplicationSecurity'];
    // this.researcherData.organizationMember = this.organizationMember;
    // this.researcherData.publishedWork = this.sanitizer.bypassSecurityTrustHtml(this.publishedWork)['changingThisBreaksApplicationSecurity'];
    this.researcherData.birthDate = this.offsetTimeZone(this.researcherData.birthDate);
    if(this.researcherData.externalPublishedWorks){
      this.reOrderNo(this.researcherData.externalPublishedWorks);
    }

    if(this.researcherData.presentWorks){
      this.reOrderNo(this.researcherData.presentWorks);
    }
    this.checkRequiredFields(this.researcherData.faxNo);
    
    for(let i = 0;i< this.researcherData.attachDocuments.length; i++){
      this.researcherData.attachDocuments[i].attachDocument=null;
    }

    console.log("datatosave",JSON.stringify(this.researcherData));

    
    
    this.brokerAPIService.post('/Researcher/SaveResearcher', JSON.stringify(this.researcherData)).subscribe(
      values => {
        this.result = values;
        
        
        if(this.result.success){
          this.getResearcher();
          this.openSnackBar(this.result.message, this.closeSnackbarMessage);
        } else {
          this.openSnackBar("ไม่สามารถบันทึกข้อมูลได้",this.result.message);
        }
        this.unBlockScreen();
      },
      error => {
        this.unBlockScreen();
        this.errorMessage = <any>error;
        this.openSnackBar(this.errorMessage, this.closeSnackbarMessage);
      }
    );

    }

  }

  offsetTimeZone(inputDate):Date{
    let x:Date = new Date(inputDate);
    let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
    let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
    x.setHours(hoursDiff);
    x.setMinutes(minutesDiff);
    return x;
  }

  checkRequiredFields(input) {
    console.log(input == "");
    if (input === "") {


      throw new Error("Attribute 'a' is required");
    }

  }



  // ประวัติการศึกษา

  onclickAddEducation() {
    let educationHistoryRow = new educationhistory;
    educationHistoryRow.researcherId = this.researcherData.id;
    if(this.researcherData.educationHists==null){
      this.researcherData.educationHists = [educationHistoryRow];
    } else {
      this.researcherData.educationHists.push(educationHistoryRow);
    }
    this.dataSourceEducationalRecord.data = this.researcherData.educationHists;
    this.checkDataInTab();
  }
  

  educationDelete(rowId: number) {
    this.researcherData.educationHists.splice(rowId,1);
    this.dataSourceEducationalRecord.data = this.researcherData.educationHists;
    this.checkDataInTab();
  }


  //ประสบการณ์การทำงาน

  onclickAddworkExpHists() {
    let workExperienceRow = new workexperiencehistory;
    workExperienceRow.researcherId = this.researcherData.id;
    if(this.researcherData.workExpHists==null){
      this.researcherData.workExpHists = [workExperienceRow];
    } else {
      this.researcherData.workExpHists.push(workExperienceRow);
    }
    this.dataSourceWorkExperience.data = this.researcherData.workExpHists;

    console.log(this.dataSourceWorkExperience.data);
    
    this.checkDataInTab();
  }
  
  workExpHistsDelete(rowID: number) {
    this.researcherData.workExpHists.splice(rowID,1);
    this.dataSourceWorkExperience.data = this.researcherData.workExpHists;
    this.checkDataInTab();
    
  }

  addIsStillWorking(){
    for(let i=0;i<this.dataSourceWorkExperience.data.length;i++){
      if(this.dataSourceWorkExperience.data[i]['strEndWorkYear']=='ยังทำอยู่ในปัจจุบัน'){
        this.dataSourceWorkExperience.data[i]['isStillWorking']=true;
      } else {
        this.dataSourceWorkExperience.data[i]['isStillWorking']=false;
      }
      console.log("isworkign",this.dataSourceWorkExperience.data[i]['isStillWorking']);
    }
  }

  onIsStillWorkingChange(rowID:number){
    if(this.dataSourceWorkExperience.data[rowID]['isStillWorking']){
      this.researcherData.workExpHists[rowID]['strEndWorkYear']='ยังทำอยู่ในปัจจุบัน';
    } else {
      this.researcherData.workExpHists[rowID]['strEndWorkYear']='';
    }
    this.dataSourceWorkExperience.data = this.researcherData.workExpHists;
    this.addIsStillWorking();
  }

  //ประวัติการอบรม/ดูงาน/สัมนา
  onclickAddseminarHists() {
    let seminarHistoryRow = new seminarhistory;
    seminarHistoryRow.researcherId = this.researcherData.id;
    if(this.researcherData.seminarHists==null){
      this.researcherData.seminarHists =[seminarHistoryRow];
    } else {
      this.researcherData.seminarHists.push(seminarHistoryRow);
    }
    this.dataSourceSeminar.data = this.researcherData.seminarHists;
    this.checkDataInTab();
  }

  seminarHistsDelete(rowID: number) {
    this.researcherData.seminarHists.splice(rowID,1);
    this.dataSourceSeminar.data = this.researcherData.seminarHists;
    this.checkDataInTab();
  }

  
  // การนำเสนอผลงาน
  onclickAddpresentWorks() {
    let presentWorkRow = new presentwork;
    presentWorkRow.researcherId = this.researcherData.id;
    presentWorkRow.presentDate = new Date();
    if(! presentWorkRow.attachDocument){
      presentWorkRow.attachDocument = new attachdocument;
    }
    presentWorkRow.attachDocument.documentName="แนบไฟล์";
    if(this.researcherData.presentWorks==null){
      this.researcherData.presentWorks = [presentWorkRow];
    } else {
      this.researcherData.presentWorks.push(presentWorkRow);
    }
    this.dataSourcePresentations.data = this.researcherData.presentWorks;
    this.checkDataInTab();
  }

  presentWorksDelete(rowID: number) {
    this.researcherData.presentWorks.splice(rowID,1);
    this.dataSourcePresentations.data = this.researcherData.presentWorks;
    this.checkDataInTab();
  }

  async handleFilePresentWork(files: FileList,rowID:number) {
    if (files && files.length > 0) {
      let file: File = files.item(0);     
      const result = await this.getBase64(files[0]).catch(e => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        this.openSnackBar(result.message,this.closeSnackbarMessage);
        return;
      }
      this.dataBase64 = result.toString();
      console.log("attach "+rowID,this.dataBase64);
      let attachedDocument = new attachdocument;
      attachedDocument.documentType = file.type;
      attachedDocument.documentName = file.name;
      attachedDocument.documentContent = this.dataBase64;
      attachedDocument.mimeType = file.type;
      this.PostFilePresentWork(attachedDocument,rowID)
    } 
  }

  PostFilePresentWork(docToUpload:attachdocument,rowID:number) {
    this.brokerAPIService.post('/DocumentUpload/UploadDocument',JSON.stringify(docToUpload)).subscribe(
      values =>{
        this.result =  values;        
        this.researcherData.presentWorks[rowID].attachDocumentId= +this.result.data;       
        this.researcherData.presentWorks[rowID].attachDocument = docToUpload;
        this.dataSourcePresentations.data = this.researcherData.presentWorks;
        console.log("showpresentwork",JSON.stringify(this.researcherData.presentWorks))
      },
      error => {
        this.errorMessage = <any>error;
        console.log("errorMessage:"+JSON.stringify(this.errorMessage));
      }
    );
 
  }

  //การตีพิมพ์
  onclickAddPublications() {
    let publicationRow = new publication;
    publicationRow.journalLevel = 1;
    publicationRow.researcherId = this.researcherData.id;

    
    if(! publicationRow.attachDocument){
      publicationRow.attachDocument = new attachdocument;
    }
    publicationRow.attachDocument.documentName="แนบไฟล์";
    if(this.researcherData.externalPublishedWorks==null){
      this.researcherData.externalPublishedWorks = [publicationRow];
    } else {
      this.researcherData.externalPublishedWorks.push(publicationRow);
    }
    this.dataSourcePublications.data = this.researcherData.externalPublishedWorks;
    this.checkDataInTab();
  }

  onclickDeletePublications(rowID:number){
    this.researcherData.externalPublishedWorks.splice(rowID,1);
    this.dataSourcePublications.data = this.researcherData.externalPublishedWorks;
    this.checkDataInTab();
  }

  async handleFilePublication(files: FileList,rowID:number) {
    if (files && files.length > 0) {
      let file: File = files.item(0);     
      const result = await this.getBase64(files[0]).catch(e => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        this.openSnackBar(result.message,this.closeSnackbarMessage);
        return;
      }
      this.dataBase64 = result.toString();
      console.log("attach "+rowID,this.dataBase64);
      let attachedDocument = new attachdocument;
      attachedDocument.documentType = file.type;
      attachedDocument.documentName = file.name;
      attachedDocument.documentContent = this.dataBase64;
      attachedDocument.mimeType = file.type;
      this.PostFilePublicationData(attachedDocument,rowID)
    } 
  }

  PostFilePublicationData(docToUpload:attachdocument,rowID:number) {
    this.brokerAPIService.post('/DocumentUpload/UploadDocument',JSON.stringify(docToUpload)).subscribe(
      values =>{
        this.result =  values;        
        this.researcherData.externalPublishedWorks[rowID].attachDocumentId= +this.result.data;       
        this.researcherData.externalPublishedWorks[rowID].attachDocument = docToUpload;
        this.dataSourcePublications.data = this.researcherData.externalPublishedWorks;
      },
      error => {
        this.errorMessage = <any>error;
        console.log("errorMessage:"+JSON.stringify(this.errorMessage));
      }
    );
 
  }

  //เอกสารแนบ
  onclickAddattachDocuments() {
    this.ishowtabattachDocuments = true;
  }


  btnSaveattachDocuments() {
    let errorMessage:string='';
    if(this.attachedFileType== null || this.attachedFileType=='' ){
      errorMessage ='ประเภทเอกสาร';
    }
    if(this.attachedFileName== null || this.attachedFileName==''){
      if(errorMessage==''){
        errorMessage ='ไฟล์ที่ต้องการแนบ';
      } else {
        errorMessage =errorMessage+'และไฟล์ที่ต้องการแนบ';
      }
    }
    if(errorMessage!=''){
      this.openSnackBar('กรุณาใส่'+errorMessage,this.closeSnackbarMessage);
      errorMessage = '';
      return;
    }
    let attachedDocument = new attachdocument;
    attachedDocument.documentType= this.attachedFileType;
    attachedDocument.documentName= this.attachedFileName;
    this.attachedFileType = '';
    this.attachedFileName = '';
    attachedDocument.documentContent= this.dataBase64;
    attachedDocument.mimeType = this.attachedFileMimeType;
    this.PostFileData(attachedDocument);
  }

  PostFileData(docToUpload:attachdocument) {
    this.brokerAPIService.post('/DocumentUpload/UploadDocument',JSON.stringify(docToUpload)).subscribe(
      values =>{
        this.result =  values;
        let researcherAttachdocumentRow = new researcherattachdocument;
        researcherAttachdocumentRow.researcherId = this.researcherData.id;
        researcherAttachdocumentRow.attachDocumentId = +this.result.data;
        docToUpload.id = +this.result.data;
        docToUpload.documentContent = null;
        researcherAttachdocumentRow.attachDocument = docToUpload;
        if(this.researcherData.attachDocuments==null){
          this.researcherData.attachDocuments = [researcherAttachdocumentRow];
        } else {
          this.researcherData.attachDocuments.push(researcherAttachdocumentRow);
        }
        this.dataSourceAttachment.data = this.researcherData.attachDocuments;

      },
      error => {
        this.errorMessage = <any>error;
        console.log("errorMessage:"+JSON.stringify(this.errorMessage));
      }
    );
 
  }

  checkDataInTab() {
    console.log("checkDataInTab");
    
    if (
      this.researcherData.educationHists &&
      this.researcherData.educationHists.length > 0
    ) {
      this.isEducationHistsValid = true;

    } else {
      this.isEducationHistsValid = false;

    }

    if (
      this.researcherData.workExpHists &&
      this.researcherData.workExpHists.length > 0
    ) {
      this.isWorkExpHistsValid = true;

    } else {
      this.isWorkExpHistsValid = false;

    }


    if (
      this.researcherData.seminarHists &&
      this.researcherData.seminarHists.length > 0
    ) {
      this.isSeminarHistsValid = true;

    } else {
      this.isSeminarHistsValid = false;

    }

    if (this.Reward != null) {
      this.isRewardValid = true;
    } else {
      this.isRewardValid = false;
    }
// console.log(this.organizationMember);
//  if (this.organizationMember == ""){
//    console.log("AA");
   
//  } else if (this.organizationMember == undefined) {
//    console.log("BB");
   
//  } else {
//    console.log("cc");
   
//  }


    if (this.organizationMember != "" ) {

      if (this.organizationMember != null) {

        if (this.organizationMember != undefined) {

          this.isOrganizationMemberValid = true;
        }

      }

    } else  {
      this.isOrganizationMemberValid = false;
    }
    

    if (
      this.researcherData.presentWorks &&
      this.researcherData.presentWorks.length > 0
    ) {
      this.isPresentWorksValid = true;

    } else {
      this.isPresentWorksValid = false;

    }

    if (
      this.researcherData.externalPublishedWorks &&
      this.researcherData.externalPublishedWorks.length > 0
    ) {
      this.isexternalPublishedWorksValid = true;

    } else {
      this.isexternalPublishedWorksValid = false;

    }
    
    
    
  }

  onEditorReward(editorReward: string) {
   
    const HTMLInner =  editorReward.replace(/<.*?>/g, '');
    this.LengthReworg = HTMLInner.length;
    console.log("RewardLength",this.LengthReworg);
    
    if (this.LengthReworg > 999) {
      // this.isshowEditorRewordStatus = true;
      this.lengOverayReword = this.LengthReworg - 1000;
      console.log("length>1000", this.lengOverayReword);
      
    }

    if (this.Reward == undefined) {

    } else if (this.Reward != undefined) {

      this.isRewardValid = true;
    }
  }

  btnCloseProjectStatusUpdate() {
    this.isshowEditorRewordStatus = false;
  }


  onclickReward(editorReward: string) {
    // console.log(editorReward);
    if (editorReward == "") {
      this.isRewardValid = false;
    }
    
  }

  onEditorOrganizationMember(editorOrganizationMember: string) {

    const HTMLInner =  editorOrganizationMember.replace(/<.*?>/g, '');
    this.LengthOrganizationMember = HTMLInner.length;
    console.log("OrganizationLength",this.LengthOrganizationMember);
    
    if (this.LengthOrganizationMember > 999) {
      // this.isshowEditorRewordStatus = true;
      this.lengOverayOrganizationMember = this.LengthOrganizationMember - 1000;
      console.log("length>1000", this.lengOverayOrganizationMember);
      
    }
    if (this.organizationMember == undefined) {

    } else if (this.organizationMember != undefined) {

      this.isOrganizationMemberValid = true;
    }
  
  }

  btnCloseProjectStatusUpdateOrg() {
    this.isshowEditorOverayOrganizationMemberStatus = false;
  }

  onclickOrganizationMember(editorOrganizationMember: string) {
    if (editorOrganizationMember == "") {
      this.isOrganizationMemberValid = false;
    }
  }
   

  GetFileData(id: any) {
    console.log("Bb", this.researcherData);

    if (id != "" || id != undefined) {

      let callMethod: string = '/DocumentUpload/GetDocument/' + id;
      this.brokerAPIService.get(callMethod).subscribe(
        values => {
          this.result02 = values;
          console.log("GetDocument", this.result02);


          this.datarResult02 = this.result02;
        },
        error => this.errorMessage = <any>error
      );

    }
    


  }

 

  btnCloseattachDocuments() {
    this.ishowtabattachDocuments = false;
  }

  attachDocumentsDelete(rowID: number) {
    console.log("rowid:",rowID);
    console.log("olddata:",JSON.stringify( this.researcherData.attachDocuments));
    this.researcherData.attachDocuments.splice(rowID,1);
    console.log("newdata:",JSON.stringify( this.researcherData.attachDocuments));
    this.dataSourceAttachment.data =  this.researcherData.attachDocuments;
  }


  async handleFileInput(files: FileList) {
    if (files && files.length > 0) {
      let file: File = files.item(0);      
      this.attachedFileName = file.name;
      this.attachedFileMimeType = file.type;

      if (files && files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          let file: File = files[i];
          const result = await this.getBase64(files[i]).catch(e => e);
          if (result instanceof Error) {
            console.log('Error: ', result.message);
            return;
          }
          this.dataBase64 = result.toString();
        }

      }
    } 
  }

  

  onTabChanged($event) {
    let clickedIndex = $event.index;
    this.eventselectedTabIndex = $event.index;
    console.log("selectedTabIndex", this.selectedTabIndex);
    console.log("clickedIndex", clickedIndex);

    if (this.selectedTabIndex == 0) {

      this.isShowEducationalRecord = true;
      this.isshowWorkExperience = false;
      this.isShowTrainingHistory = false;
      this.isShowAttachment = false;
      this.isShowPresentations = false;
    } else if (clickedIndex == 1) {

      this.isShowEducationalRecord = false;
      this.isshowWorkExperience = true;
      this.isShowTrainingHistory = false;
      this.isShowAttachment = false;
      this.isShowPresentations = false;
    } else if (clickedIndex == 2) {
      this.isShowEducationalRecord = false;
      this.isshowWorkExperience = false;
      this.isShowTrainingHistory = true;
      this.isShowAttachment = false;
      this.isShowPresentations = false;
    } else if (clickedIndex == 5) {
      this.isShowEducationalRecord = false;
      this.isshowWorkExperience = false;
      this.isShowTrainingHistory = false;
      this.isShowAttachment = true;
      this.isShowPresentations = false;
    } else if (clickedIndex == 7) {
      this.isShowEducationalRecord = false;
      this.isshowWorkExperience = false;
      this.isShowTrainingHistory = false;
      this.isShowAttachment = false;
      this.isShowPresentations = true;
    }
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = error => reject(error);
    });
  }

  btnNext() {

    this.selectedTabIndex = 1;

    if (this.eventselectedTabIndex == 1) {
      this.selectedTabIndex = 2;
    } else if (this.eventselectedTabIndex == 2) {
      this.selectedTabIndex = 3;

    } else if (this.eventselectedTabIndex == 3) {
      this.selectedTabIndex = 4;

    } else if (this.eventselectedTabIndex == 4) {
      this.selectedTabIndex = 5;

    } else if (this.eventselectedTabIndex == 5) {
      this.selectedTabIndex = 6;

    } else if (this.eventselectedTabIndex == 6) {
      this.selectedTabIndex = 7;

    } else if (this.eventselectedTabIndex == 7) {
      this.selectedTabIndex = 8;

    }


  }

  btnPrevious() {

    if (this.eventselectedTabIndex == 1) {
      this.selectedTabIndex = 0;
    } else if (this.eventselectedTabIndex == 2) {
      this.selectedTabIndex = 1;
    } else if (this.eventselectedTabIndex == 3) {
      this.selectedTabIndex = 2;

    } else if (this.eventselectedTabIndex == 4) {
      this.selectedTabIndex = 3;

    } else if (this.eventselectedTabIndex == 5) {
      this.selectedTabIndex = 4;

    } else if (this.eventselectedTabIndex == 6) {
      this.selectedTabIndex = 5;

    } else if (this.eventselectedTabIndex == 7) {
      this.selectedTabIndex = 6;

    } else if (this.eventselectedTabIndex == 8) {
      this.selectedTabIndex = 7;

    }

  }

  btnTest() {

    this.researcherData.reward = this.sanitizer.bypassSecurityTrustHtml(this.researcherData.reward)['changingThisBreaksApplicationSecurity'];
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  // onclickBack() {
  //   let link = ['researcherlist'];
  //   this.router.navigate(link);
  // }

  blockScreen(){
    this.saveInProgress = true;     
    document.getElementById("overlay").style.display = "block";
  }

  unBlockScreen(){
    this.saveInProgress = false;
        document.getElementById("overlay").style.display = "none";
  }
  onDocumentTypeInput(searchName:string){

    this._filterAttachedFileType(searchName);
  }

  private _filterAttachedFileType(value: string){
    
    if(!value){
      this.fileTypeListToShow=this.fileTypeList;
      return;
    }
    const filterValue = value.toLowerCase();
    let tempList:[enumdata];
    for(let i=0 ; i< this.fileTypeList.length;i++){
      console.log(filterValue+"<<>>"+this.fileTypeList[i].description);
      if(this.fileTypeList[i].description.includes(filterValue)){
        let tempRow = new enumdata;
        tempRow.id = this.fileTypeList[i].id;
        tempRow.description = this.fileTypeList[i].description;
        if(tempList){
          tempList.push(tempRow);
        } else {
          tempList=[tempRow];
        }
      }
    }
    
    this.fileTypeListToShow = tempList;
    // return Array.from(this.projectAttachFileTypeList.values()).filter(option => option['description'].toLowerCase().includes(filterValue)||option['description'].toLowerCase().includes(filterValue));
  }
  onAttachedFileTypeSelected(description:string){
    this.attachedFileType = description;
  
  }

  viewAttachDocument(attachmentID:Number){
    let url:string ="/DocumentUpload/GetDocument/"+attachmentID;
    this.brokerAPIService.get(url).subscribe(
      values=>{
        try{
          let docData:attachdocument = values;
          console.log("data>>",JSON.stringify(docData));
          if (docData.documentContent) {
            let blob:Blob;
            blob = base64ToBlob(docData.documentContent,docData.mimeType );
            if(blob){
              let link = document.createElement('a');
              const url= window.URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute("download",docData.documentName)
              link.click();
            }
          }
          
        } catch(error){
          this.openSnackBar("error preview:"+error,this.closeSnackbarMessage);
        }

      },
      error=>{

      }
    )
  }

  reOrderNo(dataObj:any){
    for(let i=0;i<dataObj.length;i++){
      dataObj[i]['orderNo']=i;
    }
    return dataObj;
  }

  onKey(event:any){
    
    console.log("testecent",JSON.stringify(event));
    this.onclickAddEducation();
  }



  btnBack() {
    this.router.navigate(['/researcherlist']);
  }

  openDialog(dialogTitle: string, dialogMessage: string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      data: { title: dialogTitle, message: dialogMessage },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }
}
