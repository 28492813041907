import { attachdocument } from "./attachdocument";

export class projectreviewapprove{
    projectId: number;
    isApprove: boolean;
    approveDate: Date;
    conclusion: conclusion;
}
export class conclusion{
    id: number;
    projectId: number;
    subject: string;
    conclusionMessage: string;
    attachment1Id: number;
    attachment1: attachdocument;
    attachment2Id: number;
    attachment2:attachdocument;
    attachment3Id: number;
    attachment3: attachdocument;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}