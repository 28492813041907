import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DataService {

  private messageSource = new Subject<String>();

  constructor() { }

  public getMessage(): Observable<String> {
    return this.messageSource.asObservable();
  }

  public setMessage(message: String) {
    return this.messageSource.next(message);
  }

}