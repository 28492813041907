import {Component, OnInit} from '@angular/core';
import { BrokerAPIService } from '../services/brokerapi.service';
import { Router } from '@angular/router';
import { IAPIResponse } from '../interfaces/apiResponse';
import { DataService } from '../services/messageservice';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isConsent:boolean = true;;
  azureID:string;
  isShowingConsent:boolean=false;
  result: IAPIResponse;
  message:string;;
  constructor(
    private router: Router,
    private brokerAPIService: BrokerAPIService,
    private messageservice:DataService
    ) {}

  ngOnInit() {
    // console.log('isconsent',sessionStorage.getItem("isConsent"));
    this.messageservice.getMessage().subscribe(message => {
      console.log("getconsent",message);

    
      if(message=="true"){
        this.isConsent = true;
        this.isShowingConsent = false;
      } else {
        this.isConsent = false;
        this.isShowingConsent = true;
      
      }
      
      this.azureID = sessionStorage.getItem("azureId");
    });
  }

 


  acceptConsent(){
    let url ="/Auth/ConfirmConsent";
    let requestObj:any = {'eMail': localStorage.getItem("usr"),'azureId':this.azureID}
    console.log("result",JSON.stringify(requestObj));
    this.brokerAPIService.post(url,JSON.stringify(requestObj)).subscribe(
      values=>{
        this.result = values;
        sessionStorage.setItem("isConsent","true");
        if(this.result.success){
          window.location.reload();
        }
        console.log("result",JSON.stringify(this.result));
      },
      error=>{
        console.log("result error",JSON.stringify(error));
      }
    )

  }
  
}