import { Injectable } from "@angular/core";
import { AppConfig } from "../app.config";

@Injectable()
export class SDUtilityService {
    SDLocalStorage = new SDLocalStorage();
    SDSysRole = new SDSysRole(this.SDLocalStorage);

    constructor() {

    }

}
@Injectable()
export class SDLocalStorage {
    // public ProjectName = AppConfig.settings.ProjectName;
    public ProjectName = 'craras';

    constructor() {

    }

    getItem(key: string) {
        return localStorage.getItem(this.ProjectName + key);
    }

    setItem(key: string, value: string) {
        localStorage.setItem(this.ProjectName + key, value);
    }




}
@Injectable()
export class SDSysRole {
    // public ProjectName = AppConfig.settings.ProjectName;
    public ProjectName = 'craras';
    constructor(private sdLocalStorage: SDLocalStorage) {

    }

    getSysRole() {
        let arrObjSysAccessEncode: string = this.sdLocalStorage.getItem("arrObjSysAccessEncode");
        if (arrObjSysAccessEncode != null) {
            return JSON.parse(arrObjSysAccessEncode);
        }
    }

    getMenuItem(menuOrder: string) {
        let arrMenu = this.getSysRole();
        // console.log(arrMenu);
        if (arrMenu.length > 0) {
            return arrMenu.find(x => x.menuOrder == menuOrder);
        }
        else {
            return undefined;
        }
    }

    canCreate(menuOrder: string) {
        let objmenu = this.getMenuItem(menuOrder);
        if (objmenu != undefined) {
            return objmenu.canCreate;
        }
        else {
            console.log(objmenu);
            return false;
        }
    }

    canDeleteOrCancel(menuOrder: string) {
        let objmenu = this.getMenuItem(menuOrder);
        if (objmenu != undefined) {
            return objmenu.canDeleteOrCancel;
        }
        else {
            console.log(objmenu);
            return false;
        }
    }

    canUpdate(menuOrder: string) {
        let objmenu = this.getMenuItem(menuOrder);
        if (objmenu != undefined) {
            return (objmenu.canUpdate || objmenu.canCreate) ;
        }
        else {
            console.log(objmenu);
            return false;
        }
    }

    canPrint(menuOrder: string) {
        let objmenu = this.getMenuItem(menuOrder);
        if (objmenu != undefined) {
            return objmenu.canPrint;
        }
        else {
            console.log(objmenu);
            return false;
        }
    }

    canView(menuOrder: string) {
        let objmenu = this.getMenuItem(menuOrder);
        if (objmenu != undefined) {
            return objmenu.canView;
        }
        else {
            console.log(objmenu);
            return false;
        }
    }

}




