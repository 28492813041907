import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { enumdata } from 'src/app/datamodel/enumdata';
import { researcher } from 'src/app/datamodel/researcher';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@Component({
  selector: 'app-report-cv',
  templateUrl: './report-cv.component.html',
  styleUrls: ['./report-cv.component.scss']
})
export class ReportCvComponent implements OnInit {
  Reportdatas: any = [];
  researcherData: researcher = new researcher;
  researcherID: string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    // public sdUtilityService: SDUtilityService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private dateAdapter: DateAdapter<Date>
   ) {
    this.researcherID=JSON.parse(this.route.snapshot.paramMap.get('dataobj')).param;
    this.dateAdapter.setLocale('th-TH');
   }

  ngOnInit(): void {



  }




  captureScreen() {
    let WindowPrt = window.open('', 'PRINT', 'height=600,width=900');

    WindowPrt.document.write('<html><head><title>' + document.title + '</title>');
    WindowPrt.document.write('<style> .Mat-lbl-text {font-size: 15px;padding-bottom: 10px;padding-top: 10px;color: black;font-style: normal;width: 100%;} @font-face {font-family: chulabhorn;src: url(/assets/fonts/chulabhornLikit/chulabhornlikittext-light.woff);} table {font-family: chulabhorn;} ');
     WindowPrt.document.write(' .salto_pagina_despues{page-break-after:always;} .salto_pagina_anterior{page-break-before:always;} .content {height: 100vh;width: 100%;display: flex;flex-direction: column;}  .img-content { flex: 1; display: flex; justify-content: center; align-items: center; } .observation { height: 150px;overflow: hidden; overflow-y: auto; } ');
    WindowPrt.document.write(' .Mat-lbl-data {font-size: 13px;padding-bottom: 10px;padding-top: 10px;color: rgb(2, 2, 2);font-style: normal;}');
    WindowPrt.document.write(' @page {size: A4;margin: 5mm;} ');
    WindowPrt.document.write(' @media print { html, body {width: 210mm;height: 297mm;} .page {  margin: 0; border: initial;  border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; } } ');
    WindowPrt.document.write(' body {width: 100%;height: 100%;margin: 0mm;padding: 0;} ');
    WindowPrt.document.write(' .page {width: 210mm; min-height: 297mm;padding-top: 0mm;');

    // WindowPrt.document.write(' .subpage {height: 297mm; padding: 1cm;} ');
    WindowPrt.document.write(' </style>');
    WindowPrt.document.write('</head><body >');
    WindowPrt.document.write(document.getElementById("contentToConvert").innerHTML);
    WindowPrt.document.write('</body></html>');


  setTimeout(() => {
    WindowPrt.print();
  }, 5000);
    // WindowPrt.print();
    // WindowPrt.close();

  }

  CloseClick() {
    let researcherObj: any = { param: this.researcherID };
    // this.router.navigate(['/researcheredit',JSON.stringify(this.researcherData)]);
    this.router.navigate(['/researcheredit',
    JSON.stringify(researcherObj)]);
    // let link = ['researcheredit'];
    // this.router.navigate(link);
  }

}
