export class projectsearchrequest{
    empCode: string;
    organizationId: number;
    researcherFirstName: string;
    researcherLastName: string;
    projectName: string;
    projectKeywoard: string;
    requestResearchYear: number;
    approveResearchYear: number;
    researchDescriptionTypeId: number;
    fundingType: number;

    constructor(
        empCode: string,
        organizationId: number,
        researcherFirstName: string,
        researcherLastName: string,
        projectName: string,
        projectKeywoard: string,
        requestResearchYear: number,
        approveResearchYear: number,
        researchDescriptionTypeId: number,
        fundingType: string)
    {
        if(empCode){
            this.empCode = empCode;
        }
        if(organizationId){
            this.organizationId=organizationId;
        }
        if(researcherFirstName){
            this.researcherFirstName = researcherFirstName;
        }
        if(researcherLastName){
            this.researcherLastName=researcherLastName;
        }
        if(projectName){
            this.projectName = projectName;
        }
        if(projectKeywoard){
            this.projectKeywoard = projectKeywoard;
        }
        if(requestResearchYear){
            this.requestResearchYear = requestResearchYear;
        }
        if(approveResearchYear){
            this.approveResearchYear = approveResearchYear;
        }
        if(researchDescriptionTypeId&&researchDescriptionTypeId!=0){
            this.researchDescriptionTypeId = researchDescriptionTypeId;
        }
        if(fundingType&&fundingType!='0'){
            this.fundingType = Number.parseInt(fundingType);
        }

    }
}

export class projectsearchresponse{
    orderNo: number;
    projectName: string;
    projectOwnerName: string;
    organizationName: string;
    coResearcherName: string;
    researchDuration: number;
    fundintType: string;
    approveResearchYear: number;
    projectStatus: string;
}
export class publishedworkserachrequest{
    publishWorkYear: number;
    publishWorkName: string;
    journalName: string;
    journalLevel: number;
    impactFactorFrom: number;
    impactFactorTo: number;
    quartileTCILevel: number;
    paticipation: number;
}

export class publishedworkresponse{
    orderNo: number;
    publishWorkYear: number;
    publishWorkName: string;
    journalName: string;
    journalLevel: string;
    impactFactor: number;
    quartileTCIIndex: string;
    paticipation: string;
}