<!-- <div class="mat-dialog-container">
    <div class="container">
        <mat-toolbar color="primary">
            <div fxLayout="row" fxFlexAlign="center center">
                <div fxFlex="50px" [ngSwitch]="data.Messagetype">
                    <mat-icon *ngSwitchCase="'Error'" class="iconsize">info</mat-icon>
                    <mat-icon *ngSwitchCase="'Complete'" class="iconsize">info</mat-icon>
                    <mat-icon *ngSwitchCase="'Validate'" class="iconsize">info</mat-icon>
                    <mat-icon *ngSwitchDefault class="iconsize">info</mat-icon>
                </div>
                <div>
                    {{ data.Messagetitle }}

                </div>
            </div>
        </mat-toolbar>
        <br />
        <h3 class="textbody" mat-dialog-title>{{ data.Messagebody }}</h3>
        <br/>
        <button mat-raised-button color="primary" (click)="OKClick()" [mat-dialog-close]="true">
          {{'OK' }}
        </button>
    </div>
</div> -->
<mat-card class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; width: 80%; padding: 0px;">
        <div class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px;">
            {{data.title}}
        </div>
        <div style="border-radius: 10px; margin: 10px;  ">
            <textarea readonly style="width: 100%; min-height: 250px; text-align: left;">{{data.message}}</textarea>
        </div>
        <div mat-dialog-actions style="text-align: center; width: 100%; ">
            <table width="100%">
                <tr width="100%">
                    <td width="100%" style="text-align: center;">
                        <button mat-raised-button class="btn-black" [mat-dialog-close]="data.message" cdkFocusInitial>Ok</button>
                    </td>
                </tr>
            </table>

        </div>
    </mat-card>
</mat-card>