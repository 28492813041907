import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';

@Component({
  selector: 'app-report-researcher-published',
  templateUrl: './report-researcher-published.component.html',
  styleUrls: ['./report-researcher-published.component.scss']
})
export class ReportResearcherPublishedComponent implements OnInit {
  @Input() objResearchPublished: any = {};
  dataPublishedWork: any = [];
  fiscalYearList: [number];
  fromYear: number;
  toYear: number;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    public sdUtilityService: SDUtilityService,
    private translate: TranslateService) { }

  ngOnInit(): void {

    console.log(this.objResearchPublished);
    this.fromYear = this.objResearchPublished.datefrom;
    this.toYear = this.objResearchPublished.dateto;

    // this.setFiscalYearSelection();
    this.GetPublishedWorkReport();

  }

  // /Report/GetPublishedWorkReport/{FromThaiYear},{ToThaiYear}

  GetPublishedWorkReport() {

    let callMethod: string = '/Report/GetPublishedWorkReport/' + this.fromYear + "," + this.toYear;
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        console.log(values);
        this.dataPublishedWork = values;

      });
  }

  // setFiscalYearSelection() {
  //   let currentTime = new Date();
  //   let year = currentTime.getFullYear()
  //   if (year < 2500) {
  //     year = year + 543;
  //   }
  //   this.fiscalYearList = [year];
  //   for (let i = 0; i < 1; i++) {
  //     this.fiscalYearList.push(year + i + 1);
  //   }
  //   this.fromYear = this.fiscalYearList[0]
  //   this.toYear = this.fiscalYearList[0 + 1]
  // }

}
