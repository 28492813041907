import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { researcher } from 'src/app/datamodel/researcher';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';

@Component({
  selector: 'app-report-cv-viewer',
  templateUrl: './report-cv-viewer.component.html',
  styleUrls: ['./report-cv-viewer.component.scss']
})
export class ReportCvViewerComponent implements OnInit {
  @Input() objresearcherData: any = {};

  researcherData: researcher = new researcher;
  dataResearch: any = [];
  reward: string
  organizationMember: string;
  department: string;
  researcherID:string;
  dataPresentWorks: any = []
  year: number
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,) { 
      this.researcherID=JSON.parse(this.route.snapshot.paramMap.get('dataobj')).param;
      // this.getResearcher();
      
    }

  ngOnInit(): void {
    console.log(JSON.stringify(this.objresearcherData));
    this.getResearcher()

    
    
    
  }

  getResearcher() {



    this.brokerAPIService.get('/Researcher/GetResearcherProfile/' + this.researcherID).subscribe(
      values => {
        this.objresearcherData = values;
        this.setData();
        
      },
      error => {

      }
    )
  }

  setData() {
    console.log(this.objresearcherData);
    
    for (let i = 0; i < this.objresearcherData.presentWorks.length; i++) { 
      let datetime = moment(this.objresearcherData.presentWorks[i].presentDate, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY");

    let days =   datetime.split('/')

    this.year =  +days[2] + 543

    console.log(days[0]+ "/"+ days[1] + "/" + this.year.toString());
    
      this.objresearcherData.presentWorks[i].presentDate = days[0]+ "/"+ days[1] + "/" + this.year.toString();

    }

    //  let datetime = moment(this.objresearcherData.presentWorks[0].presentDate, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY");
    //  console.log(datetime);
     

    var array = this.objresearcherData.presentWorks.sort((a, b) => {
      if (a.taskDate > b.taskDate) {
        return 1;
      }

      if (a.taskDate < b.taskDate) {
        return -1;
      }

      return 0;
    });

    console.log(array);
    
    
    this.researcherData = this.objresearcherData;
    this.researcherData = this.objresearcherData.presentWorks;
    
    this.researcherData.reward = this.sanitizer.bypassSecurityTrustHtml(this.objresearcherData.reward)['changingThisBreaksApplicationSecurity'];
    this.researcherData.organizationMember = this.sanitizer.bypassSecurityTrustHtml(this.objresearcherData.organizationMember)['changingThisBreaksApplicationSecurity'];
  
console.log(this.researcherData);

    
    let i = 0;
    if (this.objresearcherData.externalPublishedWorks != undefined) {
    this.objresearcherData.externalPublishedWorks.forEach(objPublishedWorks => {

      
    
    let strQuartileTCI
    try{
      if (objPublishedWorks.journalLevel == 1) {
        switch (objPublishedWorks.quartileTCI_Index) {
          case 1:
            strQuartileTCI = "Q1";
            this.objresearcherData[i].externalPublishedWorks.dataTest = strQuartileTCI;
            break;
          case 2:
            strQuartileTCI = "Q2";
            this.objresearcherData.externalPublishedWorks[i].dataTest = strQuartileTCI;
            break;
          case 3:
            strQuartileTCI = "Q3";
            this.objresearcherData.externalPublishedWorks[i].dataTest = strQuartileTCI;
            break;
          case 4:
            strQuartileTCI = "Q4";
            this.objresearcherData.externalPublishedWorks[i].dataTest = strQuartileTCI;
            break;
          default:
            break;
        }

      } else if (objPublishedWorks.journalLevel == 2) 
      {
        switch (objPublishedWorks.quartileTCI_Index) {
          case 1:
            strQuartileTCI = "TCI Tier 1";
            this.objresearcherData[i].externalPublishedWorks[i].dataTest = strQuartileTCI;
            break;
          case 2:
            strQuartileTCI = "TCI Tier 2";
            this.objresearcherData.externalPublishedWorks[i].dataTest = strQuartileTCI;
            break;
          case 3:
            strQuartileTCI = "TCI Tier 3";
            this.objresearcherData[i].externalPublishedWorks[i].dataTest = strQuartileTCI;
            break;
          default:
            
            break;
        }

        
      }
    } catch(error){
      
    }
      i++
    });

this.department = this.objresearcherData.organizations[0].researcherOrg.organizationName
     this.researcherData = this.objresearcherData;
  }

  

  }


}
