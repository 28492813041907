export class projectobjective{
    id: number;
    projectId: number;
    orderNo: number;
    objective: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}