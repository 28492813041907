import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ProjectProgressDetailComponent } from './projectsearch/project-progress-detail/project-progress-detail.component';
import { ProjectsearchDetailComponent } from './projectsearch/projectsearch-detail/projectsearch-detail.component';
import { ProjectsearchListComponent } from './projectsearch/projectsearch-list/projectsearch-list.component';
import { SearchpageComponent } from './projectsearch/searchpage/searchpage.component';
import { ApprovalDetailComponent } from './researcher/approval-detail/approval-detail.component';
import { RegisterComponent } from './researcher/register/register.component';
import { ResearcherEditComponent } from './researcher/researcher-edit/researcher-edit.component';
import { ResearcherListComponent } from './researcher/researcher-list/researcher-list.component';
import { ChartPortalComponent } from './chart-portal/chart-portal.component';
import { ReportCvComponent } from './reports/report-cv/report-cv.component';
import { ReportCvViewerComponent } from './reports/report-cv-viewer/report-cv-viewer.component';
import { ReportProposalComponent } from './reports/report-proposal/report-proposal.component';
import { ReportListComponent } from './reports/report-list/report-list.component';
import { SysUserRollComponent } from './sysuser/sys-user-roll/sys-user-roll.component';
import { SysUserRollDetailComponent } from './sysuser/sys-user-roll-detail/sys-user-roll-detail.component';
import { MainDataListingComponent } from './maindata/main-data-listing/main-data-listing.component';
import { MainDataDetailComponent } from './maindata/main-data-detail/main-data-detail.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: '',
    component: HomeComponent,
    // component:SearchpageComponent,
  },
  {
    path: 'searchpage',
    component: SearchpageComponent
  },
  {
    path: 'researcherregister',
    component: RegisterComponent
  },
  {
    path: 'researcherlist',
    component: ResearcherListComponent
  },
  {
    path: 'researchApproval/:dataobj',
    component: ApprovalDetailComponent
  },
  {
    path: 'researcheredit/:dataobj',
    component: ResearcherEditComponent
  },
  {
    path: 'projectsearchlist',
    component: ProjectsearchListComponent
  },
  {
    path: 'projectsearchdetail/:dataobj',
    component: ProjectsearchDetailComponent
  },
  {
    path: 'projectprogress/:dataobj',
    component: ProjectProgressDetailComponent

  },
  {
    path: 'chartportal',
    component: ChartPortalComponent

  },
  {
    path: 'reportcv/:dataobj',
    component: ReportCvComponent

  },
  {
    path: 'reportcvviewer',
    component: ReportCvViewerComponent

  },
  {
    path: 'reportproposal/:dataobj',
    component: ReportProposalComponent

  },
  {
    path: 'reportlist',
    component: ReportListComponent
  },
  {
    path: 'sysuserroll',
    component: SysUserRollComponent
  },
  {
    path: 'sysuserrolldetail/:dataobj',
    component: SysUserRollDetailComponent
  },
  {
    path: 'maindatalisting',
    component: MainDataListingComponent
  },
  {
    path: 'maindataDetail/:dataobj',
    component: MainDataDetailComponent
  }


];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
