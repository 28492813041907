import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SysUserRollComponent } from './sys-user-roll/sys-user-roll.component';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../module/material/material.module';
import { SysUserRollDetailComponent } from './sys-user-roll-detail/sys-user-roll-detail.component';



@NgModule({
  declarations: [SysUserRollComponent, SysUserRollDetailComponent],
  imports: [
    CommonModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ]
})
export class SysuserModule { }
