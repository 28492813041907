import { durablearticle } from "./durablearticle";
import { expectedbenefit } from "./expectedbenefit";
import { expectedimpact } from "./expectedimpact";
import { expectedresult } from "./expectedresult";
import { keyword } from "./keyword";
import { oecd } from "./oecd";
import { oecdsub } from "./oecdsub";
import { projectattachdocument } from "./projectattachdocument";
import { projectbudget } from "./projectbudget";
import { projectobjective } from "./projectobjective";
import { projectstatus } from "./projectstatus";
import { projecttimeline } from "./projecttimeline";
import { received } from "./received";
import { referencedocumentforproject } from "./referencedocumentforproject";
import { researcher } from "./researcher";
import { researchfororg } from "./researchfororg";
import { researchmethod } from "./researchmethod";
import { teammember } from "./teammember";

export class projectproposal{
    id: number;
    projectCode: string;
    projectStatusId: number;
    projectStatus:projectstatus;
    nextStatusId:number;
    researchForOrgId: number;
    researchForOrg: researchfororg;
    projectOwnerId: number;
    projectOwner: researcher ;
    projectNameTH: string;
    projectNameEN: string;
    teamMembers: [teammember];
    researchDescriptionTypeId: number;
    otherResearchDescriptionType: string;
    oecdId: number;
    oecd:oecd;
    oecdSubId: number;
    oecdSub:oecdsub;
    objectives: [projectobjective];
    keywords: [keyword];
    importantOfProblem: string;
    literatureReview: string;
    researchMethods: [researchmethod];
    researchSiteHeadId: number;
    researchSingleCenterName: string;
    isOnlyNationalSite: boolean;
    isCoInternationalSite: boolean;
    researchNationalSiteName: string;
    researchCoInternaltionalSiteName: string;
    timeLine: projecttimeline;
    expectedResults: [expectedresult];
    budget: projectbudget;
    durableArticles: [durablearticle];
    referenceDocuments: [referencedocumentforproject];
    expectedBenefits: [expectedbenefit];
    received: received;
    attachDocuments: [projectattachdocument];
    expectedImpacts: [expectedimpact]
    projectApproveDate:Date;
    ecNo:string;
    ecDate:Date;
    ecStatus:Number;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}