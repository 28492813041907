<div class="table-container">

    <table style="width: 100%; font-size: 14px;" class="font-light">
        <tr>
            <td colspan="2" style="width: 100%; text-align: center;">
                <h3>ผลงานวิจัยตีพิมพ์</h3>
            </td>
        </tr>
        <tr>

            <td class="" colspan="2" style="width: 100%; text-align: center;">
                <label style="font-weight: bold;">ปี </label>{{ Year }}&nbsp;&nbsp;
            </td>

        </tr>
        <tr *ngIf="hasData">
            <td colspan="2">
                <table style="width: 100%;padding: 0px;margin: 0px;">
                    <tr>
                        <td colspan="4 " style="width: 100%; ">
                            <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                <tr>
                                    <td rowspan="2" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ประเภทโครงการ
                                    </td>
                                    <td rowspan="2" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        สังกัด
                                    </td>
                                    <td rowspan="2" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        หน่วยงาน
                                    </td>
                                    <td colspan="3" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        จำนวนโครงการวิจัย
                                    </td>
                                    <td colspan="3" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        งบประมาณ
                                    </td>

                                </tr>

                                <tr>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        โครงการใหม่
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                        โครงการต่อเนื่อง
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                        รวม
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                        โครงการใหม่
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                        โครงการต่อเนื่อง
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                        รวม
                                    </td>
                                </tr>


                                <tr *ngFor="let objAmountResearchProject of AmountResearchProject let i =index">
                                    <td *ngIf="objAmountResearchProject.fundingType==-99" colspan="3" style="border: 1px solid lightgray; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                                        {{ objAmountResearchProject.fundingDesc }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType==-99" style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                        {{ objAmountResearchProject.newProjectCount }}
                                    </td>

                                    <td *ngIf="objAmountResearchProject.fundingType==-99" style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                        {{ objAmountResearchProject.contProjectCount }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType==-99" style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                        {{ objAmountResearchProject.totalProjectCount }}
                                    </td>

                                    <td *ngIf="objAmountResearchProject.fundingType==-99" style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                        {{ objAmountResearchProject.newProjectBudget }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType==-99" style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                        {{ objAmountResearchProject.contProjectBudget }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType==-99" style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                        {{ objAmountResearchProject.totalProjectBudget }}
                                    </td>



                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.fundingDesc }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.affiliation }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.organizationName }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.newProjectCount }}
                                    </td>

                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.contProjectCount }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.totalProjectCount }}
                                    </td>

                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.newProjectBudget }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.contProjectBudget }}
                                    </td>
                                    <td *ngIf="objAmountResearchProject.fundingType!=-99" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objAmountResearchProject.totalProjectBudget }}
                                    </td>

                                    <!-- <div *ngIf="objAmountResearchProject.fundingType!='99'">
                                        <td colspan="3" style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray;">
                                            {{ objAmountResearchProject.fundingDesc }}
                                        </td>

                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                            {{ objAmountResearchProject.newProjectCount }}
                                        </td>

                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                            {{ objAmountResearchProject.contProjectCount }}
                                        </td>
                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                            {{ objAmountResearchProject.totalProjectCount }}
                                        </td>

                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                            {{ objAmountResearchProject.newProjectBudget }}
                                        </td>
                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                            {{ objAmountResearchProject.contProjectBudget }}
                                        </td>
                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                            {{ objAmountResearchProject.totalProjectBudget }}
                                        </td>
                                    </div> -->
                                </tr>

                                <!-- <tr>
                                    <td colspan="3" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        โครงการวิจัยที่ไม่ใช้งบประมาณ
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        ผลรวม A1
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        ผลรวม A2
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        ผลรวม A3
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        ผลรวม B1
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        ผลรวม B2
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        ผลรวม B3
                                    </td>

                                </tr> -->
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

    </table>
</div>