import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
// import { TranslateService } from '@ngx-translate/core';
import { researcherstatusdropdown } from 'src/app/datamodel/researcherstatusdropdown';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';
import { MatPaginator } from '@angular/material/paginator';
import { sortingArrayList } from 'src/app/services/general-function';
import { Sort } from '@angular/material/sort';
import { researcher } from 'src/app/datamodel/researcher';
// import { SDUtilityService } from 'src/app/services/sdutility.service';

@Component({
  selector: 'app-researcher-list',
  templateUrl: './researcher-list.component.html',
  styleUrls: ['./researcher-list.component.scss'],
})
export class ResearcherListComponent implements OnInit {
  private researcherList: researcherRow[];
  researcherStatusList: researcherstatusdropdown[];
  statusDescList = [];
  selectedStatus: number = 0;
  filter: string = '';
  UserLang: any = {};
  errorMessage: string;
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  displayedColumns = [
    'Control',
    'position',
    'employeeCode',
    'firstNameTH',
    'organizationMember',
    'currentPosition',
    'researcherStatus',
    'createDate',
  ];

  isShowFilter: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    public sdUtilityService: SDUtilityService,
    // private translate: TranslateService
  ) {
    // #region "TranslateModule"

    this.UserLang = 'EN';
    if (
      this.sdUtilityService.SDLocalStorage.getItem('crarasUserLang') == null
    ) {
      this.sdUtilityService.SDLocalStorage.setItem(
        'crarasUserLang',
        this.UserLang
      );
    } else {
      this.UserLang =
        this.sdUtilityService.SDLocalStorage.getItem('crarasUserLang');
    }
    // this.translate.setDefaultLang(this.UserLang);
    // this.translate.use(this.UserLang);

    //#endregion "TranslateModule"
  }

  ngOnInit(): void {
    // this.dataSource.data = this.data;
    this.getResearcherList(0);
    this.getResearcherStatusList();
    this.getUserRole();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getResearcherList(statusid: number) {
    let callMethod: string = '/Researcher/GetResearcherList/' + statusid;
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.researcherList = [];
        for(let i = 0 ; i<values.length;i++){
          let researcherData:researcher = values[i];
          let researcherListRow:researcherRow = new researcherRow;
          researcherListRow.id = researcherData.id;
          researcherListRow.employeeCode = researcherData.employeeCode;
          researcherListRow.prefixTH = researcherData.prefixTH;
          researcherListRow.firstNameTH = researcherData.firstNameTH;
          researcherListRow.lastNameTH = researcherData.lastNameTH;
          researcherListRow.currentPosition = researcherData.currentPosition;
          researcherListRow.researcherStatus = researcherData.researcherStatus;
          researcherListRow.approveDate = researcherData.approveDate;
          // researcherListRow.organizationName = "";
          if(researcherData.organizations&&researcherData.organizations[0]){
            researcherListRow.organizationName = researcherData.organizations[0].researcherOrg.organizationName;
          }
          this.researcherList.push(researcherListRow);


        }

        for (let i = 0; i < this.researcherList.length; i++) {
          this.researcherList[i].rowNo = i + 1;
        }

        this.dataSource.data = this.researcherList;
      },
      (error) => {
        this.errorMessage = <any>error;
        console.log("error get status",JSON.stringify(this.errorMessage));
      }

    );
  }

  rowClicked(row: any): void {
    let selectedResearcher: researcherRow = row;
    console.log(selectedResearcher);

    let researcherObj: any = { param: selectedResearcher.id };
    if (
      selectedResearcher.researcherStatus == 1
    ) {
      this.router.navigate([
        '/researchApproval',
        JSON.stringify(researcherObj),
      ]);
    } else {
      this.router.navigate([
        '/researcheredit',
        JSON.stringify(researcherObj),
      ]);
    }
  }

  getResearcherStatusList() {
    let callMethod: string = '/Master/GetResearcherStatusForDropdown';
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.researcherStatusList = values;
        for (let i = 0; i < this.researcherStatusList.length; i++) {
          this.statusDescList.push(this.researcherStatusList[i].statusName);
        }
      },
      (error) => {
        this.errorMessage = <any>error;
        console.log("error get status",JSON.stringify(this.errorMessage));
      }
    );
  }

  // APProveResearcher(row: any) {
  //   console.log(row);

  //   let selectedResearcher: researcherRow = row;
  //   if (
  //     selectedResearcher.researcherStatus == 1 ||
  //     selectedResearcher.researcherStatus == 3
  //   ) {
  //     this.router.navigate([
  //       '/researchApproval',
  //       JSON.stringify(selectedResearcher),
  //     ]);
  //   } else {
  //     this.router.navigate([
  //       '/researcheredit',
  //       JSON.stringify(selectedResearcher),
  //     ]);
  //   }
  // }

  onSelectedStatusChange() {
    this.getResearcherList(this.selectedStatus);
  }

  transformDate(dateValue): string {
    if (dateValue == '0001-01-01T00:00:00') {
      return '';
    }
    let tempDate: Date = new Date(dateValue);
    return tempDate.toLocaleDateString('th-TH', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  }

  sortData(sort: Sort) {
    this.dataSource.data = sortingArrayList(
      this.researcherList,
      sort.active,
      sort.direction
    );
  }

  getUserRole() {
    this.brokerAPIService.get('/Auth/GetCurrentUserRole').subscribe(
      (values) => {
        for (let i = 0; i < values.length; i++) {
          if (values[i]['userSysRoleId'] == 3) {
            this.isShowFilter = true;
          }
        }
      },
      (error) => {
        this.errorMessage = <any>error;
      }
    );
  }
}

export class researcherRow{
  id: number;
  employeeCode: string;
  idCardNo: string;
  prefixTH: number;
  firstNameTH: string;
  lastNameTH: string;
  organizationName: string;
  currentPosition: string;
  researcherStatus: number;
  approveDate:Date;
  rowNo: number;
}
