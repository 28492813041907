import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject,Observable  } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import { startWith, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
// import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SDUtilityService } from './services/sdutility.service';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { systemmenucontrol } from './datamodel/systemmenucontrol';
import { linkmenu } from './datamodel/linkmenu';
import { AppConfig } from './app.config';
import { environment } from 'src/environments/environment';
import { DataService } from './services/messageservice';
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
type ProfileType = {
  displayName?:string,
  givenName?: string,
  surname?: string,
  jobTitle?:string,
  mobilePhone?:string,
  mail?:string,
  userPrincipalName?: string,
  id?: string
};



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  // @Input() sidenav;
	// @Input() sidebar;
	// @Input() drawer;
	// @Input() matDrawerShow;
  formname:string;
  profile!: ProfileType;
	isShowRequestResercher: boolean = false;
	searchOpen: boolean = false;
  filteredStates: Observable<any[]>;
	stateCtrl = new FormControl();
	UserLang: any = {};
  userTest:string='';
  systemMenu : systemmenucontrol;
  displayName:string;
  isShowDashboard: boolean = false;
	isShowResearchProject: boolean = false;
	isShowResearcher: boolean = false;
	isShowReport: boolean = false;
	isShowSetting: boolean = false;
	isShowSearchResearcher: boolean = false;
  isShowSearchMainData: boolean = false;

  isEnableDashbord: boolean = false;
	isEnableResearchProject: boolean = false;
	isEnableResearcher: boolean = false;
	isEnableReport: boolean = false;
	isEnableSetting: boolean = false;
	isEnableSearchResearcher: boolean = false;
  isEnableSearchMainData: boolean = false;

  showRegister:boolean=false;
  showNewResearchPropose:boolean=false;

  isHideMenuTabs: boolean = false;

  isShowIconLangEN: boolean = true;
	isShowIconLangTH: boolean = false;
  title:string;
  isIframe = false;
  loginDisplay = false;
  errorMessage:string;
  private readonly _destroying$ = new Subject<void>();


  constructor(

    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    // private translate: TranslateService,
		private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    public  sdUtilityService:SDUtilityService,
    private brokerAPIService:BrokerAPIService,
    private appConfig: AppConfig,
    private messageservice:DataService,

    ) {

    }

  ngOnInit() {


    this.messageservice.getMessage().subscribe(message => {})
    this.isIframe = window !== window.parent && !window.opener;
    this.appConfig.loadConfig().then((resolve:any)=>{
       this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe((aluse) => {
          this.checkAuthenticate();
      });
    });



  }



  login() {
    this.authService.loginRedirect();
  }

  logout() { // Add log out function here
    this.clearSesstionData();
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUrl
    });
  }

  checkAuthenticate() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(!this.loginDisplay){
      this.login();

    }else{
      this.brokerAPIService.getProfile(GRAPH_ENDPOINT).subscribe(values=>{
        this.profile = values;
        this.displayName = this.profile.displayName;
        this.getAPIToken();
      });

    };
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    this.clearSesstionData();
  }

  clearSesstionData(){
    sessionStorage.setItem('pagecatch','');
  }

  gotoHome(){
    let link = [''];
    this.router.navigate(link);
    this.isHideMenuTabs = false;
    this.resetTabs();
  }

  gotoResearcherList(){
    let link = ['researcherlist'];
    this.router.navigate(link);
    this.resetTabs();
	  this.isShowResearcher = true;
  }

  gotoResearcherRegister(){
    let link = ['researcherregister'];
    this.router.navigate(link);
    this.resetTabs();
	  // this.isHideMenuTabs = true;
  }

  gotoSearch(){
    let link = ['searchpage'];
    this.router.navigate(link);
    this.resetTabs();
	  this.isShowSearchResearcher = true;
  }

  gotoMainData(){
    let link = ['maindatalisting'];
    this.router.navigate(link);
    this.resetTabs();
	  this.isShowSearchMainData = true;
  }

  gotoProfile(){
    let link = ['profile'];
    this.router.navigate(link);
    this.resetTabs();
	  this.isHideMenuTabs = false;
  }

  gotoDashboard(){
    let link = ['chartportal'];
    this.router.navigate(link);
    this.resetTabs();
    this.isShowDashboard=true;
  }

  gotoReport(){
    let link = ['reportlist'];
    this.router.navigate(link);
    this.resetTabs();
    this.isShowReport = true;
  }
  gotoProjectList(){
    let link = ['projectsearchlist'];
    this.router.navigate(link);
    this.resetTabs();
	  this.isShowResearchProject = true;
  }

  gotoProjectDetail(){
    let dataToSend:any ={'mode':'new','projectid':-1};
    let link = ['projectsearchdetail',JSON.stringify(dataToSend)];
    this.router.navigate(link);
    this.resetTabs();
	  this.isShowResearchProject = true;
  }

  gotoSetting(){
    let link = ['sysuserroll'];
    this.router.navigate(link);
    this.isHideMenuTabs = false;
    this.resetTabs();
    this.isShowSetting=true;
  }

  // gotoChartportal(){
  //   let link = ['chartportal'];
  //   this.router.navigate(link);
  //   this.isHideMenuTabs = false;
  //   this.resetTabs();
  //   this.isShowDashboard=true;
  // }

  resetTabs(){
    this.isShowDashboard = false;
	  this.isShowResearchProject = false;
	  this.isShowResearcher = false;
	  this.isShowReport = false;
	  this.isShowSetting = false;
	  this.isShowSearchResearcher = false;
    this.isShowSearchMainData = false;

  }




  gotoConsent(){
    let link = ['consent'];
    this.router.navigate(link);
  }


  setMenu(){
    // this.resetTabs();
    this.gotoHome();
    this.isEnableDashbord =  this.systemMenu.mainMenu.isShowDashbord;
    this.isEnableResearchProject =  this.systemMenu.mainMenu.isShowResearchProject;
    // this.isShowResearchProjectProgress =  this.systemMenu.mainMenu.isShowResearchProjectProgress;
    this.isEnableResearcher =  this.systemMenu.mainMenu.isShowResearcher;
    this.isEnableReport =  this.systemMenu.mainMenu.isShowReport;
    this.isEnableSetting =  this.systemMenu.mainMenu.isShowSetting;
    this.isEnableSearchResearcher =  this.systemMenu.mainMenu.isShowSearchResearcher;
    this.isEnableSearchMainData = this.systemMenu.mainMenu.isShowSearchMainData;
    console.log("isShowSearchMainData",this.systemMenu.mainMenu.isShowSearchMainData);

    if(this.isEnableDashbord){
      this.isShowDashboard = true;
      this.gotoDashboard();
    } else if(this.isEnableResearchProject){
      this.isShowResearchProject = true;
      this.gotoProjectList();
    } else if(this.isEnableResearcher){
      this.isShowResearcher = true;
      this.gotoResearcherList();
    } else if(this.isEnableReport){
      this.isShowReport = true;
    } else if(this.isEnableSetting){
      this.isShowSetting = true;
    } else if(this.isEnableSearchResearcher){
      this.isShowSearchResearcher = true;
      this.gotoSearch();
    } else if(this.isEnableSearchMainData){
      this.isShowSearchMainData = true;
      this.gotoMainData();

    }
    this.loginDisplay = true;

    this.showNewResearchPropose = false;
    this.showRegister = false;
    localStorage.setItem("showprojectpropose","N");
    for(let i=0;i<this.systemMenu.linkMenu.length;i++){
      if(this.systemMenu.linkMenu[i].linkName=='new-project-request'){
        this.showNewResearchPropose = true;
        localStorage.setItem("showprojectpropose","Y");
      } else  if(this.systemMenu.linkMenu[i].linkName=='research-request'){
        this.showRegister = true;
      }
    }
  }

  async getToken(): Promise<string> {
    const redirectResponse = await this.authService.instance.handleRedirectPromise();
    if (redirectResponse !== null) {
      // Acquire token silent success
      let accessToken = redirectResponse.accessToken;
      // Call your API with token
      //callApi(accessToken);
      this.authService.instance.setActiveAccount(redirectResponse.account)
      return accessToken;
    } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const account = this.authService.instance.getAllAccounts()[0];

        const accessTokenRequest = {
            scopes: ["user.read"],
            account: account
        }

        this.authService.instance.acquireTokenSilent(accessTokenRequest).then(function(accessTokenResponse) {
            // Acquire token silent success
            // Call API with token
            let accessToken = accessTokenResponse.accessToken;
            localStorage.setItem('token',accessToken);
            return accessToken;
        }).catch(function (error) {
            console.log("error:"+error);
            return "error"
        });
    }
  }

  // researcherTest(){
  //   this.loginDisplay = true;
  //   let link = ['researcherlist'];
  //   this.router.navigate(link);
  //   this.resetTabs();
	//   this.isShowResearcher = true;
  // }

  // officerTest(){
  //   this.loginDisplay = true;
  //   let link = ['researcherlist'];
  //   this.router.navigate(link);
  //   this.resetTabs();
	//   this.isShowResearcher = true;
  // }



  getAPIToken(){
    let userObj:any={'eMail':this.profile.userPrincipalName,'azureId':this.profile.id};
    localStorage.setItem("usr",this.profile.userPrincipalName);
    console.log("result profile",JSON.stringify(this.profile));
    this.brokerAPIService.post('/Auth/GetAuthenToken',JSON.stringify(userObj)).subscribe(
      values =>{
        // console.log("apitoken:"+JSON.stringify(values));
        localStorage.setItem('token',values['token']);
        let isConsent = values["isConsented"];
        sessionStorage.setItem("isConsent",isConsent);
        sessionStorage.setItem("azureId",this.profile.id);
        console.log("isConsented",isConsent)
        if(isConsent){
          this.getSystemMenu();
        } else {
          // window.location.reload();
          this.messageservice.setMessage(isConsent);
          this.gotoHome();
        }
      },
      error => {
        this.errorMessage = <any>error
        console.log("errorMessage:"+JSON.stringify(this.errorMessage));
      }
    );
  }

  getSystemMenu(){
    this.brokerAPIService.get('/Auth/GetSystemMenu').subscribe(
      values=>{
        this.systemMenu = values;
        console.log("systemMenu",this.systemMenu);

        this.setMenu();

      },
      error => {
        this.errorMessage = <any>error
        console.log("errorMessage hello :"+JSON.stringify(this.errorMessage));
        this.resetTabs();
        // this.gotoSearch();
        // console.log("show:"+JSON.stringify(this.systemMenu));
      }
    )
  }

  // getUserRole(){
  //   this.brokerAPIService.get('/Auth/GetCurrentUserRole').subscribe(
  //     values=>{
  //       this.systemMenu = values;
  //       this.setMenu();

  //     },
  //     error => {
  //       this.errorMessage = <any>error
  //       console.log("errorMessage hello :"+JSON.stringify(this.errorMessage));
  //       this.resetTabs();
  //       this.gotoSearch();
  //       // console.log("show:"+JSON.stringify(this.systemMenu));
  //     }
  //   )
  // }
}
