export class timelinedetail{
    id: number;
    projectTimeLineHeadId: number;
    orderNo: number;
    fiscalYear: number;
    activityName: string;
    fromMonth: number;
    toMonth: number;
    percentOfActivity: number;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}