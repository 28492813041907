import { attachdocument } from "./attachdocument";

export class trackingattachdocument{
    id:number;
    orderNo:number;
    projectTrackingId:number;
    attachDocumentId:number;
    attachDocument:attachdocument;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}