import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { registermodel } from 'src/app/datamodel/registermodel';
import { BrokerAPIService } from '../../services/brokerapi.service';
import { SDLocalStorage } from 'src/app/services/sdutility.service';
import { IAPIResponse } from "src/app/interfaces/apiResponse";
import { organization } from 'src/app/datamodel/organization';
import { isceD1 } from 'src/app/datamodel/isceD1';
import { isceD2 } from 'src/app/datamodel/isceD2';
import { isceD3 } from 'src/app/datamodel/isceD3';

import { checkEmailFormat } from 'src/app/services/general-function';
import { MatDialogRef,MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerData:registermodel;
  UserLang: any = {};
  firstNameEn:string;
  result:IAPIResponse;
  organizations:[organization];
  isceD1s:[isceD1];
  isceD2s:[isceD2];
  isceD3s:[isceD3];
  selectedisceD1:isceD1;
  emailError:string="รูปแบบอีเมล์ไม่ถูกต้อง";
  closeSnackbarMessage: string = 'Close';
  private errorMessage:string;
  // private dialog: MatDialog;
  // dialogRef: MatDialogRef<MessageDialogComponent>;
  constructor(
    private brokerAPIService: BrokerAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    // public sdUtilityService: SDUtilityService,
    private sdLocaLStorage:SDLocalStorage,
    private translate: TranslateService
    ) {
      this.reset;
          //#region "TranslateModule"
    // this.UserLang = 'EN';
    // if (this.sdUtilityService.SDLocalStorage.getItem("UserLang") == null) {
    //   this.sdUtilityService.SDLocalStorage.setItem("UserLang", this.UserLang);
    // }
    // else {
    //   this.UserLang = this.sdUtilityService.SDLocalStorage.getItem("UserLang");
    // }
    // this.translate.setDefaultLang(this.UserLang);
    // this.translate.use(this.UserLang);
    //#endregion "TranslateModule"
     }

  ngOnInit(): void {
    this.reset();
    this.getOrganization();
    this.getisceD(1,0);
  }

  reset(){
    this.registerData = new registermodel();
    // this.registerData.employeeCode="EC";
    // this.registerData.idCardNo="ID";
    this.registerData.gender ="1";
    // this.registerData.firstNameEN="FN";
    // this.registerData.lastNameEN="LN";
    // this.registerData.prefixEN="PRE";
    // this.registerData.firstNameTH="ชื่อไทย";
    // this.registerData.lastNameTH="นามสกุล";
    // this.registerData.prefixTH="ชื่อต้น";
  }

  btnBlack(){
    this.gotoHome();
  }

  btnSend(){

    this.submitRegistration();
  }

  // validateData() {
  //   let strValidate: string = "";

  //   if (this.registerData.employeeCode == "" || this.registerData.employeeCode == undefined ) {
  //     strValidate = "รหัสพนักงาน";
  //   }

  //   if
  // }

  getOrganization(){
    let callMethod:string='/Master/GetOrganizationList';
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        this.organizations = values;
        console.log("org>",JSON.stringify(this.organizations));
      },
      error => this.errorMessage = <any>error
    );
  }

  getisceD(iscednumber:number,parentid:number){
    let callMethod:string='/Master/';
    if(iscednumber==1){
      callMethod = callMethod + 'GetISCED1List';
    } else
    if(iscednumber==2){
      callMethod = callMethod + 'GetISCED2List/'+parentid;
    }else
    if(iscednumber==3){
      callMethod = callMethod + 'GetISCED3List/'+parentid;
    }
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        // console.log("isced>"+iscednumber+","+parentid+" >"+JSON.stringify(values));
        if(iscednumber==1){
          this.isceD1s = values;
        } else if(iscednumber==2){
          this.isceD2s = values;
        } else if(iscednumber==3){
          this.isceD3s = values;
        } 
      },
      error => this.errorMessage = <any>error
    );
  }

  submitRegistration(){
    
    this.brokerAPIService.post('/Researcher/RegistorResearcher',JSON.stringify(this.registerData)).subscribe(
      values =>{
        this.result =  values;
        console.log("Return:"+JSON.stringify(values))
        if(!this.result.success){
          this.openDialog("ไม่สามารถลงทะเบียนได้",this.result.message);
        } else {
          this.openSnackBar(this.result.message,this.closeSnackbarMessage);
          let link = ['researcherlist'];
          this.router.navigate(link);
        }

      },
      error => {
        this.errorMessage = <any>error;
        console.log("errorMessage:"+JSON.stringify(this.errorMessage));
      }
    );
  }

  onisceD1Changed(){
    this.getisceD(2,this.registerData.isceD1Id);
    this.isceD3s=null;
  }

  onisceD2Changed(){
    this.getisceD(3,this.registerData.isceD2Id);
  }

 
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 4000,
    });
  }
  gotoHome(){
    let link = ['researcherlist'];
    this.router.navigate(link);
  }  

  onOrgChange(){ 
    console.log("onOrgChange");
    if(this.registerData.mainOrganizationId!=10&&this.registerData.mainOrganizationId!=99){
      this.registerData.otherOrganization = "";
    }
  }

  onOrgChangeSecondary(){
    console.log("onOrgChange");
    if(this.registerData.secondaryOrganization!=10&&this.registerData.secondaryOrganization!=99){
      this.registerData.OtherSecondaryOrganization = "";
    }
  }
  

  openDialog(dialogTitle: string, dialogMessage: string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      data: { title: dialogTitle, message: dialogMessage },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }
}


