export class expert{
    id: number;
    projectId: number;
    orderNo: number;
    specialistFullName: string;
    specialistOrganization: string;
    specialistEmail: string;
    evaluateDate: Date;
    receiveDocDate : Date;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}