import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fundingtype } from 'src/app/datamodel/fundingtype';
import { organization } from 'src/app/datamodel/organization';
import { researchdesctype } from 'src/app/datamodel/researchdesctype';
import { projectsearchrequest, projectsearchresponse, publishedworkresponse, publishedworkserachrequest } from 'src/app/datamodel/searchmodel';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';

@Component({
  selector: 'app-searchpage',
  templateUrl: './searchpage.component.html',
  styleUrls: ['./searchpage.component.scss']
})
export class SearchpageComponent implements OnInit {

  projectSearchCriteria : projectsearchrequest;
  projectList:projectsearchresponse[];
  researchTypeList:researchdesctype[];
  fundingList:fundingtype[];

  empCode: string;
  organizationId: number;
  researcherFirstName: string;
  researcherLastName: string;
  projectName: string;
  projectKeywoard: string;
  requestResearchYear: number;
  approveResearchYear: number;
  researchDescriptionTypeId: number;
  fundingType: string;
  organizationList:organization[];




  publishedworkSearchCriteria:publishedworkserachrequest;
  publishedWorkList: publishedworkresponse[];
  publishWorkYear: number;
  publishWorkName: string;
  journalName: string;
  journalLevel: number=0;
  impactFactorFrom: number;
  impactFactorTo: number;
  quartileTCILevel: number=0;
  paticipation: number=0;
  quartileList = [{"id":0,"description":"ไม่ระบุ"},{"id":1,"description":"Q1"},{"id":2,"description":"Q2"},{"id":3,"description":"Q3"},{"id":4,"description":"Q4"}];
  tciTierList = [{"id":0,"description":"ไม่ระบุ"},{"id":1,"description":"TCI Tier 1"},{"id":2,"description":"TCI Tier 2"},{"id":3,"description":"TCI Tier 3"}];

  closeSnackbarMessage: string = 'Close';
  selectedTabIndex: any;
  eventselectedTabIndex: any;
  dataProject: any[];
  dataSourceProject = new MatTableDataSource();
  displayedColumnsProject = ['lineNo', 'projectName', 'projectOwnerName', 'organizationName', 'coResearcherName', 'researchDuration', 'fundintType', 'approveResearchYear', 'projectStatus'];
  @ViewChild(MatPaginator) paginatorProject: MatPaginator;

  dataSourceResearchResultsPublished = new MatTableDataSource();
  displayedColumnsResearchResultsPublished = ['lineNo', 'publishWorkYear', 'publishWorkName', 'journalName', 'journalLevel', 'impactFactor', 'quartileTCIIndex', 'paticipation'];
  @ViewChild(MatPaginator) paginatorPublish: MatPaginator;
  ngAfterViewInit() {
    this.dataSourceProject.paginator = this.paginatorProject;
    this.dataSourceProject.paginator = this.paginatorPublish;
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    private snackBar: MatSnackBar,

    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe) {
      this.getOrganizationList();
      this.getResearchList();
     }

  ngOnInit(): void {
  }

  onTabChanged($event) {
    console.log($event);

  }

  //search
  async btnSearchPublished() {
    this.publishedworkSearchCriteria = new publishedworkserachrequest;
    if(this.publishWorkYear){
      this.publishedworkSearchCriteria.publishWorkYear = this.publishWorkYear;
    }
    // console.log(this.publishedworkSearchCriteria.publishWorkYear+"<>"+this.publishWorkYear);
    if(this.publishWorkName){
      this.publishedworkSearchCriteria.publishWorkName = this.publishWorkName;
    }
    if(this.journalName){
      this.publishedworkSearchCriteria.journalName = this.journalName;
    }
    if(this.journalLevel!=0){
      this.publishedworkSearchCriteria.journalLevel = this.journalLevel;
    }
    if(this.impactFactorFrom){
      this.publishedworkSearchCriteria.impactFactorFrom = this.impactFactorFrom;
    }

    if(this.paticipation!=0){
      this.publishedworkSearchCriteria.paticipation = this.paticipation;
    }
    if(this.impactFactorTo){
      this.publishedworkSearchCriteria.impactFactorTo = this.impactFactorTo;
    }
    if(this.quartileTCILevel!=0){
      this.publishedworkSearchCriteria.quartileTCILevel = this.quartileTCILevel;
    }

    console.log("showsearch",JSON.stringify(this.publishedworkSearchCriteria));
    if(JSON.stringify(this.publishedworkSearchCriteria)!="{}"){
      let url = "/Search/SearchPublishWork";
      let result:any = await this.brokerAPIService.postAwait(url,JSON.stringify(this.publishedworkSearchCriteria),null,null)
      if (result instanceof Error) {
        console.log('showresultError: ', result.message);
        return;
      }
      this.dataSourceResearchResultsPublished.data =result;
    } else {
      this.openSnackBar("กรุณาใส่เงื่อนไขการค้นหาอย่างน้อย 1 เงื่อนไข",this.closeSnackbarMessage);
    }

  }

  //clear
  btnClearPublished() {
    this.publishWorkYear=null;
    this.publishWorkName='';
    this.journalName='';
    this.journalLevel=0;
    this.impactFactorFrom=null;
    this.impactFactorTo=null;
    this.quartileTCILevel=0;
    this.paticipation=0;
    this.dataSourceResearchResultsPublished = new MatTableDataSource();
  }

  //search
  async btnSearchProject() {
    let projectsearchRequest = new projectsearchrequest(
      this.empCode,
      this.organizationId,
      this.researcherFirstName,
      this.researcherLastName,
      this.projectName,
      this.projectKeywoard,
      this.requestResearchYear,
      this.approveResearchYear,
      this.researchDescriptionTypeId,
      this.fundingType
    )
    if(JSON.stringify(projectsearchRequest)!="{}"){
      let url = "/Search/SearchProject";
      let result:any = await this.brokerAPIService.postAwait(url,JSON.stringify(projectsearchRequest),null,null)
      if (result instanceof Error) {
        console.log('showresultError: ', result.message);
        return;
      }
      this.dataProject = result

      for (let i = 0; i < this.dataProject.length; i++) {
        this.dataProject[i].rowNo = i + 1;

      }
       this.dataSourceProject.data = this.dataProject;
    } else {
      this.openSnackBar("กรุณาใส่เงื่อนไขการค้นหาอย่างน้อย 1 เงื่อนไข",this.closeSnackbarMessage);
    }
  }

  //clear
  btnClearProject() {
    this.empCode=null;
    this.organizationId=null;
    this.researcherFirstName=null;
    this.researcherLastName=null;
    this.projectName=null;
    this.projectKeywoard=null;
    this.requestResearchYear=null;
    this.approveResearchYear=null;
    this.researchDescriptionTypeId=null;
    this.fundingType=null;
    this.dataSourceProject = new MatTableDataSource();
  }

  sortData(sort: Sort) {
    // this.dataSource.data = sortingArrayList(this.projectList,sort.active,sort.direction);
  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }


  getOrganizationList(){
    let url="/Master/GetOrganizationList";
    this.brokerAPIService.get(url).subscribe(
      values=>{
        this.organizationList = values;
      },
      error=>{

      }
    )
  }

  getResearchList(){
    let url="/Master/GetResearchDescriptionType";
    this.brokerAPIService.get(url).subscribe(
      values=>{
        this.researchTypeList = values;
      },
      error=>{

      }
    )
  }
}
