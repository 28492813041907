export class registermodel{
    employeeCode: string;
    idCardNo: string;
    gender: string;
    prefixTH: string;
    prefixEN: string;
    firstNameTH: string;
    firstNameEN: string;
    lastNameTH: string;
    lastNameEN: string;
    currentAddress: string;
    currentPosition: string;
    emailAddress: string;
    mobilePhone: string;
    mainOrganizationId: number;
    secondaryOrganization: number;
    otherOrganization: string;
    OtherSecondaryOrganization: string;
    isceD1Id: number;
    isceD2Id: number;
    isceD3Id: number;
    otherISCED: string;
}