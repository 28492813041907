export class budgetdetailallocation{
    id: number;
    projectTrackingBudgetHeadId: number;
    orderNo: number;
    budgetGroupId: number;
    budgetGroup: string;
    budgetCategoryId: number;
    budgetCategory: string;
    budgetDescription: string;
    budgetRequest: number;
    budgetReceived: number;
    withdrawDate: Date;
    withdrawBudget: number;
    actualWithdrawBudget: number;
    budgetUseage: number;
    fiscalYear: number;
    budgetUsagePlan: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}