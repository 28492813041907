
import {HttpClient,} from "@angular/common/http";
import { IAppConfig } from './interfaces/app-config';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })

export class AppConfig {
    static settings: IAppConfig;
    static objOven:any;
    constructor(private http: HttpClient, ) { }

    async load() {
        await this.loadConfig();
    }

    async loadConfig() {
        const jsonFile = 'assets/config/config.json';
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: any) => {
                
                AppConfig.settings = response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}