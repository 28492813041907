export class workexperiencehistory{
    id: number;
    researcherId: number;
    orderNo: number;
    strBeginWorkYear: string;
    strEndWorkYear: string;
    workPosition: string;
    workPlace: string;
    workCountry: string;
    createBy: string;
    createDate: Date;
    upDateBy: string;
    upDateDate: Date;
    inActivated: boolean;
}