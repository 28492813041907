import { publication } from "./publication";

export class projectpublicationdetail extends publication{
    projectId:number;
    publishDate:Date;
    isDb_Scopus: boolean;
    isDb_WebOfScience: boolean;
    isDb_PubMed: boolean;
    isDb_Other: boolean;
    otherDbName: string;
}