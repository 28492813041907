import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import { SDUtilityService } from "../../services/sdutility.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements OnInit {
  UserLang: any = {};
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private sdUtilityService: SDUtilityService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
        //#region "TranslateModule"
        this.UserLang = 'EN';
        if (this.sdUtilityService.SDLocalStorage.getItem("UserLang") == null) {
          this.sdUtilityService.SDLocalStorage.setItem("UserLang", this.UserLang);
        }
        else {
          this.UserLang = this.sdUtilityService.SDLocalStorage.getItem("UserLang");
        }
        this.translate.setDefaultLang(this.UserLang);
        this.translate.use(this.UserLang);
        //#endregion "TranslateModule"
  }

  ngOnInit() {}

  onClickConfirm(): void {
    console.log("onClickConfirm");
    this.dialogRef.close("confirm");
  }

  onClickCancle(): void {
    console.log("onClickCancle");
    this.dialogRef.close("cancle");
  }
}
