import { researcherattachdocument } from "./researcherattachdocument";
import { researcherorganization } from "./researcherorganization";
import { educationhistory } from "./educationhistory";
import { seminarhistory } from "./seminarhistory";
import { presentwork } from "./presentwork";
import { workexperiencehistory } from "./workexperiencehistory";
import { isceD1 } from "./isceD1";
import { isceD2 } from "./isceD2";
import { isceD3 } from "./isceD3";
import { publication } from "./publication";
export class researcher{
    id: number;
    researcherStatus: number;
    approveDate:Date;
    approveBy: string;
    employeeCode: string;
    idCardNo: string;
    gender: string;
    prefixTH: number;
    prefixEN: number;
    firstNameTH: string;
    firstNameEN: string;
    lastNameTH: string;
    lastNameEN: string;
    birthDate: Date;
    currentAddress: string;
    currentPosition: string;
    emailAddress: string;
    mobilePhone: string;
    faxNo:string;
    isceD1Id:number;
    isceD1: isceD1;
    isceD2Id:number;
    isceD2:isceD2;
    isceD3Id:number;
    isceD3:isceD3;
    otherISCED:string;
    organizations: [researcherorganization];
    otherOrganization: string;
    otherSecondaryOrganization: string;
    educationHists: [educationhistory];
    workExpHists: [workexperiencehistory];
    seminarHists: [seminarhistory];
    reward: string;
    organizationMember: string;
    presentWorks: [presentwork];
    publishedWork: string;
    externalPublishedWorks:[publication]
    attachDocuments: [researcherattachdocument];
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
}