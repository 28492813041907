import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../module/material/material.module';
import { MainDataListingComponent } from './main-data-listing/main-data-listing.component';
import { MainDataDetailComponent } from './main-data-detail/main-data-detail.component';

@NgModule({
  declarations: [MainDataListingComponent, MainDataDetailComponent],
  imports: [
    CommonModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
  ]
})
export class MaindataModule { }
