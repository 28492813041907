import { budgetallocation } from "./budgetallocation";
import { projectobjective } from "./projectobjective";
import { projectproposal } from "./projectproposal";
import { trackingactualresult } from "./trackingActualResult";
import { trackingattachdocument } from "./trackingattachdocument";

export class projecttracking{
    id:number;
    projectId:number;
    project: projectproposal;
    trackingTime:number;
    trackingDate:Date;;
    trackingStatus:number;
    objectives:[projectobjective];
    isProjetOnPlan:boolean;
    researchDescription:string;
    percentProjectSuccess:number;
    progressStep1:number;
    progressStep2:number;
    progressStep3:number;
    progressStep4:number;
    trackingActualResults:[trackingactualresult];
    trackingBudgetHead:budgetallocation;
    problemNotice:string;
    nextStepPlan:number;
    projectExtendReason:string;
    trackingAttachDocuments:[trackingattachdocument];
    projectExtendInMonth:number;
    projectCancelReason:string;
    isLastTracking:true;
    trackingExtendTime:number;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}