export class keyword{
    id: number;
    projectId: number;
    orderNo: number;
    keywordTH: string;
    keywordEN: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}