import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { researcher } from 'src/app/datamodel/researcher';
import { BrokerAPIService } from '../../services/brokerapi.service';
import { organization } from 'src/app/datamodel/organization';
import { isceD1 } from 'src/app/datamodel/isceD1';
import { isceD2 } from 'src/app/datamodel/isceD2';
import { isceD3 } from 'src/app/datamodel/isceD3';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IAPIResponse } from 'src/app/interfaces/apiResponse';

@Component({
  selector: 'app-approval-detail',
  templateUrl: './approval-detail.component.html',
  styleUrls: ['./approval-detail.component.scss']
})
export class ApprovalDetailComponent implements OnInit {

  UserLang: any = {};
  researcherData:researcher=new researcher;
  mainOrg:string;
  secondaryOrg:string;
  
  isceD1Desc:string;
  isceD2Desc:string;
  isceD3Desc:string;

  isEditable:boolean;

  organizations:[organization];
  private errorMessage:string;
  constructor(
    private brokerAPIService: BrokerAPIService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) 
  {
    let researcherID=JSON.parse(this.route.snapshot.paramMap.get('dataobj')).param;
    console.log("showresearcher",researcherID);
    this.getResearcher(researcherID);
    let callMethod: string = '/Auth/IsEditableResearch/' + researcherID;
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        this.isEditable = values.isEditable;
        console.log("isEditable", this.isEditable);
      },
      error => this.errorMessage = <any>error
    );
  }

  ngOnInit(): void {
   
  }

  onclickBack() {
    let link = ['researcherlist'];
    this.router.navigate(link);
  }

  async getMaterData(){
    let callMethod:string='/Master/GetOrganizationList';
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        this.organizations = values;
        this.getResearcherOrganization();
      },
      error => {
        this.errorMessage = <any>error
      }
    );
    callMethod='/Master/GetISCED1List';
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        let resultData:[isceD1] = values;
        for(let i=0;i<resultData.length;i++){
          if(resultData[i].id==this.researcherData.isceD1Id){
            this.isceD1Desc = resultData[i].isceD1Desc;
          }
        }
      },
      error => {
        this.errorMessage = <any>error
      }
    );
    callMethod='/Master/GetISCED2List/'+this.researcherData.isceD1Id;
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        let resultData:[isceD2] = values;
        for(let i=0;i<resultData.length;i++){
          if(resultData[i].id==this.researcherData.isceD2Id){
            this.isceD2Desc = resultData[i].isceD2Desc;
          }
        }
      },
      error => {
        this.errorMessage = <any>error
      }
    );
    callMethod='/Master/GetISCED3List/'+this.researcherData.isceD2Id;
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        let resultData:[isceD3] = values;
        console.log("isced3:"+JSON.stringify(resultData));
        for(let i=0;i<resultData.length;i++){
          if(resultData[i].id==this.researcherData.isceD3Id){
            this.isceD3Desc = resultData[i].isceD3Desc;
          }
        }
      },
      error => {
        this.errorMessage = <any>error
      }
    );
  }

  getResearcherOrganization(){
    for(let i=0;i<this.researcherData.organizations.length;i++){
      if(this.researcherData.organizations[i].isMainOrganization){
        this.mainOrg = this.getOrganizationName(this.researcherData.organizations[i].researcherOrgId);
      } else {
        this.secondaryOrg = this.getOrganizationName(this.researcherData.organizations[i].researcherOrgId);
      }

    }
  }

  getOrganizationName(orgId:number):string{
    
    for (let i=0;i<this.organizations.length;i++) {
      
      if (this.organizations[i].id==orgId) {
        return this.organizations[i].organizationName;
      }
    }
    return 'Not found:'+orgId;
  }

  btnSend() {

  }

  btnBack(){
    let link = ['researcherlist'];
    this.router.navigate(link);
  }

  btnApprove(){
    let resercherApproved={'researcherId':this.researcherData.id,'isApprove':true};
    this.sendDecision(resercherApproved);
  }

  btnReject(){
    let resercherApproved={'researcherId':this.researcherData.id,'isApprove':false};
    this.sendDecision(resercherApproved);
  }

  sendDecision(decisionObj:any){
    this.brokerAPIService.post('/Researcher/ApproveResearcher',JSON.stringify(decisionObj)).subscribe(
      values =>{
        let result:IAPIResponse =  values;
        console.log("Return:"+JSON.stringify(result))
        this.openSnackBar(result.message,'');
        this.router.navigate(['/researcherlist']);
      },
      error => {
        this.errorMessage = <any>error;
        console.log("errorMessage:"+JSON.stringify(this.errorMessage));
        this.openSnackBar(this.errorMessage,'');
      }
    );
  }


  
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
       duration: 4000,
    });
  }
  getResearcher(researcherID:number) {
    this.brokerAPIService.get('/Researcher/GetResearcherProfile/' + researcherID).subscribe(
      values => {
        this.researcherData = values;
        console.log("getResearcher",JSON.stringify( this.researcherData));
        this.getMaterData();
       
      },
      error => {

      }
    )
  }
}
