<div class="table-container" style="padding-right: 5px;">
    <div fxFlexFill fxLayout="row">
        <table style="width: 100%;">
            <tr>
                <TD style="width: 2%;">

                </TD>
                <td width=35% style="text-align: left;">
                    <mat-form-field class="example-full-width" style="width: 95%;">
                        <mat-select class="font-light" (selectionChange)="onChangeFromDataReport()" [(ngModel)]="Year" placeholder="Year">
                            <mat-option *ngFor="let year of fiscalYearListFrom" [value]=year>
                                {{ year }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <TD style="width: 65%; text-align: right;">
                    <button (click)="downloadButtonPush()" style="margin-left: 10px;" mat-raised-button class="btn-Export-csv">{{'Export CSV'}}</button>
                </TD>

                <TD style="width: 2%;"></TD>
            </tr>
        </table>
    </div>
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px; margin-bottom: 10px;">

        <div class="welfare-container" style="margin: 10px;">

            <mat-table class="gridrow" [dataSource]="dataSourceProjectDataExport" matSort matSortDisableClear>
                <ng-container *ngFor="let disCol of displayedColumnsProjectDataExport; let colIndex = index" matColumnDef="{{disCol}}">
                    <mat-header-cell class="font-light" *matHeaderCellDef>
                        <div *ngIf="colIndex==0" style=" font-size: 10px; font-family: chulabhornlikittext-bold; background-color: transparent;">{{columnHeaderTitle[colIndex]}}</div>
                        <button *ngIf="colIndex!=0" mat-flat-button style=" font-size: 10px; font-family: chulabhornlikittext-bold; background-color: transparent; white-space: normal; width: 10px;" (click)="onHeaderClick(colIndex)">
                        <mat-checkbox (click)="false" [(ngModel)]="selectedColumn[colIndex]"  ></mat-checkbox>
                            {{columnHeaderTitle[colIndex]}}
                        </button>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index " style="text-align: center;">
                        <div style="width: 100%; text-align: center; font-size: 10px; font-family: chulabhornlikittext-bold; background-color: transparent;"> {{colIndex==0?i+1:element[disCol]}}</div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsProjectDataExport"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsProjectDataExport;" (click)="rowClicked(row)"></mat-row>
            </mat-table>
        </div>

    </div>