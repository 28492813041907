export class projectNumberByYear{
     // main dashboard
     totalProjectInFiscalYear: {
        newProjectCount: number;
        contProjectCount: number;
      };
      totalProjectByFundType: {
        noGrantFunding: number;
        internalFunding: number;
        governmentFunding: number;
        privateFunding: number;
      };
      newProjectByFundType: {
        noGrantFunding: number;
        internalFunding: number;
        externalFunding: number;
      };
      contProjectByFundType: {
        noGrantFunding: number;
        internalFunding: number;
        externalFunding: number;
      };
}

export class totalProjectByYearReport{
  fundingType: number;
  fundingDesc: string;
  affiliation: string;
  organizationName: string;
  newProjectCount: number;
  contProjectCount: number;
  totalProjectCount: number;
  newProjectBudget: number;
  contProjectBudget: number;
  totalProjectBudget: number;
  isSumRow:boolean;

  constructor(
    fundingType: number,
    fundingDesc: string,
    affiliation: string,
    organizationName: string,
    newProjectCount: number,
    contProjectCount: number,
    totalProjectCount: number,
    newProjectBudget: number,
    contProjectBudget: number,
    totalProjectBudget: number
  
  ){
    this.fundingType= fundingType;
    this.fundingDesc= fundingDesc;
    this.affiliation= affiliation;
    this.organizationName= organizationName;
    this.newProjectCount= newProjectCount;
    this.contProjectCount= contProjectCount;
    this.totalProjectCount= totalProjectCount;
    this.newProjectBudget= newProjectBudget;
    this.contProjectBudget= contProjectBudget;
    this.totalProjectBudget= totalProjectBudget;
  }
}

export class budgetUsageByOrg{
  organizationName: string;
  totalProject: number;
  budgetReceived: number;
  budgetUsage: number;
  budgetRemain: number;
  percentUsage: number;
  withdrawStatusOnplan: number;
  withdrawStatusDeplay: number;
  withdrawStatusOverlap: number;
  deliverTrackingReport: number;
  remainTrackingReport: number;
  deliverDraftReport: number;
  remainDraftReport: number;
  deliverCompleteReport: number;
  remainCompleteReport: number;
}