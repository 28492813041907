export class budgetreceived{
    id: number;
    projectTrackingBudgetHeadId: number;
    fiscalYear: number;
    budgetCode: string;
    budgetAreaCode: string;
    budgetReceived: number;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}