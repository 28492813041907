import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { totalProjectByYearReport } from 'src/app/datamodel/chartdatamodel';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';



@Component({
  selector: 'app-report-amount-research-projects',
  templateUrl: './report-amount-research-projects.component.html',
  styleUrls: ['./report-amount-research-projects.component.scss']
})
export class ReportAmountResearchProjectsComponent implements OnInit {

  @HostListener('window:message',['$event'])
  onMessage(e)
  {
    console.log("showdata",JSON.stringify(e));
  if (e.origin!="http://localhost:4200")
    {
    return false;
    }
  if (e.data.for=="user")
    {
    alert('here i am');
    }
  }

  @Input() objAmountResearchProject: any = {};
  Year: number;
  AmountResearchProject:totalProjectByYearReport[];
  hasData:boolean;
  
  dataProjectNobudget: any = [];
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    public sdUtilityService: SDUtilityService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    console.log(this.objAmountResearchProject);
    this.Year = this.objAmountResearchProject.year
    this.GetPublishedWorkReport()
  }

  GetPublishedWorkReport() {

    let callMethod: string = '/Report/GetTotalProjectByYearReport/'  + this.Year;
    let groupSum0=0; 
    let groupSum1=0; 
    let groupSum2=0; 
    let groupSum3=0; 
    let groupSum4=0; 
    let groupSum5=0;
    let netSum0=0; 
    let netSum1=0; 
    let netSum2=0; 
    let netSum3=0; 
    let netSum4=0; 
    let netSum5=0;
    
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        // console.log(values);
        let results:totalProjectByYearReport[] = values;
        this.AmountResearchProject = [];
        if(values){
          this.hasData = true;
          let projectTypeTitle:string ="";
          let organizationTitle:string ="";
          let affiliationTitle:string ="";
          for(let i = 0 ; i< results.length;i++){
            if(results[i].fundingDesc==projectTypeTitle){
              results[i].fundingDesc = "";
            } else {
              projectTypeTitle = results[i].fundingDesc;
              if(i!=0){
                let sumLine = new totalProjectByYearReport(
                  -99, 
                  'ยอดรวม', 
                  'aaaaa', 
                  '', 
                  groupSum0, 
                  groupSum1, 
                  groupSum2, 
                  groupSum3, 
                  groupSum4, 
                  groupSum5,
                );
                this.createRow(sumLine);
                groupSum0=0; 
                groupSum1=0; 
                groupSum2=0; 
                groupSum3=0; 
                groupSum4=0; 
                groupSum5=0;
              }

            }
            if(results[i].organizationName==organizationTitle){
              results[i].organizationName = "";
            } else {
              organizationTitle = results[i].organizationName;
            }
            if(results[i].affiliation==affiliationTitle){
              results[i].affiliation = "";
            } else {
              affiliationTitle = results[i].affiliation;
            }
            this.createRow(results[i]);
            groupSum0=groupSum0+results[i].newProjectCount;
            groupSum1=groupSum1+results[i].contProjectCount;
            groupSum2=groupSum2+results[i].totalProjectCount;
            groupSum3=groupSum3+results[i].newProjectBudget;
            groupSum4=groupSum4+results[i].contProjectBudget;
            groupSum5=groupSum5+results[i].totalProjectBudget;
            netSum0=netSum0+results[i].newProjectCount;
            netSum1=netSum1+results[i].contProjectCount;
            netSum2=netSum2+results[i].totalProjectCount;
            netSum3=netSum3+results[i].newProjectBudget;
            netSum4=netSum4+results[i].contProjectBudget;
            netSum5=netSum5+results[i].totalProjectBudget;
          }
          let sumLine = new totalProjectByYearReport(
            -99, 
            'ยอดรวม', 
            'aaaaa', 
            '', 
            groupSum0, 
            groupSum1, 
            groupSum2, 
            groupSum3, 
            groupSum4, 
            groupSum5,
          );
          this.createRow(sumLine);
          sumLine = new totalProjectByYearReport(
            -99, 
            'ยอดรวมทั้งหมด', 
            'aaaaa', 
            '', 
            netSum0, 
            netSum1, 
            netSum2, 
            netSum3, 
            netSum4, 
            netSum5,
          );
          this.createRow(sumLine);
          // console.log("data",JSON.stringify(this.AmountResearchProject));
          
          
        }
        else {
          this.AmountResearchProject = [];
          this.hasData = false;
        }

       
        // values.forEach(obj => {
          
        // });


        // if (values.fundingType == 0) {
        //   this.dataProjectNobudget.push({
        //     affiliation: values.affiliation,
        //     contProjectBudget: values.contProjectBudget,
        //     contProjectCount: values.contProjectCount,
        //     fundingDesc: values.fundingDesc,
        //     fundingType: values.fundingType,
        //     newProjectBudget: values.newProjectBudget,
        //     newProjectCount: values.newProjectCount,
        //     organizationName: values.organizationName,
        //     totalProjectBudget: values.totalProjectBudget,
        //     totalProjectCount: values.totalProjectCount,
        //   });
        // } 

      });

      // console.log("NoBudget",this.dataProjectNobudget);
      
  }


  createRow(dataRow:totalProjectByYearReport){
    let newRow = new totalProjectByYearReport(
      dataRow.fundingType, 
      dataRow.fundingDesc, 
      dataRow.affiliation, 
      dataRow.organizationName, 
      dataRow.newProjectCount, 
      dataRow.contProjectCount, 
      dataRow.totalProjectCount, 
      dataRow.newProjectBudget, 
      dataRow.contProjectBudget, 
      dataRow.totalProjectBudget
    );
    if(!this.AmountResearchProject){
      this.AmountResearchProject = [newRow];
    } else {
      this.AmountResearchProject.push(newRow);
    }
  }
}
