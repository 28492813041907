import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';

@Component({
  selector: 'app-main-data-listing',
  templateUrl: './main-data-listing.component.html',
  styleUrls: ['./main-data-listing.component.scss']
})
export class MainDataListingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  objDataOrganization: any[];

  dataSourceOrganization = new MatTableDataSource();
  displayedColumnsOrganization = ['RowNo', 'OrganizationName', 'OrganizationShortName', 'Affiliation', 'Inactive'];

  filter: string = "";
  pageNumber: number = 0;

  ngAfterViewInit() {
    this.dataSourceOrganization.paginator = this.paginator;
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    public sdUtilityService: SDUtilityService) { }

  ngOnInit(): void {
    this.getDataOrganization();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSourceOrganization.filter = filterValue;
  }

  getDataOrganization() {
    let callMethod: string = '/Master/GetOrganizationList';
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        this.objDataOrganization = [];

        this.objDataOrganization = values

        for (let i = 0; i < this.objDataOrganization.length; i++) {

          this.objDataOrganization[i].rowNo = i + 1;
        }

         this.dataSourceOrganization.data = this.objDataOrganization;

      });
    console.log("dataSource", this.dataSourceOrganization);

  }

  rowClicked(row) {
    this.router.navigate(['maindataDetail', JSON.stringify(row)]);

  }

  btnNew() {
    var objOrganization = {
      affiliation: "",
      createBy: "",
      createDate: "",
      inActivated: false,
      orderNo: null,
      organizationName: "",
      organizationShortName: "",
      updateBy: "",
      updateDate: "",
      isNewForm: true
    }

    this.router.navigate(['maindataDetail', JSON.stringify(objOrganization)]);
  }

  onPaginateChange(datatest: any) {
    if (datatest['pageIndex'] == 0) {
      this.pageNumber = 0;
    } else {
      this.pageNumber = datatest['pageIndex'] * datatest['pageSize'];
    }
  }

}
