<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="20px" style="padding-top: 0px; padding-right: 0px; padding-left: 0px;">
    <div fxFlex class="table-component-holder mat-elevation-z4">
        <div class="table-container" style="padding-top: 10px; padding-bottom: 10px;  background-color: #FD7E14; color: #002F87;">

            <!-- <div class="container" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center"> -->

            <div style="padding-left: 15px;">
                <button class="btn-Menu" mat-raised-button [matMenuTriggerFor]="menu">Menu </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button *ngIf="isShowingTrackingEntry" class="font-light" mat-menu-item (click)="openProgressDialog()">{{trackingResultCaption}}</button>
                    <button *ngIf="isShowingTrackingResultEntry" class="font-light" mat-menu-item (click)="openProgressReivewDialog()">{{trackingResultEntryCaption}}</button>
                </mat-menu>
            </div>
            <div fxFlex="40%"></div>

            <div fxFlex="20%" style="padding-top: 5px;">
                <label class="font-bold" style=" color: #002F87;">{{'รายงานความก้าวหน้าโครงการวิจัย'}}</label>
            </div>
            <div fxFlex="28%"></div>
            <div fxFlex="6%" style="padding-right: 15px;">
                <button mat-raised-button class="btn-back" (click)="btnBack()">{{'ย้อนกลับ'}}</button>
            </div>
            <div fxFlex="6%" style="padding-right: 15px;">

                <button *ngIf="isDataEditable" mat-raised-button class="btn-black" (click)="btnSave()">{{'บันทึก'}}</button>
            </div>
            <!-- <div style="padding-right: 10px;">
                    <button *ngIf="isDataEditable" mat-raised-button class="btn-black" (click)="btnSave()">{{'บันทึก'}}</button>
                </div> -->
        </div>

    </div>
    <!-- </div> -->
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin: 10px;">
    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px; margin-bottom: 10px;">
        <!-- <mat-toolbar class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #002F87; color: white; text-align: center;"> -->
        <div class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #b5d5f3; color: #002F87; text-align: center; padding-top: 10px; padding-bottom: 10px;">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%;">

                    </td>
                    <td style="width: 20%;">
                        <label class="font-bold">{{'รายละเอียดโครงการวิจัย'}}</label>
                    </td>
                    <td style="width: 40%;">

                    </td>
                </tr>
            </table>

        </div>


        <!-- </mat-toolbar> -->

        <div style="padding: 0px;" class="font-light">


            <div class="table-container" style="padding: 5px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="100%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="500" autocomplete="off" placeholder="{{'ชื่อโครงการวิจัย'}}" [(ngModel)]="projectTrackingData.project.projectNameTH">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="25%">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="100" autocomplete="off" placeholder="{{'รหัสโครงการ'}}" [(ngModel)]="projectTrackingData.project.projectCode">
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="37.5%">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="100" autocomplete="off" placeholder="{{'ชื่อหัวหน้าโครงการวิจัย'}}" [(ngModel)]="projectOwnerFullname">
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="37.5%">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="100" autocomplete="off" placeholder="{{'หน่วยงาน'}}" value={{organizationName}}>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="25%">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="50" autocomplete="off" placeholder="{{'โทรศัพท์'}}" value={{mobielPhone}}>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="25%">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="50" autocomplete="off" placeholder="{{'อีเมล์'}}" value={{email}}>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="25%">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="100" autocomplete="off" placeholder="{{'วันที่ได้รับการอนุมัติ'}}" value={{projectApprovalDate}}>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="25%">
                        <mat-form-field class="font-light">
                            <input style="text-align: right;" matInput readonly maxlength="100" autocomplete="off" placeholder="{{'ระยะเวลาที่ขออนุมัติดำเนินโครงการ (เดือน)'}}" value={{projectWorkingMonth}}>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

            </div>
        </div>

    </div>

</div>
<!-- dialog -->
<mat-card *ngIf="isshowdialogRecordResolutions" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">


    <mat-card style="background-color: white; border-radius: 10px; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label class="font-bold">บันทึกมติการพิจารณารายงานความก้าวหน้าของคณะกรรมการ</label>
        </div>
        <div class="table-container" style="padding: 0px;">
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <div fxFlex="2"></div>

                <div fxFlex="33%">

                    <section fxLayout="row" class="font-light;">
                        <label class="font-bold" style=" color: #002F87; padding-right: 30px; margin-top: 15px;"></label>
                        <mat-radio-group fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="trackingCommitteeSelection" (change)="onComitteeDecisionChange()">
                            <mat-radio-button style="color: #002F87; padding-bottom: 25px; " class="font-light" value="1">{{'เห็นควรสนับสนุนให้ดำเนินการวิจัยต่อ' }} </mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px; " class="font-light" value="2">{{'เห็นควรให้ขยายระยะเวลาโครงการอีก' }}
                                <mat-form-field style=" width: 20%; ">
                                    <input [disabled]="!trackingCommitteeExtendedMonthEnable" style="text-align: center;" type="number" min="0" step="1" matInput maxlength="50" autocomplete="off" [(ngModel)]="trackingCommitteeExtendedMonth">
                                </mat-form-field>
                                <mat-lable style="color: #002F87; padding-bottom: 10px; " class="font-light">เดือน</mat-lable>
                            </mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 25px; " class="font-light" value="3">{{'เห็นควรอนุมัติให้ปิดโครงการวิจัย' }}</mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px; " class="font-light" value="4">{{'ข้อเสนอแนะเพิ่มเติม' }}</mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px; " class="font-light" value="5">{{'เห็นควรให้ยุติโครงการ' }}</mat-radio-button>
                        </mat-radio-group>
                    </section>

                </div>
                <div fxFlex="67%"></div>
            </div>
            <div *ngIf="isTrackingCommitteeAttachfile" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <table style="width: 100%;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td style="width: 50%; text-align: center;">
                            <input class="font-light" style="width: 800px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px; font-family: chulabhornlikittext-light;" placeholder="เรื่อง" type="text" [(ngModel)]="trackingCommitteeTopic">
                        </td>

                    </tr>
                </table>

            </div>
            <div *ngIf="isTrackingCommitteeAttachfile||isStopingProject" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <div fxFlex="2" style="padding-right: 45px;"></div>

                <div fxFlex="100%">
                    <angular-editor [(ngModel)]="trackingCommitteeMessage" [config]="editorConnfig">
                    </angular-editor>
                </div>
                <div fxFlex="2"></div>
            </div>

            <div>
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
                    <table style="width: 100%;">
                        <tr>
                            <td fxFlex="2" style="padding-right: 45px;"></td>
                            <td *ngIf="isTrackingCommitteeAttachfile||isStopingProject" style="width: 33%; text-align: left;" class="font-light">
                                <input class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" value={{committeeDecisionFileName1}}>
                                <button mat-raised-button style=" font-family: chulabhornlikittext-bold; margin-left: 3px;" class="btn-UploadFile" (click)="imgFileInput1.click()">{{'แนบไฟล์'}}</button>
                                <input hidden type="file" #imgFileInput1 (change)="handleFileInputCommittee($event.target.files,1)" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx" />
                            </td>

                            <td *ngIf="isTrackingCommitteeAttachfile" style="width: 33%; text-align: left;" class="font-light">
                                <input class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" value={{committeeDecisionFileName2}}>
                                <button mat-raised-button style=" font-family: chulabhornlikittext-bold; margin-left: 3px;" class="btn-UploadFile" (click)="imgFileInput2.click()">{{'แนบไฟล์'}}</button>
                                <input hidden type="file" #imgFileInput2 (change)="handleFileInputCommittee($event.target.files,2)" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx" />
                            </td>
                            <td *ngIf="isTrackingCommitteeAttachfile" style="width: 33%; text-align: left;" class="font-light">
                                <input class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" value={{committeeDecisionFileName3}}>
                                <button mat-raised-button style=" font-family: chulabhornlikittext-bold; margin-left: 3px;" class="btn-UploadFile" (click)="imgFileInput3.click()">{{'แนบไฟล์'}}</button>
                                <input hidden type="file" #imgFileInput3 (change)="handleFileInputCommittee($event.target.files,3)" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx" />
                            </td>

                        </tr>
                    </table>
                </div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 20px;">
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 50%; text-align: right; padding-right: 23px;" class="font-light">
                            <button mat-raised-button (click)="submitProgressReivewDialog()" class="btn-black">{{'บันทึก' }}</button>
                        </td>
                        <!-- <td style="width: 20%; text-align: center;">

                        </td> -->
                        <td style="width: 50%; text-align: left; padding-left: 23px;" class="font-light">
                            <button mat-raised-button (click)="closenProgressReivewDialog()" class="btn-back">{{'ย้อนกลับ' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isshowdialogSentReport" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <!-- <h1 style="background-color: #002F87; color: white; width: 100%; height: 320px;">บันทึกตรวจสอบเอกสาร</h1><br> -->

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'ส่งรายงานความก้าวหน้าโครงการ' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="2"></div>
                <!-- <div fxFlex="10%"></div> -->
                <div fxFlex="100%" style="text-align: center;  width: 240px;">

                    <label class="font-bold" style="color: #002F87;">ส่งรายงานความก้าวหน้าโครงการวิจัย</label>
                </div>
                <!-- <div fxFlex="10%"></div> -->
                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 20px; padding-bottom:20px;">
                <div fxFlex="2"></div>
                <!-- <div fxFlex="10%"></div> -->
                <div fxFlex="100%" style="text-align: center;  width: 240px;">

                    <label class="font-bold" style="color: #002F87;">ครั้งที่  {{trackingTime}}</label>
                </div>
                <!-- <div fxFlex="10%"></div> -->
                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 50%; text-align: right; padding-right: 12px;" class="font-light">
                            <button mat-raised-button class="btn-send" (click)="submitProgress()">{{'ยืนยัน' }}</button>
                        </td>
                        <!-- <td style="width: 20%; text-align: center;">

                        </td> -->
                        <td style="width: 50%; text-align: left; padding-left: 12px;" class="font-light">
                            <button mat-raised-button class="btn-black" (click)="closeProgressDialog()">{{'ย้อนกลับ' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<!-- <div fxLayout="column" class="bottom-tab-area" fxLayoutGap="20px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 0px; margin-right: 0px;"> -->
<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="10px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 10px;  margin-right: 10px;">

    <!-- <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px;  "> -->
    <!-- <div fxFlex style="border-radius: 10px; margin-bottom: 0px;"> -->

    <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);" class="font-light">
        <mat-tab *ngIf="isLongForm" label="{{'วัตถุประสงค์ของการทำวิจัย'}}">
            <!-- <ng-template mat-tab-label>
                <span class="font-medium" [ngStyle]="{'color': isObjectivesValid ? 'black' : 'red'  }">{{'วัตถุประสงค์ของการทำวิจัย'}}</span>
            </ng-template> -->
            <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px;">

                <!-- <div style="padding: 10px; text-align: right;" class="font-bold" (click)="btnAddObjective()">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label style="color: #002F87; font-size: 15px; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มวัตถุประสงค์'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div> -->

                <mat-table [dataSource]="dataSourceObjective" matSort matSortActive="Objective" matSortDirection="asc" matSortDisableClear>


                    <ng-container matColumnDef="lineNo">
                        <mat-header-cell fxFlex="5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                        <mat-cell fxFlex="5" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="objective">
                        <mat-header-cell fxFlex="100%" style="color: #002F87; text-align: center;" class="font-light" *matHeaderCellDef>{{'วัตถุประสงค์'}}</mat-header-cell>
                        <mat-cell fxFlex="100%" *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input readonly matInput maxlength="100" [(ngModel)]="objjob.objective" autocomplete="off">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsObjective"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsObjective;"></mat-row>
                </mat-table>



                <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                    <tr style="width: 100%;">
                        <td style="width: 95%;"></td>
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                        </td>

                    </tr>
                </div>
            </mat-card>
            <!-- </div> -->
        </mat-tab>

        <mat-tab label="{{'ผลการดำเนินงาน'}}">
            <ng-template mat-tab-label>
                <span class="font-medium" [ngStyle]="{'color': isOperationListValid ? 'black' : 'red'  }">{{'ผลการดำเนินงาน'}}</span>
            </ng-template>
            <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px;">

                <div class="table-container" style="padding: 0px;">
                    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">

                        <div fxFlex="2"></div>
                        <div fxFlex="10"> <label class="font-light" style=" color: #002F87; padding-right: 30px; margin-top: 15px; font-weight: bold;">{{'ดำเนินการวิจัย' }}</label></div>
                        <div fxFlex="33%">
                            <section fxLayout="row" class="font-light;">

                                <mat-radio-group [disabled]="!isDataEditable" (change)="onChangeOperationList($event)" class="font-light" fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="onPlanCode">
                                    <mat-radio-button class="font-light" style="color: #002F87; margin-right: 30px;" value="1">{{'เป็นไปตามแผน' }} </mat-radio-button>
                                    <mat-radio-button class="font-light" style="color: #002F87;" value="2">{{'ไม่เป็นไปตามแผน' }}</mat-radio-button>
                                </mat-radio-group>
                            </section>

                        </div>
                        <div fxFlex="2"></div>
                    </div>

                    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                        <div fxFlex="12"></div>
                        <div fxFlex="85%">
                            <mat-form-field class="font-light">
                                <textarea [disabled]="!isDataEditable" matInput maxlength="500" autocomplete="off" placeholder="{{'โปรดระบุรายละเอียด'}}" [(ngModel)]="projectTrackingData.researchDescription"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxFlex="2"></div>

                    </div>

                    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                        <div fxFlex="2"></div>
                        <div fxflax="100%">
                            <label class="font-light" style="color: #002F87;">{{'ผลการดำเนินโครงการวิจัยคิดเป็นร้อยละ'}}
                                    <mat-form-field class="font-light" style=" width: 20%;">
                                        <input [disabled]="!isDataEditable"  style="text-align: right;" type="number" min="0" step="1" matInput maxlength="500" autocomplete="off" [(ngModel)]="projectTrackingData.percentProjectSuccess">
                                    </mat-form-field>
                                    จากเป้าหมาย
                                </label>
                        </div>
                    </div>

                    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                        <div fxFlex="2"></div>
                        <div fxflax="100%">
                            <label class="font-light" style="color: #002F87;">{{'สถานะการดำเนินงานตามขั้นตอนการวิจัย'}}</label>
                        </div>
                    </div>
                </div>

                <mat-table [dataSource]="dataSourceOperationProcedure" matSort matSortActive="Month" matSortDirection="asc" matSortDisableClear>



                    <ng-container matColumnDef="lineNo">
                        <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                        <mat-cell fxFlex="4" style="color: #002F87;" class="font-light" *matCellDef="let element; let i = index">{{i + 1}}.</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operationProcedure">
                        <mat-header-cell fxFlex="80" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ขั้นตอนการดำเนินการ'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <div fxFlex="80" style="color: #002F87;" class="font-light">{{ objjob.description }}</div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operationStatus">
                        <mat-header-cell fxFlex="16" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สถานะการดำเนินการ'}}</mat-header-cell>
                        <mat-cell fxFlex="16" *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <mat-select class="font-light" [disabled]="!isDataEditable" [(ngModel)]="objjob.status">
                                    <div class="font-light-small">
                                        <mat-option value="1">ยังไม่ดำเนินการ</mat-option>
                                        <mat-option value="2">อยู่ระหว่างดำเนินการ</mat-option>
                                        <mat-option value="3">เสร็จสิ้น</mat-option>
                                    </div>
                                </mat-select>

                            </mat-form-field>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsOperationProcedure"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsOperationProcedure;"></mat-row>
                </mat-table>

                <mat-table [dataSource]="dataSourceExpectedResults" matSort matSortActive="fromMonth" matSortDirection="asc" matSortDisableClear style="margin-top: 5px;">



                    <ng-container matColumnDef="lineNo">
                        <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                        <mat-cell fxFlex="4" style="color: #002F87;" class="font-light" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="fromMonth">
                        <mat-header-cell fxFlex="11" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'เดือนที่'}}</mat-header-cell>
                        <mat-cell fxFlex="11" *matCellDef="let objjob">
                            <div class="font-light">
                                {{objjob.fromMonth}} - {{objjob.toMonth}}
                            </div>
                        </mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="activityName">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'กิจกรรม/ขั้นตอนการดำเนินงานตามแผนงาน'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <div class="font-light">
                                {{objjob.activityName}}
                            </div>

                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="expectedResult">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ผลการดำเนินงานที่เกิดขึ้นจริง'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input [disabled]="!isDataEditable" matInput maxlength="100" [(ngModel)]="objjob.actualResult" autocomplete="off">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="percentAssociate">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ร้อยละความสำเร็จของผลการดำเนินงานที่เกิดขึ้นจริง'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input [disabled]="!isDataEditable" matInputtype="number" min="0" type="number" step="1" matInput maxlength="8" [(ngModel)]="objjob.percentOfActivity" autocomplete="off">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumnsExpectedResults"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsExpectedResults;"></mat-row>
                </mat-table>



                <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                    <tr style="width: 100%;">
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ย้อนกลับ'}}</label>
                                
                            </button>
                        </td>
                        <td style="width: 90%;"></td>
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                        </td>

                    </tr>
                </div>
            </mat-card>
        </mat-tab>

        <mat-tab label="{{'รายงานการใช้จ่ายเงินงบประมาณ'}}">
            <ng-template mat-tab-label>
                <span class="font-medium" [ngStyle]="{'color': isUseBudgetValid ? 'black' : 'red'  }">{{'รายงานการใช้จ่ายเงินงบประมาณ'}}</span>
            </ng-template>
            <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px;">


                <div class="table-container" style="padding: 0px;">
                    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                        <div fxFlex="2"></div>
                        <div fxFlex="40%">
                            <mat-form-field class="font-light" style="color: black;">
                                <div>
                                    <input disabled class="font-light" placeholder="{{'งบประมาณทั้งโครงการ (บาท)'}}" matInput maxlength="12" autocomplete="off" style="text-align: right;" matInput autocomplete="off" value="{{totalProjectBudget}}">
                                </div>
                            </mat-form-field>
                        </div>
                        <div fxFlex="2"></div>

                        <div fxFlex="54%">
                            <mat-form-field class="font-light" style="color: black;">
                                <div>
                                    <input disabled class="font-light" style="font-weight: bold;" placeholder="{{'จำนวนเงิน'}}" matInput maxlength="150" autocomplete="off" [(ngModel)]="totalProjectBudgetInThai">
                                </div>
                            </mat-form-field>
                        </div>
                        <div fxFlex="2"></div>
                    </div>
                    <mat-table [dataSource]="dataSourceSourceOfFund" matSort matSortDisableClear>


                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="4" style="color: #002F87; text-align: center;" class="font-light" *matCellDef="let element; let i = index">
                                <div class="font-light" style="text-align: center; width: 100%;">
                                    {{i + 1}}
                                </div>
                            </mat-cell>
                            <mat-footer-cell fxFlex="4" *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="fiscalYear" style="margin-left: 10px;">
                            <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีงบประมาณ'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob; let i = index">
                                <div class="font-light" style="text-align: center; width: 100%;">
                                    {{objjob.fiscalYear}}
                                </div>
                            </mat-cell>
                            <mat-footer-cell fxFlex="10" *matFooterCellDef>
                                <div class="font-light" style="text-align: left; width: 100%; font-weight: bold;">
                                    ยอดรวม
                                </div>
                            </mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="budgetReceived">
                            <mat-header-cell fxFlex="20" style="color: #002F87; font-weight: bold;" class="font-light" *matHeaderCellDef>{{'จำนวนเงิน(บาท)'}}</mat-header-cell>
                            <mat-cell fxFlex="20" *matCellDef="let objjob">
                                <div class="font-light" style="text-align: right;width: 100%; padding-right: 5px; text-align: center;">
                                    {{transformAmount(objjob.budgetReceived)}}
                                </div>
                            </mat-cell>
                            <mat-footer-cell fxFlex="20" *matFooterCellDef>
                                <div class="font-light" style="width: 100%; text-align: right;padding-right: 5px; text-align: center;">
                                    <div class="font-light" style="text-align: left; width: 100%; font-weight: bold;">
                                        {{transformAmount(totalFundingAllocation)}}
                                    </div>

                                </div>
                            </mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="budgetCode">
                            <mat-header-cell fxFlex="40" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'แหล่งเงิน(รหัสหน่่วยงาน)'}}</mat-header-cell>
                            <mat-cell fxFlex="40" *matCellDef="let objjob,let i = index">
                                <div class="font-light" style="text-align: center; width: 100%;">
                                    {{objjob.budgetCode}}
                                </div>
                            </mat-cell>
                            <mat-footer-cell fxFlex="40" *matFooterCellDef></mat-footer-cell>
                        </ng-container>

                        <ng-container matColumnDef="budgetAreaCode">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'รหัสกิจกรรม'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <div class="font-light" style="text-align: center; width: 100%;">
                                    {{objjob.budgetAreaCode}}
                                </div>
                            </mat-cell>
                            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
                        </ng-container>


                        <mat-header-row *matHeaderRowDef="displayedColumnSourceOfFund"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnSourceOfFund;"></mat-row>
                        <mat-footer-row *matFooterRowDef="displayedColumnSourceOfFund"></mat-footer-row>
                    </mat-table>

                    <div class="table-container" style="padding: 5px; margin-top: 20px;">
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="2"></div>
                            <div fxFlex="15%">
                                <mat-form-field class="font-light" style="color: black;">
                                    <div>
                                        <input placeholder="{{'จำนวนเดือน'}}" readonly class="font-light" matInput maxlength="2" autocomplete="off" style="text-align: right;" type="number" min="0" step="1" matInput autocomplete="off" [(ngModel)]="totalProjectMonth">
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="15%">
                                <mat-form-field class="font-light" style="color: black;">
                                    <div class="font-light">
                                        <input readonly class="font-light" placeholder="{{'จำนวนงวด'}}" matInput maxlength="2" autocomplete="off" style="text-align: right;" type="number" min="0" step="1" matInput autocomplete="off" [(ngModel)]="budgetPaymentTerm">
                                    </div>
                                </mat-form-field>
                            </div>
                            <!-- <div class="font-light" style="color: black;" class="font-light"> {{bahtThaiBudget}})</div> -->
                            <div fxFlex="60%"></div>
                            <div fxFlex="20%" style="text-align: right;">

                            </div>
                        </div>
                    </div>
                    <mat-table [dataSource]="dataSourceBudgetTerm" matSort matSortDisableClear>


                        <ng-container matColumnDef="termNo" style="margin-left: 10px;">
                            <mat-header-cell fxFlex="20" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'งวดที่'}}</mat-header-cell>
                            <mat-cell fxFlex="20" *matCellDef="let objjob; let i = index">
                                <div class="font-light" style="text-align: center; width: 100%;">
                                    <input disabled style="text-align: center;" style="color: #002F87;" matInput maxlength="100" [(ngModel)]="objjob.termNo" autocomplete="off">
                                </div>
                            </mat-cell>
                            <mat-footer-cell fxFlex="20" *matFooterCellDef>
                                <div class="font-light" style="text-align: left; width: 100%; font-weight: bold;">
                                    ยอดรวม
                                </div>
                            </mat-footer-cell>

                        </ng-container>

                        <ng-container matColumnDef="budgetReceived">
                            <mat-header-cell style="color: #002F87;text-align: right; font-weight: bold;" class="font-light" *matHeaderCellDef>
                                {{'จำนวนเงิน(บาท)'}}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let objjob" style="text-align: right;">
                                <div class="font-light" style="text-align: center; width: 100%;">
                                    <input readonly style="text-align: right;" matInput maxlength="100" value=" {{transformAmount(objjob.budgetReceived)}}" autocomplete="off">
                                </div>
                            </mat-cell>
                            <mat-footer-cell style="text-align: center; width: 100%;" *matFooterCellDef>
                                <!-- <div style="width: 100%; text-align: right;"> {{transformAmount(totalTermAmount)}} </div> -->
                                <div class="font-light" style="text-align: left; width: 100%; font-weight: bold;">
                                    <input readonly style="text-align: right;" matInput maxlength="100" value=" {{transformAmount(totalTermAmount)}}" autocomplete="off">
                                </div>
                                <!-- <input disabled style="text-align: center;"  matInput maxlength="100" [(ngModel)]="objjob.totalTermAmount" autocomplete="off"> -->
                            </mat-footer-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnBudgetTerm"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnBudgetTerm;"></mat-row>
                        <mat-footer-row *matFooterRowDef="displayedColumnBudgetTerm"></mat-footer-row>
                    </mat-table>

                    <div style="padding: 10px; text-align: right;" class="font-bold">
                        <tr *ngIf="isDataEditable" style="width: 100%;">
                            <td style="width: 70%;">

                            </td>
                            <td style="width: 14%;" style="margin-bottom: 10px;">
                                <label (click)="btnAddPayment()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มการเบิกจ่าย'}}</label>
                            </td>
                            <td style="width: 1%;">
                                <img (click)="btnAddPayment()" style=" height: 20px; width: 20px;" src="assets\Icon\Add.png">
                            </td>

                        </tr>
                    </div>


                </div>
                <mat-table [dataSource]="dataSourceBudgetSpending" matSort matSortActive="BudgetGroup" matSortDirection="asc" matSortDisableClear>

                    <ng-container matColumnDef="Control">
                        <mat-header-cell fxFlex="5" *matHeaderCellDef>
                        </mat-header-cell>

                        <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                            <span class="mobile-label"></span>

                            <button mat-icon-button focusable="false" color="accent">
                                    <img *ngIf="isDataEditable"  style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png" (click)="btnDeletePayment(i)">
                                </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lineNo">
                        <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                        <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                            <div class="font-light" style="color: #002F87; width: 100%; text-align: center;">
                                {{i + 1}}
                            </div>
                        </mat-cell>
                    </ng-container>



                    <ng-container matColumnDef="budgetCategory">
                        <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณย่อย'}}</mat-header-cell>
                        <mat-cell fxFlex="10" *matCellDef="let objjob,let i = index">
                            <mat-select class="font-light" style="text-align: center;" [disabled]="!isDataEditable" [(ngModel)]="objjob.budgetCategoryId" (selectionChange)="onCategoryChange($event.value,i)">
                                <div class="font-light-small" style="width: 100%; text-align: center;">
                                    <mat-option *ngFor="let budgetSubGroup of budgetSubGroupList" [value]=budgetSubGroup.id>
                                        {{ budgetSubGroup.description }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="budgetGroup">
                        <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณ'}}</mat-header-cell>
                        <mat-cell fxFlex="10" *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align: center;">
                                {{objjob.budgetGroup}}
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="budgetDescription">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'รายการ'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input matInput maxlength="100" [(ngModel)]="objjob.budgetDescription" autocomplete="off">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="withdrawDate">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'วันที่ขอเบิก'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input readonly min="0" step="1" matInput maxlength="100" value="objjob.withdrawDate|date:'dd MMM yyyy'" autocomplete="off" [matDatepicker]="picker " [(ngModel)]="objjob.withdrawDate">
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker "></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="budgetRequest">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ยอดขอเบิก (บาท)'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input localizedNumericInput [disabled]="!isDataEditable" matInput maxlength="100" style="text-align: right;" type="text" matInput maxlength="150" autocomplete="off" [(ngModel)]="objjob.withdrawBudget" (input)="summarySpendingByCategory()">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="budgetReceived">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ยอดจ่ายจริง (บาท])'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input localizedNumericInput [disabled]="!isDataEditable" matInput maxlength="100" style="text-align: right;" type="text" matInput maxlength="150" autocomplete="off" [(ngModel)]="objjob.actualWithdrawBudget" (input)="summarySpendingByCategory()">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="fiscalYear">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีงบประมาณ'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input [disabled]="!isDataEditable" matInput maxlength="100" style="text-align: center;" type="number" min="0" step="1" matInput maxlength="4" autocomplete="off" [(ngModel)]="objjob.fiscalYear" (input)="summarySpendingByCategory()">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="budgetUsagePlan">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'แผนการใช้งบประมาณ'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input [disabled]="!isDataEditable" matInput maxlength="100" style="text-align: right;" type="text" matInput maxlength="3000" autocomplete="off" [(ngModel)]="objjob.budgetUsagePlan">
                                </div>
                            </mat-form-field>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsBudgetSpending"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsBudgetSpending;"></mat-row>
                </mat-table>


                <!-- balance -->
                <br>
                <div class="font-light"> สรุปยอดคงเหลือแยกตามปีงบประมาณ</div>

                <mat-table [dataSource]="dataSourceBudgetBalance" matSort matSortActive="BudgetGroup" matSortDirection="asc" matSortDisableClear>



                    <ng-container matColumnDef="lineNo">
                        <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                        <mat-cell fxFlex="4" *matCellDef="let element; let i = index">
                            <div class="font-light" style="color: #002F87; width: 100%; text-align: center;">
                                {{i + 1}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell fxFlex="4" class="font-light" *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="fiscalYear">
                        <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีงบประมาณ'}}</mat-header-cell>
                        <mat-cell fxFlex="10" *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align: center;">
                                {{objjob.fiscalYear}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell fxFlex="10" class="font-light" *matFooterCellDef>
                            <div class="font-light" style="width: 100%; text-align: center; font-weight: bold;">ยอดรวม</div>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="budgetCategory">
                        <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณย่อย'}}</mat-header-cell>
                        <mat-cell fxFlex="10" *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align: center;">
                                {{objjob.budgetCategory}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell fxFlex="10" class="font-light" *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="budgetGroup">
                        <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณ'}}</mat-header-cell>
                        <mat-cell fxFlex="10" *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align: center;">
                                {{objjob.budgetGroup}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell fxFlex="10" class="font-light" *matFooterCellDef></mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="budgetReceived">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'งบประมาณ'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align:right;">
                                {{objjob.budgetReceived|number:'1.2-2'}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell class="font-light" *matFooterCellDef>
                            <div class="font-light" style="width: 100%; text-align:right;  font-weight: bold;">
                                {{totalBudget|number:'1.2-2'}}
                            </div>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="withdrawBudget">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ยอดขอเบิก'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align:right;">
                                {{objjob.withdrawBudget|number:'1.2-2'}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell class="font-light" *matFooterCellDef>
                            <div class="font-light" style="width: 100%; text-align:right;  font-weight: bold;">
                                {{totalRequestWithdraw|number:'1.2-2'}}
                            </div>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="actualWithdrawBudget">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ยอดจ่ายจริง'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align:right; ">
                                {{objjob.actualWithdrawBudget|number:'1.2-2'}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell class="font-light" *matFooterCellDef>
                            <div class="font-light" style="width: 100%; text-align:right;  font-weight: bold;">
                                {{totalActualWithdraw|number:'1.2-2'}}
                            </div>
                        </mat-footer-cell>
                    </ng-container>

                    <ng-container matColumnDef="balance">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ยอดคงเหลือ'}}</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <div class="font-light" style="width: 100%; text-align:right;" [ngStyle]="{'color': objjob.balance<0 ? 'red' : 'black'  }">
                                {{objjob.balance|number:'1.2-2'}}
                            </div>
                        </mat-cell>
                        <mat-footer-cell class="font-light" *matFooterCellDef>
                            <div *ngIf="totalBalance>=0" class="font-light" style="width: 100%; text-align:right; font-weight: bold;">
                                {{totalBalance|number:'1.2-2'}}
                            </div>
                            <div *ngIf="totalBalance<0" class="font-light" style="width: 100%; text-align:right; color: red; font-weight: bold;">
                                {{totalBalance|number:'1.2-2'}}
                            </div>
                        </mat-footer-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="displayedColumnsBudgetBalance"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsBudgetBalance;"></mat-row>
                    <mat-footer-row *matFooterRowDef="displayedColumnsBudgetBalance"></mat-footer-row>
                </mat-table>

                <!-- end balance -->



                <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                    <tr style="width: 100%;">
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ย้อนกลับ'}}</label>
                                
                            </button>
                        </td>
                        <td style="width: 90%;"></td>
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                        </td>

                    </tr>
                </div>
            </mat-card>
        </mat-tab>



        <mat-tab label="{{'คำชี้แจงเกี่ยวกับปัญหาหรืออุปสรรค'}}">
            <ng-template mat-tab-label>
                <span class="font-medium" [ngStyle]="{'color': isStatementValid ? 'black' : 'red'  }">{{'คำชี้แจงเกี่ยวกับปัญหาหรืออุปสรรค'}}</span>
            </ng-template>
            <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px;">

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2" style="padding-right: 45px;"></div>

                    <div *ngIf="isDataEditable" fxFlex="100%">
                        <!-- <angular-editor class="font-light" style="height: '250px '" maxlength="1000" [placeholder]="'ข้อความที่บันทึกในเรื่องที่เกี่ยวข้อง '" [(ngModel)]="Statement" ta-text-editor-class="form-control myform2-height" ta-html-editor-class="form-control myform2-height">

                            </angular-editor> -->
                        <angular-editor *ngIf="isDataEditable" (ngModelChange)="onEditorStatement()" (click)="onclickEditorStatement(Statement)" class="font-light" [(ngModel)]="Statement" [config]="editorConnfig">
                        </angular-editor>
                    </div>
                    <div *ngIf="!isDataEditable" fxFlex="100%" [innerHtml]="Statement">

                    </div>
                    <div fxFlex="2"></div>
                </div>



                <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                    <tr style="width: 100%;">
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ย้อนกลับ'}}</label>
                                
                            </button>
                        </td>
                        <td style="width: 90%;"></td>
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                        </td>

                    </tr>
                </div>
            </mat-card>

        </mat-tab>

        <mat-tab label="{{'แผนงานที่จะทำต่อไป'}}">
            <ng-template mat-tab-label>
                <span class="font-medium" [ngStyle]="{'color': isProposeDecisionValid ? 'black' : 'red'  }">{{'แผนงานที่จะทำต่อไป'}}</span>
            </ng-template>
            <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px;">


                <mat-radio-group [disabled]="!isDataEditable" fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="proposeDecision" (change)="onProposeDecisionChange($event)">
                    <div class="table-container" style="padding: 5px; " fxLayout="column">
                        <div fxFlex="15" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-bottom: 18px; padding-top: 30px;">
                            <div fxFlex="20%"></div>
                            <div fxFlex="100%">
                                <mat-radio-button class="font-light" style=" color: #002F87;" value="1">{{'ประสงค์ดำเนินการวิจัยต่อ'}}</mat-radio-button>
                            </div>

                            <div fxFlex="2"></div>
                        </div>

                        <div fxFlex="15" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="20%"></div>
                            <div fxFlex="100%" fxLayout="row">

                                <div class="font-light">
                                    <mat-radio-button class="font-light" style="color: #002F87; " value="2">{{'ขอขยายระยะเวลาโครงการ อีก'}}
                                        <mat-form-field class="font-light">
                                            <input [readonly]="!proposeExtendMonth" type="number" style="text-align: right;" min="0" step="1" matInput maxlength="3" autocomplete="off" [(ngModel)]="projectTrackingData.projectExtendInMonth">

                                        </mat-form-field>เดือน
                                    </mat-radio-button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="proposeExtendMonth" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">

                            <div fxFlex="20%"></div>
                            <div class="font-light" style="border-color: darkgray; border-width: 2px;border-style: solid; border-radius: 8px 8px 8px 8px; margin-bottom: 5px;">
                                <textarea style="padding: 5px; width: 600px; " rows="3" matInput maxlength="500" autocomplete="off" placeholder="{{'โปรดระบุกิจกรรม/ขั้นตอนการดำเนินงานที่ขอขยายระยะเวลา'}}" [(ngModel)]="projectTrackingData.projectExtendReason"></textarea>

                            </div>
                        </div>
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="20%"></div>
                            <div fxFlex="100%">
                                <mat-radio-button class="font-light" style=" color: #002F87;" value="3">{{'ขอยุติโครงการวิจัย เนื่องจาก'}} </mat-radio-button>

                            </div>

                            <div fxFlex="2"></div>
                        </div>
                        <div *ngIf="proposeProjectCancel" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">

                            <div fxFlex="20%"></div>
                            <div class="font-light" style="border-color: darkgray; border-width: 2px;border-style: solid; border-radius: 8px 8px 8px 8px; margin-bottom: 5px;">
                                <textarea style="padding: 5px; width: 600px; " rows="3" matInput maxlength="500" autocomplete="off" placeholder="{{'โปรดระบุเหตุผลการขอยุติโครงการ'}}" [(ngModel)]="projectTrackingData.projectCancelReason"></textarea>

                            </div>
                            <!-- <div class="font-light">
                                <textarea style="width: 600px;" rows="3" matInput maxlength="500" autocomplete="off" placeholder="{{'โปรดระบุรายละเอียด'}}" [(ngModel)]="projectTrackingData.projectCancelReason"></textarea>

                            </div> -->
                        </div>
                        <br *ngIf="!proposeProjectCancel">
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
                            <div fxFlex="20%"></div>
                            <div fxFlex="100%">
                                <mat-radio-button class="font-light" style=" color: #002F87; " value="4">{{'ดำเนินงานวิจัยเสร็จสิ้นแล้ว (ขอแจ้งปิดโครงการวิจัย)'}}</mat-radio-button>

                            </div>

                            <div fxFlex="2"></div>
                        </div>
                    </div>
                </mat-radio-group>



                <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                    <tr style="width: 100%;">
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ย้อนกลับ'}}</label>
                                
                            </button>
                        </td>
                        <td style="width: 90%;"></td>
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
       
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                        </td>

                    </tr>
                </div>
            </mat-card>

        </mat-tab>

        <mat-tab label="เอกสารแนบ">
            <ng-template mat-tab-label>
                <span class="font-medium" [ngStyle]="{'color': 'Black'}">{{'เอกสารแนบ'}}</span>
            </ng-template>
            <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px;">
                <mat-card>
                    <div *ngIf="isDataEditable" class="container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFill>
                        <div fxFlexFill fxLayout="row" style="padding-top: 10px;">

                            <div fxFlex="2"></div>
                            <div fxFlex="30%">
                                <mat-form-field class="font-light">
                                    <input type="text" aria-label="Country" matInput [matAutocomplete]="auto" [(ngModel)]="attachedFileType" (input)="onDocumentTypeInput(attachedFileType)" placeholder="{{'ประเภทเอกสาร'}}">
                                    <mat-autocomplete #auto="matAutocomplete" class="font-light" (optionSelected)="onAttachedFileTypeSelected($event.option.value)">
                                        <div>
                                            <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let option of projectAttachFileTypeListToShow " [value]="option.description">
                                                {{option.description}}
                                            </mat-option>
                                        </div>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="74%">
                                <mat-form-field class="font-light">
                                    <div class="font-light">
                                        <input matInput readonly maxlength="100" autocomplete="off" placeholder="{{'ชื่อไฟล์'}}" [(ngModel)]="attachedFileName">
                                    </div>
                                </mat-form-field>
                            </div>

                            <div fxFlex="2"></div>
                            <div fxFlex="10%">
                                <button style="width: 100%;" class="font-light" mat-raised-button color="primary" (click)="imgFileInput.click()">{{'เลือก ไฟล์'}}</button>
                                <input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx" />
                            </div>
                            <div fxFlex="2%"></div>
                            <div fxFlex="10%" class="font-light">
                                <button style="width: 100%; " mat-raised-button class="font-light" style="background-color: #002F87; color: white;" (click)="btnSaveattachDocuments()">{{'แนบ ไฟล์'}}</button>
                            </div>
                        </div>

                    </div>

                </mat-card><br>

                <mat-table [dataSource]="dataSourceAttachment" matSort matSortActive="ลำดับ" matSortDirection="asc" matSortDisableClear>

                    <ng-container matColumnDef="Control">
                        <mat-header-cell fxFlex="10" *matHeaderCellDef>
                        </mat-header-cell>

                        <mat-cell fxFlex="5" *matCellDef="let row; let i = index" fxFlex="10">
                            <span class="mobile-label"></span>

                            <button *ngIf="isDataEditable" mat-icon-button focusable="false" color="accent">
                                    <img (click)="btnDeleteAttachFile(row)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lineNo">
                        <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                        <mat-cell fxFlex="3" style="color: #002F87;" class="font-light" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="documentType">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ประเภทเอกสาร</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <!-- <input  matInput maxlength="100" [(ngModel)]="objjob.documentType" autocomplete="off"> -->
                            <div style="color: #002F87;" class="font-light">
                                {{ objjob.attachDocument.documentType }}
                            </div>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="documentName">
                        <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่อไฟล์</mat-header-cell>
                        <mat-cell *matCellDef="let objjob">
                            <button mat-icon-button focusable="false" class="font-light" color="accent">
                                    <img (click)="viewAttachDocument(objjob.attachDocument.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png">
                                </button>
                            <div style="color: #002F87;" class="font-light">
                                {{ objjob.attachDocument.documentName }}
                            </div>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></mat-row>
                </mat-table>

                <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                    <tr style="width: 100%;">
                        <td style="width: 5%;">
                            <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ย้อนกลับ'}}</label>
                                
                            </button>
                        </td>
                        <td style="width: 90%;"></td>
                        <td style="width: 5%;">

                        </td>

                    </tr>
                </div>

            </mat-card>
        </mat-tab>

    </mat-tab-group>
    <!-- </div> -->
</div>
<div id="overlay">
    <span id="nav" *ngIf="saveInProgress">
        <div class="spinner" style="display: block; position: fixed">
        </div>
    </span>
    <span id="nav" *ngIf="saveInProgress">
        <div class="submit-message" style="display: block; color: #FFFFFF; position: fixed; left: 49.7%; top: 54.5%;">
            Saving...
        </div>
    </span>
</div>