<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="20px" style="padding-top: 0px; padding-right: 0px; padding-left: 0px;">

    <div fxFlex class="table-component-holder mat-elevation-z4">
        <div class="table-container" style=" background-color: #FD7E14; color: #002F87; text-align: center; padding: 10px;">
            <table style="width: 100%;">
                <tr>

                    <td style="width: 20%; text-align: left;" class="font-light">
                        <button mat-raised-button class="btn-back" (click)="btnBack()">{{'ย้อนกลับ' }}</button>
                    </td>
                    <td style="width: 60%; text-align: center;">
                        <label class="font-bold" style="color: #002F87;">{{'อนุมัติคำขอเป็นนักวิจัยใหม่' }}</label>
                    </td>
                    <td style="width: 20%; text-align: right;" class="font-light">
                        <button mat-raised-button *ngIf="isEditable" style="margin-right: 10px;" class="btn-black" (click)="btnReject()">{{'ไม่อนุมัติ'}}</button>
                        <button mat-raised-button *ngIf="isEditable" class="btn-send" (click)="btnApprove()">{{'อนุมัติ'}}</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 10px; margin-left: 10px; margin-right: 10px;">
    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px; ">
        <!-- <mat-toolbar class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #002F87; color: white; text-align: center;">
            <div style="text-align: center;">
                <label>Personal information</label>
            </div>


        </mat-toolbar> -->
        <div class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #b5d5f3; color: #002F87; text-align: center; padding-top: 10px; padding-bottom: 10px;">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%;">

                    </td>
                    <td style="width: 20%; text-align: center;">
                        <label class="font-bold">{{'ข้อมูลส่วนตัว'}}</label>
                    </td>
                    <td style="width: 30%;">

                    </td>
                    <td (click)="onclickBack()" style="width: 10%;">

                        <!-- <img style=" height: 25px; width: 23px; padding-top: 10px;" src="assets\Icon\ArrowCircleLeft.png"> -->

                    </td>
                </tr>
            </table>


        </div>
        <mat-card style="padding: 0px;">
            <!-- <mat-card-content [style.overflow]="'auto'" [style.height.px]="'450'">
                <div style="padding: 0px;" class="font-light"> -->
            <div class="table-container" style="padding: 0px; padding-top: 15px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput style="font-size: 13px;" readonly maxlength="50" autocomplete="off" placeholder="{{'รหัสพนักงาน'}}" [(ngModel)]="researcherData.employeeCode">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput style="font-size: 13px;" readonly maxlength="50" autocomplete="off" placeholder="{{'หมายเลขบัตรประชาชน'}}" [(ngModel)]="researcherData.idCardNo">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="33%">

                        <section fxLayout="row" class="font-light;">
                            <label class="font-light" style="font-size: 15px; color: #002F87; padding-right: 30px; margin-top: 15px;">{{'เพศ'}}</label>
                            <mat-radio-group class="font-light" fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="researcherData.gender">
                                <mat-radio-button disabled style="color: #002F87; margin-right: 30px;" value="1">{{'ชาย'}}</mat-radio-button>
                                <mat-radio-button disabled style="color: #002F87;" value="2">{{'หญิง'}}</mat-radio-button>
                            </mat-radio-group>
                        </section>

                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput style="font-size: 13px;" readonly maxlength="50" autocomplete="off" placeholder="{{'คำนำหน้านาม'}}" [(ngModel)]="researcherData.prefixTH">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ชื่อ (ไทย)'}}" [(ngModel)]="researcherData.firstNameTH">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'นามสกุล (ไทย)'}}" [(ngModel)]="researcherData.lastNameTH">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'คำนำหน้านาม'}}" [(ngModel)]="researcherData.prefixEN">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ชื่อ (อังกฤษ)'}}" [(ngModel)]="researcherData.firstNameEN">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'นามสกุล (อังกฤษ)'}}" [(ngModel)]="researcherData.lastNameEN">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="97%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="200" autocomplete="off" placeholder="{{'ที่อยู่ปัจจุบัน'}}" [(ngModel)]="researcherData.currentAddress">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="97%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ตำแหน่งงานปัจจุบัน'}}" [(ngModel)]="researcherData.currentPosition">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'อีเมล์ (ที่ทำงาน)'}}" [(ngModel)]="researcherData.emailAddress">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'หมายเลขโทรศัพท์มือถือ'}}" [(ngModel)]="researcherData.mobilePhone">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>


                </div>







                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field style="padding: 0px;" class="font-light">
                            <div class="font-light">
                                <input matInput style="font-size: 13px;" readonly maxlength="50" autocomplete="off" placeholder="{{'สังกัดหลัก'}}" [(ngModel)]="mainOrg">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>


                    <div fxFlex="50%" style="padding: 0px;">
                        <mat-form-field style="padding: 0px;" class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ระบุ'}}" [(ngModel)]="researcherData.otherOrganization">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field style="padding: 0px;" class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'สังกัดรอง'}}" [(ngModel)]="secondaryOrg">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="50%" style="padding: 0px;">

                    </div>
                    <div fxFlex="2"></div>
                </div>






                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field style="padding: 0px;" class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ความเชี่ยวชาญสาขา ISCED1'}}" [(ngModel)]="isceD1Desc">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ความเชี่ยวชาญสาขา  ISCED2' }}" [(ngModel)]="isceD2Desc">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ความเชี่ยวชาญสาขา ISCED3' }}" [(ngModel)]="isceD3Desc">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light" *ngIf="researcherData.otherISCED">
                            <div class="font-light">
                                <input matInput readonly style="font-size: 13px;" maxlength="50" autocomplete="off" placeholder="{{'ระบุ' }}" [(ngModel)]="researcherData.otherISCED">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>
            </div>

            <!-- </div> -->
            <!-- </mat-card-content> -->
            <!-- <mat-card-footer class="footer">
                <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
            </mat-card-footer> -->
        </mat-card>
        <!-- <div style="padding-bottom: 10px;"></div> -->
        <!-- <footer class="footer">
            <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
        </footer> -->
    </div>