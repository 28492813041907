import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { organization } from 'src/app/datamodel/organization';
import { IAPIResponse } from 'src/app/interfaces/apiResponse';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';

@Component({
  selector: 'app-sys-user-roll-detail',
  templateUrl: './sys-user-roll-detail.component.html',
  styleUrls: ['./sys-user-roll-detail.component.scss']
})
export class SysUserRollDetailComponent implements OnInit {
  dataOrganization: any[];
  isAdmin: boolean;
  isManagement: boolean;
  isHightLevelManagement: boolean;
  isInactive: boolean;
  result: IAPIResponse;
  dataRow: any = {}
  organizationAuthorizations: organizationAuthorization[];

  dataSourceAuthenticationList = new MatTableDataSource();
  displayedColumns = ['lineNo','isSelected','organizationName'];
 

  private errorMessage: string;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    private snackBar: MatSnackBar,
    // public objSysRoleAccessService: SysRoleAccessService,
    // private dialogService: DialogService,
    public sdUtilityService: SDUtilityService,
    private translate: TranslateService) {
    this.dataRow = JSON.parse(this.route.snapshot.paramMap.get('dataobj'));
    console.log("showdata",JSON.stringify(this.dataRow));

  }

  ngOnInit(): void {
    this.getOrganization()

  }

  getOrganization() {
    console.log("xxxxxx",JSON.stringify(this.dataRow.manageOrganization));
    let callMethod: string = '/Master/GetOrganizationList';
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        console.log(values);

        this.organizationAuthorizations = values;
        for(let i = 0 ; i<this.organizationAuthorizations.length;i++){
          if(this.dataRow.manageOrganization.find(x => x.organizationId == this.organizationAuthorizations[i].id)){
            this.organizationAuthorizations[i].isSelected = true;
          } else {
            this.organizationAuthorizations[i].isSelected = false;
          }
        }
        this.dataSourceAuthenticationList.data = this.organizationAuthorizations ;

      });
  }

  btnSave() {
    this.save()
  }

addRightToSysUserRoles(roleData:any){
  if( this.dataRow.sysUserRoles){
    this.dataRow.sysUserRoles.push(roleData);
  } else{
    this.dataRow.sysUserRoles = [roleData];
  }
}

  save() {
 
    // this.dataRow.sysUserRoles=[];
    let isResearcher:boolean;
    if(this.dataRow.sysUserRoles.find(x=>x.userSysRoleId==2)){
      isResearcher = true;
    }

    

    this.dataRow.sysUserRoles = [];

    if(isResearcher){
      let objdataManagement = {
        id: 0,
        userId: this.dataRow.id,
        userSysRoleId: 2
      }
      this.addRightToSysUserRoles(objdataManagement)
    }

    if(this.dataRow.isAdmin){
      let objdataManagement = {
        id: 0,
        userId: this.dataRow.id,
        userSysRoleId: 3
      }
      this.addRightToSysUserRoles(objdataManagement)
    } 

    if(this.dataRow.isManagement){
      let objdataManagement = {
          id: 0,
          userId: this.dataRow.id,
          userSysRoleId: 4
        }
        this.addRightToSysUserRoles(objdataManagement)      
    }
    if(this.dataRow.isHightLevelManagement){
      let objdataManagement = {
          id: 0,
          userId: this.dataRow.id,
          userSysRoleId: 5
        }
        this.addRightToSysUserRoles(objdataManagement)
    }

    // organization
    this.dataRow.manageOrganization = [];
    for(let i = 0; i<this.organizationAuthorizations.length;i++){
      if(this.organizationAuthorizations[i].isSelected){
        let objOrganization1 = {
        id: 0,
        sysUserId: this.dataRow.id,
        organizationId: this.organizationAuthorizations[i].id,
        }
        this.dataRow.manageOrganization.push(objOrganization1)
      }
    }
  
    console.log("datasecure",JSON.stringify(this.dataRow));

    // return;

    //  /Auth/SaveUser
    this.brokerAPIService.post('/Auth/SaveUser',this.dataRow).subscribe(
      values => {
        this.result = values;
        console.log("values",values);
        
        this.openSnackBar(this.result.message,"Close");

      },
      error => {
        this.errorMessage = <any>error;
        console.log("errorMessage:" + JSON.stringify(this.errorMessage));
      }
    );
  }

  btnClose() {
    let link = ['sysuserroll'];
    this.router.navigate(link);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

}

export class organizationAuthorization extends organization{
  isSelected:boolean;
}
