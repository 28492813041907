// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  titleName:'Chulabhorn',



  //dev
  // tenantId: 'e835a631-49be-4657-a4ac-8fb9f7b61a89',
  // uiClienId: '3784fa3d-4e4d-4a88-8325-046cc77b2ff4',
  //  scopeUri: ['api://0e632ffd-3070-4028-8076-2cab44a51ee0/.default'],
  //   redirectUrl: 'http://localhost:4200',
  // // // redirectUrl: 'https://localhost:5001',
  // webPath:'/crarasweb'



    //qa
  // tenantId: 'e835a631-49be-4657-a4ac-8fb9f7b61a89',
  // uiClienId: '3784fa3d-4e4d-4a88-8325-046cc77b2ff4',
  // redirectUrl: 'https://172.16.40.52/',
  // // webPath: ''
  // webPath:'/crarasweb'


  // production
  tenantId: 'e835a631-49be-4657-a4ac-8fb9f7b61a89',
  uiClienId: '3784fa3d-4e4d-4a88-8325-046cc77b2ff4',
  redirectUrl: 'https://crms.cra.ac.th',
  // webPath: ''
  webPath:'/crarasweb'

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

  //localhost
  // tenantId: 'd70f3596-f335-4f19-824f-dd09044c11c7',
  // uiClienId: '3784fa3d-4e4d-4a88-8325-046cc77b2ff4',
  // scopeUri: ['api://0e632ffd-3070-4028-8076-2cab44a51ee0/.default'],
  // redirectUrl: 'http://localhost:4200',
  // webPath:'/crarasweb'
