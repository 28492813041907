<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="20px" style="padding-top: 0px; padding-right: 0px; padding-left: 0px;">
    <!-- <div fxFlex class="table-component-holder mat-elevation-z4">
        <div class="table-container" style="padding-top: 10px; padding-bottom: 10px;">

            <div class="container" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">

                <div>
                    <button mat-raised-button class="btn-send" (click)="submitRegistration()">{{'Send request' }}</button>
                </div>
                <div fxFlex="40%"></div>

                <div fxFlex="20%" style="text-align: center;">
                    <label class="font-bold" style="font-size: 18px; color: #002F87;">{{'Register a researcher' }}</label>
                </div>
                <div fxFlex="40%"></div>
                <div>
                    <button mat-raised-button class="btn-black" (click)="btnBlack()">{{'Back' }}</button>
                </div>
            </div>


        </div>


    </div> -->
    <div fxFlex class="table-component-holder mat-elevation-z4">
        <div class="table-container" style="  background-color: #FD7E14; color: #002F87;  text-align: center; padding: 10px;">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%; text-align: left;" class="font-light">
                        <button mat-raised-button class="btn-back" (click)="btnBlack()">{{'ย้อนกลับ' }}</button>
                    </td>
                    <td style="width: 20%; text-align: center;">
                        <label class="font-bold">{{'ลงทะเบียนนักวิจัย' }}</label>
                    </td>
                    <td style="width: 40%; text-align: right;" class="font-light">
                        <button mat-raised-button class="btn-black" (click)="btnSend()">{{'ส่งคำขอ' }}</button>

                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px">

    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px; ">
        <!-- <mat-toolbar class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #002F87; color: white; text-align: center;">
            <div style="text-align: center;">
                <label>{{'Personal information' }}</label>
            </div>


        </mat-toolbar> -->
        <div class="font-bold" style="border-radius: 8px 8px 0px 0px;background-color: #b5d5f3; color: #002F87; text-align: center; padding-top: 10px; padding-bottom: 10px;">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%;">

                    </td>
                    <td style="width: 20%; text-align: center;">
                        <label class="font-bold">{{'ข้อมูลส่วนตัว' }}</label>
                    </td>
                    <td style="width: 40%;">

                    </td>
                </tr>
            </table>


        </div>


        <mat-card style="padding: 0px;">
            <!-- <mat-card-content [style.overflow]="'auto'" [style.height.px]="'450'">

                <div style="padding: 0px;" class="font-light"> -->
            <div class="table-container" style="padding: 0px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'รหัสพนักงาน'}}" [(ngModel)]="registerData.employeeCode">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'หมายเลขบัตรประชาชน' }}" [(ngModel)]="registerData.idCardNo">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="33%">

                        <section fxLayout="row" class="font-light;">
                            <label class="font-light" style=" color: #002F87; padding-right: 30px; margin-top: 15px;">{{'เพศ' }}</label>
                            <mat-radio-group fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="registerData.gender">
                                <mat-radio-button class="font-light" style=" color: #002F87; margin-right: 30px;" value="1">{{'ชาย' }} </mat-radio-button>
                                <mat-radio-button class="font-light" style=" color: #002F87;" value="2">{{'หญิง' }}</mat-radio-button>
                            </mat-radio-group>
                        </section>

                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="15%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'คำนำหน้านาม' }}" [(ngModel)]="registerData.prefixTH">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="42.5%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'ชื่อ (ไทย)' }}" [(ngModel)]="registerData.firstNameTH">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="42.5%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'นามสกุล (ไทย)' }}" [(ngModel)]="registerData.lastNameTH">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="15%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'คำนำหน้านาม' }}" [(ngModel)]="registerData.prefixEN">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="42.5%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'ชื่อ (อังกฤษ)' }}" [(ngModel)]="registerData.firstNameEN">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="42.5%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'นามสกุล (อังกฤษ)' }}" [(ngModel)]="registerData.lastNameEN">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                    <!-- <div fxFlex="97%">
                                <mat-form-field class="font-light">
                                    <input matInput maxlength="50" autocomplete="off" placeholder="{{'Address' }}" [(ngModel)]="registerData.currentAddress">
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div> -->
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="97%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="200" autocomplete="off" placeholder="{{'ที่อยู่ปัจจุบัน' }}" [(ngModel)]="registerData.currentAddress">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="97%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="500" autocomplete="off" placeholder="{{'ตำแหน่งงานปัจจุบัน' }}" [(ngModel)]="registerData.currentPosition">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input type="email" matInput maxlength="50" autocomplete="off" placeholder="{{'อีเมล์ (ที่ทำงาน)' }}" [(ngModel)]="registerData.emailAddress">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'หมายเลขโทรศัพท์มือถือ' }}" [(ngModel)]="registerData.mobilePhone">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>


                </div>
            </div>



            <div class="table-container" style="padding: 0px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <mat-select class="font-light" placeholder="{{'สังกัดหลัก'}}" [(ngModel)]="registerData.mainOrganizationId" (selectionChange)="onOrgChange()">
                                <div class="font-light-small">
                                    <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let org of organizations" [value]=org.id>
                                        {{ org.organizationName }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="50%" style="padding: 0px;">
                        <mat-form-field style="padding: 0px;" class="font-light" *ngIf="registerData.mainOrganizationId==10">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'ระบุ'}}" [(ngModel)]="registerData.otherOrganization">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <mat-select class="font-light" placeholder="{{'สังกัดรอง'}}" [(ngModel)]="registerData.secondaryOrganization" (selectionChange)="onOrgChangeSecondary()">
                                <div class="font-light-small">
                                    <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let org of organizations" [value]=org.id>
                                        {{ org.organizationName }}
                                    </mat-option>
                                    <mat-option value="">ไม่มี</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="50%" style="padding: 0px;">
                        <!-- ||registerData.secondaryOrganization==10" -->
                        <mat-form-field style="padding: 0px;" class="font-light" *ngIf="registerData.secondaryOrganization==10">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'ระบุ'}}" [(ngModel)]="registerData.OtherSecondaryOrganization">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>
                <!-- </div>




              <div class="table-container" style="padding: 5px;"> -->
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <mat-select class="font-light" placeholder="{{'ความเชี่ยวชาญสาขา ISCED1'}}" [(ngModel)]="registerData.isceD1Id" (selectionChange)="onisceD1Changed()">
                                <div class="font-light-small">
                                    <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let isceD1 of isceD1s" [value]=isceD1.id>
                                        {{ isceD1.isceD1Desc }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput maxlength="50" autocomplete="off" placeholder="{{'ระบุ'}}" [(ngModel)]="registerData.otherISCED">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <mat-select class="font-light" placeholder="{{'ความเชี่ยวชาญสาขา ISCED2'}}" [(ngModel)]="registerData.isceD2Id" (selectionChange)="onisceD2Changed()">
                                <div class="font-light-small">
                                    <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let isceD2 of isceD2s" [value]=isceD2.id>
                                        {{ isceD2.isceD2Desc }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="50%">
                        <mat-form-field class="font-light">
                            <mat-select class="font-light" placeholder="{{'ความเชี่ยวชาญสาขา ISCED3'}}" [(ngModel)]="registerData.isceD3Id">
                                <div class="font-light-small">
                                    <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let isceD3 of isceD3s" [value]=isceD3.id>
                                        {{ isceD3.isceD3Desc }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxFlex="2"></div>
                </div>
            </div>
            <!-- </div> -->
            <!-- </mat-card-content> -->
            <!-- <mat-card-footer class="footer">
                <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
            </mat-card-footer> -->
        </mat-card>
    </div>

    <!-- <div style="padding-bottom: 10px;"></div> -->
    <!-- <footer class="footer">
        <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
    </footer> -->
</div>