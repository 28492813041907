<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="20px" style="padding-top: 0px; padding-right: 0px; padding-left: 0px; margin-bottom: 10px;">

    <div fxFlex class="table-component-holder mat-elevation-z4">
        <div class="table-container" style=" background-color: #FD7E14; color: #002F87; text-align: center; padding: 10px; padding-left: 15px; padding-right: 15px;">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%; text-align: left;" class="font-light">
                        <button mat-raised-button class="btn-Menu" (click)="btnPrint()">{{'พิมพ์ CV' }}</button>
                    </td>
                    <td style="width: 20%; text-align: center;">
                        <label class="font-bold" style="color: #002F87;">{{'ทะเบียนนักวิจัย' }}</label>
                    </td>
                    <td style="width: 40%; text-align: right;" class="font-light">
                        <button mat-raised-button class="btn-back" (click)="btnBack()">{{'ย้อนกลับ'}}</button>
                        <button mat-raised-button style=" margin-left: 5px;" class="btn-black" (click)="btnSave()">{{'บันทึก' }}</button>

                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<mat-card *ngIf="isshowEditorRewordStatus " class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'แจ้งเตือนการกรอกข้อมูล' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="5%"></div>
                <div fxFlex="95%">
                    <div class="font-light">
                        <label style="font-size: 15px; color: red;">
                            คุณกรอกข้อมูล รางวัล เกิน 1000 ตัวอักษร
                        </label><br>
                        <label style="font-size: 15px;">
                            โปรดลบจำนวนที่เกิน {{ lengOverayReword}} ตัวอักษร
                        </label>
                    </div>
                </div>

                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%; ">
                    <tr>
  
                        <td style="width: 50%; text-align: left; padding-left: 23px; text-align: center;" class="font-light">
                            <button mat-raised-button (click)="btnCloseProjectStatusUpdate()" class="btn-back ">{{'ตกลง' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isshowEditorOverayOrganizationMemberStatus " class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'แจ้งเตือนการกรอกข้อมูล' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="5%"></div>
                <div fxFlex="95%">
                    <div class="font-light">
                        <label style="font-size: 15px; color: red;">
                            คุณกรอกข้อมูล สมาชิกองกรวิชาชีพ เกิน 1000 ตัวอักษร
                        </label><br>
                        <label style="font-size: 15px;">
                            โปรดลบจำนวนที่เกิน {{ lengOverayOrganizationMember}} ตัวอักษร
                        </label>
                    </div>
                </div>

                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%; ">
                    <tr>
  
                        <td style="width: 50%; text-align: left; padding-left: 23px; text-align: center;" class="font-light">
                            <button mat-raised-button (click)="btnCloseProjectStatusUpdateOrg()" class="btn-back ">{{'ตกลง' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>


<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="10px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 10px;  margin-right: 10px;">

    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px;  ">

        <div style="background-color: #b5d5f3; color: #002F87;  text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%;">

                    </td>
                    <td style="width: 20%; text-align: center;">
                        <label class="font-bold">{{'ข้อมูลส่วนตัว' }}</label>
                    </td>
                    <td style="width: 40%;">

                    </td>
                    <!-- <td (click)="onclickBack()" style="width: 10%;">

                        <Label class="font-light" style="color: white;">ย้อนกลับ</Label>
                    </td> -->
                </tr>
            </table>


        </div>

        <mat-card style="padding: 0px; border-style: solid; border-color: #d9d9d9; border-radius: 0px 0px 10px 10px; ">
            <div class="table-container" style="padding: 5px;">
                <div style="padding-bottom: 15px;"></div>
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="15%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.prefixTH" placeholder="{{'คำนำหน้านาม' }}">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="40%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.firstNameTH" placeholder="{{'ชื่อ (ไทย)' }}">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="40%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.lastNameTH" placeholder="{{'นามสกุล (ไทย)' }}">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                    <!-- <div fxFlex="20%" class="font-light">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="25" autocomplete="off" [(ngModel)]="researcherData.idCardNo" placeholder="{{'หมายเลขบัตรประชาชน'}}">

                        </mat-form-field>
                    </div> -->



                    <!-- <div fxFlex="2"></div> -->
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <!-- <div fxFlex="15%" class="font-light">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.prefixEN" placeholder="{{'คำนำหน้านาม' }}">
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="25%" class="font-light">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.firstNameEN" placeholder="{{'ชื่อ (อังกฤษ)' }}">
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="30%" class="font-light">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.lastNameEN" placeholder="{{'นามสกุล (อังกฤษ)' }}">

                        </mat-form-field>
                    </div> -->
                    <div fxFlex="55">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="100" autocomplete="off" [(ngModel)]="researcherData.currentPosition" placeholder="{{'ตำแหน่งงานปัจจุบัน'}}">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="23">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="25" autocomplete="off" [(ngModel)]="researcherData.idCardNo" placeholder="{{'หมายเลขบัตรประชาชน'}}">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="14">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input readonly placeholder="{{'วันเดือนปีเกิด' }}" matInput [(ngModel)]="researcherData.birthDate" [matDatepicker]="picker">
                            </div>
                            <mat-datepicker-toggle *ngIf="isShowFieldInput" matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                </div>

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="97">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="500" autocomplete="off" [(ngModel)]="researcherData.currentAddress" placeholder="{{'ที่อยู่ปัจจุบัน'}}">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div>

                <!-- <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="97">
                        <mat-form-field class="font-light">
                            <input matInput readonly maxlength="100" autocomplete="off" [(ngModel)]="researcherData.currentPosition" placeholder="{{'ตำแหน่งงานปัจจุบัน'}}">
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                </div> -->

                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="33">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.mobilePhone" placeholder="{{'เบอร์โทรศัพท์'}}">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                    <div fxFlex="33">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input matInput [readonly]="!isEditable" maxlength="50" autocomplete="off" [(ngModel)]="researcherData.faxNo" placeholder="{{'เบอร์โทรสาร'}}">
                            </div>
                        </mat-form-field>
                    </div>
                    <!-- <div *ngIf="!isShowFieldInput" fxFlex="33">
                        <mat-form-field class="font-light">
                            <input [readonly]="!isEditable" matInput readonly maxlength="50" autocomplete="off" [(ngModel)]="researcherData.faxNo" placeholder="{{'เบอร์โทรสาร'}}">
                        </mat-form-field>
                    </div> -->
                    <div fxFlex="2"></div>

                    <div fxFlex="33">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input [readonly]="!isEditable" type="email" matInput readonly maxlength="100" autocomplete="off" [(ngModel)]="researcherData.emailAddress" placeholder="{{'อีเมล์'}}">
                            </div>
                        </mat-form-field>
                    </div>

                    <div fxFlex="2"></div>
                </div>
            </div>
        </mat-card>
    </div>









    <div fxLayout="column" class="bottom-tab-area" fxLayoutGap="20px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 0px; margin-right: 0px;">
        <div fxFlex style="border-radius: 10px; margin-bottom: 0px;">

            <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);" dynamicHeight class="font-light">
                <mat-tab label="ประวัติการศึกษา">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isEducationHistsValid ? 'Black': 'Red'}">{{'ประวัติการศึกษา'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">
                        <!-- <div *ngIf="isShowTabeducation" style="padding: 10px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 93%;"></td>
                                <td style="width: 6%;">
                                    <label (click)="onclickAddEducation()" class="font-light" style="color: #002F87;  padding-bottom: 30px; padding-right: 5px;">เพิ่มประวัติ</label>
                                </td>
                                <td style="width: 1%;">
                                    <img (click)="onclickAddEducation()" style=" height: 25px; width: 25px; padding-top: 10px;" src="assets\Icon\Add.png">
                                </td>

                            </tr>
                        </div> -->
                        <div *ngIf="isShowTabeducation" style="padding: 10px; text-align: right;">
                            <div fxFlex="80%"></div>

                            <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                                <label (click)="onclickAddEducation()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มประวัติ'}}</label>
                            </div>

                            <div style="padding-right: 12px; " fxFlex="1.5%">
                                <img (click)="onclickAddEducation()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                            </div>
                        </div>

                        <mat-table *ngIf="isShowEducationalRecord" [dataSource]="dataSourceEducationalRecord" matSort matSortActive="beginEduYear" matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="Control">
                                <mat-header-cell fxFlex="5" *matHeaderCellDef>
                                </mat-header-cell>

                                <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                    <span class="mobile-label"></span>

                                    <div mat-icon-button focusable="false" color="accent">
                                        <img class="unselectable" (click)="educationDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                    </div>

                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lineNo">
                                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                                <mat-cell fxFlex="3" class="font-light" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="beginEduYear">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ปีเริ่มต้น</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" type="number" min="0" step="1" matInput maxlength="100" [(ngModel)]="objjob.beginEduYear" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="endEduYear">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ปีสิ้นสุด</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" type="number" min="0" step="1" matInput maxlength="100" [(ngModel)]="objjob.endEduYear" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="eduQualification">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ระดับศึกษา</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field style="width: 95%;">
                                        <mat-select class="font-light" *ngIf="isEditable" [(ngModel)]="objjob.eduQualification">
                                            <div class="font-light-small">
                                                <mat-option *ngFor="let degree of degreeList" [value]=degree.description>
                                                    {{ degree.description }}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                        <div class="font-light-small">
                                            <input *ngIf="!isEditable" readonly matInput maxlength="100" autocomplete="off" [(ngModel)]="objjob.eduQualification">
                                        </div>
                                        <!-- <input [readonly]="!isEditable"  matInput maxlength="100" [(ngModel)]="objjob.eduQualification" autocomplete="off"> -->
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="qualification">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>คุณวุฒิ</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.qualification" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="fieldOfStudy">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>สาขา</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.fieldOfStudy" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="eduInstitution">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>สถาบัน</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.eduInstitution" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="eduCountry">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ประเทศ</mat-header-cell>
                                <mat-cell class="font-light" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="50" [(ngModel)]="objjob.eduCountry" autocomplete="off" (keydown.Tab)="onclickAddEducation()">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>



                            <mat-header-row *matHeaderRowDef="displayedColumnsEducationalRecord"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsEducationalRecord;"></mat-row>
                        </mat-table>



                        <div class="container" style="padding: 10px; margin-top: 10px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 95%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                    background-color: #002F87; border-radius: 10px; " (click)="btnNext()">{{'ถัดไป '}}
                                    <a style="padding-right: 15px;"></a>
                                   <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                    
                                </button>

                                </td>

                            </tr>
                        </div>
                    </mat-card>
                </mat-tab>

                <mat-tab class="font-light" label="ประสบการณ์การทำงาน">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isWorkExpHistsValid ? 'Black': 'Red'}">{{'ประสบการณ์การทำงาน'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">

                        <!-- <div *ngIf="ishowtabWorkExp" style="padding: 10px; text-align: right;">
                            <tr *ngIf="!ishowtabWorkExpHists" style="width: 100%;">
                                <td style="width: 90%;"></td>
                                <td style="width: 6%;">
                                    <label (click)="onclickAddworkExpHists()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">ประสบการณ์</label>
                                </td>
                                <td style="width: 1%;">
                                    <img (click)="onclickAddworkExpHists()" style=" height: 25px; width: 25px; padding-top: 10px;" src="assets\Icon\Add.png">
                                </td>

                            </tr>
                        </div> -->
                        <div *ngIf="ishowtabWorkExp" style="padding: 10px; text-align: right;">
                            <div fxFlex="80%"></div>

                            <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                                <label (click)="onclickAddworkExpHists()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'ประสบการณ์'}}</label>
                            </div>

                            <div style="padding-right: 12px; " fxFlex="1.5%">
                                <img (click)="onclickAddworkExpHists()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                            </div>
                        </div>

                        <mat-table *ngIf="isshowWorkExperience" [dataSource]="dataSourceWorkExperience" matSort matSortActive="strBeginWorkYear" matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="Control">
                                <mat-header-cell fxFlex="5" *matHeaderCellDef>
                                </mat-header-cell>

                                <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                    <span class="mobile-label"></span>
                                    <button *ngIf="!row.isEdit" mat-icon-button focusable="false" color="accent">
                                    <img (click)="workExpHistsDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lineNo">
                                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                                <mat-cell fxFlex="3" class="font-light" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="isStillWorking">
                                <mat-header-cell fxFlex="10" style="color: #002F87;text-align: center;" class="font-light" *matHeaderCellDef>ยังทำงานอยู่</mat-header-cell>
                                <mat-cell fxFlex="10" *matCellDef="let objjob, let i = index" style="text-align: center;">
                                    <div class="font-light">
                                        <mat-checkbox [(ngModel)]="objjob.isStillWorking" (change)="onIsStillWorkingChange(i)"></mat-checkbox>
                                    </div>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="strBeginWorkYear">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ปีเริ่มต้น</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" type="text" matInput maxlength="4" [(ngModel)]="objjob.strBeginWorkYear" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="strEndWorkYear">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ปีสิ้นสุด</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" [disabled]="objjob.isStillWorking" type="text" min="2500" step="1" matInput maxlength="4" [(ngModel)]="objjob.strEndWorkYear" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="workPosition">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ตำแหน่ง</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.workPosition" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="workPlace">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>หน่วยงาน/สถาบัน/บริษัท</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.workPlace" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="workCountry">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ประเทศ</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="50" [(ngModel)]="objjob.workCountry" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumnsWorkExperience"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsWorkExperience;"></mat-row>
                        </mat-table>


                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป '}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                                </td>

                            </tr>
                        </div>
                    </mat-card>
                </mat-tab>
                <mat-tab class="font-light" label="ประวัติการอบรม/ดูงาน/สัมมนา">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isSeminarHistsValid ? 'Black': 'Red'}">{{'ประวัติการอบรม/ดูงาน/สัมมนา'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">
                        <!-- <div *ngIf="ishowtabSeminar" style="padding: 10px; text-align: right;">
                            <tr *ngIf="!ishowtabSeminarHists" style="width: 100%;">
                                <td style="width: 90%;"></td>
                                <td style="width: 6%;">
                                    <label (click)="onclickAddseminarHists()" class="font-light" style="color: #002F87;  padding-bottom: 30px; padding-right: 5px;">เพิ่มประวัติ</label>
                                </td>
                                <td style="width: 1%;">
                                    <img (click)="onclickAddseminarHists()" style=" height: 25px; width: 25px; padding-top: 10px;" src="assets\Icon\Add.png">
                                </td>

                            </tr>
                        </div> -->
                        <div *ngIf="ishowtabSeminar" style="padding: 10px; text-align: right;">
                            <div fxFlex="80%"></div>

                            <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                                <label (click)="onclickAddseminarHists()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มประวัติ'}}</label>
                            </div>

                            <div style="padding-right: 12px; " fxFlex="1.5%">
                                <img (click)="onclickAddseminarHists()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                            </div>
                        </div>



                        <mat-table [dataSource]="dataSourceSeminar" matSort matSortActive="year" matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="Control">
                                <mat-header-cell fxFlex="5" *matHeaderCellDef>
                                </mat-header-cell>

                                <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                    <span class="mobile-label"></span>

                                    <button *ngIf="!row.isEdit" mat-icon-button focusable="false" color="accent">
                                    <img (click)="seminarHistsDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lineNo">
                                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                                <mat-cell fxFlex="3" class="font-light" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="year">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ปี</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" type="number" min="0" step="1" matInput maxlength="100" [(ngModel)]="objjob.year" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="seminarSubject">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>เรื่อง</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.seminarSubject" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="organizerName">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>หน่วยงานผู้จัด</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="200" [(ngModel)]="objjob.organizerName" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumnsSeminarHists"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsSeminarHists;"></mat-row>
                        </mat-table>
                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป '}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                                </td>

                            </tr>
                        </div>
                    </mat-card>
                </mat-tab>
                <mat-tab label="รางวัล">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isRewardValid ? 'Black': 'Red'}">{{'รางวัล'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">
                        <div style="padding: 10px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 91%;"></td>
                                <td style="width: 8%;">
                                </td>
                                <td style="width: 1%;">
                                </td>

                            </tr>
                        </div>
                        <div>

                            <angular-editor *ngIf="isEditable" class="font-light" (ngModelChange)="onEditorReward($event)" (click)="onclickReward(Reward)" [(ngModel)]="Reward" [config]="config" maxlength="10">
                            </angular-editor>
                            <div *ngIf="!isEditable" style="margin: 10px; display: block;" class="font-light" [innerHtml]="Reward">
                            </div>

                        </div>
                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป '}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                                </td>

                            </tr>
                        </div>

                    </mat-card>
                </mat-tab>

                <mat-tab label="สมาชิกองค์กรวิชาชีพ">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isOrganizationMemberValid ? 'Black': 'Red'}">{{'สมาชิกองค์กรวิชาชีพ'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">
                        <div style="padding: 10px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 91%;"></td>
                                <td style="width: 8%;">
                                </td>
                                <td style="width: 1%;">
                                </td>

                            </tr>
                        </div>
                        <angular-editor *ngIf="isEditable" [config]="config" class="font-light" (ngModelChange)="onEditorOrganizationMember($event)" (click)="onclickOrganizationMember(organizationMember)" [(ngModel)]="organizationMember">
                        </angular-editor>

                        <div *ngIf="!isEditable" style="margin: 10px; display: block;" class="font-light" [innerHtml]="organizationMember">
                        </div>
                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป '}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                                </td>

                            </tr>
                        </div>
                    </mat-card>
                </mat-tab>

                <mat-tab label="การนำเสนอผลงาน">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isPresentWorksValid ? 'Black': 'Red'}">{{'การนำเสนอผลงาน'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">

                        <div style="padding: 10px; text-align: right;">
                            <div fxFlex="80%"></div>

                            <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                                <label (click)="onclickAddpresentWorks()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มผลงาน'}}</label>
                            </div>

                            <div style="padding-right: 12px; " fxFlex="1.5%">
                                <img (click)="onclickAddpresentWorks()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                            </div>
                        </div>


                        <mat-table *ngIf="isShowAttachment" [dataSource]="dataSourcePresentations" matSort matSortActive="year" matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="Control">
                                <mat-header-cell fxFlex="5" *matHeaderCellDef>
                                </mat-header-cell>

                                <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                    <span class="mobile-label"></span>

                                    <button mat-icon-button focusable="false" color="accent">
                                    <img (click)="presentWorksDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                                </mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="lineNo">
                                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                                <mat-cell fxFlex="3" class="font-light" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="presentDate">
                                <mat-header-cell fxFlex="8" style="color: #002F87;" class="font-light" *matHeaderCellDef>วันที่</mat-header-cell>
                                <mat-cell fxFlex="8" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input readonly min="0" step="1" matInput maxlength="100" value="objjob.presentDate|date:'dd MMM yyyy'" autocomplete="off" [matDatepicker]="picker " [(ngModel)]="objjob.presentDate">
                                        </div>
                                        <mat-datepicker-toggle matSuffix [for]="picker "></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="presentWorkName">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่อผลงาน</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input matInput maxlength="200" [(ngModel)]="objjob.presentWorkName" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="eventName">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่องานประชุม</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input matInput maxlength="200" [(ngModel)]="objjob.eventName" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="presentLevel" style="margin-left: 10px;">
                                <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'นำเสนอในประเทศ/ ต่างประเทศ'}}</mat-header-cell>
                                <mat-cell fxFlex="10" *matCellDef="let objjob; let i = index">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <mat-select class="font-light" [(ngModel)]="objjob.presentLevel">
                                            <div class="font-light-small">
                                                <mat-option *ngFor="let type of locationType" [value]=type.id>
                                                    {{ type.desc }}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-cell>

                            </ng-container>

                            <ng-container matColumnDef="presentLocation">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>สถานที่</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input matInput maxlength="100" [(ngModel)]="objjob.presentLocation" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="attachDocument">
                                <mat-header-cell fxFlex="13" style="color: #002F87;" class="font-light" *matHeaderCellDef>ไฟล์แนบ</mat-header-cell>
                                <mat-cell fxFlex="13" *matCellDef="let objjob,let i=index">
                                    <button class="font-light" *ngIf="objjob.attachDocument&&objjob.attachDocument.documentName=='แนบไฟล์'" [readonly]="!isEditable" style="width: 100%;" mat-raised-button color="primary" (click)="presentFile.click()">{{objjob.attachDocument?objjob.attachDocument.documentName:"Choose File"}}</button>

                                    <input hidden type="file" #presentFile (change)="handleFilePresentWork($event.target.files,i)" />
                                    <button *ngIf="objjob.attachDocument&&objjob.attachDocument.documentName!='แนบไฟล์'" mat-icon-button focusable="false" color="accent">
                                    <img (click)="viewAttachDocument(objjob.attachDocument.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png">
                                </button>
                                    <mat-label class="font-light" *ngIf="objjob.attachDocument&&objjob.attachDocument.documentName!='แนบไฟล์'">{{objjob.attachDocument?objjob.attachDocument.documentName:""}}</mat-label>
                                </mat-cell>
                            </ng-container>

                            <mat-header-row *matHeaderRowDef="displayedColumnsPresentations"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsPresentations;"></mat-row>
                        </mat-table>



                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป '}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                                </td>

                            </tr>
                        </div>

                    </mat-card>
                </mat-tab>

                <mat-tab class="font-light" label="ผลงานที่ตีพิมพ์">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': isexternalPublishedWorksValid ? 'Black': 'Red'}">{{'ผลงานที่ตีพิมพ์'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">
                        <!-- <div *ngIf="ishowtabSeminar" style="padding: 10px; text-align: right;">
                            <tr *ngIf="!ishowtabSeminarHists" style="width: 100%;">
                                <td style="width: 70%;">

                                </td>
                                <td style="width: 14%;">
                                    <label (click)="onclickAddPublications()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">เพิ่มผลงานตีพิมพ์</label>
                                </td>
                                <td style="width: 1%;">
                                    <img (click)="onclickAddPublications()" style=" height: 25px; width: 25px; padding-top: 10px;" src="assets\Icon\Add.png">
                                </td>

                            </tr>
                        </div> -->
                        <div *ngIf="ishowtabSeminar" style="padding: 10px; text-align: right;">
                            <div fxFlex="80%"></div>

                            <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                                <label (click)="onclickAddPublications()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มผลงานตีพิมพ์'}}</label>
                            </div>

                            <div style="padding-right: 12px; " fxFlex="1.5%">
                                <img (click)="onclickAddPublications()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                            </div>
                        </div>



                        <mat-table [dataSource]="dataSourcePublications" matSort matSortActive="year" matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="Control">
                                <mat-header-cell fxFlex="5" *matHeaderCellDef>
                                </mat-header-cell>

                                <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                    <span class="mobile-label"></span>

                                    <button *ngIf="!row.isEdit" mat-icon-button focusable="false" color="accent">
                                    <img (click)="onclickDeletePublications(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lineNo">
                                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                                <mat-cell fxFlex="3" class="font-light" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                            </ng-container>


                            <ng-container matColumnDef="publishYear">
                                <mat-header-cell fxFlex="5" style="color: #002F87;" class="font-light" *matHeaderCellDef>ปี</mat-header-cell>
                                <mat-cell fxFlex="5" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" type="number" min="0" step="1" matInput maxlength="100" [(ngModel)]="objjob.publishYear" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="publishWorkName">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ผลงานตีพิมพ์</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="400" [(ngModel)]="objjob.publishWorkName" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="journalName">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่อวารสาร/ ฉบับที่ตีพิมพ์</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" matInput maxlength="100" [(ngModel)]="objjob.journalName" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="journalLevel">
                                <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>ระดับตีพิมพ์</mat-header-cell>
                                <mat-cell fxFlex="10" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <mat-select class="font-light" [disabled]="!isEditable" [(ngModel)]="objjob.journalLevel" (selectionChange)="objjob.quartileTCI_Index=0">
                                            <div class="font-light-small">
                                                <mat-option *ngFor="let level of journalLeveList" [value]=level.id>
                                                    {{ level.description }}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="impactFactor">
                                <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-light" *matHeaderCellDef>Impact Factor</mat-header-cell>
                                <mat-cell fxFlex="7" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div class="font-light">
                                            <input [readonly]="!isEditable" type="number" min="0" matInput maxlength="6" matInput maxlength="100" style="text-align:center;" [(ngModel)]="objjob.impactFactor" autocomplete="off">
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="quartileTCI_Index">
                                <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-light" *matHeaderCellDef>Quartile/ Indexed in TCI</mat-header-cell>
                                <mat-cell fxFlex="7" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <div *ngIf="objjob.journalLevel==2">
                                            <mat-select class="font-light" [disabled]="!isEditable" [(ngModel)]="objjob.quartileTCI_Index">
                                                <div class="font-light-small">
                                                    <mat-option *ngFor="let type of quartileList" [value]=type.id>
                                                        {{ type.description }}
                                                    </mat-option>
                                                </div>
                                            </mat-select>
                                        </div>
                                        <div *ngIf="objjob.journalLevel==1">
                                            <mat-select class="font-light" [disabled]="!isEditable" [(ngModel)]="objjob.quartileTCI_Index">
                                                <div class="font-light-small">
                                                    <mat-option *ngFor="let type of tciTierList" [value]=type.id>
                                                        {{ type.description }}
                                                    </mat-option>
                                                </div>
                                            </mat-select>
                                        </div>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="paticipation">
                                <mat-header-cell fxFlex="8" style="color: #002F87;" class="font-light" *matHeaderCellDef>ความเกี่ยวข้อง</mat-header-cell>
                                <mat-cell fxFlex="8" *matCellDef="let objjob">
                                    <mat-form-field class="example-full-width" style="width: 95%;">
                                        <mat-select class="font-light" [disabled]="!isEditable" [(ngModel)]="objjob.paticipation">
                                            <div class="font-light-small">
                                                <mat-option *ngFor="let type of participationList" [value]=type.id>
                                                    {{ type.description }}
                                                </mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="attachDocument">
                                <mat-header-cell fxFlex="13" style="color: #002F87;" class="font-light" *matHeaderCellDef>ไฟล์แนบ</mat-header-cell>
                                <mat-cell fxFlex="13" *matCellDef="let objjob,let i=index" class="font-light">
                                    <button *ngIf="objjob.attachDocument.documentName=='แนบไฟล์'" class="font-light" [readonly]="!isEditable" style="width: 100%;" mat-raised-button color="primary" (click)="publicationFile.click()">{{objjob.attachDocument?objjob.attachDocument.documentName:"Choose File"}}</button>

                                    <input hidden type="file" #publicationFile (change)="handleFilePublication($event.target.files,i)" />
                                    <button *ngIf="objjob.attachDocument.documentName!='แนบไฟล์'" class="font-light" mat-icon-button focusable="false" color="accent">
                                    <img (click)="viewAttachDocument(objjob.attachDocument.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png">
                                </button>
                                    <mat-label *ngIf="objjob.attachDocument.documentName!='แนบไฟล์'">{{objjob.attachDocument?objjob.attachDocument.documentName:""}}</mat-label>
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumnsPublications"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsPublications;"></mat-row>
                        </mat-table>
                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px;" (click)="btnNext()">{{'ถัดไป '}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">
                                  
                              </button>

                                </td>

                            </tr>
                        </div>
                    </mat-card>
                </mat-tab>

                <mat-tab label="เอกสารแนบ">
                    <ng-template mat-tab-label>
                        <span class="font-medium" [ngStyle]="{'color': 'Black'}">{{'เอกสารแนบ'}}</span>
                    </ng-template>
                    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin-top: 10px; padding: 16px;">
                        <mat-card *ngIf="isEditable">
                            <div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFill>
                                <div fxFlexFill fxLayout="row" style="padding-top: 10px;">

                                    <div fxFlex="2"></div>
                                    <div fxFlex="30%">
                                        <mat-form-field class="font-light" style="width: 95%;">
                                            <div>
                                                <input class="font-light" placeholder="{{'ประเภทเอกสาร'}}" [readonly]='!isEditable' type=" text " aria-label="Country " matInput [matAutocomplete]="auto" [(ngModel)]="attachedFileType " (input)="onDocumentTypeInput(attachedFileType) ">
                                            </div>
                                            <mat-autocomplete #auto class="font-light" (optionSelected)="onAttachedFileTypeSelected($event.option.value) ">
                                                <div>
                                                    <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let option of fileTypeListToShow " [value]="option.description ">
                                                        {{option.description}}
                                                    </mat-option>
                                                </div>
                                            </mat-autocomplete>

                                            <!-- <input *ngIf="!isEditable" readonly  matInput maxlength="100" autocomplete="off"> -->
                                            <!-- <input [readonly]="!isEditable"  matInput maxlength="100" [(ngModel)]="objjob.eduQualification" autocomplete="off"> -->
                                        </mat-form-field>
                                    </div>
                                    <div fxFlex="2"></div>
                                    <div fxFlex="74%">
                                        <mat-form-field class="font-light">
                                            <div class="font-light">
                                                <input [readonly]="!isEditable" matInput readonly maxlength="100" [(ngModel)]="attachedFileName" autocomplete="off" placeholder="{{'ชื่อไฟล์'}}">
                                            </div>
                                        </mat-form-field>
                                    </div>

                                    <div fxFlex="2"></div>
                                    <div fxFlex="10%">
                                        <button style="width: 100%;" class="font-light" mat-raised-button color="primary" (click)="imgFileInput.click()">{{'เลือกไฟล์'}}</button>
                                        <input [readonly]="!isEditable" hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files)" />
                                    </div>
                                    <div fxFlex="2%"></div>
                                    <div fxFlex="10%">
                                        <button style="width: 100%; " mat-raised-button class="font-light" style="background-color: #002F87; color: white;" (click)="btnSaveattachDocuments()">{{'แนบไฟล์'}}</button>
                                    </div>
                                </div>

                            </div>

                        </mat-card><br>

                        <mat-table *ngIf="isShowPresentations" [dataSource]="dataSourceAttachment" matSort matSortActive="ลำดับ" matSortDirection="asc" matSortDisableClear>

                            <ng-container matColumnDef="Control">
                                <mat-header-cell fxFlex="10" *matHeaderCellDef>
                                </mat-header-cell>

                                <mat-cell fxFlex="5" *matCellDef="let row; let i = index" fxFlex="10">
                                    <span class="mobile-label"></span>

                                    <button *ngIf="!row.isEdit" mat-icon-button focusable="false" color="accent">
                                    <img (click)="attachDocumentsDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="lineNo">
                                <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                                <mat-cell fxFlex="3" class="font-light" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="documentType">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ประเภทเอกสาร</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <!-- <input  matInput maxlength="100" [(ngModel)]="objjob.documentType" autocomplete="off"> -->
                                    <div *ngIf="objjob.attachDocument" style="color: #002F87;" class="font-light">{{ objjob.attachDocument.documentType }}</div>
                                </mat-cell>
                            </ng-container>

                            <ng-container matColumnDef="documentName">
                                <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่อไฟล์</mat-header-cell>
                                <mat-cell *matCellDef="let objjob">
                                    <button mat-icon-button focusable="false" color="accent">
                                    <img (click)="viewAttachDocument(objjob.attachDocument.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png">
                                </button>
                                    <div *ngIf="objjob.attachDocument" style="color: #002F87;" class="font-light">{{ objjob.attachDocument.documentName }}</div>
                                </mat-cell>
                            </ng-container>


                            <mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></mat-row>
                        </mat-table>

                        <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                            <tr style="width: 100%;">
                                <td style="width: 5%;">
                                    <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="color: white; padding-right: 10px;">{{'ก่อนหน้า '}}</label>
                                
                            </button>
                                </td>
                                <td style="width: 90%;"></td>
                                <td style="width: 5%;">


                                </td>

                            </tr>
                        </div>

                    </mat-card>
                    <!-- </div> -->
                </mat-tab>

            </mat-tab-group>

        </div>
    </div>

    <!-- </mat-card-content> -->
    <!-- <mat-card-footer class="footer">
        <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
    </mat-card-footer> -->
    <!-- </mat-card> -->
    <!-- <div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 10px; margin-left: 10px; margin-right: 10px;">

    <footer class="footer">
        <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
    </footer>
</div> -->
</div>
<div id="overlay">
    <span id="nav" *ngIf="saveInProgress">
        <div class="spinner" style="display: block; position: fixed">
        </div>
    </span>
    <span id="nav" *ngIf="saveInProgress">
        <div class="submit-message" style="display: block; color: #FFFFFF; position: fixed; left: 49.7%; top: 54.5%;">
            Saving...
        </div>
    </span>
</div>