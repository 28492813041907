<mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px; width: 100%; height: 50%; min-height: 600px;">
    <mat-card-content>
        <div fxLayout="column">
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="mainDashboard(0)">{{mainDashBoardCaption}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="projectByOrgchartandYear(0)">{{projectByOrganizationCaption}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="projectByFundingSourceAndDepartment(0,0)">{{projectByFundSourceAndDepartment}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="budgetByFunding(0,0)">{{budgetByFundingSource}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="projectByOrgchart()">{{projectByOrganizationandCaption}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="budgetReceivedByYearReport(0,0)">{{projectByFundSourceAndFiscalYear}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="projectProgrssCountReport()">{{progressComparisonCaption}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="reserachByOrgChart()">{{researchByOrganizationCaption}}</button>
            <button mat-raised-button style="font-size: 10px; font-family: chulabhornlikittext-bold;" class="btn-chart-menu" (click)="researcherGrowthRate(0)">{{researchGrowthRateCaption}}</button>
        </div>
        <div fxLayout="column" fxFlex="85%">
            <div style="width: 80%; height: 50%; padding-left: 5px;">
                <div fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="10%"></div>
                    <div *ngIf="showStartYear" fxFlex="35%">
                        <mat-form-field class="example-full-width">
                            <mat-select placeholder="{{startYearDesc}}" [(ngModel)]="startFromYear" (selectionChange)="fromYearChange()">
                                <mat-option *ngFor="let year of yearList" [value]=year>
                                    {{ year }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxFlex="10%"></div>
                    <div *ngIf="showEndYear" fxFlex="35%">
                        <mat-form-field class="example-full-width">
                            <mat-select placeholder="{{'ถึงปี'}}" [(ngModel)]="endAtYear" (selectionChange)="toYearChange()">
                                <mat-option *ngFor="let year of yearList" [value]=year>
                                    {{ year }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div fxFlex="10%"></div>
                </div>

                <canvas *ngIf="isShowingOrgAndYear" id="lineChartOrgYear"></canvas>
                <canvas *ngIf="isShowingOrg" id="lineChart"></canvas>
                <canvas *ngIf="isShowingResearch" id="researchInOrgChart"></canvas>
                <canvas *ngIf="isShowingProgress" id="progressSummaryChart"></canvas>
                <canvas *ngIf="isShowingResearcherGrowth" id="growthRateChart"></canvas>
                <canvas *ngIf="isShowingProjectByFundingSourceAndDepartment" id="projectByFundingSourceAndDepartment"></canvas>
                <canvas *ngIf="isShowingBudgetByFunding" id="budgetByFunding"></canvas>
                <canvas *ngIf="isShowingBudgetByYear" id="budgetByYear"></canvas>
                <table *ngIf="isShowingMainChart" width=100%>
                    <tr width=100%>
                        <td width=50%>
                            <canvas *ngIf="isShowingMainChart" id="projectTypeChart"></canvas>
                        </td>
                        <td width=50%>
                            <canvas *ngIf="isShowingMainChart" id="fundingSourceComparisonChart"></canvas>
                        </td>
                    </tr>
                    <tr width=100%>
                        <td width=50%>
                            <canvas *ngIf="isShowingMainChart" id="newProjectFundingChart"></canvas>
                        </td>
                        <td width=50%>
                            <canvas *ngIf="isShowingMainChart" id="continueProjectFundingChart"></canvas>
                        </td>
                    </tr>
                </table>
            </div>
            <!-- <app-project-number-by-org></app-project-number-by-org> -->
        </div>
    </mat-card-content>
</mat-card>