export class enumdata  {
    id: number;
    groupName: string;
    orderNo:number;
    description: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
  }