import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { MsalGuard,MsalModule, MsalRedirectComponent,MsalInterceptor  } from '@azure/msal-angular';
import { InteractionType,PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';

import { HTTP_INTERCEPTORS,HttpClientModule, HttpClient } from '@angular/common/http';
import { MaterialModule } from './module/material/material.module';
import { ProjectsearchModule } from './projectsearch/projectsearch.module';
import { ResearcherModule } from "./researcher/researcher.module";
import { ChartPortalModule } from "./chart-portal/chart-portal.module";
import { TranslateModule, TranslateLoader} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmDeleteDialogComponent } from './dialogs/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { ReportModule } from "./reports/report.module";
import { SysuserModule } from "./sysuser/sysuser.module";
import { DataService } from "./services/messageservice";
import { MaindataModule } from "./maindata/maindata.module";

// import { SDLocalStorage } from "./services/sdutility.service";


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    ConfirmDialogComponent,
    ConfirmDeleteDialogComponent,

  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    ProjectsearchModule,
    ResearcherModule,
    ChartPortalModule,
    ReportModule,
    SysuserModule,
    MaindataModule,
    CommonModule,
    // SDUtilityService,
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId:environment.uiClienId,
        redirectUri:environment.redirectUrl,
        authority:'https://login.microsoftonline.com/' + environment.tenantId,
        // postLogoutRedirectUri:environment.redirectUrl, //Web ที่จะแสดงหลัง logout
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
      }
    }), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
        }
    }, {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']]
      ])
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
    }})

  ],
  providers: [
    MsalGuard,{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
    },
    DataService
  ],
  exports:[
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule
  ],
  bootstrap: [AppComponent, MsalRedirectComponent] // MsalRedirectComponent bootstrapped here
})
export class AppModule { }
