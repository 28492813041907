<div class="container">
    <div *ngIf="!loginDisplay" class="header">
        <table width="100%">
            <tr width="100%">
                <td colspan="3" width="100%" align="center">
                    <img style=" height:60%;width: 60%; margin-top: 53px; margin-left: 50px; " src="assets\images\logo\RoyalAcademy_medium.png">
                </td>
            </tr>
            <tr>
                <td colspan="3" align="center">
                    <br> กำลังเข้าสู่ระบบ กรุณารอสักครู่...
                </td>
            </tr>
            <tr>
                <td width="40%">

                </td>
                <td align="center" width="20%">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </td>
                <td width="40%">

                </td>
            </tr>
        </table>
    </div>
    <div *ngIf="loginDisplay" class="header">
        <table width='100%' cellspacing="0" cellpadding="0">
            <tr width='100%' height='70px'>
                <td width='10%'> <img style=" height:100%;width: 100%;" src="assets\images\logo\RoyalAcademy_small.png"></td>
                <td width='90%' style="background-color: navy;">
                    <div class="font-bold" style="color: white; font-size: 26px; width: 100%;height: 100%; text-align: center;">ระบบสารสนเทศเพื่อการวิจัย ราชวิทยาลัยจุฬาภรณ์ (CRA Research Management System (CRMS))</div>
                    <!-- <div class="font-bold" style="color: white; font-size: 26px; width: 100%;height: 100%; text-align: center;">ระบบสารสนเทศ เพื่อการวิจัยด้านการสืบค้นและแสดงข้อมูลงานวิจัย ราชวิทยาลัยจุฬาภรณ์</div> -->

                    <!-- <label class="font-bold" style="color: navy; font-size: 22px; ;">ระบบสารสนเทศ เพื่อการวิจัยด้านการสืบค้นและแสดงข้อมูลงานวิจัย ราชวิทยาลัยจุฬาภรณ์</label> -->
                </td>
            </tr>
            <tr width='100%'>
                <td width='100%' colspan="2">
                    <mat-toolbar class="toolbar-menu">

                        <!-- <div class="toolbar-spacer"></div> -->
                        <div *ngIf="!isHideMenuTabs" fxLayout fxLayoutGap="2px" fx-layout-align="space-between" class="navigation-items" style="padding: 0px;">
                            <!-- <div class="toolbar-spacer"></div> -->
                            <tr style="margin: 0px; padding: 0px; width: 107px;">
                                <!-- <div *ngIf="isEnableDashbord"> -->
                                <td *ngIf="isShowDashboard && isEnableDashbord" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                                 border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                                color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                                padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                                display: block; width: 75px;" class="font-bold" (click)="gotoDashboard()">Dashboard</label>
                                </td>
                                <td *ngIf="!isShowDashboard && isEnableDashbord" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                                background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                                padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoDashboard()">Dashbord</label>
                                </td>
                                <!-- </div> -->
                                <!-- <div *ngIf="isEnableResearchProject"> -->
                                <td *ngIf="isShowResearchProject && isEnableResearchProject" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                                border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                               color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                               padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                               display: block; width: 75px;" class="font-bold" (click)="gotoProjectList()">โครงการวิจัย</label>
                                </td>
                                <td *ngIf="!isShowResearchProject && isEnableResearchProject" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                                background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                                padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoProjectList()">โครงการวิจัย</label>
                                </td>
                                <!-- </div> -->
                                <!-- <div *ngIf="isEnableResearcher"> -->
                                <td *ngIf="isShowResearcher && isEnableResearcher" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                                border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                               color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                               padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                               display: block; width: 75px;" class="font-bold" (click)="gotoResearcherList()">นักวิจัย</label>
                                </td>
                                <td *ngIf="!isShowResearcher && isEnableResearcher" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                                background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                                padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoResearcherList()">นักวิจัย</label>
                                </td>
                                <!-- </div>
                            <div *ngIf="isEnableReport"> -->
                                <td *ngIf="isShowReport && isEnableReport" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                                border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                               color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                               padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                               display: block; width: 75px;" class="font-bold" (click)="gotoReport()">รายงาน</label>
                                </td>
                                <td *ngIf="!isShowReport && isEnableReport" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                                background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                                padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoReport()">รายงาน</label>
                                </td>
                                <!-- </div>
                            <div *ngIf="isEnableSetting"> -->
                                <td *ngIf="isShowSetting && isEnableSetting" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                                border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                               color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                               padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                               display: block; width: 75px;" class="font-bold" (click)="gotoSetting()">กำหนดสิทธิ์</label>
                                </td>
                                <td *ngIf="!isShowSetting && isEnableSetting" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                                background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                                padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoSetting()">กำหนดสิทธิ์</label>
                                </td>
                                <!-- </div>
                            <div *ngIf="isEnableSearchResearcher"> -->
                                <td *ngIf="isShowSearchResearcher && isEnableSearchResearcher" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                                border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                               color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                               padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                               display: block; width: 75px;" class="font-bold" (click)="gotoSearch()">สืบค้นงานวิจัย</label>

                                </td>
                                <td *ngIf="!isShowSearchResearcher && isEnableSearchResearcher" style="margin: 0px; padding: 0px;">
                                    <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                                background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87;; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                                padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoSearch()">สืบค้นงานวิจัย</label>
                                </td>
                                <!-- </div> -->
                                <td *ngIf="isShowSearchMainData && isEnableSearchMainData" style="margin: 0px; padding: 0px;">
                                  <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                              border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                             color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                             padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                             display: block; width: 75px;" class="font-bold" (click)="gotoMainData()">ข้อมูลหลัก</label>

                              </td>
                              <td *ngIf="!isShowSearchMainData && isEnableSearchMainData" style="margin: 0px; padding: 0px;">
                                  <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                              background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87;; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                              padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoMainData()">ข้อมูลหลัก</label>
                              </td>
                            </tr>
                        </div>
                        <div class="toolbar-spacer"></div>
                        <div class="toolbar-spacer"></div>

                        <button mat-button class="button" [matMenuTriggerFor]="menu">

                            <div class="font-light" style="text-align:center; font-weight: bold;"><img style="margin-right: 3px;" src="assets\Icon\outline_account_white_1.png"> {{displayName}}  </div>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">

                            <button mat-menu-item (click)="logout()">{{'ออกจากระบบ'}}</button>
                        </mat-menu>
                        <button mat-raised-button class="btn-register" *ngIf="showRegister" (click)="gotoResearcherRegister()">{{'ลงทะเบียนนักวิจัย'}}</button>

                    </mat-toolbar>
                </td>
            </tr>
        </table>
        <div style="height: 1px; width: 100%; background-color: #FD7E14;"></div>
        <div class="content-body">
            <router-outlet *ngIf="!isIframe"></router-outlet>
        </div>
        <!-- <div>
            <mat-toolbar class="toolbar-bacground">
                <img style=" height: 200px;width: 150px;  margin-left: 50px;" src="assets\images\logo\RoyalAcademy.png">
                <div class="toolbar-spacer"></div>
                <label class="font-bold" style="color: navy; font-size: 22px;">ระบบสารสนเทศ เพื่อการวิจัยด้านการสืบค้นและแสดงข้อมูลงานวิจัย ราชวิทยาลัยจุฬาภรณ์</label>
                <div class="toolbar-spacer"></div>

                <div>
                    <button *ngIf="isShowIconLangTH">TH</button>
                </div>

                <div style="padding-right: 15px;"></div>

            </mat-toolbar>
            <div style="background-color:  #FD7E14; height: 1px;"></div>

        </div>

        <div>

            <mat-toolbar class="toolbar-menu">

                <div class="toolbar-spacer"></div>
                <div *ngIf="!isHideMenuTabs" fxLayout fxLayoutGap="2px" fx-layout-align="space-between" class="navigation-items" style="padding: 0px;">
                    <tr style="margin: 0px; padding: 0px; width: 107px;">
                        <td *ngIf="isShowDashboard && isEnableDashbord" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                         border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                        color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                        padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                        display: block; width: 75px;" class="font-bold" (click)="gotoDashboard()">Dashboard</label>
                        </td>
                        <td *ngIf="!isShowDashboard && isEnableDashbord" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                        background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                        padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoDashboard()">Dashbord</label>
                        </td>
                        <td *ngIf="isShowResearchProject && isEnableResearchProject" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                        border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                       color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                       padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                       display: block; width: 75px;" class="font-bold" (click)="gotoProjectList()">โครงการวิจัย</label>
                        </td>
                        <td *ngIf="!isShowResearchProject && isEnableResearchProject" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                        background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                        padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoProjectList()">โครงการวิจัย</label>
                        </td>
                        <td *ngIf="isShowResearcher && isEnableResearcher" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                        border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                       color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                       padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                       display: block; width: 75px;" class="font-bold" (click)="gotoResearcherList()">นักวิจัย</label>
                        </td>
                        <td *ngIf="!isShowResearcher && isEnableResearcher" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                        background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                        padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoResearcherList()">นักวิจัย</label>
                        </td>
                        <td *ngIf="isShowReport && isEnableReport" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                        border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                       color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                       padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                       display: block; width: 75px;" class="font-bold" (click)="gotoReport()">รายงาน</label>
                        </td>
                        <td *ngIf="!isShowReport && isEnableReport" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                        background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                        padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoReport()">รายงาน</label>
                        </td>
                        <td *ngIf="isShowSetting && isEnableSetting" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                        border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                       color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                       padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                       display: block; width: 75px;" class="font-bold" (click)="gotoSetting()">กำหนดสิทธิ์</label>
                        </td>
                        <td *ngIf="!isShowSetting && isEnableSetting" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                        background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                        padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoSetting()">กำหนดสิทธิ์</label>
                        </td>
                        <td *ngIf="isShowSearchResearcher && isEnableSearchResearcher" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px;     text-align: center; border-style: solid; border-width: thin;
                        border-color: #FD7E14; background-color: white; border-radius: 10px 10px 0px 0px;
                       color: #002F87;  font-size: 13px; font-family: chulabhornlikittext-bold;
                       padding-top: 5px; padding-bottom: 1px; padding-left: 20px; padding-right: 20px;
                       display: block; width: 75px;" class="font-bold" (click)="gotoSearch()">สืบค้นงานวิจัย</label>

                        </td>
                        <td *ngIf="!isShowSearchResearcher && isEnableSearchResearcher" style="margin: 0px; padding: 0px;">
                            <label style="font-size: 13px; text-align: center; border-style: solid; border-width: thin; border-color: white;
                        background-color: #c5c5c5; border-radius: 10px 10px 0px 0px; color: #002F87;; font-size: 13px; font-family: chulabhornlikittext-bold; padding-top: 5px;
                        padding-bottom: 1px; padding-left: 20px; padding-right: 20px; display: block; width: 75px;" class="font-bold" (click)="gotoSearch()">สืบค้นงานวิจัย</label>
                        </td>
                    </tr>
                </div>
                <div class="toolbar-spacer"></div>
                <div class="toolbar-spacer"></div>

                <button mat-button class="button" [matMenuTriggerFor]="menu">

                    {{displayName}}<img  src="assets\Icon\more_vert.png">
                </button>
                <mat-menu #menu="matMenu" xPosition="before">

                    <button mat-menu-item (click)="logout()">{{'ออกจากระบบ'}}</button>
                </mat-menu>
                <button *ngIf="showRegister" mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold;" (click)="gotoResearcherRegister()">{{'ลงทะเบียนนักวิจัย'}}</button>


            </mat-toolbar>
            <div style="background-color:  #FD7E14; height: 1px;"></div>
        </div>
        <div class="content-body">
            <router-outlet *ngIf="!isIframe"></router-outlet>
        </div>

    </div> -->
        <div *ngIf="loginDisplay" class="footer" style="color:white;">
            สงวนลิขสิทธิ์ ๒๕๖๔ ศูนย์การเรียนรู้และวิจัยเฉลิมพระเกียรติ ๖๐ ปี เจ้าฟ้าจุฬาภรณ์ ราชวิทยาลัยจุฬาภรณ์
        </div>


    </div>
