export class attachdocument{
    id: number;
    documentType: string;
    documentName: string;
    documentExtention: string;
    documentContent: string;
    mimeType:string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
}