<div fxLayout="row" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
  <div fxFlex="100%" style="padding-left: 5px;">
      <mat-form-field class="font-light">
          <div class="font-light">
              <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{'ค้นหา'}}">
          </div>
      </mat-form-field>
  </div>

  <div fxFlex="2%">

  </div>
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
    <!-- <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px; margin-bottom: 10px;"> -->
    <mat-card style="border-radius: 10px; margin: 10px;  border-style: solid; border-color: #d9d9d9;">
        <!-- <div style="margin: 10px;"> -->

        <mat-table *ngIf="isShowSysUserRoll" [dataSource]="dataSourceSysuserRoll" matSort matSortActive="email" matSortDirection="asc" matSortDisableClear>
            <ng-container matColumnDef="email">
                <mat-header-cell fxFlex="40" style="color: #002F87;" class="font-bold" *matHeaderCellDef>User</mat-header-cell>
                <mat-cell fxFlex="40" *matCellDef="let objjob">
                    {{ objjob.email }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="admin">
                <mat-header-cell style="color: #002F87;" class="font-bold" *matHeaderCellDef>Admin</mat-header-cell>
                <mat-cell style="justify-content: center;" *matCellDef="let objjob, let i = index" style="text-align: center;">
                    <div style="text-align: center; width: 100%;">
                        <mat-checkbox readonly style="text-align: center;" [(ngModel)]="objjob.isAdmin"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="management">
                <mat-header-cell style="color: #002F87;" class="font-bold" *matHeaderCellDef>Management</mat-header-cell>
                <mat-cell style="justify-content: center;" *matCellDef="let objjob, let i = index" style="text-align: center;">
                    <div style="text-align: center; width: 100%;">
                        <mat-checkbox readonly [(ngModel)]="objjob.isManagement"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="hightlevelmanagement">
                <mat-header-cell style="color: #002F87;" class="font-bold" *matHeaderCellDef>High Level Management</mat-header-cell>
                <mat-cell style="justify-content: center;" *matCellDef="let objjob, let i = index" style="text-align: center;">
                    <div style="text-align: center; width: 100%;">
                        <mat-checkbox readonly [(ngModel)]="objjob.isHightLevelManagement"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="inactive">
                <mat-header-cell style="color: #002F87;" class="font-bold" *matHeaderCellDef>Inactive</mat-header-cell>
                <mat-cell style="justify-content: center;" *matCellDef="let objjob, let i = index" style="text-align: center;">
                    <div style="text-align: center; width: 100%;">
                        <mat-checkbox readonly [(ngModel)]="objjob.inActivated"></mat-checkbox>
                    </div>
                </mat-cell>
            </ng-container>



            <!-- <ng-container matColumnDef="control">
                            <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef></mat-header-cell>
                            <mat-cell fxFlex="10" style="justify-content: center;" *matCellDef="let objjob, let i = index" style="text-align: center;">
                                <button mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold; " class="btn-black" (click)="btnSave(objjob)">{{'Save' }}</button>
                            </mat-cell>
                        </ng-container> -->

            <mat-header-row *matHeaderRowDef="displayedColumnsSysuserRoll"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsSysuserRoll;" (click)="rowClicked(row)"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list" (page)="onPaginateChange($event)">
        </mat-paginator>
        <!-- </div> -->
    </mat-card>
    <!-- </div> -->
</div>
<!-- </div>
</div> -->
