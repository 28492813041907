<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px">
    <div class="table-container">
        <div fxFlex class="table-component-holder mat-elevation-z4">

            <br>
            <table width=100% style="margin-top: 5px;">
                <tr>
                    <td width=35% style="text-align: left;">
                        <button style="margin-right: 10px; " mat-raised-button class="btn-back" (click)="CloseClick()">{{'ย้อนกลับ'}}</button>

                    </td>
                    <td width=30% style="text-align: center;">

                    </td>
                    <td width=35% style="text-align: right;">

                        <button style="margin-left: 10px;" mat-raised-button class="btn-black" (click)="captureScreen()">{{'พิมพ์'}}</button>


                    </td>
                </tr>
            </table><br>

            <!-- <div id="contentToConvert" style="margin-bottom: 100px; ">
                <div class="page" style="display: block;overflow:hidden;">
                    <app-report-cv-viewer [objresearcherData]="Reportdatas"></app-report-cv-viewer>
                </div>
            </div> -->

            <div id="contentToConvert" style="margin-bottom: 100px;">
                <div class="page">
                    <div class="subpage">
                        <app-report-cv-viewer [objresearcherData]="Reportdatas"></app-report-cv-viewer>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
