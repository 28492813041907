<!-- <div class="table-container">
    <div fxFlexFill fxLayout="row" style="vertical-align: middle;">
        <div fxFlex="0.8"></div>
        <div fxFlex="95%">
            <mat-form-field>
                <input style="font-size: 13px;" class="font-light" matInput class="font-bold" (keyup)="applyFilter($event.target.value)" placeholder="{{'ค้นหา'}}">
            </mat-form-field>
        </div>
        <div fxFlex="2"></div>
        <div fxFlex="10%" *ngIf="isShowFilter" style="padding-right: 5px;">
            <mat-form-field class="example-full-width">
                <mat-select style="font-size: 13px; " class="font-light" [(ngModel)]="selectedStatus" (selectionChange)='onSelectedStatusChange()'>
                    <mat-option *ngFor="let statusList of researcherStatusList" [value]=statusList.id>
                        {{ statusList.statusName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div> -->
<div fxLayout="row" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
    <div fxFlex="88%" style="padding-left: 5px;">
        <mat-form-field class="font-light">
            <div class="font-light">
                <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{'ค้นหา'}}">
            </div>
        </mat-form-field>
    </div>
    <div fxFlex="12%" style="padding-right: 5px;">
        <mat-form-field class="example-full-width">
            <mat-select class="font-light" [(ngModel)]="selectedStatus" (selectionChange)='onSelectedStatusChange()'>
                <div class="font-light-small">
                    <mat-option *ngFor="let statusList of researcherStatusList" [value]=statusList.id>
                        {{ statusList.statusName }}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <!-- <div fxFlex="2%">

    </div> -->
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">

    <mat-card style="border-radius: 10px; margin: 10px;  border-style: solid; border-color: #d9d9d9;">

        <mat-table [dataSource]="dataSource" matSort matSortActive="firstNameTH" matSortDirection="asc" matSortDisableClear (matSortChange)="sortData($event)">


            <ng-container matColumnDef="Control">
                <mat-header-cell fxFlex="5" *matHeaderCellDef>
                </mat-header-cell>

                <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                    <span class="mobile-label"></span>


                    <button *ngIf="row.researcherStatus == 1" mat-icon-button focusable="false" color="accent">
                        <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\AddPersonal.png">
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="position">
                <mat-header-cell class="font-bold" fxFlex="5" style="color: #002F87;" *matHeaderCellDef mat-sort-header>{{'ลำดับ'}}</mat-header-cell>
                <mat-cell class="font-light" fxFlex="5" style="color: #002F87;" *matCellDef="let element; let i = index">{{ element.rowNo }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="employeeCode">
                <mat-header-cell class="font-bold" fxFlex="9" style="color: #002F87;" *matHeaderCellDef mat-sort-header>{{'รหัสพนักงาน'}}</mat-header-cell>
                <mat-cell fxFlex="9" *matCellDef="let objjob">
                    <div class="font-light" style="color: #002F87;">{{ objjob.employeeCode }}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="firstNameTH">
                <mat-header-cell class="font-bold" fxFlex="15" style="color: #002F87;" *matHeaderCellDef mat-sort-header>{{'ชื่อนักวิจัย'}}</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let objjob">
                    <div class="font-light" style="color: #002F87;">{{ objjob.prefixTH+' '+objjob.firstNameTH+' '+objjob.lastNameTH }}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="organizationMember">
                <mat-header-cell class="font-bold" style="color: #002F87;" *matHeaderCellDef>{{'สังกัด'}}</mat-header-cell>
                <mat-cell *matCellDef="let objjob">
                    <div class="font-light" style="color: #002F87;">{{ objjob.organizationName }}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="currentPosition">
                <mat-header-cell class="font-bold" style="color: #002F87;" *matHeaderCellDef mat-sort-header>{{'ตำแหน่ง'}}</mat-header-cell>
                <mat-cell fxFlex="15" *matCellDef="let objjob">
                    <div class="font-light" style="color: #002F87;">{{ objjob.currentPosition }}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="researcherStatus">
                <mat-header-cell class="font-bold" fxFlex="9" style="color: #002F87;" *matHeaderCellDef mat-sort-header>{{'สถานะนักวิจัย'}}</mat-header-cell>
                <mat-cell fxFlex="9" *matCellDef="let objjob">
                    <div class="font-light" style="color: #002F87;">{{ statusDescList[objjob.researcherStatus] }}</div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="createDate">
                <mat-header-cell class="font-bold" fxFlex="10" style="color: #002F87;" *matHeaderCellDef>{{'วันที่เริ่มเป็นนักวิจัย'}}</mat-header-cell>
                <mat-cell fxFlex="10" *matCellDef="let objjob">

                    <div class="font-light" style="color: #002F87;">{{ transformDate(objjob.approveDate)}}</div>
                </mat-cell>
            </ng-container>


            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></mat-row>
        </mat-table>
        <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list">
        </mat-paginator>
    </mat-card>
</div>
