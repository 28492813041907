<div *ngIf="isConsent" style="width: 100%;height: 400px; padding: 100px; " fxLayout="row" fxLayoutAlign="center center">
    <br> <br> <br> <br> <br>
    <mat-progress-bar width="200px" mode="indeterminate"></mat-progress-bar>
</div>
<div *ngIf="!isConsent">
    <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 50px; height: 300px; padding: 50px;">
        <div>
            <b class="font-bold">ข้าพเจ้ายินยอมให้ศูนย์การเรียนรู้และวิจัยเฉลิมพระเกียรติ 60 ปี เจ้าฟ้าจุฬาภรณ์ จัดเก็บ รวบรวม เก็บรักษา ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลเพื่อใช้ใน <br>ระบบสารสนเทศวิจัย ของราชวิทยาลัยจุฬาภรณ์ และให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล</b>
        </div>
    </mat-card>
    <div style="margin-bottom: 30px; text-align: center; width: 100%;">
        <button class="btn-black" mat-raised-button (click)="acceptConsent()">ยินยอม </button>
    </div>
</div>