<div class="table-container">
  <div fxFlexFill fxLayout="row">
      <table style="width: 100%;">
          <tr>
              <td style="width: 87%; text-align: left; padding-right: 15px; padding-top: 10px;">
                  <!-- <button *ngIf="showProjectProposeBtn" (click)="refreshList()" style="margin-left: 10px; margin-top: 0px;" mat-raised-button class="btn-send">{{'เรียกข้อมูล'}}</button> -->
                  <button style="margin-left: 10px; margin-top: 0px; font-family: chulabhornlikittext-light; font-size: small;" mat-raised-button class="btn-Menu" class="btn-New" (click)="btnNew()">{{'เพิ่มหน่วยงาน' }}</button>
              </td>

              <!-- <TD style="width: 2%;"></TD> -->
          </tr>
      </table>
  </div>
</div>
<div fxLayout="row" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
  <div fxFlex="100%" style="padding-left: 5px;">
      <mat-form-field class="font-light">
          <div class="font-light">
              <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event.target.value)" placeholder="{{'ค้นหา'}}">
          </div>
      </mat-form-field>
  </div>

  <div fxFlex="2%">

  </div>
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
  <mat-card style="border-radius: 10px; margin: 10px;  border-style: solid; border-color: #d9d9d9;">

      <mat-table  [dataSource]="dataSourceOrganization" matSort matSortActive="email" matSortDirection="asc" matSortDisableClear>
          <ng-container matColumnDef="RowNo">
              <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-bold" *matHeaderCellDef>ลำดับ</mat-header-cell>
              <mat-cell fxFlex="10" style="justify-content: center;" *matCellDef="let objjob, let i = index">
                <div class="font-light" style="color: #002F87; text-align: center; width: 100%;">
                  {{ objjob.rowNo }}
                </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="OrganizationName">
              <mat-header-cell fxFlex="22.5" style="color: #002F87;" class="font-bold" *matHeaderCellDef>หน่วยงาน</mat-header-cell>
              <mat-cell fxFlex="22.5" style="justify-content: left;" *matCellDef="let objjob" >
                  <div class="font-light" style="color: #002F87;width: 100%;">
                    {{ objjob.organizationName }}
                  </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="OrganizationShortName">
              <mat-header-cell fxFlex="22.5" style="color: #002F87;" class="font-bold" *matHeaderCellDef>ชื่อย่อหน่วยงาน</mat-header-cell>
              <mat-cell fxFlex="22.5" style="justify-content: left;" *matCellDef="let objjob" >
                  <div class="font-light" style="color: #002F87; width: 100%;">
                      {{ objjob.organizationShortName }}
                  </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Affiliation">
              <mat-header-cell fxFlex="22.5" style="color: #002F87;" class="font-bold" *matHeaderCellDef>สังกัด</mat-header-cell>
              <mat-cell fxFlex="22.5" style="justify-content: left;" *matCellDef="let objjob" >
                  <div class="font-light" style="color: #002F87; width: 100%;">
                      {{ objjob.affiliation }}
                  </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="Inactive">
              <mat-header-cell fxFlex="22.5" style="color: #002F87;" class="font-bold" *matHeaderCellDef>การใช้งาน</mat-header-cell>
              <mat-cell fxFlex="22.5" style="justify-content: center;" *matCellDef="let objjob" style="text-align: center;">
                  <div class="font-light" style="color: #002F87; text-align: center; width: 100%;">
                      <!-- <mat-checkbox  [(ngModel)]="objjob.inActivated"></mat-checkbox> -->
                      {{ objjob.inActivated == true ? 'ไม่ใช้งาน': 'ใช้งาน' }}
                  </div>
              </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsOrganization"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsOrganization;" (click)="rowClicked(row)"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list" (page)="onPaginateChange($event)">
      </mat-paginator>

  </mat-card>

</div>
