import { attachdocument } from "./attachdocument";

export class projectcancellation{
    id: number;
    projectId: number;
    cancelReason: string;
    cancelDocumentId: number;
    cancelDocument:attachdocument
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}