import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResearcherRoutingModule } from './researcher-routing.module';
import { RegisterComponent } from './register/register.component';
import { MaterialModule } from '../module/material/material.module';
import { ResearcherListComponent } from './researcher-list/researcher-list.component';
import { ApprovalDetailComponent } from './approval-detail/approval-detail.component';
import { ResearcherEditComponent } from './researcher-edit/researcher-edit.component';
import { MessageDialogComponent } from '../dialogs/message-dialog/message-dialog.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from '../app.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// import { AppRoutingModule } from '../app-routing.module';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [RegisterComponent, ResearcherListComponent, ApprovalDetailComponent, ResearcherEditComponent,MessageDialogComponent],
  imports: [
    CommonModule,
    ResearcherRoutingModule,
    MaterialModule,
    BrowserModule,
    
    // AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers:[
  ]
})
export class ResearcherModule { }
