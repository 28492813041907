export class durablearticle{
    id: number;
    projectId: number;
    orderNo: number;
    durableArticleName: string;
    durableArticleStatus: string;
    equivalentDurableArticle: string;
    presentStatus: string;
    usage: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}