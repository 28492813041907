export class educationhistory{
    id: number;
    researcherId: number;
    orderNo: number;
    beginEduYear: number;
    endEduYear: number;
    eduQualification: string;
    qualification:string;
    fieldOfStudy: string;
    eduInstitution: string;
    eduCountry: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}