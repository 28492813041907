import { Component, OnInit, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
  
})
export class MessageDialogComponent implements OnInit {
  UserLang: any = {};
  constructor(
    public dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
        //#region "TranslateModule"
        //#endregion "TranslateModule"
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  ngOnInit() {
 
  }

  OKClick(): void {
    this.dialogRef.close();
  }


  validate(){
    console.log(this.dialogRef.afterOpened);
    let strValidate : string = "";

    if(strValidate != "")
    {
      alert(strValidate);
      return false;
    }
    else
    {
      return true;
    }
   
  }
  // Messagetype: type,
  // Messagetitle: title,
  // Messagebody: body
  
}
