import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { projectrowforlist } from 'src/app/datamodel/projectrowforlist';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { sortingArrayList, transformDateToBE } from '../../services/general-function';
import { projectstatus } from 'src/app/datamodel/projectstatus';
import * as moment from 'moment';

@Component({
  selector: 'app-projectsearch-list',
  templateUrl: './projectsearch-list.component.html',
  styleUrls: ['./projectsearch-list.component.scss']
})
export class ProjectsearchListComponent implements OnInit {
  [x: string]: any;
  filter: string = "";
  sortField: string;
  sortDirection: string;
  UserLang: any = {};

  projectList: [projectrowforlist]
  errorMessage: string;
  showProjectProposeBtn: boolean = false;
  pageNumber: number = 0;
  pageCatch: any;
  projectStatusList: projectstatus[];
  selectedStatus: number;

  isShowTimerGreen: boolean;
  isShowTimerYello: boolean;
  isShowTimerRed: boolean;


  dataSource = new MatTableDataSource();
  displayedColumns = ['lineNo', 'organizationName', 'fiscalYear', 'projectCode', 'projectName', 'researcherFullName', 'projectStatus', 'projectStartDate', 'nextProjectProgressReportDate', 'isNewMessage', 'projectRequestDate', 'istimer'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

  }

  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.

  }

  private Url_ResearchProject: string = "/auth/researcher/create-new-research-project";

  constructor(private router: Router,
    private brokerAPIService: BrokerAPIService,
  ) {
    if (localStorage.getItem("showprojectpropose") == "Y") {
      this.showProjectProposeBtn = true;
    } else {
      this.showProjectProposeBtn = false;
    }

  }


  onPaginateChange(datatest: any) {

    // this.capturePageParameter();
    // console.log("page==out>"+sessionStorage.getItem('pagecatch'));
    if (datatest['pageIndex'] == 0) {
      this.pageNumber = 0;
    } else {
      this.pageNumber = datatest['pageIndex'] * datatest['pageSize'];
    }
  }
  capturePageParameter() {
    let pageCatch: any = { "filter": this.filter, "page": this.paginator.pageIndex, "sortField": this.sortField, "sortDirection": this.sortDirection };
    console.log("sendout", JSON.stringify(pageCatch));
    sessionStorage.setItem('pagecatch', JSON.stringify(pageCatch));
  }

  ngOnInit(): void {
    this.getProjectList(0);
    this.getProjectStatusList();

  }

  refreshList() {
    this.filter = '';
    this.applyFilter('');
    sessionStorage.setItem('pagecatch', '');
    if (this.projectStatusList) {
      this.selectedStatus = this.projectStatusList[this.projectStatusList.length - 1].id;
    }
    this.getProjectList(0);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    // this.capturePageParameter();
  }

  onclickCreate() {
    this.router.navigate([this.Url_ResearchProject]);
  }

  rowClicked(row: any): void {

    this.capturePageParameter();
    let selectedRow: projectrowforlist = row;
    let dataToSend: any = { 'mode': 'edit', 'projectid': selectedRow.projectId };
    this.router.navigate(['/projectsearchdetail', JSON.stringify(dataToSend)]);
  }

  getProjectList(statuscode: number) {
    let callMethod: string = '/Project/GetProjectList/' + statuscode;
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        this.projectList = values;
        // this.dataSource.data = this.projectList;
        if (sessionStorage.getItem('pagecatch')) {
          console.log("page==in>" + sessionStorage.getItem('pagecatch'));
          this.pageCatch = JSON.parse(sessionStorage.getItem('pagecatch'));
          this.filter = this.pageCatch['filter'];
          this.sortDirection = this.pageCatch['sortDirection'];
          if (!this.sortDirection) {
            this.sortDirection = "asc";
          }
          this.sortField = this.pageCatch['sortField'];
          this.applyFilter(this.filter);
          if (this.sortField) {
            this.dataSource.data = sortingArrayList(this.projectList, this.sortField, this.sortDirection);
          }



        }
        console.log('ProjectList:' + JSON.stringify(this.projectList));


        for (let i = 0; i < this.projectList.length; i++) {

          if (this.projectList[i].nextProjectProgressReportDate.toString() != '0001-01-01T00:00:00') {

            if (this.projectList[i].nextProjectProgressReportDate.toString() > '2000-01-01T00:00:00') {


              let firstDate = moment(this.projectList[i].nextProjectProgressReportDate);
              let secondDate = moment(new Date);
              let diffInDays = Math.abs(firstDate.diff(secondDate, 'days'));

              //  console.log(dateProgress);
              if (diffInDays < 1) {
                //  console.log("AAA");
                this.projectList[i].isShowTimerRed = true;

              } else if (diffInDays < 15) {
                // console.log("BBB");
                this.projectList[i].isShowTimerYello = true;

              } else if (diffInDays < 30) {
                // console.log("CCC");
                // this.projectList[i].push({isShowTimerGreen: true})
                this.projectList[i].isShowTimerGreen = true;

              } else {
                // console.log("DDD");
              }


            }


          }

          // if(this.dataSource.data[i].nextProjectProgressReportDate =='new-project-request'){
          //   this.showNewResearchPropose = true;
          //   localStorage.setItem("showprojectpropose","Y");
          // } else  if(this.systemMenu.linkMenu[i].linkName=='research-request'){
          //   this.showRegister = true;
          // }
          this.projectList[i].rowNo = i + 1;
        }
        this.dataSource.data = this.projectList;


      },
      error => {
        this.errorMessage = <any>error;
        console.log('ProjectList:' + JSON.stringify(this.errorMessage));
      }
    );
  }


  sortData(sort: Sort) {
    // this.capturePageParameter();
    this.sortDirection = sort.direction;
    this.sortField = sort.active;
    this.dataSource.data = sortingArrayList(this.projectList, sort.active, sort.direction);
  }

  transformDate(dateValue): string {
    return transformDateToBE(dateValue);
  }

  gotoProjectDetail() {
    let dataToSend: any = { 'mode': 'new', 'projectid': -1 };
    let link = ['/projectsearchdetail', JSON.stringify(dataToSend)];
    this.router.navigate(link);
  }

  ngOnDestroy(): void {
  }

  getProjectStatusList() {
    let url = "/Master/GetProjectStatusList";
    this.brokerAPIService.get(url).subscribe(
      values => {
        this.projectStatusList = values;
        let projectStatus = new projectstatus;
        projectStatus.id = 0;
        this.selectedStatus = projectStatus.id;
        projectStatus.statusNameAdmin = "ทั้งหมด";
        this.projectStatusList.push(projectStatus);
      },
      error => {
        console.log("error project status list", JSON.stringify(error));
      }
    )
  }

  progressStatusSelectionChanged() {
    this.getProjectList(this.selectedStatus);
  }
}
