import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import "rxjs/observable";
import "rxjs/operator";
// import "rxjs/operator/delay";
import {
  HttpClient,
  HttpErrorResponse,
  HttpClientJsonpModule
} from "@angular/common/http";
import { IAPIResponse } from "../interfaces/apiResponse";
// import { AppConfig } from "../app.config";
import { SDUtilityService } from "./sdutility.service";
import { environment } from "src/environments/environment";
import { AppConfig } from "../app.config";

@Injectable()
export class BrokerAPIService {
  // protected ServerApiUrl = AppConfig.settings.ServerApiUrl;
  // protected ServerApiUrl = environment.baseUrl;
  // ServerApiUrl:string='';
  constructor(private http: HttpClient, 
    private sdUtilityService : SDUtilityService,
    private appConfig:AppConfig
    ) { 
      // this.loadConfig();
    }

 

  getHeaderContentTypeJson() {
    const headerDict = {
      // authorization: "Bearer " + this.sdUtilityService.SDLocalStorage.getItem("token"),
      authorization: "Bearer " +localStorage.getItem('token'),
      "Content-Type": "application/json"
    };
    return headerDict;
  }

  getHeader() {
    const headerDict = {
      authorization: "Bearer " + this.sdUtilityService.SDLocalStorage.getItem("token")
    };
    return headerDict;
  }

  getHeaderDownloadImage() {
    this.sdUtilityService
    const headerDict = {
      authorization:
        "Bearer "+ this.sdUtilityService.SDLocalStorage.getItem("token")
    };
    return headerDict;
  }

  async getAwait(
    strUrl: string,
    resolveCallBack: Function = null,
    rejectCallBack: Function = null
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(strUrl).subscribe(
        data => {
          resolve(data);
          if (resolveCallBack != null) resolveCallBack(data);

        },
        err => {
          reject(err);
          if (rejectCallBack != null) rejectCallBack(err);

        }
      );
    });
  
  }

  async postAwait(
    strUrl: string,
    objbody: any,
    resolveCallBack: Function = null,
    rejectCallBack: Function = null
  ) {
    return new Promise((resolve, reject) => {
      this.post(strUrl, objbody).subscribe(
        data => {
          console.log("Complete Load : success",JSON.stringify(data));

          // setTimeout(() => {
          resolve(data);
          if (resolveCallBack != null) resolveCallBack(data);
          // }, 1000);
        },
        err => {
          console.log("Error post : fail", err);
          // alert(err.message);
          // setTimeout(() => {
          reject(err);
          if (rejectCallBack != null) rejectCallBack(err);
          // }, 1000);
        }
      );
    });
   
  }

  get(methodUrl: string): Observable<any> {
    // let callUrl:string=this.ServerApiUrl + methodUrl;
    let callUrl:string=AppConfig.settings.ServerApiUrl+ methodUrl;
    // console.log("url:",callUrl);
    return this.http.get<any>(callUrl, {
      headers: this.getHeaderContentTypeJson()
    });
  }

  getProfile(GRAPH_ENDPOINT:string): Observable<any>{
    return this.http.get<any>(GRAPH_ENDPOINT);
  }

  loadConfig(): Observable<any> {
    const jsonFile = 'assets/config/config.json';
    return this.http.get<any>(jsonFile, {});
  }

  // loadConfiga() {
  //   const jsonFile = 'assets/config/config.json';
  //   return new Promise<void>((resolve, reject) => {
  //       this.http.get(jsonFile).toPromise().then((response: Response) => {
  //           console.log("config:"+JSON.stringify(response['ServerApiUrl']));
  //           this.ServerApiUrl = response['ServerApiUrl'];
  //           resolve();
  //       }).catch((response: any) => {
  //           reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
  //       });
  //   });
  // }

  post(strUrl: string, objbody: any): Observable<IAPIResponse> {
    let url:string = AppConfig.settings.ServerApiUrl + strUrl; 
    
    // console.log("url:",url);
    // console.log("datatosend:",objbody);
    try{
      let result:any=this.http.post<IAPIResponse>(url, objbody, {
        headers: this.getHeaderContentTypeJson()
      });
      // console.log("result:"+JSON.stringify(result));
      // console.log("result>>>>>>>>>>>>>>>:",JSON.stringify(result));
      return result;
    } catch(error){
      // console.log("error>>>>>>>>>>>>>>>:",JSON.stringify(error));
      return error;
    }
  }

  upload(strUrl: string, objbody: any): Observable<IAPIResponse> {
    let input = new FormData();
    input.append("file", objbody);
    return this.http.post<IAPIResponse>(AppConfig.settings.ServerApiUrl + strUrl, input, {
      headers: this.getHeader()
    });
  }

  downloadimage(strUrl: string): Observable<Blob> {
    return this.http.get(AppConfig.settings.ServerApiUrl + strUrl, {
      responseType: "blob",
      headers: this.getHeaderDownloadImage()
    });
  }
}
