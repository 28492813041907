export class received{
    id: number;
    projectId: number;
    presentWork: boolean;
    presentWorkNumber: number;
    presentWorkNational: boolean;
    presentWorkNationalNumber: number;
    presentWorkNationalOralNumber: number;
    presentWorkNationalPosterNumber: number;
    presentWorkInternational: boolean;
    presentWorkInternationalNumber: number;
    presentWorkInternationalOralNumber: number;
    presentWorkInternationalPosterNumber: number;
    article: boolean;
    articleNumber: number;
    journalNational: boolean;
    journalNationalNumber: number;
    journalNationalPublishYear: number;
    tciTier: number;
    journalInternational: boolean;
    journalInternationalNumber: number;
    journalInternationalPublishYear: number;
    journalInternationalDatabase: number;
    journalInternationalOther: string;
    quartile: number;
    patent: boolean;
    patentNumber: number;
    commercialInnovation: boolean;
    commercialInnovationNumber: number;
    commercialInnovationWorkPiece: string;
    copyright:boolean;
    other: boolean;
    otherDetail: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}