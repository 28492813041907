import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportCvComponent } from './report-cv/report-cv.component';

import { AppRoutingModule } from '../app-routing.module';
import { MaterialModule } from '../module/material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { ReportCvViewerComponent } from './report-cv-viewer/report-cv-viewer.component';
import { ReportProposalComponent } from './report-proposal/report-proposal.component';
import { ReportProposalViewerComponent } from './report-proposal-viewer/report-proposal-viewer.component';
import { ReportResearcherPublishedComponent } from './report-researcher-published/report-researcher-published.component';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportProjectMeetingProgressComponent } from './report-project-meeting-progress/report-project-meeting-progress.component';
import { ReportAmountResearchProjectsComponent } from './report-amount-research-projects/report-amount-research-projects.component';
import { ReportFromDataExportComponent } from './report-from-data-export/report-from-data-export.component';




@NgModule({
  declarations: [ReportCvComponent, ReportCvViewerComponent, ReportProposalComponent, ReportProposalViewerComponent, ReportResearcherPublishedComponent, ReportListComponent, ReportProjectMeetingProgressComponent, ReportAmountResearchProjectsComponent, ReportFromDataExportComponent],
  imports: [
    CommonModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

  ]
})
export class ReportModule { }
