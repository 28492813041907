import { AngularEditorConfig } from "@kolkov/angular-editor";

export function sortStringArray(object1, object2, key) {
    if(object1[key]==null){
      object1[key]='';
    }
    if(object2[key]==null){
      object2[key]='';
    }
    const obj1 = object1[key].toUpperCase();
    const obj2 = object2[key].toUpperCase();
    if (obj1 < obj2) {
      return -1
    }
    if (obj1 > obj2) {
      return 1
    }
    return 0
  }

  export function sortNumberArray(object1:number, object2:number, key) {
    if(object1[key]==null){
        object1[key]=0;
      }
      if(object2[key]==null){
        object2[key]=0;
      }
    const obj1 = object1[key];
    const obj2 = object2[key];
    if (obj1 < obj2) {
        return -1
      }
      if (obj1 > obj2) {
        return 1
      }
      return 0
  }

  export function sortingArrayList(dataToSort:any[],keyName:string,directtionSort:string):any{
    if (!keyName || directtionSort === ''||dataToSort==null) {
        this.dataSource.data = this.projectList;
        return dataToSort;
      }
      let direction:number;
      if(directtionSort.toLowerCase()=='asc'){
        direction = 1;
      } else {
        direction = -1;
      }

       if(typeof dataToSort[0][keyName] ==='number'){
        return dataToSort.sort((a,b) => sortNumberArray(a,b,keyName)*direction);
      }else {
        return dataToSort.sort((a,b) => sortStringArray(a,b,keyName)*direction);
      }
         
      
  }

  export function transformDateToBE(dateValue):string{
    if(dateValue=='0001-01-01T00:00:00')
    {return ''};
    let tempDate:Date = new Date(dateValue);
    return tempDate.toLocaleDateString('th-TH', { day: 'numeric', month: 'long', year: 'numeric' });
  }

  export function base64ToBlob(b64Data, contentType='', sliceSize=512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  export function  offsetTimeZone(inputDate):Date{
    let x:Date = new Date(inputDate);
    let hoursDiff = x.getHours() - x.getTimezoneOffset() / 60;
    let minutesDiff = (x.getHours() - x.getTimezoneOffset()) % 60;
    x.setHours(hoursDiff);
    x.setMinutes(minutesDiff);
    return x;
  }

  export function getEditorConfig():AngularEditorConfig{
    return {
      editable: true,
      spellcheck: true,
      height: '15',
      minHeight: '15',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      // fonts: [
      //   {class: 'arial', name: 'Arial'},
      //   {class: 'times-new-roman', name: 'Times New Roman'},
      //   {class: 'calibri', name: 'Calibri'},
      //   {class: 'comic-sans-ms', name: 'Comic Sans MS'},
      //   {class: 'chulabhornlikittext-regular', name: 'chulabhornlikittext'}
      // ],
      defaultFontName: 'Arial',
      sanitize:false,
      toolbarHiddenButtons: [
        [],[
        'backgroundColor',
        
        'link',
        'unlink',
        'insertVideo',]
        ],
    };
  }

  export function moneyToThai(moneyAmount):string {
    if (!moneyAmount) {
      return "N/A";
    }
    let moneyInThai:string = '';
    let moneyToConvert: string = moneyAmount.toString();
    let moneySplit = moneyToConvert.split('.');
    if (moneySplit[0].length > 13) {
      this.openSnackBar('ไม่สามารถแปลงเงินเกิน 13 หลักได้', this.closeSnackbarMessage);
      moneyAmount = Number.parseFloat(moneyToConvert.substring(0, 12));

    } else {
      let exceedMillion: string = '';
      let underMillion: string = '';
      let isExceedMillion: boolean = false;
      if (moneySplit[0].length > 7) {
        exceedMillion = convertMoneyToThaiDescription(Number.parseInt(moneySplit[0].substring(0, moneySplit[0].length - 7) + 0), false);
        isExceedMillion = true;
      }
      underMillion = convertMoneyToThaiDescription(Number.parseInt(moneySplit[0].substring(moneySplit[0].length - 7)), isExceedMillion);
      if (isExceedMillion) {
        moneyInThai = exceedMillion + "ล้าน" + underMillion + 'บาทถ้วน';
      } else {
        moneyInThai = underMillion + 'บาทถ้วน';
      }

    }
    return moneyInThai;
  }

  function  convertMoneyToThaiDescription(amount: number, exceed: boolean): string {
    let txtNumArr = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า', 'สิบ'];
    let txtDigitArr = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];
    let bahtText: string = '';
    if (amount == null) {
      return;
    }
    let moneyString: string = amount.toString();
    let moneyLength = moneyString.length;

    for (let i = 0; i < moneyLength; i++) {
      let tmp = Number.parseInt(moneyString.substring(i, i + 1));
      if (tmp != 0) {
        // if ((i == (moneyLength - 1)) && (tmp == 1))
        if (((i == (moneyLength - 1)) || ((i == (moneyLength - 7) && exceed))) && (tmp == 1)) {
          bahtText += "เอ็ด";
        } else if ((i == (moneyLength - 2)) && (tmp == 2)) {
          bahtText += "ยี่";
        } else if ((i == (moneyLength - 2)) && (tmp == 1)) {
          bahtText += "";
        } else {

          bahtText += txtNumArr[tmp];
        }
        bahtText += txtDigitArr[moneyLength - i - 1];
      }
    }
    return bahtText;
  }

  export function checkEmailFormat(emailAddress:string){
    const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(! regularExpression.test(String(emailAddress).toLowerCase()))
    {
      return false;
    }
    return true;
  }

  export function transformYearToGovernementFiscalYear(dateToTransform:Date){
    console.log("getmonth",dateToTransform.getFullYear());
    if(dateToTransform.getMonth()+1 < 10){
      if(dateToTransform.getFullYear()<2500){
        return dateToTransform.getFullYear() + 543;
      } else { 
        return dateToTransform.getFullYear();
      }
    } else {
      if(dateToTransform.getFullYear()<2500){
        return dateToTransform.getFullYear() + 544;
      } else { 
        return dateToTransform.getFullYear() +1;
      }
    }

  }