import { budgetdetail } from "./budgetdetail";
import { fundingtype } from "./fundingtype";

export class projectbudget{
    id: number;
    projectId: number;
    fundingTypeId: number;
    fundingType:fundingtype;
    noGrantsFundingSource: string;
    noGrantsFundingBudget: number;
    noGrantsEducationLevel: number;
    noGrantsEducationalInstitution: string;
    noGrantsApproveProjectDate: Date;
    noGrantsOther: string;
    totalProjectBudget: number;
    budgetDetails: [budgetdetail];
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}