<!-- <mat-card class="matCard">
    <div style="padding-top: 5vw;">
    </div>

    <div style="padding-bottom: 8.5vw;"></div>

    <div style="margin:auto;text-align: center;">
        <div class="input-icons">

            <input class="input-field" style="width: 800px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px; padding-left: 45px; font-family: chulabhornlikittext-light;" type="text" placeholder="สืบค้นงานวิจัยจาก Key word ที่ต้องการค้นหา">
        </div>
    </div>
    <div style="padding-top: 8.5vw;"></div>

    <div style="padding-bottom: 5vw;"></div>
    <div style="padding-bottom: 100px;"></div>
</mat-card> -->
<div fxLayout="column" class="bottom-tab-area" fxLayoutGap="20px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 10px; margin-right: 10px;">
    <div fxFlex style="border-radius: 10px; margin-bottom: 0px;">

        <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);" class="font-light">
            <mat-tab label="{{'โครงการวิจัย'}}">
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9;margin-top: 10px;">
                    <div class="table-container" style="padding-right: 5px; margin-top: 20px;">

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="text" maxlength="100" autocomplete="off" placeholder="{{'รหัสพนักงาน'}}" [(ngModel)]="empCode">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" placeholder="{{'สังกัด'}}" style="font-size: 16px;" [(ngModel)]="organizationId">
                                        <mat-option *ngFor="let type of organizationList" [value]=type.id>{{type.organizationName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="text" maxlength="100" autocomplete="off" placeholder="{{'ชื่อ (ไทย)'}}" [(ngModel)]="researcherFirstName">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="text" maxlength="100" autocomplete="off" placeholder="{{'นามสกุล (ไทย)'}}" [(ngModel)]="researcherLastName">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="text" maxlength="100" autocomplete="off" placeholder="{{'ชื่อโครงการวิจัย'}}" [(ngModel)]="projectName">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="text" maxlength="100" autocomplete="off" placeholder="{{'คำสำคัญ'}}" [(ngModel)]="projectKeywoard">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="number" min="0" step="1" maxlength="100" autocomplete="off" placeholder="{{'ปีที่ยื่นข้อเสนอโครงการวิจัย'}}" [(ngModel)]="requestResearchYear">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="number" min="0" step="1" maxlength="100" autocomplete="off" placeholder="{{'ปีที่อนุมัติโครงการ'}}" [(ngModel)]="approveResearchYear">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" placeholder="{{'ประเภทการวิจัย'}}" style="font-size: 16px; text-align: left;" [(ngModel)]="researchDescriptionTypeId">
                                        <mat-option *ngFor="let type of researchTypeList" [value]=type.id>
                                            {{type.descriptionTypeName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" placeholder="{{'ประเภทแหล่งทุน'}}" style=" font-size: 16px;" [(ngModel)]="fundingType">
                                        <mat-option value="1">ไม่ขอทุน</mat-option>
                                        <mat-option value="2">ทุนภายใน</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 10px;">
                            <div fxFlex="47.5%" style="text-align: right;">
                                <button mat-raised-button style="font-size: 13px; font-family: chulabhornlikittext-bold;" class="btn-black" (click)="btnSearchProject()">{{'ค้นหา' }}</button>
                            </div>
                            <div fxFlex="5%"></div>
                            <div fxFlex="47.5%" style="text-align: left;">
                                <button mat-raised-button style="font-size: 13px; font-family: chulabhornlikittext-bold; " class="btn-back" (click)="btnClearProject()">{{'ล้างค่า' }}</button>
                            </div>

                        </div>
                    </div>

                    <mat-table class="gridrow" [dataSource]="dataSourceProject" matSort matSortActive="organizationName" matSortDirection="asc" matSortDisableClear (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="4" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="4" style="color: #002F87; font-size: 14px;" *matCellDef="let element; let i = index">{{ element.rowNo }}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="projectName">
                            <mat-header-cell style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ชื่อโครงการวิจัย' }}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.projectName }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="projectOwnerName">
                            <mat-header-cell style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'หัวหน้าโครงการวิจัย' }}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.projectOwnerName }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="organizationName">
                            <mat-header-cell fxFlex="15" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef>{{'สังกัด' }}</mat-header-cell>
                            <mat-cell fxFlex="15" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.organizationName }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="coResearcherName">
                            <mat-header-cell fxFlex="12" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ผู้ร่วมวิจัย' }}</mat-header-cell>
                            <mat-cell fxFlex="12" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.coResearcherName }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="researchDuration">
                            <mat-header-cell fxFlex="8" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ระยะเวลาดำเนินการ(เดือน)' }}</mat-header-cell>
                            <mat-cell fxFlex="8" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.researchDuration }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="fundintType">
                            <mat-header-cell fxFlex="8" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ประเภทการขอรับทุน' }}</mat-header-cell>
                            <mat-cell fxFlex="8" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.fundintType }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="approveResearchYear">
                            <mat-header-cell fxFlex="5" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ปีที่อนุมัติโครงการ'}}</mat-header-cell>
                            <mat-cell fxFlex="5" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.approveResearchYear}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="projectStatus">
                            <mat-header-cell fxFlex="10" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'สถานะโครงการ'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.projectStatus }}</div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsProject"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsProject;"></mat-row>
                    </mat-table>
                    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list">
                    </mat-paginator>
                </mat-card>
            </mat-tab>
            <mat-tab label="{{'ผลงานวิจัยตีพิมพ์'}}">
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9;margin-top: 10px;">
                    <div class="table-container" style="padding-right: 5px; margin-top: 20px;">


                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="number" min="0" step="1" max="3000" autocomplete="off" placeholder="{{'ปีที่พิมพ์'}}" [(ngModel)]="publishWorkYear">
                                </mat-form-field>

                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="text" maxlength="100" autocomplete="off" placeholder="{{'ชื่อผลงานตีพิมพ์'}}" [(ngModel)]="publishWorkName">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>


                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input style="font-size: 16px;" matInput type="text" maxlength="100" autocomplete="off" placeholder="{{'ชื่อวารสาร'}}" [(ngModel)]="journalName">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" placeholder="{{'การมีส่วนร่วม'}}" style=" font-size: 16px;" [(ngModel)]="paticipation">
                                        <mat-option value=1>First Authur</mat-option>
                                        <mat-option value=2>Corresponding Authur</mat-option>
                                        <mat-option value=3>Co-Investigator</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="number" min="0" step=".001" max="5" autocomplete="off" placeholder="{{'ผลกระทบ (ตั้งแต่)'}}" [(ngModel)]="impactFactorFrom">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <input matInput style="font-size: 16px;" type="number" min="0" step=".001" max="5" autocomplete="off" placeholder="{{'ผลกระทบ (ถึง)'}}" [(ngModel)]="impactFactorTo">
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>


                        <div fxFlexFill fxLayout="row" style="padding-bottom: 15px;">
                            <div fxFlex="10%"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" placeholder="{{'ประเภทวารสาร'}}" style="font-size: 16px;" [(ngModel)]="journalLevel" value=0 (selectionChange)="quartileTCILevel=0">

                                        <mat-option value="1">ระดับชาติ</mat-option>
                                        <mat-option value="2">ระดับนานาชาติ</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                            <div fxFlex="35%" style="text-align: center;">
                                <mat-form-field *ngIf="journalLevel!=0" class="example-full-width" style="width: 95%;">
                                    <div>
                                        <mat-select disabled *ngIf="journalLevel==0" placeholder="{{'N/A'}}">
                                            <mat-option>

                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div *ngIf="journalLevel==2">
                                        <mat-select [(ngModel)]="quartileTCILevel" placeholder="{{'Quartile'}}">
                                            <mat-option *ngFor="let type of quartileList" [value]=type.id>
                                                {{ type.description }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div *ngIf="journalLevel==1">
                                        <mat-select [(ngModel)]="quartileTCILevel" placeholder="{{'TCI'}}">
                                            <mat-option *ngFor="let type of tciTierList" [value]=type.id>
                                                {{ type.description }}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="10%"></div>
                        </div>

                        <div fxFlexFill fxLayout="row" style="padding-bottom: 10px;">
                            <div fxFlex="47.5%" style="text-align: right;">
                                <button mat-raised-button style="font-size: 13px; font-family: chulabhornlikittext-bold;" class="btn-black" (click)="btnSearchPublished()">{{'ค้นหา' }}</button>
                            </div>
                            <div fxFlex="5%"></div>
                            <div fxFlex="47.5%" style="text-align: left;">
                                <button mat-raised-button style="font-size: 13px; font-family: chulabhornlikittext-bold; " class="btn-back" (click)="btnClearPublished()">{{'ล้างค่า' }}</button>
                            </div>

                        </div>
                    </div>

                    <mat-table class="gridrow" [dataSource]="dataSourceResearchResultsPublished" matSort matSortActive="organizationName" matSortDirection="asc" matSortDisableClear (matSortChange)="sortData($event)">
                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="4" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="publishWorkYear">
                            <mat-header-cell fxFlex="5" style="color: #002F87;  font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ปีที่พิมพ์' }}</mat-header-cell>
                            <mat-cell fxFlex="5" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px; " class="font-light">{{ objjob.publishWorkYear}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="publishWorkName">
                            <mat-header-cell style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef>{{'ชื่อผลงานตีพิมพ์' }}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.publishWorkName }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="journalName">
                            <mat-header-cell style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ชื่อวารสาร/ฉบับที่ตีพิมพ์' }}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.journalName }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="journalLevel">
                            <mat-header-cell fxFlex="8" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ประเภทวารสาร' }}</mat-header-cell>
                            <mat-cell fxFlex="8" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.journalLevel }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="impactFactor">
                            <mat-header-cell fxFlex="8" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'impact Factor' }}</mat-header-cell>
                            <mat-cell fxFlex="8" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.impactFactor }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="quartileTCIIndex">
                            <mat-header-cell fxFlex="10" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'Quartile/Indexed in TCI'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.quartileTCIIndex }}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="paticipation">
                            <mat-header-cell fxFlex="10" style="color: #002F87; font-size: 14px;" class="font-light" *matHeaderCellDef mat-sort-header>{{'การมีส่วนร่วม'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob">
                                <div style="color: #002F87; font-size: 14px;" class="font-light">{{ objjob.paticipation }}</div>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsResearchResultsPublished"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsResearchResultsPublished;"></mat-row>
                    </mat-table>
                    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list">
                    </mat-paginator>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
<div style="padding-bottom: 5px;"></div>
<footer class="footer">
    <label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</label>
</footer>
