import { attachdocument } from "./attachdocument";

export class presentwork{
    id: number;
    researcherId: number;
    orderNo: number;
    year: number;
    presentWorkName: string;
    eventName: string;
    presentLocation: string;
    presentLevel:number;
    attachDocumentId:number;
    attachDocument:attachdocument;
    presentDate:Date;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}