import { attachdocument } from "./attachdocument";
import { researcher } from "./researcher";

export class publication{    
    id:number;
    researcherId:number;
    orderNo:number;
    researcher:researcher;
    publishYear:number;
    publishWorkName:string;
    journalName:string;
    journalLevel:number;
    impactFactor:number;
    isQuartile:boolean;
    quartileTCI_Index:number;
    paticipation:number;
    attachDocumentId:number;
    attachDocument:attachdocument;
    createBy:string;
    createDate:Date;
    updateBy:string;
    updateDate:Date;
    inActivated:boolean;

}