<mat-card
    style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px; height: 50%; min-height: 600px;">
    <mat-card-content>
        <div fxLayout="column">
            <button mat-raised-button
                style="white-space: normal;width: 250; font-size: 10px; font-family: chulabhornlikittext-bold;"
                class="btn-chart-menu"
                (click)="onclickReportResearchPublised(fromYear,toYear)">ผลงานวิจัยตีพิมพ์</button>
            <button mat-raised-button
                style="white-space: normal;width: 250px; font-size: 10px; font-family: chulabhornlikittext-bold;"
                class="btn-chart-menu"
                (click)="onclickProjectMeetingProgress()">รายงานแสดงโครงการที่ใกล้ถึงกำหนดประชุมแจ้งความคืบหน้า</button>
            <button mat-raised-button
                style="white-space: normal;width: 250px; font-size: 10px; font-family: chulabhornlikittext-bold;"
                class="btn-chart-menu"
                (click)="onclickAmuntResearchProject(year)">รายงานโครงการวิจัย/นวัตกรรม/R2Rทั้งหมดภายในราชวิทยาลัยจุฬาภรณ์</button>
            <button mat-raised-button
                style="white-space: normal;width: 250px; font-size: 10px; font-family: chulabhornlikittext-bold;"
                class="btn-chart-menu"
                (click)="onclickFromDataReport(yearFromDataExport)">รายงานจากการนำออกข้อมูลในรูปแบบExcelสรุปข้อมูลโครงการและสถานะ</button>
            <button mat-raised-button
                style="white-space: normal;width: 250px; font-size: 10px; font-family: chulabhornlikittext-bold;"
                class="btn-chart-menu"
                (click)="onClickForBudgetReport(budgetyear)">สถานะการดำเนินงานและการเบิกจ่ายงบประมาณโครงการวิจัย
                (งบอุดหนุน)</button>

        </div>
        <table fxLayout="column" fxFlex="85%">
            <div style="width: 100%;  padding-left: 5px;">
                <table width=100% style="margin-top: 5px;">
                    <tr>
                        <!-- <td fxFlex="5">

                        </td> -->
                        <td *ngIf="isShowReportResearcherPublished" width=35% style="text-align: left;">
                            <mat-form-field class="example-full-width" style="width: 95%;">
                                <mat-select class="font-light" [(ngModel)]="fromYear" placeholder="จากปีงบประมาณ">
                                    <mat-option *ngFor="let year of fiscalYearListFrom" [value]=year>
                                        {{ year }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                        <td *ngIf="isShowReportResearcherPublished" width=35% style="text-align: left;">
                            <mat-form-field class="example-full-width" style="width: 95%;">
                                <mat-select class="font-light" (selectionChange)="onChangedate()" [(ngModel)]="toYear"
                                    placeholder="ถึงปีงบประมาณ">
                                    <mat-option *ngFor="let year of fiscalYearListTo" [value]=year>
                                        {{ year }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td *ngIf="isShowSelectOneYear" width=35% style="text-align: left;">
                            <mat-form-field class="example-full-width" style="width: 95%;">
                                <mat-select class="font-light" (selectionChange)="onChangeAmuntResearchProject()"
                                    [(ngModel)]="year" placeholder="ปีงบประมาณ">
                                    <mat-option *ngFor="let year of fiscalYearListFrom" [value]=year>
                                        {{ year }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                        <td *ngIf="isShowBudgetYear" width=35% style="text-align: left; padding-left: 10px;">
                            <mat-form-field class="example-full-width" style="width: 95%;">
                                <mat-select class="font-light" (selectionChange)="onBudgetYearChange()"
                                    [(ngModel)]="budgetyear" placeholder="ปีงบประมาณ">
                                    <mat-option *ngFor="let year of fiscalYearListFrom" [value]=year>
                                        {{ year }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                        <td *ngIf="isShowReportFromDataExport" width=35% style="text-align: left;">
                            <mat-form-field class="example-full-width" style="width: 95%;">
                                <mat-select class="font-light" (selectionChange)="onChangeFromDataReport()"
                                    [(ngModel)]="yearFromDataExport" placeholder="ปีงบประมาณ">
                                    <mat-option *ngFor="let year of fiscalYearListFrom" [value]=year>
                                        {{ year }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                        <td *ngIf="isShowReportProjectMeetingProgress" width=35% style="text-align: left;">
                            <div style="margin-top: 60px;"></div>
                        </td>

                        <td *ngIf="isNoCriteria" width=70% style="text-align: center;"></td>
                        <td width=30% style="text-align: right;">

                            <button *ngIf="isShowPrint" style="margin-left: 10px;" mat-raised-button class="btn-black"
                                (click)="captureScreen()">{{'พิมพ์'}}</button>
                            <button *ngIf="isShowExport" (click)="downloadButtonPush()" style="margin-left: 10px;"
                                mat-raised-button class="btn-black">{{'Export CSV'}}</button>
                        </td>
                    </tr>
                </table><br>

                <!-- <div *ngIf="isShowReportResearcherPublished" id="contentToConvert" style="margin-bottom: 100px;">
                    <div class="page">
                        <div class="subpage">
                            <app-report-researcher-published [objResearchPublished]="Reportdatas"></app-report-researcher-published>
                        </div>
                    </div>
                </div> -->

                <!-- <div *ngIf="isShowReportProjectMeetingProgress" id="contentToConvert" style="margin-bottom: 100px;">
                    <div class="page">
                        <div class="subpage">
                            <app-report-project-meeting-progress></app-report-project-meeting-progress>
                        </div>
                    </div>
                </div> -->

                <!-- <div *ngIf="isShowReportAmountResearchProject" id="contentToConvert" style="margin-bottom: 100px;">
                    <div class="page">
                        <div class="subpage">
                            <app-report-amount-research-projects [objAmountResearchProject]="Reportdatas"></app-report-amount-research-projects>
                        </div>
                    </div>
                </div> -->
                <!-- <div *ngIf="isShowReportFromDataExport" id="contentToConvert" style="margin-bottom: 100px;">
                    <div class="subpage-portrait">
                        <div>
                            <app-report-from-data-export [objFromDataExport]="Reportdatas"></app-report-from-data-export>
                        </div>
                    </div>
                </div> -->

            </div>
            <table *ngIf="isShowBudgetYear" class="page-portrait" id="contentToConvert" style="height: 600px; padding: 0px;  overflow:scroll; padding: 5px;">

                <table width=100% >
                    <tr>
                        <td colspan="15" style="width: 100%; text-align: center;">
                            <h3>สถานะการดำเนินงานและการเบิกจ่ายงบประมาณโครงการวิจัย (งบอุดหนุน)</h3>
                        </td>
                    </tr>
                    <tr>

                        <td colspan="15" style="width: 100%; text-align: center;">
                            <label style="font-weight: bold;">ปี </label>{{budgetyear}}&nbsp;&nbsp;
                        </td>

                    </tr>
                    <tr>

                        <td colspan="15" style="width: 100%; text-align: center;">
                        </td>

                    </tr>
                    <tr width=100% style="background-color: green;">
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px; color: white;" rowspan="3">หน่วยงาน</td>
                        <td style="width: 20px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="3">จำนวนโครงการ</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" colspan="4">งบประมาณ</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" colspan="3">สถานะการเบิกจ่าย</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" colspan="6">สถานะการส่งรายงาน</td>
                    </tr>
                    <tr width=100% style="background-color: green;">
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">จัดสรร</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">เบิกจ่าย</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">คงเหลือ</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">ร้อยละการเบิกจ่าย</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">ตามแผนงาน</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">ช้ากว่าแผนงาน</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" rowspan="2">เหลื่อมปี</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" colspan="2">รายงานความก้าวหน้า</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" colspan="2">ร่างรายงานวิจัย</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;" colspan="2">รายงานฉบับสมบูรณ์</td>
                    </tr>

                    <tr width=100% style="background-color: green;">
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;">ส่ง</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;">ค้างส่ง</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;">ส่ง</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;">ค้างส่ง</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;">ส่ง</td>
                        <td style="width: 80px; border: 1px solid black; text-align: center; font-size: 10px;color: white;">ค้างส่ง</td>
                    </tr>
                    <tr width=100% *ngFor="let dataRow of budgetUsageByOrgs let i =index">

                        <td style="width: 80px;border: 1px solid black; text-align: center; font-size: 10px;;">{{dataRow.organizationName}}</td>
                        <td style="width: 20px;border: 1px solid black; text-align: center; font-size: 10px;;">{{dataRow.totalProject}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.budgetReceived)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.budgetUsage)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.budgetRemain)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.percentUsage)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.withdrawStatusOnplan)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.withdrawStatusDeplay)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.withdrawStatusOverlap)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.deliverTrackingReport)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.remainTrackingReport)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.deliverDraftReport)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.remainDraftReport)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.deliverCompleteReport)}}</td>
                        <td style="width: 80px; border: 1px solid black; text-align: right; font-size: 10px;;">{{transformAmount(dataRow.remainCompleteReport)}}</td>
                    </tr>
                </table>
            </table>

             <!-- รายงานสถานะการดำเนินงานและการเบิกจ่ายงบประมาณโครงการวิจัย อีกแบบ 20221012 -->
            <!-- <table *ngIf="isShowBudgetYear" class="page-portrait" id="contentToConvert"
                style="height: 600px; padding: 0px;  overflow:scroll; padding: 5px;">

                <table style="width: 100%; font-size: 14px;">
                    <tr>
                        <td colspan="15" style="width: 100%; text-align: center;">
                            <h3>สถานะการดำเนินงานและการเบิกจ่ายงบประมาณโครงการวิจัย (งบอุดหนุน)</h3>
                        </td>
                    </tr>
                    <tr>

                        <td colspan="15" style="width: 100%; text-align: center;">
                            <label style="font-weight: bold;">ปี </label>{{budgetyear}}&nbsp;&nbsp;
                        </td>

                    </tr>

                    <tr>
                        <td>
                            <table style="width: 100%;padding: 0px;margin: 0px; font-size: 14px;">
                                <tr>
                                    <td style="width: 100%; ">
                                        <table
                                            style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                            <tr style="width: 100%;">
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="3">หน่วยงาน</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="3">จำนวนโครงการ</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    colspan="4">งบประมาณ</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    colspan="3">สถานะการเบิกจ่าย</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    colspan="6">สถานะการส่งรายงาน</td>
                                            </tr>

                                            <tr style="width: 100%;">
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">จัดสรร</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">เบิกจ่าย</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">คงเหลือ</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">ร้อยละการเบิกจ่าย</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">ตามแผนงาน</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">ช้ากว่าแผนงาน</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    rowspan="2">เหลื่อมปี</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    colspan="2">รายงานความก้าวหน้า</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    colspan="2">ร่างรายงานวิจัย</td>
                                                <td style="border: 1px solid black; text-align: center; font-size: 10px;"
                                                    colspan="2">รายงานฉบับสมบูรณ์</td>
                                            </tr>

                                            <tr style="width: 100%;">
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;">
                                                    ส่ง</td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;">
                                                    ค้างส่ง</td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;">
                                                    ส่ง</td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;">
                                                    ค้างส่ง</td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;">
                                                    ส่ง</td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;">
                                                    ค้างส่ง</td>
                                            </tr>

                                            <tr style="width: 100%;"
                                                *ngFor="let dataRow of budgetUsageByOrgs let i =index">

                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;;">
                                                    {{dataRow.organizationName}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 10px;;">
                                                    {{dataRow.totalProject}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.budgetReceived)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.budgetUsage)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.budgetRemain)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.percentUsage)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.withdrawStatusOnplan)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.withdrawStatusDeplay)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.withdrawStatusOverlap)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.deliverTrackingReport)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.remainTrackingReport)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.deliverDraftReport)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.remainDraftReport)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.deliverCompleteReport)}}</td>
                                                <td
                                                    style="border: 1px solid black; text-align: right; font-size: 10px;;">
                                                    {{transformAmount(dataRow.remainCompleteReport)}}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>

            </table> -->

            <table *ngIf="isShowReportResearcherPublished" class="page-portrait" id="contentToConvert"
                style="height: 600px; padding: 0px;  overflow:scroll; padding: 5px;">
                <!-- <div class="subpage-portrait"> -->
                <table style="width: 100%; font-size: 14px;" class="font-light">
                    <tr>
                        <td colspan="2" style="width: 100%; text-align: center;">
                            <h3>ผลงานวิจัยตีพิมพ์</h3>
                        </td>
                    </tr>
                    <tr>

                        <td class="" colspan="2" style="width: 100%; text-align: center;">
                            <label style="font-weight: bold;">จากปี </label>{{ fromYear }}&nbsp;&nbsp;
                            <label style="font-weight: bold;">ถึงปี </label>{{ toYear }}
                        </td>

                    </tr>
                    <tr>
                        <td colspan="2">
                            <table style="width: 100%;padding: 0px;margin: 0px;">
                                <tr>
                                    <td colspan="4 " style="width: 100%; ">
                                        <table
                                            style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                            <tr>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    ลำดับ
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    ชื่อผลงานตีพิมพ์เผยแพร่
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    Pl/Co Pl
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    สังกัด
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    ชื่อวารสาร/ฉบับที่ตีพิมพ์
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    impact factor
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; font-size: 12px;">

                                                    ประเภท
                                                </td>


                                            </tr>
                                            <tr *ngFor="let objPublishedWork of dataPublishedWork let i =index">
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ i + 1 }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objPublishedWork.publishWorkName }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objPublishedWork.paticipation }}
                                                </td>

                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objPublishedWork.organizationName }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objPublishedWork.journalName }}
                                                </td>

                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objPublishedWork.impactFactor }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objPublishedWork.journalLevel }}
                                                </td>

                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
                <!-- </div> -->
            </table>

            <table *ngIf="isShowSelectOneYear" class="page-portrait" id="contentToConvert"
                style="height: 600px; padding: 0px;  overflow:scroll; padding: 5px;">
                <!-- <div class="subpage-portrait"> -->
                <table style="width: 100%; font-size: 14px;" class="font-light">
                    <tr>
                        <td colspan="2" style="width: 100%; text-align: center;">
                            <h3>รายงานโครงการวิจัย/นวัตกรรม/R2Rทั้งหมดภายในราชวิทยาลัยจุฬาภรณ์</h3>
                        </td>
                    </tr>
                    <tr>

                        <td class="" colspan="2" style="width: 100%; text-align: center;">
                            <label style="font-weight: bold;">ปี </label>{{ year }}&nbsp;&nbsp;
                        </td>

                    </tr>
                    <tr *ngIf="hasData">
                        <td colspan="2">
                            <table style="width: 100%;padding: 0px;margin: 0px;">
                                <tr>
                                    <td colspan="4 " style="width: 100%; ">
                                      <!-- page-break-before: always !important; -->
                                        <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                            <!-- style="page-break-after: always;" -->
                                            <tr>
                                                <td rowspan="2"
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    ประเภทโครงการ
                                                </td>
                                                <td rowspan="2"
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    สังกัด
                                                </td>
                                                <td rowspan="2"
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    หน่วยงาน
                                                </td>
                                                <td colspan="3"
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    จำนวนโครงการวิจัย
                                                </td>
                                                <td colspan="3"
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    งบประมาณ
                                                </td>

                                            </tr>

                                            <!-- style="page-break-after: always;" -->
                                            <tr>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    โครงการใหม่
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                                    โครงการต่อเนื่อง
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                                    รวม
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                                    โครงการใหม่
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                                    โครงการต่อเนื่อง
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; width: 50px;">

                                                    รวม
                                                </td>
                                            </tr>


                                            <tr
                                                *ngFor="let objAmountResearchProject of AmountResearchProject let i =index">
                                                <td *ngIf="objAmountResearchProject.fundingType==-99" colspan="3"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                                                    {{ objAmountResearchProject.fundingDesc }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType==-99"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                                    {{ objAmountResearchProject.newProjectCount }}
                                                </td>

                                                <td *ngIf="objAmountResearchProject.fundingType==-99"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                                    {{ objAmountResearchProject.contProjectCount }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType==-99"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                                    {{ objAmountResearchProject.totalProjectCount }}
                                                </td>

                                                <td *ngIf="objAmountResearchProject.fundingType==-99"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                                    {{ objAmountResearchProject.newProjectBudget }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType==-99"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                                    {{ objAmountResearchProject.contProjectBudget }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType==-99"
                                                    style="border: 1px solid lightgray; text-align: center; font-size: 12px;background-color: gray; color: white">
                                                    {{ objAmountResearchProject.totalProjectBudget }}
                                                </td>



                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.fundingDesc }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.affiliation }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.organizationName }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.newProjectCount }}
                                                </td>

                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.contProjectCount }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.totalProjectCount }}
                                                </td>

                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.newProjectBudget }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.contProjectBudget }}
                                                </td>
                                                <td *ngIf="objAmountResearchProject.fundingType!=-99"
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objAmountResearchProject.totalProjectBudget }}
                                                </td>

                                                <!-- <div *ngIf="objAmountResearchProject.fundingType!='99'">
                                                        <td colspan="3" style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray;">
                                                            {{ objAmountResearchProject.fundingDesc }}
                                                        </td>

                                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                            {{ objAmountResearchProject.newProjectCount }}
                                                        </td>

                                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                            {{ objAmountResearchProject.contProjectCount }}
                                                        </td>
                                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                            {{ objAmountResearchProject.totalProjectCount }}
                                                        </td>

                                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                            {{ objAmountResearchProject.newProjectBudget }}
                                                        </td>
                                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                            {{ objAmountResearchProject.contProjectBudget }}
                                                        </td>
                                                        <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                            {{ objAmountResearchProject.totalProjectBudget }}
                                                        </td>
                                                    </div> -->
                                            </tr>

                                            <!-- <tr>
                                                    <td colspan="3" style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        โครงการวิจัยที่ไม่ใช้งบประมาณ
                                                    </td>
                                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        ผลรวม A1
                                                    </td>
                                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        ผลรวม A2
                                                    </td>
                                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        ผลรวม A3
                                                    </td>
                                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        ผลรวม B1
                                                    </td>
                                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        ผลรวม B2
                                                    </td>
                                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                        ผลรวม B3
                                                    </td>

                                                </tr> -->
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
                <!-- </div> -->
            </table>

            <table *ngIf="isShowReportProjectMeetingProgress" class="page-portrait" id="contentToConvert"
                style="height: 600px; padding: 0px;  overflow:scroll; padding: 5px;">
                <!-- <div class="subpage-portrait"> -->
                <table style="width: 100%; font-size: 14px;" class="font-light">
                    <tr>
                        <td colspan="2" style="width: 100%; text-align: center;margin-top: 30px;">
                            <h3>รายงานแสดงโครงการที่ใกล้ถึงกำหนดประชุมแจ้งความคืบหน้า</h3>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <table style="width: 100%;padding: 0px;margin: 0px;">
                                <tr>
                                    <td colspan="4 " style="width: 100%; ">
                                        <table
                                            style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                            <tr>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    ลำดับ
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    หน่วยงาน
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    ปีงบประมาณ
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    รหัสโครงการ
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    ชื่อโครงการ
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    ชื่อหัวหน้าโครงการ
                                                </td>
                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    วันที่เริ่มโครงการ
                                                </td>

                                                <td
                                                    style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                                    วันที่ติดตามความก้าวหน้าครั้งถัดไป
                                                </td>


                                            </tr>
                                            <tr *ngFor="let objDataProjectMeeting of dataProjectTracking let i = index">
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ i + 1 }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.organizationName }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.fiscalYear }}
                                                </td>

                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.projectCode }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.projectNameTH }}
                                                </td>

                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.researcherName }}

                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.projectStartDate | date : 'dd/MM/yyyy' }}
                                                </td>
                                                <td
                                                    style="border: 1px solid black; text-align: center; font-size: 12px;">
                                                    {{ objDataProjectMeeting.trackingDate | date : 'dd/MM/yyyy' }}
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>

                </table>
                <!-- </div> -->
            </table>

            <div *ngIf="isShowReportFromDataExport" class="page-portrait" id="contentToConvert"
                style="padding: 0px;   padding: 5px;">

                <!-- <div class="subpage-portrait"> -->

                <!-- <div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;"> -->
                <div fxFlex class="table-component-holder mat-elevation-z4 welfare-container"
                    style="height: 400px; border-radius: 10px; margin-bottom: 10px; overflow:scroll;">

                    <!-- <div class="welfare-container" style="margin: 10px;"> -->

                    <mat-table class="gridrow" [dataSource]="dataSourceProjectDataExport" matSort matSortDisableClear>
                        <ng-container *ngFor="let disCol of displayedColumnsProjectDataExport; let colIndex = index"
                            matColumnDef="{{disCol}}">
                            <mat-header-cell [fxFlex]="colIndex==0?'50px':'200px'" class="font-light" *matHeaderCellDef>
                                <div *ngIf="colIndex==0"
                                    style=" font-size: 10px; font-family: chulabhornlikittext-bold; background-color: transparent;">
                                    {{columnHeaderTitle[colIndex]}}</div>
                                <button *ngIf="colIndex!=0" mat-flat-button
                                    style=" font-size: 10px; font-family: chulabhornlikittext-bold; background-color: transparent;"
                                    (click)="onHeaderClick(colIndex)">
                                    <mat-checkbox (click)="false" [(ngModel)]="selectedColumn[colIndex]"></mat-checkbox>
                                    {{columnHeaderTitle[colIndex]}}
                                </button>
                            </mat-header-cell>
                            <mat-cell [fxFlex]="colIndex==0?'50px':'200px'" *matCellDef="let element; let i = index "
                                style="text-align: center;">
                                <div
                                    style="width: 100%; text-align: center; font-size: 10px; font-family: chulabhornlikittext-bold; background-color: transparent; ">
                                    {{colIndex==0?i+1:element[disCol]}}</div>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsProjectDataExport"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsProjectDataExport;"
                            (click)="rowClicked(row)"></mat-row>
                    </mat-table>

                    <!-- </div> -->
                </div>
                <!-- </div> -->
                <!-- </div> -->

            </div>
        </table>

    </mat-card-content>
</mat-card>
