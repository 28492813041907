import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectNumberByOrgComponent } from './project-number-by-org/project-number-by-org.component';
import { ChartPortalComponent } from './chart-portal.component';
import { MaterialModule } from '../module/material/material.module';


@NgModule({
  declarations: [ProjectNumberByOrgComponent, ChartPortalComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ]
})
export class ChartPortalModule { }
