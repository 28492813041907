export class expectedbenefit{
    id: number;
    projectId: number;
    orderNo: number;
    expectation: string;
    targetGroup: string;
    impact: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}