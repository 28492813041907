export class seminarhistory{
    id: number;
    researcherId: number;
    orderNo: number;
    year: number;
    seminarSubject: string;
    organizerName: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}