import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { projectproposal } from 'src/app/datamodel/projectproposal';
import { teammember } from 'src/app/datamodel/teammember';
import { IAPIResponse } from 'src/app/interfaces/apiResponse';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { oecd } from 'src/app/datamodel/oecd';
import { oecdsub } from 'src/app/datamodel/oecdsub';
import { Options } from '@angular-slider/ngx-slider';
import { projectobjective } from 'src/app/datamodel/projectobjective';
import { keyword } from 'src/app/datamodel/keyword';
import { researchmethod } from 'src/app/datamodel/researchmethod';
import { timelinedetail } from 'src/app/datamodel/timelinedetail';
import { projecttimeline } from 'src/app/datamodel/projecttimeline';
import { expectedresult } from 'src/app/datamodel/expectedresult';
import { referencedocumentforproject } from 'src/app/datamodel/referencedocumentforproject';
import { expectedbenefit } from 'src/app/datamodel/expectedbenefit';
import { researcher } from 'src/app/datamodel/researcher';
import { researchertype } from 'src/app/datamodel/researchertype';
import { attachdocument } from 'src/app/datamodel/attachdocument';
import { projectattachdocument } from 'src/app/datamodel/projectattachdocument';
import { expectedimpact } from 'src/app/datamodel/expectedimpact';
import { durablearticle } from 'src/app/datamodel/durablearticle';
import { received } from 'src/app/datamodel/received';
import { DomSanitizer } from '@angular/platform-browser';
import { budgetdetail } from 'src/app/datamodel/budgetdetail';
import { projectbudget } from 'src/app/datamodel/projectbudget';
import { fundingtype } from 'src/app/datamodel/fundingtype';
import { CurrencyPipe, formatNumber, Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { organization } from 'src/app/datamodel/organization';
import { projectProposalControlData } from 'src/app/datamodel/projectProposalControlData';
import { revieweddocumentdecision } from 'src/app/datamodel/revieweddocumentdecision';
import { expert } from 'src/app/datamodel/expert';
import { messagecontent } from 'src/app/datamodel/messagecontent';
import { enumdata } from 'src/app/datamodel/enumdata';
import { budgetallocation } from 'src/app/datamodel/budgetallocation';
import { budgetreceived } from 'src/app/datamodel/budgetreceived';
import { budgetterm } from 'src/app/datamodel/budgetterm';
import { DateAdapter } from '@angular/material/core';
import { projectcancellation } from 'src/app/datamodel/projectcancellation';
import { projectpublicationdetail } from 'src/app/datamodel/projectpublicationdetail';
import { projectpublication } from 'src/app/datamodel/projectpublication';
import {
  offsetTimeZone,
  getEditorConfig,
  sortingArrayList,
} from 'src/app/services/general-function';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { BooleanInput } from '@angular/cdk/coercion';
import { budgetdetailallocation } from 'src/app/datamodel/budgetdetailallocation';
import {
  conclusion,
  projectreviewapprove,
} from 'src/app/datamodel/projectreviewapprove';
import { environment } from 'src/environments/environment';
//<!-- // ยังไม่ขึ้น CR Phase2 --> Get Data User Login
// const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Component({
  selector: 'app-projectsearch-detail',
  templateUrl: './projectsearch-detail.component.html',
  styleUrls: ['./projectsearch-detail.component.scss'],
})
export class ProjectsearchDetailComponent implements OnInit {
  editorConnfig: AngularEditorConfig = getEditorConfig();
  projectProposal: projectproposal = new projectproposal();
  projectProposalOldData: string;
  projectPublishedWork: projectpublication;
  documentReviewInfo: revieweddocumentdecision;
  documentApprovalInfo: revieweddocumentdecision;
  isDocumentReviewInfoReadOnly: boolean;
  experts: [expert];
  saveInProgress: boolean;
  selectedTabIndex: any;
  eventselectedTabIndex: any;
  methodologyDoc: string = environment.webPath + "/assets/files/guideline/methodology.pdf"
  expectedResultDoc: string = environment.webPath + "/assets/files/guideline/expectedresult.pdf"
  //-------- toggle tab title
  isTeamDataValid: boolean = false;
  isBudgetValid: boolean = false;
  isObjectValid: boolean = false;
  isKeywordValid: boolean = false;
  isKeyworAndProblemdValid: boolean = false;
  isLiteratureReviewValid: boolean = false;
  isResearchMethodsValid: boolean = false;

  isExpectedImpactsValid: boolean = false;
  isExpectedBenefitsValid: boolean = false;
  isReferenceDocumentsValid: boolean = false;
  isDurableArticlesValid: boolean = false;
  isExpectedResultsValid: boolean = false;
  isTimeLineValid: boolean = false;
  isOecdValid: boolean = false;
  isResearchSiteHeadIdValid: boolean = false;
  isProductAndResultProjectValid: boolean = false;
  isResearcherTypeValid: boolean = false;
  ecApprove: string;
  dialogCancelTitle: string;

  options: Options = {
    floor: 1,
    ceil: 12,
    showTicks: true,
  };
  projectProposalControl: projectProposalControlData;
  isEditable: boolean = false;
  isSubmitable: boolean = false;
  isAtDocumentReviewStep: boolean = false;
  documentReviewCaption: string;
  isAddSpecialistStep: boolean = false;
  specialistBtnCaption: string;
  isAddSpecialistStepSubmitEnable: boolean = false;
  isShowBudgetBtn: boolean = false;
  budgetBtnCaption: string;
  isShowingProgressBtn: boolean = false;
  progressBtnCaption: string;
  isShowingProgressReviewBtn: boolean = false;
  progressReviewBtnCaption: string;
  isShowingProjectCancel: boolean = false;
  projectCancelBtnCaption: string;
  isShowingProjectStatusUpdate: boolean = false;
  projectStatusUpdateBtnCaption: string;
  isApprovingStep: boolean = false;
  projectReviewBtnCaption: string;
  isShowingMessageButton = false;
  messageBtnCaption: string;
  isShowingPublicationButton: boolean;
  publicationBtnCaption: string;
  isShowingPrintProposalButton: boolean;
  printProposalCaption: string;
  isShowingConslusion: boolean;
  conclusionCaption: string;
  isConclusion: boolean;
  conclusionMessage: string;
  changeProjectOwnerCaption: string;
  isShowChangeProjectOwner: boolean;
  newOwnerID: string;
  newOwnerName: string;

  isShowMenuDivider: boolean;


  historyMode: boolean;

  isShowingMenu: boolean;
  isshowdialogMessageContent = false;

  objRow: any = {};
  appInfo: any;
  arrfileName: string[];
  dataBase64: any;
  objUpdatefile: any = {};
  private errorMessage: string;
  bahtThaiBudget: string;
  tciTier: string;
  researchMethodologyList: enumdata[];
  budgetGroupList: enumdata[];
  budgetSubGroupList: enumdata[];
  expectedImpactList: enumdata[];
  projectAttachFileTypeList: enumdata[];
  projectAttachFileTypeListToShow: enumdata[];
  journalInternationalDatabase: string;
  quartile: string;
  researchDescriptionTypeId: string;
  closeSnackbarMessage: string = 'Close';
  titleUnabelTosave: string = 'ไม่สามารถบันทึกข้อมูลได้';
  // resarcherNameControl = new FormControl();
  researcherSelectionList: any[];
  filteredOptions: Observable<string[]>;
  researchSiteHeadId: string;
  organizationDescList: Map<number, string>;
  reviewDecision: string;
  messageContents: [messagecontent];
  messageContentData: messagecontent;
  approvedDate: Date;
  approvedDateText: string;

  projectStatusAfterApprove: number;

  showBudgetAllocation: boolean = false;
  showPublication: boolean = false;

  decisionAtCommiteeApproval: string;
  decisionOption: string;
  CommiteeApprovalDialogTopic: string;
  isShowDecisionDetail: boolean = false;
  approveDateLabel: string = '';
  isShowApprovedDate: boolean = false;
  messageCount: number = 10;
  fileSuffix: Array<string> = [
    'gif',
    'jpg',
    'jpeg',
    'png',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'pdf',
    'ppt',
    'pptx',
  ];
  journalLeveList = [
    { id: 1, description: 'ระดับชาติ' },
    { id: 2, description: 'ระดับนานชาติ' },
  ];
  participationList = [
    { id: 1, description: 'First Authur' },
    { id: 2, description: 'Corresponding Authur' },
    { id: 3, description: 'Co-Investigator' },
  ];
  quartileList = [
    { id: 0, description: 'ไม่ระบุ' },
    { id: 1, description: 'Q1' },
    { id: 2, description: 'Q2' },
    { id: 3, description: 'Q3' },
    { id: 4, description: 'Q4' },
  ];
  tciTierList = [
    { id: 0, description: 'ไม่ระบุ' },
    { id: 1, description: 'TCI Tier 1' },
    { id: 2, description: 'TCI Tier 2' },
    { id: 3, description: 'TCI Tier 3' },
  ];

  projectBudgetAllocation: budgetallocation;

  isReadonlyDate: boolean = true;
  startProcessDate: Date;

  isLongForm: boolean = true; // สำหรับทุนที่ต้องกรอกทุก field
  isDetailBudgetNeeded: boolean;

  projectFundInThai: string;
  projectFundTotal: string = '0';
  budgetPaymentTerm: number = 0;
  projectPaymentByTermTotal: string = '0';
  totalProjectBudget: number;
  totalProjectMonth: number;
  totalFundAllocation: number;

  totalFundAllocationInString: string;
  // บันทึกตรวจสอบเอกสาร (ฝั่งAdmin) dialog
  isshowdialogDocumentCheckRecordAdmin: boolean;
  DocumentCheckRecord: string;
  //บันทึกผู้เชี่ยวชาญ dialog
  isshowdialogExpertNotes: boolean;
  //บันทึกผลการอนุมัติโครงการ dialog
  isshowdialogRecordTheResults: boolean;
  approvalRevisedMessage: messagecontent;
  RecordTheResults: string;
  // บันทึกตรวจสอบเอกสาร (ฝั่งนักวิจัย) dialog
  isshowdialogDocumentCheckRecordResearcher: boolean;
  DocumentRecord: string;
  //ส่งคำร้องที่ปรับปรุงเสร็จสิ้น dialog
  isshowdialogSubmitCompletedRequest: boolean;

  //กล่องแก้ไข Text Editor
  isShowTextEditorDialog: boolean;
  editBoxTitle: string = 'กล่องแก้ไข';
  htmlContent: string;
  currentDataIndex: number;

  screenTitle: string = '';

  //กล่องเปลี่ยน owner
  isShowOwnerChangeDialog: boolean;

  //บันทึกเหตุผลการยกเลิกโครงการ
  isshowdialogReasonForCancelingProject: boolean;
  CancelingProject: string;

  //คณะผู้ทำวิจัย
  ishowtabAddResearchTeam: boolean;
  employeeType = [
    { desc: 'Internal', isInternal: true },
    { desc: 'External', isInternal: false },
  ];

  //master

  oecds: [oecd];
  oecdsubs: [oecdsub];
  fiscalYearList: [number];
  fiscalYearListTwo: [number];
  mainresearcher: researcher;
  researcherTypeList: [researchertype];
  defaultresearchertype: researchertype;
  fundingtypes: [fundingtype];
  fundingTypeIDSelected: number;
  fundingTypeName: string;
  fundingTotal: number;
  fundingTotalDisplay: string;
  fundingTypeRemark: string;
  // forcastedBudget: string;

  attachedFileName: string;
  reviewCommentFile1: string;
  reviewCommentFile2: string;
  reviewCommentFile3: string;

  attachedFileType: string;
  attachedFileMimeType: string;
  attachDocumentExtention: string;
  fundingRequest: number = 0;
  fundingOther: string;

  totalTime: number;

  isshowdialogProcessStatus: boolean;
  isshowdialogMessage: boolean;

  cancellationDocument: attachdocument;
  cancellationReason: string;

  dataSourceResearchTeam = new MatTableDataSource();
  displayedColumnsResearchTeam = [
    'Control',
    'lineNo',
    'isInternal',
    'researcherType',
    'researcherFullName',
    'nationalId',
    'organizationName',
    'percentAssociate',
    'eMail',
  ];

  //วัตถุประสงค์ของการทำวิจัย

  ishowtabAddResearchObjectives: boolean;
  arrAddResearchObjectives: any = [];

  dataSourceObjective = new MatTableDataSource();
  displayedColumnsObjective = ['Control', 'lineNo', 'objective'];

  //คำสำคัญ
  ishowtabAddKeyword: boolean;
  arrAddKeyword: any = [];

  dataSourceKeyword = new MatTableDataSource();
  displayedColumnsKeyword = ['Control', 'lineNo', 'keywordTH', 'keywordEN'];

  //ระเบียบวิจัย
  ishowtabAddResearchMethodology: boolean;
  arrAddResearchMethodology: any = [];

  dataSourceResearchMethodology = new MatTableDataSource();
  displayedColumnsResearchMethodology = [
    'lineNo',
    'researchMethodType',
    'researchMethodDetail',
  ];

  //ผลที่คาดว่าจะได้รับ
  ishowtabAddExpectedResults: boolean;
  arrAddExpectedResults: any = [];

  dataSourceExpectedResults = new MatTableDataSource();
  displayedColumnsExpectedResults = [
    'Control',
    'lineNo',
    'fromMonth',
    'toMonth',
    'activityName',
    'expectedResult',
  ];

  //ระยะเวลาดำเนินการ
  ishowtabAddProcessingTime: boolean;
  arrAddProcessingTime: any = [];

  dataSourceProcessingTime = new MatTableDataSource();
  displayedColumnsProcessingTime = [
    'Control',
    'lineNo',
    'fiscalYear',
    'activityName',
    'timeperiod',
    'percentOfActivity',
  ];

  //งบประมาณ
  ishowtabAddBudget: boolean;
  arrAddBudget: any = [];

  dataSourceBudget = new MatTableDataSource();
  displayedColumnsBudget = [
    'Control',
    'lineNo',
    'fiscalYear',
    'budgetCategory',
    'budgetGroup',
    'budgetDescription',
    'budget',
  ];

  //เหตุผลการจัดซื้อครุภัณฑ์
  // ishowtabAddReasonsForPurchasingEquipment: boolean;
  // arrAddReasonsForPurchasingEquipment: any = [];

  dataSourceReasonforPurchasingEquipment = new MatTableDataSource();
  displayedColumnsReasonforPurchasingEquipment = [
    'Control',
    'orderNo',
    'durableArticleName',
    'durableArticleStatus',
    'equivalentDurableArticle',
    'presentStatus',
    'usage',
  ];

  //เอกสารอ้างอิง
  ishowtabAddReferences: boolean;
  arrAddReferences: any = [];
  keepsAttachDocument: any = [];
  dataSourceReferenceDocuments = new MatTableDataSource();
  displayedColumnsReferenceDocuments = [
    'Control',
    'lineNo',
    'referenceDocumentDetail',
  ];

  //ประโยชน์ที่คาดว่าจะได้รับ
  ishowtabAddExpectedBenefits: boolean;
  arrAddExpectedBenefits: any = [];

  dataSourceExpectedBenefits = new MatTableDataSource();
  // displayedColumnsExpectedBenefits = ['Control', 'lineNo', 'expectation', 'targetGroup', 'impact'];
  displayedColumnsExpectedBenefits = ['Control', 'lineNo', 'expectation'];

  //ผลกระทบที่คาดว่าจะได้รับ
  ishowtabAddExpectedImpact: boolean;
  arrAddExpectedImpact: any = [];

  dataSourceExpectedImpact = new MatTableDataSource();
  displayedColumnsExpectedImpact = [
    'lineNo',
    'canBeUtilized',
    'expectedImpact',
    'comment',
  ];

  //เอกสารแนบ
  ishowtabAddAttachment: boolean;
  arrAddAttachment: any = [];
  keepResult: number;
  result: IAPIResponse;
  result02: IAPIResponse;
  id: number;
  datarResult02: any = {};

  dataSourceAttachment = new MatTableDataSource();
  displayedColumnsAttachment = [
    'Control',
    'lineNo',
    'documentType',
    'documentName',
  ];

  dataSourceExpert = new MatTableDataSource();
  displayedColumnsExpert = [
    'Control',
    'lineNo',
    'specialistFullName',
    'specialistOrganization',
    'specialistEmail',
    'evaluateDate',
    'receiveDocDate',
  ];

  dataSourceMessage = new MatTableDataSource();
  displayedColumnsMessage = [
    'Control',
    'lineNo',
    'messageDate',
    'subject',
    'isRead',
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    this.dataSourceMessage.paginator = this.paginator;
  }

  showTypeOtherInformation: boolean = false;
  showMultipleSites = false;

  UserLang: any = {};
  editorKewardAndProblem: string;
  lengthKeyWord: number;
  lengthOverrayKeyword: number;
  isShowKeyWordAndProblrm: boolean = false;

  editorLiteratureReview: string;
  lengthLiteratureReview: number;
  lengthOverrayLiteratureReview: number;
  isShowLiteratureReview: boolean = false;


  lengthResearchMethodType1: number;
  lengthResearchMethodType2: number;
  lengthResearchMethodType3: number;
  lengthResearchMethodType4: number;
  lengthResearchMethodType5: number;
  lengthResearchMethodType: number;
  lengthOverrayResearchMethodType: number;
  isShowResearchMethodType: boolean = false;
  RowIDResearchMethodDetail: number;
  ApproveResearchMethodDetail: any = [];
  researchMethodDetail: any = [];
  researchMethodDetailTwo: any = [];
  // งบประมาณและแหล่งทุน
  dataSourceSourceOfFund = new MatTableDataSource();
  displayedColumnSourceOfFund = [
    'Control',
    'lineNo',
    'fiscalYear',
    'budgetReceived',
    'budgetCode',
    'budgetAreaCode',
  ];

  dataSourceBudgetTerm = new MatTableDataSource();
  displayedColumnBudgetTerm = ['Control', 'lineNo', 'termNo', 'budgetReceived'];

  dataSourceBudgetAllocation = new MatTableDataSource();
  displayedColumnsBudgetAllocation = [
    'Control',
    'lineNo',
    'fiscalYear',
    'budgetCategory',
    'budgetGroup',
    'budgetDescription',
    'budget',
    'budgetReceived',
  ];

  //สิ่งตีพิมพ์
  dataSourcePublications = new MatTableDataSource();
  displayedColumnsPublications = [
    'Control',
    'lineNo',
    'publishDate',
    'publishWorkName',
    'journalName',
    'journalLevel',
    'isDb_Scopus',
    'isDb_WebOfScience',
    'isDb_PubMed',
    'isDb_Other',
    'otherDbName',
    'impactFactor',
    'quartileTCI_Index',
    'paticipation',
    'attachDocument',
  ];
  budgetDescriptionByYear: string;
  checkExpectedImpacts1: boolean;
  checkExpectedImpacts2: boolean;
  checkExpectedImpacts3: boolean;
  checkKeyworAndProblemd: string;
  // checkExpectedImpacts2: boolean;
  // checkExpectedImpacts3: boolean;
  isReadonlyJournalNational: boolean = true;
  isEditableJournalNational: boolean = true;

  isReadonlyJournalInternational: boolean = true;
  isDisableJournalInternational: boolean = true;

  isReadonlyCommercialInnovation: boolean = true;
  isReadonlyReceivedOther: boolean = true;
  isShowBudget: boolean = false;
  keepsExceedMillion: string = "";
  // ยังไม่ขึ้น CR Phase2
  // getMail: string;
  // checkattachDocumentsLength: number;
   isSaveAttachment: boolean = false;
  // getUserList: any = [];
  // userSysRole: any = [];
  // isEditAdmin: boolean = false;
  // isEditResearcher: boolean = false;
  // checkReadonlyInAttachment = false;
  // CheckDownloadAttachment = false;
  // CheckRemoveAttachment = false;

  saveApprove: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    private snackBar: MatSnackBar,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    private currencyPipe: CurrencyPipe,
    private locationService: Location,
    public dialog: MatDialog //  public objSysRoleAccessService: SysRoleAccessService,
  ) //  private dialogService: DialogService,
  //  public sdUtilityService: SDUtilityService,
  {
    this.resetForm();
    this.dateAdapter.setLocale('th-TH');

    this.appInfo = JSON.parse(this.route.snapshot.paramMap.get('dataobj'));
    this.getCurrentResearcher();
    this.getOECDS();
    this.getResearcherType();
    this.getMethodologyList();
    this.getBudgetGroupList();
    this.getProjectAttachFileTypeList();
    this.getExpectedImpactList();

    if (this.appInfo['mode'] == 'edit') {
      this.getProjectInfo(this.appInfo['projectid']);
    } else {
      this.projectProposal = new projectproposal();
      this.projectProposal.ecDate = new Date();
      this.projectProposal.received = new received();

      // พี่เก่งบอกว่าตอนSaveไม่ต้องส่งIDไปก็เลยเอาออก 20230104
      // this.projectProposal.id = 0;

      this.projectProposal.teamMembers;
      this.isEditable = true;
      this.isApprovingStep = false;
      this.screenTitle = 'สร้างคำขอเสนอโครงการวิจัยใหม่';
      if (!this.projectProposal.received.presentWorkNationalOralNumber)
        this.projectProposal.received.presentWorkNationalOralNumber = 0;
      if (!this.projectProposal.received.presentWorkNationalPosterNumber)
        this.projectProposal.received.presentWorkNationalPosterNumber = 0;
      if (!this.projectProposal.received.presentWorkInternationalOralNumber)
        this.projectProposal.received.presentWorkInternationalOralNumber = 0;
      if (!this.projectProposal.received.presentWorkInternationalPosterNumber)
        this.projectProposal.received.presentWorkInternationalPosterNumber = 0;
      if (!this.projectProposal.received.presentWorkNationalNumber)
        this.projectProposal.received.presentWorkNationalNumber = 0;
      if (!this.projectProposal.received.presentWorkInternationalNumber)
        this.projectProposal.received.presentWorkInternationalNumber = 0;
      this.projectProposal.received.journalNationalNumber = 0;
      this.projectProposal.received.journalInternationalNumber = 0;
      this.projectProposal.received.articleNumber = 0;
      this.projectProposal.received.tciTier = 0;
      this.projectProposal.received.quartile = 0;
      this.projectProposal.received.journalInternationalDatabase = 0;
      this.projectProposal.received.journalNationalPublishYear = 0;
      this.projectProposal.received.journalInternationalPublishYear = 0;
      // this.setResearchMethodologyObject();

    }
  }

  ngOnInit(): void {

    this.isshowdialogDocumentCheckRecordAdmin = false; //ดูข้อมูลเอกสาร
    this.isshowdialogExpertNotes = false; //บันทึกผู้เชี่ยวชาญ
    this.isshowdialogRecordTheResults = false; //committee approval
    this.isshowdialogDocumentCheckRecordResearcher = false; //ตรวจสอบเอกสาร
    this.isshowdialogSubmitCompletedRequest = false; //ส่งคำรองปรับปรุงเสร็จสิ้น
    this.isshowdialogProcessStatus = false; //สถานะการดำเนินงาน
    this.isshowdialogMessage = false; // message list
    this.isshowdialogReasonForCancelingProject = false; //บันทึกเหตุผลการยกเลิกโครงการ

  }

  onTabChanged($event) {
    console.log('tabno', $event.index);
    let clickedIndex = $event.index;
    this.eventselectedTabIndex = $event.index;
    if (clickedIndex == 8) {
      this.setResearchMethodologyObject();
    } else if (clickedIndex == 16) {
      this.setResearchImpactObject();
    }
  }

  ChangeProcessStatus(event: any) {
    if (event == 2) {
      this.isReadonlyDate = false;
    } else {
      this.isReadonlyDate = true;
      this.startProcessDate = null;
    }
  }

  btnNext() {
    this.selectedTabIndex = 1;

    if (this.eventselectedTabIndex == 1) {
      this.selectedTabIndex = 2;
    } else if (this.eventselectedTabIndex == 2) {
      this.selectedTabIndex = 3;
    } else if (this.eventselectedTabIndex == 3) {
      this.selectedTabIndex = 4;
    } else if (this.eventselectedTabIndex == 4) {
      this.selectedTabIndex = 5;
    } else if (this.eventselectedTabIndex == 5) {
      this.selectedTabIndex = 6;
    } else if (this.eventselectedTabIndex == 6) {
      this.selectedTabIndex = 7;
    } else if (this.eventselectedTabIndex == 7) {
      this.selectedTabIndex = 8;
    } else if (this.eventselectedTabIndex == 8) {
      this.selectedTabIndex = 9;
    } else if (this.eventselectedTabIndex == 9) {
      this.selectedTabIndex = 10;
    } else if (this.eventselectedTabIndex == 10) {
      this.selectedTabIndex = 11;
    } else if (this.eventselectedTabIndex == 11) {
      this.selectedTabIndex = 12;
    } else if (this.eventselectedTabIndex == 12) {
      this.selectedTabIndex = 13;
    } else if (this.eventselectedTabIndex == 13) {
      this.selectedTabIndex = 14;
    } else if (this.eventselectedTabIndex == 14) {
      this.selectedTabIndex = 15;
    } else if (this.eventselectedTabIndex == 15) {
      this.selectedTabIndex = 16;
    } else if (this.eventselectedTabIndex == 16) {
      this.selectedTabIndex = 17;
    } else if (this.eventselectedTabIndex == 17) {
      this.selectedTabIndex = 18;
    }
  }

  btnPrevious() {
    if (this.eventselectedTabIndex == 1) {
      this.selectedTabIndex = 0;
    } else if (this.eventselectedTabIndex == 2) {
      this.selectedTabIndex = 1;
    } else if (this.eventselectedTabIndex == 3) {
      this.selectedTabIndex = 2;
    } else if (this.eventselectedTabIndex == 4) {
      this.selectedTabIndex = 3;
    } else if (this.eventselectedTabIndex == 5) {
      this.selectedTabIndex = 4;
    } else if (this.eventselectedTabIndex == 6) {
      this.selectedTabIndex = 5;
    } else if (this.eventselectedTabIndex == 7) {
      this.selectedTabIndex = 6;
    } else if (this.eventselectedTabIndex == 8) {
      this.selectedTabIndex = 7;
    } else if (this.eventselectedTabIndex == 9) {
      this.selectedTabIndex = 8;
    } else if (this.eventselectedTabIndex == 10) {
      this.selectedTabIndex = 9;
    } else if (this.eventselectedTabIndex == 11) {
      this.selectedTabIndex = 10;
    } else if (this.eventselectedTabIndex == 12) {
      this.selectedTabIndex = 11;
    } else if (this.eventselectedTabIndex == 13) {
      this.selectedTabIndex = 12;
    } else if (this.eventselectedTabIndex == 14) {
      this.selectedTabIndex = 13;
    } else if (this.eventselectedTabIndex == 15) {
      this.selectedTabIndex = 14;
    } else if (this.eventselectedTabIndex == 16) {
      this.selectedTabIndex = 15;
    } else if (this.eventselectedTabIndex == 17) {
      this.selectedTabIndex = 16;
    } else if (this.eventselectedTabIndex == 18) {
      this.selectedTabIndex = 17;
    }
  }

  btnReportProposal() {
    this.router.navigate([
      '/reportproposal',
      JSON.stringify(this.projectProposal),
    ]);
  }

  setFiscalYearSelection() {
    //Chang By weerapon 20220908
    let currentTime = new Date();
    let year = currentTime.getFullYear();
    // let yearTwo = currentTime.getFullYear();
    let years: number;
    if (year < 2500) {
      year = year + 543;
    }
    year = year - 3;
    this.fiscalYearList = [year];
    for (let i = 0; i < 20; i++) {
      this.fiscalYearList.push(year + i + 1);
    }

    // if (yearTwo < 2500) {
    //   yearTwo = yearTwo + 543;

    // }
    // this.fiscalYearListTwo = [yearTwo];
    this.fiscalYearListTwo = [1];
    years = 1;
    for (let i = 0; i < 19; i++) {
      this.fiscalYearListTwo.push(years + i + 1);
    }


  }


  // ยังไม่ขึ้น CR Phase2
  // getUserRole() {
  //   this.brokerAPIService.getProfile(GRAPH_ENDPOINT).subscribe(values => {
  //     console.log("value", values.mail);
  //     this.getMail = values.mail
  //     if (this.getMail != undefined) {

  //       let callMethod: string = '/Auth/GetUserList';
  //       this.brokerAPIService.get(callMethod).subscribe(
  //         values => {
  //           this.getUserList = {};
  //           this.getUserList = values;

  //           this.userSysRole = this.getUserList.find(a => a.email == this.getMail);
  //           console.log("projectProposal", this.projectProposal);

  //           for (let i = 0; i < this.userSysRole.sysUserRoles.length; i++) {

  //             if (this.userSysRole.sysUserRoles[i].userSysRoleId == 3) {
  //               this.userSysRole.isAdmin = true;
  //               this.CheckRemoveAttachment = true;
  //             }

  //             if (this.userSysRole.sysUserRoles[i].userSysRoleId == 2) {
  //               this.userSysRole.isResearcher = true;
  //             }

  //             if (this.userSysRole.sysUserRoles[i].userSysRoleId == 4) {
  //               this.userSysRole.isManagement = true;
  //             }

  //             if (this.userSysRole.sysUserRoles[i].userSysRoleId == 5) {
  //               this.userSysRole.isHightLevelManagement = true;
  //             }

  //           }
  //           console.log("userSysRole", this.userSysRole);

  //           if (this.userSysRole.isAdmin == true) {

  //               if (this.projectProposal.projectStatusId == 1 || this.projectProposal.projectStatusId == 2 || this.projectProposal.projectStatusId == 3 || this.projectProposal.projectStatusId == 4 || this.projectProposal.projectStatusId == 5 ||
  //                 this.projectProposal.projectStatusId == 6 || this.projectProposal.projectStatusId == 8 || this.projectProposal.projectStatusId == 9) {

  //                 this.isEditAdmin = true;
  //                 if (this.isEditable != true) {
  //                   this.isSaveAttachment = true;
  //                 } else {
  //                   this.isSaveAttachment = false;
  //                 }

  //                 this.CheckDownloadAttachment = true;
  //                 if (this.checkReadonlyInAttachment == true) {

  //                 } else {
  //                   this.checkReadonlyInAttachment = true;
  //                 }

  //                 console.log("isEditAdmin");
  //               } else {

  //                 if (this.projectProposal.projectStatusId == 10) {
  //                   this.CheckRemoveAttachment = false;
  //                 } else if (this.projectProposal.projectStatusId == 13) {
  //                   this.CheckRemoveAttachment = false;
  //                 } else if (this.projectProposal.projectStatusId == 11) {
  //                   this.CheckRemoveAttachment = false;
  //                 } else if (this.projectProposal.projectStatusId == 7) {
  //                   this.CheckRemoveAttachment = false;
  //                 }

  //               }


  //           } else {

  //             if (this.userSysRole.isResearcher == true) {

  //               if (this.userSysRole.isAdmin == undefined && this.userSysRole.isResearcher == true) {

  //                 if (this.projectProposal.projectStatusId == 1 || this.projectProposal.projectStatusId == 2 || this.projectProposal.projectStatusId == 3 || this.projectProposal.projectStatusId == 4) {
  //                   this.isEditResearcher = true;
  //                   this.isEditAdmin = true;
  //                   if (this.isEditable != true) {
  //                     this.isSaveAttachment = true;
  //                   } else {
  //                     this.isSaveAttachment = false;
  //                   }
  //                   if (this.checkReadonlyInAttachment == true) {
  //                   } else {
  //                     this.checkReadonlyInAttachment = true;
  //                   }

  //                   if (this.projectProposal.attachDocuments.length > 0) {
  //                     this.CheckDownloadAttachment = true;
  //                   } else {
  //                     this.CheckDownloadAttachment = false;
  //                   }
  //                   console.log("isEditResearcher");


  //                 }
  //               }
  //             }
  //           }
  //         });
  //     }
  //   });
  // }
  // research team
  btnAddResearchTeam() {
    let teamMemberRow = new teammember();
    teamMemberRow.projectId = this.projectProposal.id;
    teamMemberRow.isIntenalResearch = false;
    teamMemberRow.researcherType =
      this.researcherTypeList[this.researcherTypeList.length - 2].id;
    teamMemberRow.isIntenalResearch = true;
    if (this.projectProposal.teamMembers == null) {
      this.projectProposal.teamMembers = [teamMemberRow];
    } else {
      this.projectProposal.teamMembers.push(teamMemberRow);
    }
    this.dataSourceResearchTeam.data = this.projectProposal.teamMembers;


    this.checkDataInTab();
  }

  ResearchTeamDelete(rowid) {
    this.projectProposal.teamMembers.splice(rowid, 1);
    this.dataSourceResearchTeam.data = this.projectProposal.teamMembers;
    this.checkDataInTab();
  }

  // research objective
  btnAddObjective() {
    let projectObjectiveRow = new projectobjective();
    projectObjectiveRow.projectId = this.projectProposal.id;
    if (this.projectProposal.objectives == null) {
      this.projectProposal.objectives = [projectObjectiveRow];
    } else {
      this.projectProposal.objectives.push(projectObjectiveRow);
    }
    this.dataSourceObjective.data = this.projectProposal.objectives;
    this.checkDataInTab();
  }

  ResearchObjectivesDelete(rowid: any) {
    this.projectProposal.objectives.splice(rowid, 1);
    this.dataSourceObjective.data = this.projectProposal.objectives;
  }

  // keyWord

  btnAddKeyword() {
    if (
      this.projectProposal.keywords &&
      this.projectProposal.keywords.length >= 5
    ) {
      this.openSnackBar(
        'ไม่สามารถเพิ่มคำสำคัญได้เกิน 5 บรรทัด',
        this.closeSnackbarMessage
      );
      return;
    } else {
      let keywordRow = new keyword();
      keywordRow.projectId = this.projectProposal.id;
      if (this.projectProposal.keywords == null) {
        this.projectProposal.keywords = [keywordRow];
      } else {
        this.projectProposal.keywords.push(keywordRow);
      }
      this.dataSourceKeyword.data = this.projectProposal.keywords;
      this.checkDataInTab();
    }
  }

  KeywordDelete(rowid: any) {
    this.projectProposal.keywords.splice(rowid, 1);
    this.dataSourceKeyword.data = this.projectProposal.keywords;
    this.checkDataInTab();
  }

  // ResearchMethodology
  // btnAddMethodology() {
  //   let researchMethodRow = new researchmethod;
  //   researchMethodRow.projectId = this.projectProposal.id;
  //   if (this.projectProposal.researchMethods == null) {
  //     this.projectProposal.researchMethods = [researchMethodRow];
  //   } else {
  //     this.projectProposal.researchMethods.push(researchMethodRow);
  //   }
  //   this.dataSourceResearchMethodology.data = this.projectProposal.researchMethods;
  // }

  // ResearchMethodologyDelete(rowid: any) {
  //   this.projectProposal.researchMethods.splice(rowid, 1);
  //   this.dataSourceResearchMethodology.data = this.projectProposal.researchMethods;
  // }

  //ProcessingTime
  btnAddProcessingTime() {
    let newTimeLine = new timelinedetail();
    newTimeLine.fiscalYear = this.fiscalYearListTwo[0];
    newTimeLine.fromMonth = 1;
    newTimeLine.toMonth = 1;
    if (this.projectProposal.timeLine == null) {
      this.projectProposal.timeLine = new projecttimeline();
      this.projectProposal.timeLine.projectId = this.projectProposal.id;
      this.projectProposal.timeLine.timeLineDetails = [newTimeLine];
    } else {
      this.projectProposal.timeLine.timeLineDetails.push(newTimeLine);
    }

    if (this.projectProposal.timeLine != null &&
      this.projectProposal.timeLine.timeLineDetails != null
    ) {
      if (this.projectProposal.timeLine.timeLineDetails.length > 0) {

        // this.dataSourceProcessingTime.data = sortingArrayList(
        //   this.projectProposal.timeLine.timeLineDetails,
        //   'lineNo',
        //   'asc'
        // )
        this.dataSourceProcessingTime.data = this.projectProposal.timeLine.timeLineDetails;
        console.log("dataSourceProcessingTime", JSON.stringify(this.dataSourceProcessingTime.data));

      };
    }

    this.checkDataInTab();
  }

  ProcessingTimeDelete(rowid: any) {
    this.projectProposal.timeLine.timeLineDetails.splice(rowid, 1);
    if (this.projectProposal.timeLine != null &&
      this.projectProposal.timeLine.timeLineDetails != null
    ) {
      // if (this.projectProposal.timeLine.timeLineDetails.length > 0) {
      // this.dataSourceProcessingTime.data = sortingArrayList(
      //   this.projectProposal.timeLine.timeLineDetails,
      //   'lineNo',
      //   'asc'
      // )
      this.dataSourceProcessingTime.data = this.projectProposal.timeLine.timeLineDetails;
      // }
    }

    this.checkDataInTab();
  }

  // ExpectedResults

  btnAddExpectedResult() {
    if (!this.totalTime || this.totalTime == 0) {
      this.openDialog(
        'ข้อมูลไม่ครบถ้วน',
        'กรุณาระบุระยะเวลาการดำเนินการวิจัยในหัวข้อระยะเวลาดำเนินการ'
      );
      return;
    }

    let expectedResultRow = new expectedresult();
    expectedResultRow.projectId = this.projectProposal.id;
    if (
      this.projectProposal.expectedResults == null ||
      this.projectProposal.expectedResults.length < 1
    ) {
      expectedResultRow.fromMonth = 1;
      if (this.totalTime > 5) {
        expectedResultRow.toMonth = 6;
      }
      this.projectProposal.expectedResults = [expectedResultRow];
    } else {
      let fromMonth =
        this.projectProposal.expectedResults[
          this.projectProposal.expectedResults.length - 1
        ].toMonth + 1;
      let toMonth =
        this.projectProposal.expectedResults[
          this.projectProposal.expectedResults.length - 1
        ].toMonth + 6;
      if (fromMonth > this.totalTime) {
        this.openDialog(
          'แจ้งเตือน',
          'ไม่สามารถเพิ่มข้อมูลเนื่องจากเกินระยะเวลาดำเนินการ'
        );
        return;
      }
      if (toMonth > this.totalTime) {
        toMonth = this.totalTime;
      }
      expectedResultRow.fromMonth = fromMonth;
      expectedResultRow.toMonth = toMonth;
      this.projectProposal.expectedResults.push(expectedResultRow);
    }
    this.dataSourceExpectedResults.data = this.projectProposal.expectedResults;
    this.checkDataInTab();
  }
  ExpectedResultsDelete(rowid: any) {
    this.projectProposal.expectedResults.splice(rowid, 1);
    this.dataSourceExpectedResults.data = this.projectProposal.expectedResults;
    this.checkDataInTab();
  }

  checkIfGreaterThanTotalTime(
    monthNumber: number,
    whichData: string,
    rowID: number
  ) {
    console.log('showmonth', monthNumber, whichData, this.totalTime, rowID);
    if (monthNumber > this.totalTime) {
      console.log('in1', monthNumber, whichData, this.totalTime, rowID);
      if (whichData == 'from') {
        console.log('in2', monthNumber, whichData, this.totalTime, rowID);
        this.projectProposal.expectedResults[rowID].fromMonth = this.totalTime;
      } else {
        this.projectProposal.expectedResults[rowID].toMonth = this.totalTime;
      }
      this.dataSourceExpectedResults.data =
        this.projectProposal.expectedResults;
    }
  }

  //Budget
  btnAddBudget() {
    let budgetDetailrow = new budgetdetail();
    console.log(this.dataSourceBudget.data.length);
    let currentTime = new Date();
    let year = currentTime.getFullYear();
    budgetDetailrow.fiscalYear = year + 543;
    // if (this.dataSourceBudget.data.length > 0) {
    // budgetDetailrow.fiscalYear = this.fiscalYearList[3];

    // } else {
    //   budgetDetailrow.fiscalYear = this.fiscalYearList[0];

    // }

    if (this.projectProposal.budget == null) {
      this.projectProposal.budget = new projectbudget();
      this.projectProposal.budget.projectId = this.projectProposal.id;
      this.projectProposal.budget.budgetDetails = [budgetDetailrow];
    } else {
      if (this.projectProposal.budget.budgetDetails == null) {
        this.projectProposal.budget.budgetDetails = [budgetDetailrow];
      } else {
        console.log("budgetDetailrow", budgetDetailrow);

        this.projectProposal.budget.budgetDetails.push(budgetDetailrow);

      }
    }
    console.log("budgetDetails", this.projectProposal.budget.budgetDetails);

    this.dataSourceBudget.data = this.projectProposal.budget.budgetDetails;
    this.checkDataInTab();
  }

  BudgetDelete(rowid: any) {
    this.projectProposal.budget.budgetDetails.splice(rowid, 1);
    this.dataSourceBudget.data = this.projectProposal.budget.budgetDetails;
    this.checkDataInTab();
    this.onBudgetInRowChange();
  }

  sumBudgetByFiscalYear() {
    let totalAmountByYear: number = 0;
    let groupFiscalYear: number;
    if (this.projectProposal.budget.budgetDetails) {
      groupFiscalYear = this.projectProposal.budget.budgetDetails[0].fiscalYear;
      for (
        let i = 0;
        i < this.projectProposal.budget.budgetDetails.length;
        i++
      ) {
        if (
          this.projectProposal.budget.budgetDetails[i].fiscalYear ==
          groupFiscalYear
        ) {
          totalAmountByYear +=
            this.projectProposal.budget.budgetDetails[i].budget;
        } else {
          //push to something
          if (this.budgetDescriptionByYear) {
            this.budgetDescriptionByYear += ',' + totalAmountByYear.toString;
          } else {
            this.budgetDescriptionByYear = totalAmountByYear.toString();
          }
          totalAmountByYear = 0;
          groupFiscalYear =
            this.projectProposal.budget.budgetDetails[i].fiscalYear;
        }
      }
    }
  }

  // ReasonsForPurchasingEquipment
  onclickReasonsForPurchasingEquipment() {
    let durablearticleRow = new durablearticle();
    durablearticleRow.projectId = this.projectProposal.id;

    if (this.projectProposal.durableArticles == null) {
      durablearticleRow.orderNo = [durablearticleRow].length;
      this.projectProposal.durableArticles = [durablearticleRow];
    } else {
      this.projectProposal.durableArticles.push(durablearticleRow);
    }
    this.dataSourceReasonforPurchasingEquipment.data =
      this.projectProposal.durableArticles;
    this.checkDataInTab();
  }

  ReasonsForPurchasingEquipmentDelete(rowid: any) {
    this.projectProposal.durableArticles.splice(rowid, 1);
    this.dataSourceReasonforPurchasingEquipment.data =
      this.projectProposal.durableArticles;
    this.checkDataInTab();
  }

  // References

  btnAddReference() {
    let referenceDocumentForProjectRow = new referencedocumentforproject();
    referenceDocumentForProjectRow.projectId = this.projectProposal.id;
    if (this.projectProposal.referenceDocuments == null) {
      this.projectProposal.referenceDocuments = [
        referenceDocumentForProjectRow,
      ];
    } else {
      this.projectProposal.referenceDocuments.push(
        referenceDocumentForProjectRow
      );
    }
    this.dataSourceReferenceDocuments.data =
      this.projectProposal.referenceDocuments;
    this.checkDataInTab();
  }

  ReferencesDelete(rowid: any) {
    this.projectProposal.referenceDocuments.splice(rowid, 1);
    this.dataSourceReferenceDocuments.data =
      this.projectProposal.referenceDocuments;
    this.checkDataInTab();
  }

  // ExpectedBenefits

  btnAddBenefits() {
    let expectedBenefitRow = new expectedbenefit();
    expectedBenefitRow.projectId = this.projectProposal.id;
    if (this.projectProposal.expectedBenefits == null) {
      this.projectProposal.expectedBenefits = [expectedBenefitRow];
    } else {
      this.projectProposal.expectedBenefits.push(expectedBenefitRow);
    }
    this.dataSourceExpectedBenefits.data =
      this.projectProposal.expectedBenefits;
    this.checkDataInTab();
  }

  ExpectedBenefitsDelete(rowid: any) {
    this.projectProposal.expectedBenefits.splice(rowid, 1);
    this.dataSourceExpectedBenefits.data =
      this.projectProposal.expectedBenefits;
    this.checkDataInTab();
  }

  // ExpectedImpact

  btnAddImpact() {
    let expectedImpactRow = new expectedimpact();
    expectedImpactRow.projectId = this.projectProposal.id;
    if (this.projectProposal.expectedImpacts == null) {
      this.projectProposal.expectedImpacts = [expectedImpactRow];
    } else {
      this.projectProposal.expectedImpacts.push(expectedImpactRow);
    }
    this.dataSourceExpectedImpact.data = this.projectProposal.expectedImpacts;
  }

  ExpectedImpactDelete(rowID: any) {
    this.projectProposal.expectedImpacts.splice(rowID, 1);
    this.dataSourceExpectedImpact.data = this.projectProposal.expectedImpacts;
  }

  // Attachment

  btnSaveattachDocuments() {
    let errorMessage: string = '';
    if (this.attachedFileType == null || this.attachedFileType == '') {
      errorMessage = 'ประเภทเอกสาร';
    }
    if (this.attachedFileName == null || this.attachedFileName == '') {
      if (errorMessage == '') {
        errorMessage = 'ไฟล์ที่ต้องการแนบ';
      } else {
        errorMessage = errorMessage + 'และไฟล์ที่ต้องการแนบ';
      }
    }
    if (errorMessage != '') {
      this.openSnackBar('กรุณาใส่' + errorMessage, this.closeSnackbarMessage);
      errorMessage = '';
      return;
    }
    let attachedDocument = new attachdocument();
    attachedDocument.documentType = this.attachedFileType;
    attachedDocument.documentName = this.attachedFileName;
    attachedDocument.mimeType = this.attachedFileMimeType;
    attachedDocument.documentContent = this.dataBase64;
    this.attachedFileType = '';
    this.attachedFileName = '';
    this.attachedFileMimeType = '';
    this.dataBase64 = '';
    this.PostFileData(attachedDocument, 1);
  }

  getExtension(fileName): string {
    try {
      let fileExtension = fileName.substring(
        fileName.indexOf('.') + 1,
        fileName.length
      );
      console.log('fileExtension', fileExtension);
      return fileExtension;
    } catch (error) {
      return error + ',' + fileName;
    }
  }

  attachDocumentsDelete(rowID: any) {
    this.projectProposal.attachDocuments.splice(rowID, 1);
    this.dataSourceAttachment.data = this.projectProposal.attachDocuments;
  }

  onDocumentTypeInput(searchName: string) {
    this._filterAttachedFileType(searchName);
  }

  private _filterAttachedFileType(value: string) {
    if (!value) {
      this.projectAttachFileTypeListToShow = this.projectAttachFileTypeList;
      return;
    }
    const filterValue = value.toLowerCase();
    let typeList: [enumdata];
    // for(let i=0 ; i<this.projectAttachFileTypeList.length;i++){
    //   if(!this.projectAttachFileTypeList[i].description.includes(value)){
    //     this.projectAttachFileTypeList.splice(i,1);
    //   }
    // }
    let tempList: [enumdata];
    for (let i = 0; i < this.projectAttachFileTypeList.length; i++) {
      if (this.projectAttachFileTypeList[i].description.includes(filterValue)) {
        let tempRow = new enumdata();
        tempRow.id = this.projectAttachFileTypeList[i].id;
        tempRow.description = this.projectAttachFileTypeList[i].description;
        if (tempList) {
          tempList.push(tempRow);
        } else {
          tempList = [tempRow];
        }
      }
    }

    this.projectAttachFileTypeListToShow = tempList;
    // return Array.from(this.projectAttachFileTypeList.values()).filter(option => option['description'].toLowerCase().includes(filterValue)||option['description'].toLowerCase().includes(filterValue));
  }

  onAttachedFileTypeSelected(description: string) {
    this.attachedFileType = description;
  }

  viewAttachDocument(attachmentID: Number) {
    let url: string = '/DocumentUpload/GetDocument/' + attachmentID;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        try {
          let docData: attachdocument = values;
          console.log('data>>', JSON.stringify(docData));
          if (docData.documentContent) {
            let blob: Blob;
            blob = this.base64ToBlob(docData.documentContent, docData.mimeType);
            if (blob) {
              let link = document.createElement('a');
              const url = window.URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute('download', docData.documentName);
              link.click();
            }
          }
        } catch (error) {
          this.openSnackBar(
            'error preview:' + error,
            this.closeSnackbarMessage
          );
        }
      },
      (error) => { }
    );
  }

  public base64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ''); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  async PostFileData(docToUpload: attachdocument, extratAction: number) {
    console.log('attachment>>', JSON.stringify(docToUpload));
    // return;
    this.openSnackBar("กำลังบันทึกเอกสารแนบ", this.closeSnackbarMessage);
    this.brokerAPIService
      .post('/DocumentUpload/UploadDocument', JSON.stringify(docToUpload))
      .subscribe(
        (values) => {
          this.result = values;
          docToUpload.id = Number.parseInt(this.result.data);
          if (extratAction == 1) {
            // for tab
            let projectAttachdocumentRow = new projectattachdocument();
            projectAttachdocumentRow.projectId = this.projectProposal.id;
            projectAttachdocumentRow.attachDocumentId = Number.parseInt(
              this.result.data
            );
            docToUpload.documentContent = null;
            projectAttachdocumentRow.attachDocument = docToUpload;
            if (this.projectProposal.attachDocuments == null) {
              this.projectProposal.attachDocuments = [projectAttachdocumentRow];
            } else {
              this.projectProposal.attachDocuments.push(
                projectAttachdocumentRow
              );
            }
            this.dataSourceAttachment.data =
              this.projectProposal.attachDocuments;
          }
          console.log(this.dataSourceAttachment.data);
          console.log(this.dataSourceAttachment.data.length);
        },
        (error) => {
          this.errorMessage = <any>error;
          console.log('errorMessage:' + JSON.stringify(this.errorMessage));
        }
      );
  }
  // getResearchMethodologyList(){
  //   let url:string = "/Master/GetResearcMethodologyList";
  //   this.brokerAPIService.getAwait(url,null ,null).then(
  //     values=>{
  //       this.researchMethodologyList = values;
  //     },
  //     error=>{
  //       console.log("eror:",error);
  //     }
  //   )
  // }

  getMethodologyList() {
    let url: string = '/Master/GetResearcMethodologyList';
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.researchMethodologyList = values;
      },
      (error) => { }
    );
  }

  getBudgetGroupList() {
    let url: string = '/Master/GetBudgetGroupList';
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.budgetGroupList = values;
        for (let i = 0; i < this.budgetGroupList.length; i++) {
          this.getBudgetSubGroupList(this.budgetGroupList[i]);
        }
      },
      (error) => { }
    );
  }

  getBudgetSubGroupList(budgetGroup: enumdata) {
    //category
    let url: string = '/Master/GetBudgetCategoryList/' + budgetGroup.id;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        let categoryList: [enumdata] = values;
        for (let i = 0; i < categoryList.length; i++) {
          let budgetSubGroupRow: enumdata = new enumdata();
          budgetSubGroupRow.id = categoryList[i].id;
          budgetSubGroupRow.description = categoryList[i].description;
          budgetSubGroupRow.groupName = budgetGroup.description;
          if (this.budgetSubGroupList == null) {
            this.budgetSubGroupList = [budgetSubGroupRow];
          } else {
            this.budgetSubGroupList.push(budgetSubGroupRow);
          }
        }
      },
      (error) => { }
    );
  }

  getExpectedImpactList() {
    let url: string = '/Master/GetExpectedImpactList';
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.expectedImpactList = values;
        // console.log(
        //   'expectedImpactList',
        //   JSON.stringify(this.expectedImpactList)
        // );
      },
      (error) => {
        console.log('expectedImpactList', error);
      }
    );
  }

  onCategoryChange(categoryID: any, rowID: any) {
    let budgetSubGroup: enumdata = this.budgetSubGroupList.find((x) => x.id == categoryID);

    this.projectProposal.budget.budgetDetails[rowID].budgetGroup = budgetSubGroup.groupName;
    this.projectProposal.budget.budgetDetails[rowID].budgetGroupId = this.budgetGroupList.find(x => x.description == budgetSubGroup.groupName).id;
    this.projectProposal.budget.budgetDetails[rowID].budgetCategory = budgetSubGroup.description;
    this.dataSourceBudget.data = this.projectProposal.budget.budgetDetails;
    console.log('categorymain', JSON.stringify(this.projectProposal.budget.budgetDetails[rowID]));
  }

  getProjectAttachFileTypeList() {
    //category
    let url: string = '/Master/GetProjectAttachFileTypeList';
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.projectAttachFileTypeList = values;
        this.projectAttachFileTypeListToShow = this.projectAttachFileTypeList;
      },
      (error) => { }
    );
  }

  GetFileData(fileID: string) {
    let callMethod: string = '/DocumentUpload/GetDocuemt/' + fileID;
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.result02 = values;
        this.datarResult02 = this.result02;
      },
      (error) => (this.errorMessage = <any>error)
    );
  }

  async handleFileInput(files: FileList, fileNameDisplay: string) {
    if (files && files.length > 0) {
      let file: File = files.item(0);
      // this.attachedFileName = file.name;
      if (fileNameDisplay == 'projectAttacFile') {
        this.attachedFileName = file.name;
        this.attachedFileMimeType = file.type;
      } else if (fileNameDisplay == 'reviewCommentFile1') {
        this.documentReviewInfo.attachment1.documentName = file.name;
        this.documentReviewInfo.attachment1.mimeType = file.type;
      } else if (fileNameDisplay == 'reviewCommentFile2') {
        this.documentReviewInfo.attachment2.documentName = file.name;
        this.documentReviewInfo.attachment2.mimeType = file.type;
      } else if (fileNameDisplay == 'reviewCommentFile3') {
        this.documentReviewInfo.attachment3.documentName = file.name;
        this.documentReviewInfo.attachment3.mimeType = file.type;
      } else if (fileNameDisplay == 'approveAttachment1') {
        this.documentApprovalInfo.attachment1.documentName = file.name;
        this.documentApprovalInfo.attachment1.mimeType = file.type;
      } else if (fileNameDisplay == 'approveAttachment2') {
        this.documentApprovalInfo.attachment2.documentName = file.name;
        this.documentApprovalInfo.attachment2.mimeType = file.type;
      } else if (fileNameDisplay == 'approveAttachment3') {
        this.documentApprovalInfo.attachment3.documentName = file.name;
        this.documentApprovalInfo.attachment3.mimeType = file.type;
      }

      if (files && files.length > 0) {
        for (var i = 0; i < files.length; i++) {
          let file: File = files[i];
          const result = await this.getBase64(files[i]).catch((e) => e);
          if (result instanceof Error) {
            console.log('Error: ', result.message);
            return;
          }

          if (fileNameDisplay == 'projectAttacFile') {
            this.dataBase64 = result.toString();
          } else if (fileNameDisplay == 'reviewCommentFile1') {
            this.documentReviewInfo.attachment1.documentContent =
              result.toString();
            this.PostFileData(this.documentReviewInfo.attachment1, 0);
          } else if (fileNameDisplay == 'reviewCommentFile2') {
            this.documentReviewInfo.attachment2.documentContent =
              result.toString();
            this.PostFileData(this.documentReviewInfo.attachment2, 0);
          } else if (fileNameDisplay == 'reviewCommentFile3') {
            this.documentReviewInfo.attachment3.documentContent =
              result.toString();
            this.PostFileData(this.documentReviewInfo.attachment3, 0);
          } else if (fileNameDisplay == 'approveAttachment1') {
            this.documentApprovalInfo.attachment1.documentContent =
              result.toString();
            this.PostFileData(this.documentApprovalInfo.attachment1, 0);
          } else if (fileNameDisplay == 'approveAttachment2') {
            this.documentApprovalInfo.attachment2.documentContent =
              result.toString();
            this.PostFileData(this.documentApprovalInfo.attachment2, 0);
          } else if (fileNameDisplay == 'approveAttachment3') {
            this.documentApprovalInfo.attachment3.documentContent =
              result.toString();
            this.PostFileData(this.documentApprovalInfo.attachment3, 0);
          }
          // this.objRow.companyLogo = this.imagePath;
        }
      }
    }
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  validate() {
    if (this.lengthKeyWord > 10000) {
      this.isShowKeyWordAndProblrm = true;
      return false;
    }

    if (this.lengthLiteratureReview > 10000) {
      this.isShowLiteratureReview = true;
      return false;
    }
    if (this.fundingTotalDisplay != undefined) {
      let dataBudket = this.fundingTotalDisplay.replace(",", "").replace(",", "").replace(",", "").toString()
      console.log("dataBudket", Number.parseInt(dataBudket));



      if (Number.parseInt(dataBudket) >= 9999999999.99) {
        console.log("inIf");

        this.isShowBudget = true;
        return false;
        // ไม่สามารถแปลงเงินเกิน 13 หลักได้
      }
    }

    // for(let i= 0;i< this.researchMethodDetailTwo.length;i++){

    //   if (this.researchMethodDetailTwo[i].Length > 10000){
    //     this.isShowLiteratureReview = true;

    //   }

    // }

    if (this.researchMethodDetail.length > 0) {
      this.researchMethodDetail = sortingArrayList(this.researchMethodDetail, 'RowID', 'asc');
    }

    if (this.lengthResearchMethodType1 > 10000) {
      this.isShowResearchMethodType = true;
      return false;

    }

    if (this.lengthResearchMethodType2 > 10000) {
      this.isShowResearchMethodType = true;
      return false;

    }

    if (this.lengthResearchMethodType3 > 10000) {
      this.isShowResearchMethodType = true;
      return false;

    }
    if (this.lengthResearchMethodType4 > 10000) {
      this.isShowResearchMethodType = true;
      return false;
    }

    if (this.lengthResearchMethodType5 > 10000) {
      this.isShowResearchMethodType = true;
      return false;
    }
    return true;
  }

  btnSave() {

    if (this.validate()) {
      this.blockScreen();
      // if (this.projectProposalOldData == JSON.stringify(this.projectProposal)) {
      //   console.log('data', 'No change');
      // } else {
      //   console.log('data', 'Data is changed');
      // }

      this.projectProposal.importantOfProblem = this.editorKewardAndProblem;
      this.projectProposal.literatureReview = this.editorLiteratureReview;
      if (this.researchDescriptionTypeId != null) {
        this.projectProposal.researchDescriptionTypeId = Number.parseInt(
          this.researchDescriptionTypeId
        );
      }
      if (this.projectProposal.budget == null) {
        this.projectProposal.budget = new projectbudget();
      }

      this.projectProposal.budget.fundingTypeId = this.fundingTypeIDSelected;
      // if(this.fundingTypeRemark){
      //   this.projectProposal.budget.fundingType.fundingComment = this.fundingTypeRemark;
      // }
      // console.log("fundingTypeRemark",this.fundingTypeRemark);
      this.projectProposal.budget.totalProjectBudget = this.fundingTotal;
      if (this.researchSiteHeadId != null) {
        this.projectProposal.researchSiteHeadId = Number.parseInt(
          this.researchSiteHeadId
        );
      }
      if (this.projectProposal.keywords) {
        this.reOrder(this.projectProposal.keywords);
      }
      if (this.projectProposal.timeLine != null) {
        this.projectProposal.timeLine.totalTimeLineInMonth = this.totalTime;
        this.reOrder(this.projectProposal.timeLine.timeLineDetails);
      } else {
        this.projectProposal.timeLine = new projecttimeline();
        this.projectProposal.timeLine.totalTimeLineInMonth = this.totalTime;
      }

      if (this.fundingOther) {
        this.projectProposal.budget.noGrantsOther = this.fundingOther;
      }

      if (this.projectProposal.teamMembers) {
        this.reOrder(this.projectProposal.teamMembers);
      }
      if (this.projectProposal.expectedResults) {
        this.reOrder(this.projectProposal.expectedResults);
      }
      if (this.tciTier) {
        this.projectProposal.received.tciTier = Number.parseInt(this.tciTier);
      } else {
        this.projectProposal.received.tciTier = 0;
      }
      if (this.journalInternationalDatabase) {
        this.projectProposal.received.journalInternationalDatabase =
          Number.parseInt(this.journalInternationalDatabase);
      } else {
        this.projectProposal.received.journalInternationalDatabase = 0;
      }
      if (this.quartile) {
        this.projectProposal.received.quartile = Number.parseInt(this.quartile);
      } else {
        this.projectProposal.received.quartile = 0;
      }



      if (isNaN(this.projectProposal.received.tciTier)) {
        this.projectProposal.received.tciTier = 0;
      }

      if (isNaN(this.projectProposal.received.journalInternationalDatabase)) {
        this.projectProposal.received.journalInternationalDatabase = 0;
      }


      console.log('datatosend', JSON.stringify(this.projectProposal));

      if (this.appInfo['mode'] == 'edit') {
        this.projectProposal.projectStatus = null;
        this.projectProposal.researchForOrg = null;
        this.projectProposal.projectOwner = null;
        this.brokerAPIService
          .post('/Project/SaveProject', JSON.stringify(this.projectProposal))
          .subscribe(
            (values) => {
              this.result = values;
              this.unBlockScreen();
              if (this.result.success) {
                this.openSnackBar(this.result.message, this.closeSnackbarMessage);
              } else {
                this.openDialog(this.titleUnabelTosave, this.result.message);
              }
            },
            (error) => {
              let errorResult: any = <any>error;
              this.openDialog(
                this.titleUnabelTosave,
                JSON.stringify(errorResult.error.errors)
              );
              this.unBlockScreen();
              console.log(
                'errorMessage update' + JSON.stringify(errorResult.error.errors)
              );
            }
          );
      } else {
        this.projectProposal.researchForOrgId =
          this.mainresearcher.organizations[0].researcherOrgId;
        this.projectProposal.projectOwnerId = this.mainresearcher.id;
        this.keepsAttachDocument = this.projectProposal.attachDocuments;

        if (this.projectProposal.attachDocuments != undefined) {
          if (this.projectProposal.attachDocuments[0].attachDocumentId != null || this.projectProposal.attachDocuments[0].attachDocumentId != undefined) {
            for (var i = 0; i < this.projectProposal.attachDocuments.length; i++) {
              this.projectProposal.attachDocuments[i].attachDocument = null

            }
          }
        }

        console.log(JSON.stringify(this.projectProposal));


        this.brokerAPIService
          .post('/Project/CreateNewProject', JSON.stringify(this.projectProposal))
          .subscribe(
            (values) => {
              this.result = values;
              try {
                this.unBlockScreen();
                this.appInfo['mode'] = 'edit';
                this.projectProposal.id = Number.parseInt(this.result.data);
                this.getProjectInfo(this.projectProposal.id);
                if (this.result.success) {
                  this.openSnackBar(
                    this.result.message,
                    this.closeSnackbarMessage
                  );
                } else {
                  this.openDialog(this.titleUnabelTosave, this.result.message);
                }
              } catch (error) {
                this.openDialog(this.titleUnabelTosave, JSON.stringify(error));
              }
            },
            (error) => {
              let errorResult: any = <any>error;
              var str1 = JSON.stringify(errorResult.error.errors)
              var index = str1.indexOf("teamMembers[0].percentAssociate");
              if (index != -1) {
                errorResult.error.errors = 'โปรดใส่ข้อมูล สัดส่วนร้อยละให้ถูกต้องตามฟอร์แมต';
              }
              this.openDialog(
                this.titleUnabelTosave,
                JSON.stringify(errorResult.error.errors)
              );
              this.unBlockScreen();
              console.log(
                'errorMessage insert' + JSON.stringify(errorResult.error.errors)
              );
            }
          );
      }

    }
  }

  btnSend() {
    if (this.appInfo['mode'] != 'edit') {
      this.openSnackBar('กรุณาบันทึกข้อมูลก่อนส่งคำขอ', this.closeSnackbarMessage);
      return;
    } else {

      this.blockScreen();
      this.projectProposal.importantOfProblem = this.editorKewardAndProblem;
      this.projectProposal.literatureReview = this.editorLiteratureReview;
      if (this.researchDescriptionTypeId != null) {
        this.projectProposal.researchDescriptionTypeId = Number.parseInt(
          this.researchDescriptionTypeId
        );
      }
      if (this.projectProposal.budget) {
        this.projectProposal.budget.fundingTypeId = this.fundingTypeIDSelected;
        // if(this.fundingTypeRemark){
        //   this.projectProposal.budget.fundingType.fundingComment = this.fundingTypeRemark;
        // }
        this.projectProposal.budget.totalProjectBudget = this.fundingTotal;
      }

      // if (this.projectProposal.timeLine != null) {
      //   this.projectProposal.timeLine.totalTimeLineInMonth = this.totalTime;
      // }
      if (this.projectProposal.keywords) {
        this.reOrder(this.projectProposal.keywords);
      }
      if (this.projectProposal.timeLine != null) {
        this.projectProposal.timeLine.totalTimeLineInMonth = this.totalTime;
        this.reOrder(this.projectProposal.timeLine.timeLineDetails);
      }

      if (this.projectProposal.teamMembers) {
        this.reOrder(this.projectProposal.teamMembers);
      }
      if (this.projectProposal.expectedResults) {
        this.reOrder(this.projectProposal.expectedResults);
      }
      if (this.tciTier != null) {
        this.projectProposal.received.tciTier = Number.parseInt(this.tciTier);
      }
      if (this.journalInternationalDatabase != null) {
        this.projectProposal.received.journalInternationalDatabase =
          Number.parseInt(this.journalInternationalDatabase);
      }
      if (this.quartile != null) {
        this.projectProposal.received.quartile = Number.parseInt(this.quartile);
      }
      // if (this.appInfo['mode'] == 'edit') {
      this.projectProposal.projectStatus = null;
      this.projectProposal.researchForOrg = null;
      this.projectProposal.projectOwner = null;
      if (this.tciTier) {
        this.projectProposal.received.tciTier = Number.parseInt(this.tciTier);
      } else {
        this.projectProposal.received.tciTier = 0;
      }
      if (this.journalInternationalDatabase) {
        this.projectProposal.received.journalInternationalDatabase =
          Number.parseInt(this.journalInternationalDatabase);
      } else {
        this.projectProposal.received.journalInternationalDatabase = 0;
      }
      if (this.quartile) {
        this.projectProposal.received.quartile = Number.parseInt(this.quartile);
      } else {
        this.projectProposal.received.quartile = 0;
      }
      console.log("projectProposal", JSON.stringify(this.projectProposal));

      this.brokerAPIService
        .post('/Project/SubmitProject', JSON.stringify(this.projectProposal))
        .subscribe(
          (values) => {
            this.result = values;
            this.unBlockScreen();
            if (this.result.success) {
              this.openSnackBar(this.result.message, this.closeSnackbarMessage);
              this.btnBack();
            } else {
              this.openDialog('ไม่สามารถส่งคำขอได้ ', this.result.message);
            }
          },
          (error) => {
            this.errorMessage = <any>error;
            this.unBlockScreen();
            console.log('btnsend:' + JSON.stringify(this.errorMessage));
          }
        );
    }
  }

  // at comittee approval
  SubmitApproval(decision: boolean) {
    this.blockScreen();
    let methodToCall: string = '/Project/ApproveProject';
    let approvalDecision: projectreviewapprove = new projectreviewapprove();

    approvalDecision.projectId = this.projectProposal.id;
    approvalDecision.isApprove = decision;
    approvalDecision.approveDate = offsetTimeZone(this.approvedDate);
    approvalDecision.conclusion = new conclusion();
    approvalDecision.conclusion.projectId = this.projectProposal.id;
    approvalDecision.conclusion.subject = this.documentApprovalInfo.subject;
    approvalDecision.conclusion.conclusionMessage = this.RecordTheResults;
    if (this.documentApprovalInfo.attachment1.id) {
      approvalDecision.conclusion.attachment1Id =
        this.documentApprovalInfo.attachment1.id;
    }
    if (this.documentApprovalInfo.attachment2.id) {
      approvalDecision.conclusion.attachment2Id =
        this.documentApprovalInfo.attachment2.id;
    }
    if (this.documentApprovalInfo.attachment3.id) {
      approvalDecision.conclusion.attachment3Id =
        this.documentApprovalInfo.attachment3.id;
    }
    console.log('showdecision', JSON.stringify(approvalDecision));
    this.isshowdialogRecordTheResults = false;

    this.brokerAPIService
      .post(methodToCall, JSON.stringify(approvalDecision))
      .subscribe(
        (values) => {
          let result: IAPIResponse = values;
          this.unBlockScreen();
          if (result.success) {
            // this.isshowdialogRecordTheResults = false;
            this.openSnackBar(result.message, this.closeSnackbarMessage);
            this.getProjectInfo(this.projectProposal.id);
            // this.btnBack();
          } else {
            this.openDialog('ไม่สามารถบันทึกข้อมูลได้', result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
          this.openDialog('ไม่สามารถบันทึกข้อมูลได้', JSON.stringify(error));
        }
      );
  }

  btnSubmitApprovalDecision() {
    // this.blockScreen();
    this.blockScreen();
    let errorValidation: string;
    this.documentApprovalInfo.message = this.RecordTheResults;

    if (this.decisionAtCommiteeApproval == null) {
      errorValidation = 'ผลการพิจารณา';
    }
    if (this.documentApprovalInfo.subject == null) {
      errorValidation = errorValidation + ' เรื่่อง';
    }
    if (this.documentApprovalInfo.message == null) {
      errorValidation = errorValidation + ' ข้อความ';
    }
    if (errorValidation != null) {
      this.openDialog('ไม่สามารถบันทึกข้อมูลได้', 'errorValidation');
      this.unBlockScreen();
      return;
    }
    if (this.decisionAtCommiteeApproval == '1') {
      this.SubmitApproval(true);
    } else if (this.decisionAtCommiteeApproval == '2') {
      if (this.documentApprovalInfo.attachment1.id) {
        this.documentApprovalInfo.attachment1Id =
          this.documentApprovalInfo.attachment1.id;
      }
      if (this.documentApprovalInfo.attachment2.id) {
        this.documentApprovalInfo.attachment2Id =
          this.documentApprovalInfo.attachment2.id;
      }
      if (this.documentApprovalInfo.attachment3.id) {
        this.documentApprovalInfo.attachment3Id =
          this.documentApprovalInfo.attachment3.id;
      }


      if (this.documentApprovalInfo.attachment1.id === null) {
        this.documentApprovalInfo.attachment1Id = 0;
        this.documentApprovalInfo.attachment1.id = 0;
      }

      if (this.documentApprovalInfo.attachment2.id === null) {
        this.documentApprovalInfo.attachment2Id = 0;
        this.documentApprovalInfo.attachment2.id = 0;
      }

      if (this.documentApprovalInfo.attachment3.id === null) {
        this.documentApprovalInfo.attachment3Id = 0;
        this.documentApprovalInfo.attachment3.id = 0;
      }

      if (this.decisionOption) {
        this.documentApprovalInfo.optionNo = Number.parseInt(
          this.decisionOption
        );
      }

      let url = '/Project/SendReviseDocumentToResearcher';
      console.log("documentApprovalInfo", this.documentApprovalInfo);
      this.isshowdialogRecordTheResults = false;

      this.brokerAPIService
        .post(url, JSON.stringify(this.documentApprovalInfo))
        .subscribe(
          (values) => {
            this.unBlockScreen();
            this.result = values;
            if (this.result.success) {
              //  this.isshowdialogRecordTheResults = false;
              this.openSnackBar(this.result.message, this.closeSnackbarMessage);
              this.getProjectInfo(this.projectProposal.id);
              // this.btnBack();
            } else {
              this.openDialog(this.titleUnabelTosave, this.result.message);
            }
          },
          (error) => {
            this.unBlockScreen();
            this.openDialog('ไม่สามารถบันทึกข้อมูลได้', JSON.stringify(error));
          }
        );
    } else if (this.decisionAtCommiteeApproval == '3') {
      this.SubmitApproval(false);
    }
  }

  btnOpenApprovalDecision(isDecison: boolean) {
    this.isshowdialogRecordTheResults = true;
    this.decisionAtCommiteeApproval = '0';
    this.decisionOption = '0';
    this.isShowDecisionDetail = false;
    this.documentApprovalInfo = new revieweddocumentdecision();
    if (isDecison) {
      this.isConclusion = false;
      this.documentApprovalInfo.projectId = this.projectProposal.id;
      this.documentApprovalInfo.subject = '';
      this.documentApprovalInfo.message = '';
      this.approvedDate = new Date;
      this.RecordTheResults = '';
      this.conclusionMessage = '';

      this.approvedDate = new Date();
    } else {
      this.isConclusion = true;
      let url = "/Project/GetProjectConclusion/" + this.projectProposal.id;
      this.brokerAPIService.get(url).subscribe(
        values => {
          this.documentApprovalInfo = values;
          console.log('showconclusion', JSON.stringify(this.documentApprovalInfo));
          this.approvedDate = this.documentApprovalInfo.decisionDate;
          this.approvedDateText = 'zzzz';
          this.conclusionMessage = this.documentApprovalInfo.conclusionMessage.toString().replace('Arial','chulabhorn');

          console.log('showconclusion', this.approvedDate);
        }
      )
    }
    if (this.documentApprovalInfo.attachment1 == null) {
      this.documentApprovalInfo.attachment1 = new attachdocument();
    }
    if (this.documentApprovalInfo.attachment2 == null) {
      this.documentApprovalInfo.attachment2 = new attachdocument();
    }
    if (this.documentApprovalInfo.attachment3 == null) {
      this.documentApprovalInfo.attachment3 = new attachdocument();
    }
  }

  btnProjectStatusUpdate() {
    this.isshowdialogProcessStatus = true;
    this.projectStatusAfterApprove = 0;
  }

  btnCloseProjectStatusUpdate() {
    this.isshowdialogProcessStatus = false;
  }

  btnSubmitProjectStatusUpdate() {
    this.blockScreen();

    this.isshowdialogProcessStatus = false;
    //1=false จัดสรรงบประมา,2 = true ดำเนินการ
    let approveStatusData: any;
    if (this.projectStatusAfterApprove == 1) {
      approveStatusData = {
        projectId: this.projectProposal.id,
        isProjectStartProceed: false,
      };
    } else {
      approveStatusData = {
        projectId: this.projectProposal.id,
        isProjectStartProceed: true,
      };
    }
    let url = '/Project/SetApproveProjectStatus';
    this.brokerAPIService
      .post(url, JSON.stringify(approveStatusData))
      .subscribe(
        (values) => {
          this.result = values;
          this.unBlockScreen();
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);

            this.getProjectInfo(this.projectProposal.id);
            // this.btnBack();
          } else {
            this.openDialog(this.titleUnabelTosave, this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
        }
      );
  }

  btnProjectCancellation() {
    this.dialogCancelTitle = this.projectCancelBtnCaption;
    if (this.historyMode) {
      let url: string = '/Project/GetProjectCancel/' + this.projectProposal.id;
      this.brokerAPIService.get(url).subscribe((value) => {
        let projectCancellationInfo: projectcancellation = value;
        console.log('shoecancel', JSON.stringify(projectCancellationInfo));
        this.cancellationReason = projectCancellationInfo.cancelReason;
        // console.log(this.cancellationReason);

        this.cancellationDocument = projectCancellationInfo.cancelDocument;
      });
    } else {
      this.cancellationDocument = new attachdocument();
    }
    this.isshowdialogReasonForCancelingProject = true;
  }

  btnCloseProjectCancellation() {
    this.isshowdialogReasonForCancelingProject = false;
  }

  async handleFileCancellation(files: FileList) {
    if (files && files.length > 0) {
      let file: File = files.item(0);
      let attachDocument = new attachdocument();
      attachDocument.documentName = file.name;
      attachDocument.mimeType = file.type;
      const result = await this.getBase64(file).catch((e) => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        return;
      }
      attachDocument.documentContent = result.toString();
      this.PostFileCancellation(attachDocument);
    }
  }

  async PostFileCancellation(docToUpload: attachdocument) {
    console.log('attachment>>', JSON.stringify(docToUpload));
    // return;
    this.openSnackBar("กำลังบันทึกเอกสารแนบ", this.closeSnackbarMessage);
    this.brokerAPIService
      .post('/DocumentUpload/UploadDocument', JSON.stringify(docToUpload))
      .subscribe(
        (values) => {
          this.result = values;
          this.cancellationDocument = docToUpload;
          this.cancellationDocument.id = Number.parseInt(this.result.data);
        },
        (error) => {
          this.errorMessage = <any>error;
          console.log('errorMessage:' + JSON.stringify(this.errorMessage));
        }
      );
  }

  btnSubmitProjectCancellation() {
    this.blockScreen();
    this.isshowdialogReasonForCancelingProject = false;
    let projectCancellation = new projectcancellation();
    projectCancellation.projectId = this.projectProposal.id;
    if (this.cancellationDocument) {
      projectCancellation.cancelDocument = this.cancellationDocument;
      projectCancellation.cancelDocumentId = this.cancellationDocument.id;
    }
    projectCancellation.cancelReason = this.cancellationReason;
    let url = '/Project/CancelProject';
    this.brokerAPIService
      .post(url, JSON.stringify(projectCancellation))
      .subscribe(
        (values) => {
          this.result = values;
          this.unBlockScreen();
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
            this.getProjectInfo(this.projectProposal.id);
            // this.btnBack();
          } else {
            this.openDialog(this.titleUnabelTosave, this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
        }
      );
  }

  btnCloseApprovalDecision() {
    this.isshowdialogRecordTheResults = false;
    this.approvalRevisedMessage = new messagecontent();
  }

  onCommotteDecisionChange() {
    if (this.decisionAtCommiteeApproval == '1') {
      this.isShowApprovedDate = true;
      this.approveDateLabel = 'วันที่อนุมัติ';
      this.isShowDecisionDetail = false;
    } else if (this.decisionAtCommiteeApproval == '2') {
      this.isShowDecisionDetail = true;
      this.isShowApprovedDate = false;
    } else {
      this.isShowDecisionDetail = false;
      this.isShowApprovedDate = true;
      this.approveDateLabel = 'วันที่';
    }
  }

  onEditorKewardAndProblem(kewardAndProblem: string) {

    const HTMLInner = kewardAndProblem.replace(/<.*?>/g, '');
    this.lengthKeyWord = HTMLInner.length;

    if (this.lengthKeyWord > 9999) {

      this.lengthOverrayKeyword = this.lengthKeyWord - 10000;

    }

    // console.log(this.editorKewardAndProblem);

    if (this.editorKewardAndProblem == undefined) {
    } else if (this.editorKewardAndProblem != undefined) {
      this.isKeyworAndProblemdValid = true;
    }
  }

  btnCloseDialogKeyword() {
    this.isShowKeyWordAndProblrm = false;
  }

  onclickEditorKewardAndProblem(editorKewardAndProblem: string) {
    // console.log(editorKewardAndProblem);
    if (editorKewardAndProblem == '') {
      this.isKeyworAndProblemdValid = false;
    }
  }

  onEditorLiteratureReview(literatureReview) {
    // console.log(this.editorLiteratureReview);
    const HTMLInner = literatureReview.replace(/<.*?>/g, '');
    this.lengthLiteratureReview = HTMLInner.length;

    if (this.lengthLiteratureReview > 9999) {
      // this.isshowEditorRewordStatus = true;
      this.lengthOverrayLiteratureReview = this.lengthLiteratureReview - 10000;

    }

    if (this.editorLiteratureReview == undefined) {
    } else if (this.editorLiteratureReview != undefined) {
      this.isLiteratureReviewValid = true;
    }
  }

  btnCloseDialogLiteratureReview() {
    this.isShowLiteratureReview = false;
  }

  btnCloseDialogBudket() {
    this.isShowBudget = false;
  }

  onclickEditorLiteratureReview(editorLiteratureReview: string) {
    // console.log(editorLiteratureReview);
    if (editorLiteratureReview == '') {
      this.isLiteratureReviewValid = false;
    }
  }

  //end approval by committee
  // at Document Review By Admin
  btnShowDocumentReview() {
    // this.isDocumentReviewInfoReadOnly = !isNew;
    // if(isNew){
    this.documentReviewInfo = new revieweddocumentdecision();
    this.documentReviewInfo.projectId = this.projectProposal.id;
    this.documentReviewInfo.attachment1 = new attachdocument();
    this.documentReviewInfo.attachment2 = new attachdocument();
    this.documentReviewInfo.attachment3 = new attachdocument();
    this.reviewDecision = '0';
    this.isShowDecisionDetail = false;


    this.isshowdialogDocumentCheckRecordAdmin = true;
  }

  onReviewDecisionChange() {
    if (this.reviewDecision == '1') {
      this.isShowDecisionDetail = false;
    } else {
      this.isShowDecisionDetail = true;
    }
  }

  btnSendDocumentReview() {
    this.isshowdialogDocumentCheckRecordAdmin = false;
    this.documentReviewInfo.message = this.DocumentCheckRecord;
    if (this.reviewDecision == null) {
      this.openSnackBar('กรุณาระบุผลการประเมิน', this.closeSnackbarMessage);
    } else {
      this.submitReviewResult();
    }
  }

  btnCloseDocumentReview() {
    this.isshowdialogDocumentCheckRecordAdmin = false;
  }

  submitReviewResult() {
    this.blockScreen();
    let urlToPost: string = '';
    if (this.reviewDecision == '1') {
      urlToPost = '/Project/ReviewProjectDocumentComplete';
    } else {
      if (this.documentReviewInfo.attachment1.id) {
        this.documentReviewInfo.attachment1Id =
          this.documentReviewInfo.attachment1.id;
      }
      if (this.documentReviewInfo.attachment2.id) {
        this.documentReviewInfo.attachment2Id =
          this.documentReviewInfo.attachment2.id;
      }
      if (this.documentReviewInfo.attachment3.id) {
        this.documentReviewInfo.attachment3Id =
          this.documentReviewInfo.attachment3.id;
      }
      this.documentReviewInfo.attachment1 = null;
      this.documentReviewInfo.attachment2 = null;
      this.documentReviewInfo.attachment3 = null;
      urlToPost = '/Project/SendModDocumentMessageToResearcher';
    }

    this.brokerAPIService
      .post(urlToPost, JSON.stringify(this.documentReviewInfo))
      .subscribe(
        (values) => {
          this.unBlockScreen();
          this.result = values;
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
            this.getProjectInfo(this.projectProposal.id);
            // this.btnBack();
          } else {
            this.openDialog(this.titleUnabelTosave, this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
          console.log('docreview error:' + JSON.stringify(error));
        }
      );
  }

  // End at Document Review By Admin

  // At expert step
  btnShowExpertInformation() {
    this.isshowdialogExpertNotes = true;
    if (this.experts == null) {
      let url = '/Project/GetProjectSpecialists/' + this.projectProposal.id;
      this.brokerAPIService.get(url).subscribe(
        (values) => {
          this.experts = values;
          this.dataSourceExpert.data = this.experts;
        },
        (error) => { }
      );
    }
  }

  btnAddExpert() {
    let expertRow = new expert();
    expertRow.projectId = this.projectProposal.id;
    if (this.experts == null) {
      this.experts = [expertRow];
    } else {
      this.experts.push(expertRow);
    }
    this.dataSourceExpert.data = this.experts;
  }
  btnDeleteExpert(rowID: number) {
    this.experts.splice(rowID, 1);
    this.dataSourceExpert.data = this.experts;
  }

  btnSendForConsideration() {
    this.blockScreen();
    if (this.experts == null) {
      this.openSnackBar(
        'กรุณากรอกข้อมูลผู้เชี่ยวชาญ',
        this.closeSnackbarMessage
      );
    } else {
      let url = '/Project/SubmitProjectToApprove';
      let dataToSend = {
        projectId: this.projectProposal.id,
        currentProjectStatusId: this.projectProposal.projectStatusId,
      };
      this.brokerAPIService.post(url, JSON.stringify(dataToSend)).subscribe(
        (values) => {
          this.unBlockScreen();
          this.result = values;
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
            this.getProjectInfo(this.projectProposal.id);
            this.isshowdialogExpertNotes = false;
            // this.btnBack();
          } else {
            this.openDialog(this.titleUnabelTosave, this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
          this.openSnackBar(JSON.stringify(error), this.closeSnackbarMessage);
        }
      );
    }
  }

  btnSaveExpert() {
    this.blockScreen();
    let url = '/Project/SaveSpecialists';
    if (this.experts) {
      for (let i = 0; i < this.experts.length; i++) {
        this.experts[i].evaluateDate = offsetTimeZone(
          this.experts[i].evaluateDate
        );
        this.experts[i].receiveDocDate = offsetTimeZone(
          this.experts[i].receiveDocDate
        );
      }
    }
    this.brokerAPIService.post(url, JSON.stringify(this.experts)).subscribe(
      (values) => {
        this.unBlockScreen();
        this.result = values;
        if (this.result.success) {
          this.openSnackBar(this.result.message, this.closeSnackbarMessage);
        } else {
          this.openDialog(this.titleUnabelTosave, this.result.message);
        }
      },
      (error) => {
        this.unBlockScreen();
        this.openSnackBar(JSON.stringify(error), this.closeSnackbarMessage);
      }
    );
  }

  btnExpertDialogClose() {
    this.isshowdialogExpertNotes = false;
  }
  // end expert step
  //message box
  btnMessageDialogClose() {
    this.isshowdialogMessage = false;
  }

  btnMessageDialogOpen() {
    this.isshowdialogMessage = true;
  }

  btnMessageAttachmentOpen(attachmentNumber: number) {
    switch (attachmentNumber) {
      case 1: {
        if (this.messageContentData.attachment1Id) {
          this.viewAttachDocument(this.messageContentData.attachment1Id);
        }
        return;
      }
      case 2: {
        if (this.messageContentData.attachment2Id) {
          this.viewAttachDocument(this.messageContentData.attachment2Id);
        }
        return;
      }
      case 3: {
        if (this.messageContentData.attachment3Id) {
          this.viewAttachDocument(this.messageContentData.attachment3Id);
        }
        return;
      }
    }
  }

  getMessageList() {
    let url = '/Project/GetProjectMessageList/' + this.projectProposal.id;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.messageContents = values;
        if (this.messageContents) {
          this.messageCount = 0;
          for (let i = 0; i < this.messageContents.length; i++) {
            if (!this.messageContents[i].isRead) {
              this.messageCount++;
            }
          }
        }
        this.dataSourceMessage.data = this.messageContents;
      },
      (error) => { }
    );
  }

  onMessageRowClicked(dataRow: messagecontent) {
    this.isshowdialogMessageContent = true;
    let url = '/Project/GetProjectMessage/' + dataRow.id;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.messageContentData = values;
       this.messageContentData.message = this.messageContentData.message.toString().replace('Arial','chulabhorn');

        this.getMessageList();
      },
      (error) => { }
    );
  }

  onBtnMessageContentClose() {
    this.isshowdialogMessageContent = false;
  }

  onProjectTypeChange() {
    if (this.researchDescriptionTypeId == '5') {
      this.showTypeOtherInformation = true;
    } else {
      this.showTypeOtherInformation = false;
      this.projectProposal.otherResearchDescriptionType = '';
    }

    // console.log(this.researchDescriptionTypeId);

    // researchDescriptionTypeId
    if (this.researchDescriptionTypeId != undefined) {
      //  if (this.researchDescriptionTypeId != 0) {
      this.isResearcherTypeValid = true;
    } else {
      this.isResearcherTypeValid = false;
      // }
    }
  }

  onresearchSiteChange() {
    if (this.researchSiteHeadId == '1') {
      this.showMultipleSites = false;
      this.projectProposal.researchNationalSiteName = '';
      this.projectProposal.researchCoInternaltionalSiteName = '';
      this.projectProposal.isOnlyNationalSite = false;
      this.projectProposal.isCoInternationalSite = false;
    } else {
      this.showMultipleSites = true;
      this.projectProposal.researchSingleCenterName = '';
    }
    this.checkDataInTab();
  }

  resetForm() {
    this.projectProposal = new projectproposal();
    // พี่เก่งบอกว่าตอนSaveไม่ต้องส่งIDไปก็เลยเอาออก 20230104
    // this.projectProposal.id = 0;
    this.projectProposal.received = new received();
    this.projectProposal.received.projectId = this.projectProposal.id;
    this.projectProposal.researchDescriptionTypeId = 1;
    this.setFiscalYearSelection();
    this.fundingRequest = 0;
    this.getRequestGrants(this.fundingRequest, false);
    this.getOrganizationList();
  }

  getOrganizationList() {
    this.brokerAPIService
      .get('/Master/GetOrganizationList')
      .subscribe((values) => {
        let orgListObj: [organization] = values;
        if (orgListObj != null) {
          this.organizationDescList = new Map();
          for (let i = 0; i < orgListObj.length; i++) {
            this.organizationDescList.set(
              orgListObj[i].id,
              orgListObj[i].organizationName
            );
          }
        }
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  getOECDS() {
    let callMethod: string = '/Master/GetOECDList';
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.oecds = values;
      },
      (error) => (this.errorMessage = <any>error)
    );
  }

  onOECDSChanged() {
    this.getOECDSublists(this.projectProposal.oecdId);

    // console.log(this.projectProposal.oecdId);

    if (
      this.projectProposal.oecdId != null ||
      this.projectProposal.oecdSubId != null
    ) {
      this.isOecdValid = true;
      console.log('ASD');
    } else {
      this.isOecdValid = false;
    }
  }

  getOECDSublists(oecdsID: number) {
    let callMethod: string = '/Master/GetOECDsubList/' + oecdsID;
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.oecdsubs = values;
      },
      (error) => {
        this.errorMessage = <any>error;

        console.log('errorgetOECDSublists:' + JSON.stringify(this.oecdsubs));
      }
    );
  }

  onFundRequestChanged() {
    this.getRequestGrants(this.fundingRequest, true);
  }
  onFundRequestTypeChange() {
    this.fundingTypeRemark = '';
    this.fundingOther = '';
    // console.log("fundselecg",this.fundingTypeIDSelected+"<>"+this.fundingtypes[this.fundingTypeIDSelected].fundingName+"<>"+this.fundingtypes[this.fundingTypeIDSelected].isAttachFileOnly);
    this.isLongForm = this.checkIfLongForm(this.fundingTypeIDSelected);
    this.checkDataInTab();
  }

  checkIfLongForm(selectedID: number) {
    for (let i = 0; i < this.fundingtypes.length; i++) {
      if (selectedID == this.fundingtypes[i].id) {
        this.fundingTypeRemark = this.fundingtypes[i].fundingComment;

        this.isDetailBudgetNeeded = this.checkIfNeedBudgetDetail(this.fundingtypes[i].fundingType);

        this.checkDataInTab();
        return !this.fundingtypes[i].isAttachFileOnly;
      }
    }
    return true;
  }

  checkIfNeedBudgetDetail(typeCode: number) {
    return typeCode == 1;
  }

  getRequestGrants(fundRequestCode: number, fromChangeType: boolean) {
    let isRequest: boolean;
    if (fundRequestCode == 0) {
      isRequest = true;
    } else {
      isRequest = false;
    }
    let callMethod: string = '/Master/GetFundingList/' + isRequest;
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.fundingtypes = values;
        if (!this.fundingTypeIDSelected) {
          this.fundingTypeIDSelected = this.fundingtypes[0].id;
        } else if (fromChangeType) {
          this.fundingTypeIDSelected = this.fundingtypes[0].id;
        } else {
          this.fundingTypeIDSelected =
            this.projectProposal.budget.fundingTypeId;
        }
        this.isLongForm = this.checkIfLongForm(this.fundingTypeIDSelected);

        // this.checkDataInTab();
      },
      (error) => {
        this.errorMessage = <any>error;

        console.log('fundingtypes erro:' + this.errorMessage);
      }
    );
  }

  getResearcherType() {
    let callMethod: string = '/Master/GetResearcherType';
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.researcherTypeList = values;
        this.defaultresearchertype = this.researcherTypeList[0];
      },
      (error) => (this.errorMessage = <any>error)
    );
  }

  getCurrentResearcher() {
    let callMethod: string = '/Researcher/GetCurrentResearcher';
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.mainresearcher = values;
        let teamMemberRow: teammember = new teammember();
        if (this.appInfo['mode'] != 'edit' && this.mainresearcher.firstNameTH) {
          try {
            teamMemberRow.isIntenalResearch = true;
            teamMemberRow.researcherType = 1;
            teamMemberRow.researcherFullName =
              this.mainresearcher.prefixTH +
              ' ' +
              this.mainresearcher.firstNameTH +
              ' ' +
              this.mainresearcher.lastNameTH;
            teamMemberRow.nationalId = this.mainresearcher.idCardNo;
            teamMemberRow.eMail = this.mainresearcher.emailAddress;
            teamMemberRow.organizationId =
              this.mainresearcher.organizations[0].researcherOrgId;
            teamMemberRow.organizationName =
              this.mainresearcher.organizations[0].researcherOrg.organizationName;
            this.projectProposal.teamMembers = [teamMemberRow];

            if (this.projectProposal.teamMembers.length > 0) {
              this.dataSourceResearchTeam.data = sortingArrayList(
                this.projectProposal.teamMembers,
                "researcherType",
                "asc"
              )
              this.dataSourceResearchTeam.data = sortingArrayList(
                this.dataSourceResearchTeam.data,
                "id",
                "asc"
              )

            }

            this.checkDataInTab();
          } catch (error) {
            console.log('init:', error);
          }
        }
      },
      (error) => (this.errorMessage = <any>error)
    );
  }

  getProjectInfo(projectID: number) {
    let callMethod: string = '/Project/GetProject/' + projectID;
    this.isSubmitable = false;
    this.historyMode = false;

    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        this.projectProposal = values;
        console.log('projectData', JSON.stringify(this.projectProposal));
        if (this.projectProposal.ecStatus) {
          switch (this.projectProposal.ecStatus) {
            case (1): {
              this.ecApprove = 'ไม่กี่ยวข้อง';
              break;
            }
            case (2): {
              this.ecApprove = 'อยู่ระหว่างการยื่นขอ';
              break;
            }
            case (3): {
              this.ecApprove = 'ผ่านการอนุมัติ';
              break;
            }
          }
        }
        this.resetMenu();
        // ยังไม่ขึ้น CR Phase2
        // this.getUserRole();
        if (this.projectProposal.projectStatusId == 2 ||
          this.projectProposal.projectStatusId == 12 ||
          (this.projectProposal.projectStatusId == 5 && (this.projectProposal.nextStatusId == 6 || this.projectProposal.nextStatusId == 4 || this.projectProposal.nextStatusId == 3))) {
          this.isSubmitable = true;
        } else
          if (
            this.projectProposal.projectStatusId == 7 ||
            this.projectProposal.projectStatusId == 11 ||
            this.projectProposal.projectStatusId == 13
          ) {
            this.historyMode = true;
          }
        if (this.projectProposal.projectStatus.statusNameAdmin) {
          this.screenTitle =
            'โครงการวิจัย' +
            ':' +
            this.projectProposal.projectStatus.statusNameAdmin;
        } else {
          this.screenTitle = 'สร้างคำขอเสนอโครงการวิจัยใหม่';
        }
        let callMethod: string =
          '/Auth/IsEditableProject/' + this.projectProposal.id;
        this.brokerAPIService.get(callMethod).subscribe(
          (values) => {
            this.projectProposalControl = values;
            this.isEditable = this.projectProposalControl.isEditable;

            // ยังไม่ขึ้น CR Phase2
            // console.log("isEditable", this.isEditable);

            // if (this.isEditable == true) {
            //   this.checkReadonlyInAttachment = true;
            //   this.CheckRemoveAttachment = true;
            // } else {
            //   this.checkReadonlyInAttachment = false;
            //   this.CheckRemoveAttachment = false;
            // }

            this.isShowingMenu = false;
            if (this.projectProposalControl.dropdownMenu.length > 0) {
              for (
                let i = 0;
                i < this.projectProposalControl.dropdownMenu.length;
                i++
              ) {
                if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'ReviewDocument'
                ) {
                  this.isAtDocumentReviewStep = true;
                  this.documentReviewCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'AddSpecialist'
                ) {
                  this.isAddSpecialistStep = true;
                  this.specialistBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isAddSpecialistStepSubmitEnable =
                    this.projectProposalControl.dropdownMenu[
                      i
                    ].isEnableSubmitButton;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'ApproveProject'
                ) {
                  this.isApprovingStep = true;
                  this.projectReviewBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'MessageBox'
                ) {
                  this.isShowingMessageButton = true;
                  this.messageBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'ProjectBudget'
                ) {
                  this.isShowBudgetBtn = true;
                  this.budgetBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'ProjectProgressEntry'
                ) {
                  this.isShowingProgressBtn = true;
                  this.progressBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName == 'ProjectCancel'
                ) {
                  this.isShowingProjectCancel = true;
                  this.projectCancelBtnCaption = this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                  this.isShowMenuDivider = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'UpdateApproveStatus'
                ) {
                  this.isShowingProjectStatusUpdate = true;
                  this.projectStatusUpdateBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'ProjectProgressView'
                ) {
                  this.isShowingProgressReviewBtn = true;
                  this.progressReviewBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (
                  this.projectProposalControl.dropdownMenu[i].linkName ==
                  'PublishedWorkEntry'
                ) {
                  this.isShowingPublicationButton = true;
                  this.publicationBtnCaption =
                    this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (this.projectProposalControl.dropdownMenu[i].linkName == 'PrintProposal') {
                  this.isShowingPrintProposalButton = true;
                  this.printProposalCaption = this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (this.projectProposalControl.dropdownMenu[i].linkName == 'ProjectConclusion') {
                  this.isShowingConslusion = true;
                  this.conclusionCaption = this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                } else if (this.projectProposalControl.dropdownMenu[i].linkName == 'ChangeProjectOwner') {
                  this.isShowChangeProjectOwner = true;
                  this.changeProjectOwnerCaption = this.projectProposalControl.dropdownMenu[i].caption;
                  this.isShowingMenu = true;
                  this.isShowMenuDivider = true;
                }
              }
            }
            this.options = Object.assign({}, this.options, {
              readOnly: !this.isEditable,
            }); // set slider disable
          },
          (error) => {
            // this.isShowingMenu = false;
          }
        );
        if (this.projectProposal.received == null) {
          this.projectProposal.received = new received();
        }
        if (this.projectProposal.teamMembers.length > 0) {

          this.projectProposal.teamMembers = sortingArrayList(
            this.projectProposal.teamMembers,
            "researcherType",
            "asc"
          )
           // ยังไม่ขึ้น CR Phase2
          // this.setDecimalInTeamMenber(this.projectProposal.teamMembers);
          this.dataSourceResearchTeam.data = sortingArrayList(
            this.projectProposal.teamMembers,
            "id",
            "asc"
          )

          // this.dataSourceResearchTeam.data = this.projectProposal.teamMembers;
        }
        this.dataSourceObjective.data = this.projectProposal.objectives;
        this.dataSourceKeyword.data = this.projectProposal.keywords;

        if (this.projectProposal.researchSiteHeadId != null) {
          this.researchSiteHeadId =
            this.projectProposal.researchSiteHeadId.toString();
          if (this.researchSiteHeadId == '1') {
            this.showMultipleSites = false;
          } else {
            this.showMultipleSites = true;
          }
        }
        if (
          this.projectProposal.timeLine != null &&
          this.projectProposal.timeLine.timeLineDetails != null
        ) {

          if (this.projectProposal.timeLine.timeLineDetails.length > 0) {

            // this.dataSourceProcessingTime.data = sortingArrayList(
            //   this.projectProposal.timeLine.timeLineDetails,
            //   'fiscalYear',
            //   'asc'
            // )

            // this.dataSourceProcessingTime.data = sortingArrayList(
            //   this.dataSourceProcessingTime.data,
            //   'fromMonth',
            //   'asc'
            // )

            this.dataSourceProcessingTime.data = sortingArrayList(
              this.projectProposal.timeLine.timeLineDetails,
              'fromMonth',
              'asc'
            )

            this.dataSourceProcessingTime.data = sortingArrayList(
              this.dataSourceProcessingTime.data,
              'fiscalYear',
              'asc'
            )


            //this.dataSourceProcessingTime.data = this.projectProposal.timeLine.timeLineDetails;

          }

        }
        if (this.projectProposal.expectedResults.length > 0) {
          this.dataSourceExpectedResults.data = sortingArrayList(
            this.projectProposal.expectedResults,
            'fromMonth',
            'asc'
          );

          //this.dataSourceExpectedResults.data = this.projectProposal.expectedResults;
        }
        if (
          this.projectProposal.budget != null && this.projectProposal.budget.budgetDetails != null) {
          // this.dataSourceBudget.data = this.projectProposal.budget.budgetDetails;
          this.setDecimalInGrid(this.projectProposal.budget.budgetDetails);

          this.fundingTypeName = this.projectProposal.budget.fundingType.fundingName;
        }
        this.dataSourceReasonforPurchasingEquipment.data =
          this.projectProposal.durableArticles;
        this.dataSourceReferenceDocuments.data =
          this.projectProposal.referenceDocuments;
        this.dataSourceExpectedBenefits.data =
          this.projectProposal.expectedBenefits;
        if (this.projectProposal.expectedResults.length > 0) {
          this.dataSourceExpectedResults.data = sortingArrayList(
            this.projectProposal.expectedResults,
            'fromMonth',
            'asc'
          );
          // this.dataSourceExpectedResults.data = this.projectProposal.expectedResults;
        }
        this.dataSourceExpectedImpact.data =
          this.projectProposal.expectedImpacts;
        this.dataSourceAttachment.data = this.projectProposal.attachDocuments;

        this.editorKewardAndProblem = this.projectProposal.importantOfProblem;
        this.editorLiteratureReview = this.projectProposal.literatureReview;
        if (this.projectProposal.budget != null) {
          if (this.projectProposal.budget.fundingType.isRequestGrants) {
            this.fundingRequest = 0;
          } else {
            this.fundingRequest = 1;
          }
          this.onFundRequestChanged();
          this.onFundRequestTypeChange();
          this.getRequestGrants(this.fundingRequest, false);
          this.fundingTypeIDSelected =
            this.projectProposal.budget.fundingTypeId;
          // this.fundingTypeRemark =  this.projectProposal.budget.fundingType.fundingComment;

          this.fundingTotal = this.projectProposal.budget.totalProjectBudget;
          this.onBudgetTotalChange();
          this.onBudgetInRowChange();
          this.getMessageList();
          this.fundingOther = this.projectProposal.budget.noGrantsOther;
        }
        if (this.projectProposal.received != null) {
          if (this.projectProposal.received.tciTier != null) {
            this.tciTier = this.projectProposal.received.tciTier.toString();
          }
          if (
            this.projectProposal.received.journalInternationalDatabase != null
          ) {
            this.journalInternationalDatabase =
              this.projectProposal.received.journalInternationalDatabase.toString();
          }
          if (this.projectProposal.received.quartile != null) {
            this.quartile = this.projectProposal.received.quartile.toString();
          }
        }

        if (this.projectProposal.researchDescriptionTypeId != null) {
          this.researchDescriptionTypeId =
            this.projectProposal.researchDescriptionTypeId.toString();
          if (this.researchDescriptionTypeId == '5') {
            this.showTypeOtherInformation = true;
          } else {
            this.showTypeOtherInformation = false;
          }
        }

        if (this.projectProposal.timeLine != null) {
          this.totalTime = this.projectProposal.timeLine.totalTimeLineInMonth;
        }
        if (this.projectProposal.oecdId != null) {
          this.getOECDSublists(this.projectProposal.oecdId);
        }
        this.projectProposalOldData = JSON.stringify(this.projectProposal);
        this.dataSourceResearchMethodology.data =
          this.projectProposal.researchMethods;
        this.checkDataInTab();
        // ยังไม่ขึ้น CR Phase2
        // this.getUserRole();
      },
      (error) => {
        this.errorMessage = <any>error;

        console.log('projectinfoerror:' + JSON.stringify(this.errorMessage));
      }
    );
  }


  setDecimalInGrid(budget: any) {

    for (let i = 0; i < budget.length; i++) {

      let originalNumber = budget[i].budget;
      let formattedNumber: string = originalNumber.toFixed(2);
      budget[i].budget = formattedNumber;

    }

    this.dataSourceBudget.data = budget;

  }

  // ยังไม่ขึ้น CR Phase2
  // setDecimalInTeamMenber(teammember: any) {

  //   for (let i = 0; i < teammember.length; i++) {

  //     let originalNumber = teammember[i].percentAssociate;
  //     let formattedNumber: string = originalNumber.toFixed(2);
  //     teammember[i].percentAssociate = formattedNumber;

  //   }

  //   this.dataSourceResearchTeam.data = teammember;

  // }

  setResearchMethodologyObject() {
    if (
      this.projectProposal.researchMethods == null ||
      this.projectProposal.researchMethods.length < 1
    ) {
      for (let i = 0; i < this.researchMethodologyList.length; i++) {
        let researchMethodRow = new researchmethod();
        researchMethodRow.projectId = this.projectProposal.id;
        researchMethodRow.orderNo = this.researchMethodologyList[i].orderNo;
        researchMethodRow.researchMethodType =
          this.researchMethodologyList[i].description;
        if (i == 0) {
          this.projectProposal.researchMethods = [researchMethodRow];
        } else {
          this.projectProposal.researchMethods.push(researchMethodRow);
        }
      }
      console.log(
        'showmethod',
        JSON.stringify(this.projectProposal.researchMethods)
      );
    }
    this.dataSourceResearchMethodology.data =
      this.projectProposal.researchMethods;
  }

  setResearchImpactObject() {
    if (
      this.projectProposal.expectedImpacts == null ||
      this.projectProposal.expectedImpacts.length < 1
    ) {
      for (let i = 0; i < this.expectedImpactList.length; i++) {
        let expectedImpactRow = new expectedimpact();
        expectedImpactRow.projectId = this.projectProposal.id;
        expectedImpactRow.orderNo = this.expectedImpactList[i].orderNo;
        expectedImpactRow.expectedImpact =
          this.expectedImpactList[i].description;
        expectedImpactRow.canBeUtilized = false;
        if (i == 0) {
          this.projectProposal.expectedImpacts = [expectedImpactRow];
        } else {
          this.projectProposal.expectedImpacts.push(expectedImpactRow);
        }
      }
    }
    this.dataSourceExpectedImpact.data = this.projectProposal.expectedImpacts;
  }

  onBudgetTotalChange() {
    if (this.fundingTotal == null) {
      this.bahtThaiBudget = '';
      return;
    }

    let moneyToConvert: string = this.transformAmount(this.fundingTotal);
    let moneySplit = moneyToConvert.split('.');



    if (moneySplit[0].length > 13) {
      this.openSnackBar(
        'ไม่สามารถแปลงเงินเกิน 13 หลักได้',
        this.closeSnackbarMessage
      );

    }


    // if (moneySplit[0].length > 13) {
    //   this.openSnackBar(
    //     'ไม่สามารถแปลงเงินเกิน 13 หลักได้',
    //     this.closeSnackbarMessage
    //   );
    //   // this.fundingTotal = Number.parseFloat(moneyToConvert.substring(0, 12));
    // } else {
    let decimal: string = '';
    let exceedMillion: string = '';
    let underMillion: string = '';
    let isExceedMillion: boolean = false;
    if (moneySplit[0].length > 7) {
      exceedMillion = this.convertMoneyToThaiDescription(
        Number.parseInt(
          moneySplit[0].substring(0, moneySplit[0].length - 7).replace(',', '').replace(',', '').replace(',', '')
        ),
        false
      );

      this.keepsExceedMillion = exceedMillion;

      // console.log("exceedMillion", exceedMillion);

      isExceedMillion = true;
    }
    if (moneySplit[1].length.toString() != '00') {
      decimal = this.convertMoneyToThaiDescription(
        Number.parseInt(
          moneySplit[1].substring(0, moneySplit[1].length)
        ),
        false
      );
    }

    underMillion = this.convertMoneyToThaiDescription(
      Number.parseInt(moneySplit[0].substring(moneySplit[0].length - 7).replace(',', '')),
      isExceedMillion
    );

    // console.log("underMillion", underMillion);


    let strDecimal: string;
    if (moneySplit[1].toString() != '00' && moneySplit[1].toString() != '') {
      strDecimal = 'สตางค์';
    } else {
      strDecimal = '';
    }


    if (isExceedMillion) {
      let strMillion: string;
      var str1 = new String(exceedMillion);
      var index = str1.indexOf("ล้าน");
      if (index != -1) {
        strMillion = "";

        if (moneySplit[0].length > 12) {
          //กรณีมีตัวเลขมากกว่า 12 หลักให้ เพิ่ม เป็น ล้านล้าน
          const subExceedMillion = exceedMillion.split('ล้าน');
          exceedMillion = "";
          exceedMillion = subExceedMillion[0] + "ล้านล้าน" + subExceedMillion[1]
          // console.log("Length > 12", exceedMillion);
        }

      } else {
        var str2 = new String(underMillion);
        var index2 = str2.indexOf("ล้าน");

        if (index2 != -1) {
          strMillion = "";
        } else if (exceedMillion != "") {
          strMillion = "ล้าน";
        } else {
          strMillion = "";
        }

      }

      this.bahtThaiBudget = exceedMillion + strMillion + underMillion + 'บาท' + decimal + strDecimal + 'ถ้วน';

    } else {
      this.bahtThaiBudget = underMillion + 'บาท' + decimal + strDecimal + 'ถ้วน';
    }
    // }
  }

  // convertMoneyToThaiDescription(amount: number, exceed: boolean): string {
  //   let txtNumArr = [
  //     'ศูนย์',
  //     'หนึ่ง',
  //     'สอง',
  //     'สาม',
  //     'สี่',
  //     'ห้า',
  //     'หก',
  //     'เจ็ด',
  //     'แปด',
  //     'เก้า',
  //     'สิบ',
  //   ];
  //   let txtDigitArr = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];
  //   let bahtText: string = '';
  //   if (amount == null) {
  //     return;
  //   }
  //   let moneyString: string = amount.toString();
  //   let moneyLength = moneyString.length;

  //   for (let i = 0; i < moneyLength; i++) {
  //     let tmp = Number.parseInt(moneyString.substring(i, i + 1));
  //     console.log("tmp",tmp);

  //     if (tmp != 0) {
  //       // if ((i == (moneyLength - 1)) && (tmp == 1))
  //       if (
  //         (i == moneyLength - 1 || (i == moneyLength - 7 && exceed)) &&
  //         tmp == 1
  //       ) {

  //         bahtText += 'หนึ่ง';

  //         // bahtText += 'เอ็ด';
  //       } else if (i == moneyLength - 2 && tmp == 2) {
  //         bahtText += 'ยี่';
  //       } else if (i == moneyLength - 2 && tmp == 1) {
  //         bahtText += '';
  //       } else {
  //         bahtText += txtNumArr[tmp];
  //         console.log(i,":",tmp);

  //       }
  //       //Length 9
  //       bahtText += txtDigitArr[moneyLength - i - 1];
  //       console.log(i,":",moneyLength - i - 1);

  //     }
  //   }
  //   return bahtText;
  // }

  convertMoneyToThaiDescription(amount: number, exceed: boolean): string {
    let txtNumArr = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า', 'สิบ',];
    let txtDigitArr = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];
    let bahtText: string = '';
    if (amount == null) {
      return;
    }


    // console.log("amontBeforeConvert",amount);


    let moneyString: string = amount.toString();
    let moneyLength = moneyString.length;

    // console.log("moneyString", moneyString, "exceed", exceed);

    for (let i = 0; i < moneyLength; i++) {
      //แยกตัวเลขออกเป็นที่ละตัวต่อ1รอบ
      let tmp = Number.parseInt(moneyString.substring(i, i + 1));

      if (tmp != 0) {
        if (
          (i == moneyLength - 1 || (i == moneyLength - 7 && exceed)) && tmp == 1) {
          console.log("CheckBahtText ===> ", bahtText);

          var str1 = new String(bahtText);
          var index1 = str1.indexOf("สิบ");
          console.log("index1", index1);


          var str2 = new String(this.keepsExceedMillion);
          var index2 = str2.indexOf("สิบ");
          console.log("this.keepsExceedMillion", this.keepsExceedMillion);
          console.log("index2", index2, "exceed", exceed, "i", i);
          console.log(index2 != -1 && exceed == true && i == 0);

          //จำแนกบาทกับเอ็ด(สตางค์ กับ หลักสิบ)
          if (index1 != -1) {
            bahtText += 'เอ็ด';
            console.log("เอ็ดที่1");

          } else if (index2 != -1 && exceed == false && i == 0) {
            //จำแนกบาทกับเอ็ด(หลักล้าน)
            // console.log("เอ็ดล้าน");
            bahtText += 'เอ็ด';
            console.log("เอ็ดที่2");
          } else {
            bahtText += 'หนึ่ง';
          }

        } else if (i == moneyLength - 2 && tmp == 2) {
          bahtText += 'ยี่';
        } else if (i == moneyLength - 2 && tmp == 1) {
          bahtText += '';
        } else {
          bahtText += txtNumArr[tmp];
        }
        bahtText += txtDigitArr[moneyLength - i - 1];

      }
    }
    return bahtText;
  }

  onBudgetInRowChange() {
    let totalAmount: number = 0;
    let totalAmountByYear: number = 0;
    // let groupFiscalYear: number;
    this.budgetDescriptionByYear = '';
    let sumMap: Map<string, number> = new Map();
    if (this.projectProposal.budget.budgetDetails) {
      // groupFiscalYear = this.projectProposal.budget.budgetDetails[0].fiscalYear;
      for (let i = 0; i < this.projectProposal.budget.budgetDetails.length; i++) {
        let tempBudget = this.projectProposal.budget.budgetDetails[i].budget.toString();
        totalAmount = totalAmount + Number.parseFloat(tempBudget);
        if (
          sumMap.get(
            this.projectProposal.budget.budgetDetails[i].fiscalYear.toString()
          )
        ) {
          //let sumAmount: number = sumMap.get(this.projectProposal.budget.budgetDetails[0].fiscalYear.toString()) + Number.parseFloat(tempBudget);;
          let sumAmount: number = sumMap.get(this.projectProposal.budget.budgetDetails[i].fiscalYear.toString()) + Number.parseFloat(tempBudget);;
          sumMap.set(this.projectProposal.budget.budgetDetails[i].fiscalYear.toString(), sumAmount);
        } else {
          sumMap.set(this.projectProposal.budget.budgetDetails[i].fiscalYear.toString(), Number.parseFloat(tempBudget));
        }
      }
      // this.forcastedBudget = totalAmount.toString();
      this.fundingTotal = totalAmount;
      this.fundingTotalDisplay = this.transformAmount(this.fundingTotal);
      this.onBudgetTotalChange();
      sumMap.forEach((value: number, key: string) => {
        if (this.budgetDescriptionByYear) {
          this.budgetDescriptionByYear +=
            ' , ' + key + ':' + this.transformAmount(value);
        } else {
          this.budgetDescriptionByYear =
            key + ':' + this.transformAmount(value);
        }
      });
      // for(let keyname in sumMap.keys()){
      //   this.budgetDescriptionByYear =  this.budgetDescriptionByYear+keyname + ":"+ sumMap.get(keyname)+",";
      //   console.log("loopin",keyname,sumMap.get(keyname));
      // }
    }
    // this.sumBudgetByFiscalYear();
  }

  transformAmount(Amount: number): string {
    return this.currencyPipe.transform(Amount, '฿').replace('฿', '');
  }

  transformAmountHTMLInput(element, Amount: number) {
    element.target.value = this.currencyPipe
      .transform(Amount, '฿')
      .replace('฿', '');
  }

  onResearcherNameInput(rowID: number, searchName: string) {
    if (this.projectProposal.teamMembers[rowID].isIntenalResearch) {
      if (searchName.length == 3) {
        this.getResearchlistByName(searchName);
      } else if (searchName.length < 3) {
        this.researcherSelectionList = [];
      }
      this._filter(searchName);
    }
  }

  onProjectOwnerInput(searchName: string) {
    if (searchName.length == 3) {
      this.getResearchlistByName(searchName);
    } else if (searchName.length < 3) {
      this.researcherSelectionList = [];
    }
    this._filter(searchName);
  }

  private _filter(value: string): any[] {
    if (value.length > 3) {
      const filterValue = value.toLowerCase();
      return Array.from(this.researcherSelectionList.values()).filter(
        (option) =>
          option['nameTH'].toLowerCase().includes(filterValue) ||
          option['nameEN'].toLowerCase().includes(filterValue)
      );
    } else {
      this.researcherSelectionList = [];
    }
  }

  onResearcherNameselected(rowID: number, fullName: string) {
    if (
      this.researcherSelectionList != null &&
      this.researcherSelectionList.length > 0
    ) {
      for (let i = 0; i < this.researcherSelectionList.length; i++) {
        if (this.researcherSelectionList[i]['nameTH'] == fullName) {
          this.projectProposal.teamMembers[rowID].eMail =
            this.researcherSelectionList[i]['email'] ? this.researcherSelectionList[i]['email'] : '';
          this.projectProposal.teamMembers[rowID].organizationId =
            this.researcherSelectionList[i]['orgID'];
          this.projectProposal.teamMembers[rowID].organizationName =
            this.organizationDescList.get(
              this.researcherSelectionList[i]['orgID']
            );
          this.projectProposal.teamMembers[rowID].nationalId =
            this.researcherSelectionList[i]['nid'];
        }
      }
    }
  }

  getResearchlistByName(fullname: string) {
    let callMethod: string =
      '/Researcher/SearchResearcherByFullName/' + fullname;
    this.brokerAPIService.get(callMethod).subscribe(
      (values) => {
        let researcherListObj: [researcher] = values;
        this.researcherSelectionList = [];
        for (let i = 0; i < researcherListObj.length; i++) {
          if (i == 0) {
            this.researcherSelectionList = [
              {
                id: researcherListObj[i].id,
                nameTH:
                  researcherListObj[i].prefixTH +
                  ' ' +
                  researcherListObj[i].firstNameTH +
                  ' ' +
                  researcherListObj[i].lastNameTH,
                nameEN:
                  researcherListObj[i].prefixEN +
                  ' ' +
                  researcherListObj[i].firstNameEN +
                  ' ' +
                  researcherListObj[i].lastNameEN,
                orgID: researcherListObj[i].organizations[0].researcherOrgId,
                nid: researcherListObj[i].idCardNo,
                email: researcherListObj[i].emailAddress,
              },
            ];
          } else {
            this.researcherSelectionList.push({
              id: researcherListObj[i].id,
              nameTH:
                researcherListObj[i].prefixTH +
                ' ' +
                researcherListObj[i].firstNameTH +
                ' ' +
                researcherListObj[i].lastNameTH,
              nameEN:
                researcherListObj[i].prefixEN +
                ' ' +
                researcherListObj[i].firstNameEN +
                ' ' +
                researcherListObj[i].lastNameEN,
              orgID: researcherListObj[i].organizations[0].researcherOrgId,
              nid: researcherListObj[i].idCardNo,
              email: researcherListObj[i].emailAddress,
            });
          }
        }

        console.log(
          'showlistname',
          JSON.stringify(this.researcherSelectionList)
        );
      },
      (error) => {
        this.errorMessage = <any>error;
        console.log('searchname error>' + JSON.stringify(this.errorMessage));
      }
    );
  }

  transformResearchOrgType(isInternal: boolean): string {
    if (isInternal) {
      return 'Internal';
    } else {
      return 'External';
    }
  }

  transformResearchType(typeID: number): string {
    if (this.researcherTypeList != null) {
      for (let i = 0; i < this.researcherTypeList.length; i++) {
        if (this.researcherTypeList[i].id == typeID) {
          return this.researcherTypeList[i].researcherTypeName;
        }
      }
    }
    return typeID + ' not found';
  }

  onTrackingClicked() {
    let dataToSend: any = {
      projectid: this.projectProposal.id,
      budgetTypr: this.projectProposal.budget.fundingType.fundingType
    };
    this.router.navigate(['/projectprogress', JSON.stringify(dataToSend)]);
  }

  onBudgetAllocationClicked() {
    this.getProjectBudgetAllocation(this.projectProposal.id);
    this.showBudgetAllocation = true;
    this.showPublication = false;
  }

  onBackToMain() {
    this.showBudgetAllocation = false;
    this.showPublication = false;
  }

  onPublicationClicked() {
    this.showBudgetAllocation = false;
    this.showPublication = true;
    this.getProjectPublication();
  }

  getProjectBudgetAllocation(projectID: number) {
    let url: string = '/Project/GetBudgetAllocation/' + projectID;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.projectBudgetAllocation = values;

        console.log('allocation', JSON.stringify(this.projectBudgetAllocation));
        this.dataSourceSourceOfFund.data =
          this.projectBudgetAllocation.budgetReceiveds;
        this.dataSourceBudgetTerm.data =
          this.projectBudgetAllocation.budgetTerms;
        this.dataSourceBudgetAllocation.data = sortingArrayList(
          this.projectBudgetAllocation.budgetReceivedDetails,
          'fiscalYear',
          'ASC'
        );
        this.totalProjectMonth = this.projectBudgetAllocation.totalProjectMonth;
        this.totalFundAllocation = this.projectBudgetAllocation.totalProjectBudget;
        this.onFundingInRowChange();
        this.onPaymentTermAmountRowChange();
        this.onFundAllocartionTotalChange();
        this.budgetPaymentTerm = this.projectBudgetAllocation.budgetTerms.length;
        if (this.projectBudgetAllocation.budgetReceivedDetails) {
          this.onBudgetAllocationInRowChange();
        }
      },
      (error) => { }
    );
  }

  btnAddFunding() {
    let fundingRow: budgetreceived = new budgetreceived();
    fundingRow.projectTrackingBudgetHeadId = this.projectBudgetAllocation.id;
    fundingRow.budgetReceived = 0;
    if (this.projectBudgetAllocation.budgetReceiveds) {
      this.projectBudgetAllocation.budgetReceiveds.push(fundingRow);
    } else {
      this.projectBudgetAllocation.budgetReceiveds = [fundingRow];
    }
    this.dataSourceSourceOfFund.data =
      this.projectBudgetAllocation.budgetReceiveds;
  }
  btnDeleteFunding(rowid: number) {
    this.projectBudgetAllocation.budgetReceiveds.splice(rowid, 1);
    this.dataSourceSourceOfFund.data =
      this.projectBudgetAllocation.budgetReceiveds;
    this.onFundingInRowChange();
  }

  onFundingInRowChange() {
    let totalAmount: number = 0;
    if (!this.projectBudgetAllocation.budgetReceiveds) {
      return;
    }
    for (
      let i = 0;
      i < this.projectBudgetAllocation.budgetReceiveds.length;
      i++
    ) {
      let tempbudgetReceived: string = this.projectBudgetAllocation.budgetReceiveds[i].budgetReceived.toString();
      totalAmount += Number.parseFloat(tempbudgetReceived);
    }

    this.projectFundTotal = this.transformAmount(totalAmount);
  }

  btnAddPaymentTerm() {
    let paymentTermRow: budgetterm = new budgetterm();
    paymentTermRow.projectTrackingBudgetHeadId =
      this.projectBudgetAllocation.id;
    if (this.projectBudgetAllocation.budgetTerms) {
      this.projectBudgetAllocation.budgetTerms.push(paymentTermRow);
    } else {
      this.projectBudgetAllocation.budgetTerms = [paymentTermRow];
    }
    this.dataSourceBudgetTerm.data = this.projectBudgetAllocation.budgetTerms;
    this.budgetPaymentTerm = this.projectBudgetAllocation.budgetTerms.length;
  }

  btnDeletePaymentTerm(rowid: number) {
    this.projectBudgetAllocation.budgetTerms.splice(rowid, 1);
    this.dataSourceBudgetTerm.data = this.projectBudgetAllocation.budgetTerms;
    this.budgetPaymentTerm = this.projectBudgetAllocation.budgetTerms.length;
    this.onPaymentTermAmountRowChange();
  }

  onPaymentTermAmountRowChange() {
    let totalAmount: number = 0;
    for (let i = 0; i < this.projectBudgetAllocation.budgetTerms.length; i++) {
      let tempbudgetReceived: string = this.projectBudgetAllocation.budgetTerms[i].budgetReceived.toString();
      totalAmount += Number.parseFloat(tempbudgetReceived);
    }

    this.projectPaymentByTermTotal = this.transformAmount(totalAmount);
  }

  btnSaveBudgetAllocation() {
    this.blockScreen();
    this.projectBudgetAllocation.totalProjectBudget = this.totalFundAllocation;

    let url: string = '/Project/SaveBudgetAllocation';
    console.log('budgetallo', JSON.stringify(this.projectBudgetAllocation));
    this.brokerAPIService
      .post(url, JSON.stringify(this.projectBudgetAllocation))
      .subscribe(
        (values) => {
          this.unBlockScreen();
          this.result = values;
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          } else {
            this.openDialog(this.titleUnabelTosave, this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
        }
      );
  }

  onFundAllocartionTotalChange() {
    if (this.totalFundAllocation == null) {
      this.projectFundInThai = '';
      return;
    }
    let moneyToConvert: string = this.totalFundAllocation.toString();
    let moneySplit = moneyToConvert.split('.');
    if (moneySplit[0].length > 13) {
      this.openSnackBar(
        'ไม่สามารถแปลงเงินเกิน 13 หลักได้',
        this.closeSnackbarMessage
      );
      this.totalFundAllocation = Number.parseFloat(
        moneyToConvert.substring(0, 12)
      );
    } else {
      let exceedMillion: string = '';
      let underMillion: string = '';
      let isExceedMillion: boolean = false;
      if (moneySplit[0].length > 7) {
        exceedMillion = this.convertMoneyToThaiDescription(
          Number.parseInt(
            moneySplit[0].substring(0, moneySplit[0].length - 7) + 0
          ),
          false
        );
        isExceedMillion = true;
      }
      underMillion = this.convertMoneyToThaiDescription(
        Number.parseInt(moneySplit[0].substring(moneySplit[0].length - 7)),
        isExceedMillion
      );
      if (isExceedMillion) {
        this.projectFundInThai =
          exceedMillion + 'ล้าน' + underMillion + 'บาทถ้วน';
      } else {
        this.projectFundInThai = underMillion + 'บาทถ้วน';
      }
    }
  }
  reOrder(dataObj: any) {
    if (dataObj > 0) {
      for (let i = 0; i < dataObj.length; i++) {
        dataObj[i]['orderNo'] = i;
      }
      return dataObj;
    }
  }

  sortAllocationBugerYear() { }

  blockScreen() {
    this.saveInProgress = true;
    document.getElementById('overlay').style.display = 'block';
  }

  unBlockScreen() {
    this.saveInProgress = false;
    document.getElementById('overlay').style.display = 'none';
  }

  // blockScreenApprove() {
  //   this.saveApprove = true;
  //   document.getElementById('approve').style.display = 'block';
  // }

  // unBlockScreenApprove() {
  //   this.saveApprove = false;
  //   document.getElementById('approve').style.display = 'none';
  // }

  btnBack() {
    this.router.navigate(['/projectsearchlist']);
  }

  openDialog(dialogTitle: string, dialogMessage: string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      data: { title: dialogTitle, message: dialogMessage },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }

  onclickAddPublications() {
    let publicationRow = new projectpublicationdetail();
    publicationRow.journalLevel = 1;
    publicationRow.projectId = this.projectProposal.id;
    publicationRow.publishDate = new Date();
    if (!publicationRow.attachDocument) {
      publicationRow.attachDocument = new attachdocument();
    }
    publicationRow.attachDocument.documentName = 'new';
    if (!this.projectPublishedWork) {
      this.projectPublishedWork = new projectpublication();
      this.projectPublishedWork.projectId = this.projectProposal.id;
    }
    if (this.projectPublishedWork.projectPublishedWorks) {
      this.projectPublishedWork.projectPublishedWorks.push(publicationRow);
    } else {
      this.projectPublishedWork.projectPublishedWorks = [publicationRow];
    }

    this.dataSourcePublications.data =
      this.projectPublishedWork.projectPublishedWorks;
  }

  btnPublicationDelete(rowID: number) {
    this.projectPublishedWork.projectPublishedWorks.splice(rowID, 1);
    this.dataSourcePublications.data =
      this.projectPublishedWork.projectPublishedWorks;
  }

  btnSavePublication() {
    this.blockScreen();
    let url = '/Project/SaveProjectPublishedWork';
    for (
      let i = 0;
      i < this.projectPublishedWork.projectPublishedWorks.length;
      i++
    ) {
      this.projectPublishedWork.projectPublishedWorks[i].publishDate =
        offsetTimeZone(
          this.projectPublishedWork.projectPublishedWorks[i].publishDate
        );
    }
    this.brokerAPIService
      .post(url, JSON.stringify(this.projectPublishedWork))
      .subscribe(
        (values) => {
          this.result = values;
          this.unBlockScreen();
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          } else {
            this.openDialog(this.titleUnabelTosave, this.result.message);
          }
        },
        (error) => {
          this.openSnackBar(JSON.stringify(error), this.closeSnackbarMessage);
          this.unBlockScreen();
        }
      );
  }

  async handleFilePublication(files: FileList, rowID: number) {
    if (files && files.length > 0) {
      let file: File = files.item(0);
      const result = await this.getBase64(files[0]).catch((e) => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        this.openSnackBar(result.message, this.closeSnackbarMessage);
        return;
      }
      this.dataBase64 = result.toString();
      console.log('attach ' + rowID, this.dataBase64);
      let attachedDocument = new attachdocument();
      attachedDocument.documentType = file.type;
      attachedDocument.documentName = file.name;
      attachedDocument.documentContent = this.dataBase64;
      attachedDocument.mimeType = file.type;
      this.PostFilePublicationData(attachedDocument, rowID);
    }
  }

  PostFilePublicationData(docToUpload: attachdocument, rowID: number) {
    this.brokerAPIService
      .post('/DocumentUpload/UploadDocument', JSON.stringify(docToUpload))
      .subscribe(
        (values) => {
          this.result = values;
          console.log('atta', JSON.stringify(this.result));
          this.projectPublishedWork.projectPublishedWorks[
            rowID
          ].attachDocumentId = +this.result.data;
          this.projectPublishedWork.projectPublishedWorks[
            rowID
          ].attachDocument = docToUpload;
          this.dataSourcePublications.data =
            this.projectPublishedWork.projectPublishedWorks;
        },
        (error) => {
          this.errorMessage = <any>error;
          console.log('errorMessage:' + JSON.stringify(this.errorMessage));
        }
      );
  }

  getProjectPublication() {
    this.projectPublishedWork = new projectpublication();
    this.projectPublishedWork.projectId = this.projectProposal.id;
    let url =
      '/Project/GetProjectPublishedWork/' + this.projectPublishedWork.projectId;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.projectPublishedWork.projectPublishedWorks = values;
        this.dataSourcePublications.data =
          this.projectPublishedWork.projectPublishedWorks;
      },
      (error) => { }
    );
  }

  openTextEditorDialog(rowId: number) {
    this.isShowTextEditorDialog = true;
    this.currentDataIndex = rowId;
    this.RowIDResearchMethodDetail = rowId;
    this.editBoxTitle =
      this.projectProposal.researchMethods[rowId].researchMethodType;
    this.htmlContent =
      this.projectProposal.researchMethods[rowId].researchMethodDetail;

    console.log(
      'researchMethodDetail',
      this.projectProposal.researchMethods[rowId].researchMethodDetail
    );

    // console.log(this.htmlContent);

    if (this.htmlContent != null) {
      this.isResearchMethodsValid = true;
      if (
        this.projectProposal.researchMethods[rowId].researchMethodDetail == ''
      ) {

        this.isResearchMethodsValid = false;
      }
    } else {
      this.isResearchMethodsValid = false;
    }
  }

  onEditorResearchMethodDetail(researchMethodType: string) {
    const HTMLInner = researchMethodType.replace(/<.*?>/g, '');
    this.lengthResearchMethodType = HTMLInner.length;

    if (this.researchMethodDetail.length > 0) {
      this.researchMethodDetail = this.researchMethodDetail.filter(a => a.RowID != this.RowIDResearchMethodDetail);
    }

    if (this.lengthResearchMethodType > 10000) {

      this.lengthOverrayResearchMethodType = this.lengthResearchMethodType - 10000;



      if (this.RowIDResearchMethodDetail == 0) {
        this.lengthResearchMethodType1 = this.lengthResearchMethodType;
      } else if (this.RowIDResearchMethodDetail == 1) {
        this.lengthResearchMethodType2 = this.lengthResearchMethodType;
      } else if (this.RowIDResearchMethodDetail == 2) {
        this.lengthResearchMethodType3 = this.lengthResearchMethodType;
      } else if (this.RowIDResearchMethodDetail == 3) {
        this.lengthResearchMethodType4 = this.lengthResearchMethodType;
      } else if (this.RowIDResearchMethodDetail == 4) {
        this.lengthResearchMethodType5 = this.lengthResearchMethodType;
      }

      this.researchMethodDetail.push(
        {
          RowID: this.RowIDResearchMethodDetail,
          Length: this.lengthResearchMethodType,
          OverrayLength: this.lengthOverrayResearchMethodType
        }
      );

    } else {

      // this.researchMethodDetail = this.researchMethodDetail.filter(a => a.RowID != this.RowIDResearchMethodDetail);
      // console.log("this.researchMethodDetail", this.researchMethodDetail);
      if (this.RowIDResearchMethodDetail == 0) {
        if (this.lengthResearchMethodType < 10001) {
          this.lengthResearchMethodType1 = undefined;
        }
      } else if (this.RowIDResearchMethodDetail == 1) {
        if (this.lengthResearchMethodType < 10001) {
          this.lengthResearchMethodType2 = undefined;
        }
      } else if (this.RowIDResearchMethodDetail == 2) {
        if (this.lengthResearchMethodType < 10001) {
          this.lengthResearchMethodType3 = undefined;
        }
      } else if (this.RowIDResearchMethodDetail == 3) {
        if (this.lengthResearchMethodType < 10001) {
          this.lengthResearchMethodType4 = undefined;
        }
      } else if (this.RowIDResearchMethodDetail == 4) {
        if (this.lengthResearchMethodType < 10001) {
          this.lengthResearchMethodType5 = undefined;
        }

      }

    }



  }

  btnCloseDialogOverrayResearchMethodType() {
    this.isShowResearchMethodType = false;
  }

  closeTextEditorDialog() {
    console.log('CC');
    this.isShowTextEditorDialog = false;
    this.projectProposal.researchMethods[
      this.currentDataIndex
    ].researchMethodDetail = this.htmlContent;

    if (this.htmlContent != null) {
      this.isResearchMethodsValid = true;
    }
    if (this.htmlContent == '') {
      this.isResearchMethodsValid = false;
    }
    this.htmlContent = '';
  }

  onPresentationTabDataChange() {
    if (!this.projectProposal.received.presentWorkNationalOralNumber)
      this.projectProposal.received.presentWorkNationalOralNumber = 0;
    if (!this.projectProposal.received.presentWorkNationalPosterNumber)
      this.projectProposal.received.presentWorkNationalPosterNumber = 0;
    if (!this.projectProposal.received.presentWorkInternationalOralNumber)
      this.projectProposal.received.presentWorkInternationalOralNumber = 0;
    if (!this.projectProposal.received.presentWorkInternationalPosterNumber)
      this.projectProposal.received.presentWorkInternationalPosterNumber = 0;
    if (!this.projectProposal.received.presentWorkNationalNumber)
      this.projectProposal.received.presentWorkNationalNumber = 0;
    if (!this.projectProposal.received.presentWorkInternationalNumber)
      this.projectProposal.received.presentWorkInternationalNumber = 0;
    this.projectProposal.received.presentWorkNationalNumber =
      this.projectProposal.received.presentWorkNationalOralNumber +
      this.projectProposal.received.presentWorkNationalPosterNumber;
    this.projectProposal.received.presentWorkInternationalNumber =
      this.projectProposal.received.presentWorkInternationalOralNumber +
      this.projectProposal.received.presentWorkInternationalPosterNumber;
    this.projectProposal.received.presentWorkNumber =
      this.projectProposal.received.presentWorkNationalNumber +
      this.projectProposal.received.presentWorkInternationalNumber;
    if (this.projectProposal.received.presentWorkNationalNumber > 0) {
      this.projectProposal.received.presentWorkNational = true;
    } else {
      this.projectProposal.received.presentWorkNational = false;
    }
    if (this.projectProposal.received.presentWorkInternationalNumber > 0) {
      this.projectProposal.received.presentWorkInternational = true;
    } else {
      this.projectProposal.received.presentWorkInternational = false;
    }
    if (this.projectProposal.received.presentWorkNumber > 0) {
      this.projectProposal.received.presentWork = true;
    } else {
      this.projectProposal.received.presentWork = false;
    }
    //Jornal
    this.projectProposal.received.articleNumber =
      this.projectProposal.received.journalNationalNumber +
      this.projectProposal.received.journalInternationalNumber;
    if (this.projectProposal.received.journalNationalNumber > 0) {
      this.projectProposal.received.journalNational = true;
    } else {
      console.log('journalNationalNumber == 0');

      this.projectProposal.received.journalNational = false;
      this.projectProposal.received.journalNationalPublishYear = 0;
      this.tciTier = '';
    }
    if (this.projectProposal.received.journalInternationalNumber > 0) {
      this.projectProposal.received.journalInternational = true;
    } else {
      this.projectProposal.received.journalInternational = false;
      this.projectProposal.received.journalInternationalPublishYear = 0;
      this.journalInternationalDatabase = '';
      this.projectProposal.received.journalInternationalOther = '';
      this.quartile = '';
    }

    if (
      this.projectProposal.received.journalNationalNumber +
      this.projectProposal.received.journalInternationalNumber >
      0
    ) {
      this.projectProposal.received.article = true;
    } else {
      this.projectProposal.received.article = false;
    }
    if (this.projectProposal.received.patentNumber > 0) {
      this.projectProposal.received.patent = true;
    } else {
      this.projectProposal.received.patent = false;
    }
    if (this.projectProposal.received.commercialInnovationNumber > 0) {
      this.projectProposal.received.commercialInnovation = true;
    } else {
      this.projectProposal.received.commercialInnovation = false;
      this.projectProposal.received.commercialInnovationWorkPiece = '';
    }

    // projectProposal.received
    // console.log(this.projectProposal);

    if (this.projectProposal.received.journalNationalNumber > 0) {
      this.isReadonlyJournalNational = false;
      this.isEditableJournalNational = false;
    } else {
      this.isReadonlyJournalNational = true;
      this.isEditableJournalNational = true;
    }

    if (this.projectProposal.received.journalInternationalNumber > 0) {
      this.isReadonlyJournalInternational = false;
      this.isDisableJournalInternational = false;
    } else {
      this.isReadonlyJournalInternational = true;
      this.isDisableJournalInternational = true;
    }

    if (this.projectProposal.received.commercialInnovationNumber > 0) {
      this.isReadonlyCommercialInnovation = false;
    } else {
      this.isReadonlyCommercialInnovation = true;
    }

    if (this.projectProposal.received != null) {
      if (
        this.projectProposal.received.presentWork == true ||
        this.projectProposal.received.article == true ||
        this.projectProposal.received.patent == true ||
        this.projectProposal.received.commercialInnovation == true ||
        this.projectProposal.received.copyright == true ||
        this.projectProposal.received.other == true
      ) {
        this.isProductAndResultProjectValid = true;
      } else {
        this.isProductAndResultProjectValid = false;
      }
    }

    // if (this.projectProposal.received.article == false) {
    //   this.projectProposal.received.tciTier = "0";
    //   this.projectProposal.received.journalInternationalDatabase = "0";
    //   this.projectProposal.received.quartile = 0;
    // } else if (this.projectProposal.received.tciTier == undefined) {
    //   this.projectProposal.received.tciTier = "0";

    // } else if (this.projectProposal.received.journalInternationalDatabase == undefined) {
    //   this.projectProposal.received.journalInternationalDatabase = "0";
    // } else if (this.projectProposal.received.quartile == undefined) {
    //   this.projectProposal.received.quartile = "0";
    // }

    // console.log(this.projectProposal.received.tciTier);
  }

  onchangjournalInternationalDatabase(journalInternationalDatabase: string) {
    console.log(
      'onchangjournalInternationalDatabase',
      journalInternationalDatabase
    );
    this.projectProposal.received.journalInternationalDatabase =
      +journalInternationalDatabase;
  }

  onChangReceivedOthrt() {
    console.log(this.projectProposal.received.other);
    if (this.projectProposal.received.other == false) {
      this.projectProposal.received.otherDetail = '';
      this.isReadonlyReceivedOther = true;
    } else if (this.projectProposal.received.other == true) {
      console.log('AA');
      this.isReadonlyReceivedOther = false;
    } else {
      console.log('BB');
    }

    if (this.projectProposal.received.other == false) {
      this.isProductAndResultProjectValid = false;
    } else if (this.projectProposal.received.other == true) {
      this.isProductAndResultProjectValid = true;
    } else {
    }
    // this.onPresentationTabDataChange()
  }

  onChangcopyright() {
    if (this.projectProposal.received.copyright == false) {
      this.isProductAndResultProjectValid = false;
    } else if (this.projectProposal.received.copyright == true) {
      this.isProductAndResultProjectValid = true;
    } else {
    }
  }

  oncanBeUtilized(canBeUtilized: boolean, i: number) {
    console.log('data', canBeUtilized);
    console.log('index', i);

    if (i == 0) {
      this.isExpectedImpactsValid = true;

      if (canBeUtilized == false) {
        this.isExpectedImpactsValid = false;
      }
    } else if (i == 1) {
      this.isExpectedImpactsValid = true;

      if (canBeUtilized == false) {
        this.isExpectedImpactsValid = false;
      }
    } else if (i == 2) {
      this.isExpectedImpactsValid = true;

      if (canBeUtilized == false) {
        this.isExpectedImpactsValid = false;
      }
    } else {
    }

    // this.projectProposal.expectedImpacts = this.dataSourceExpectedImpact.data

    //  console.log(this.checkExpectedImpacts1 , this.checkExpectedImpacts2 , this.checkExpectedImpacts3 );

    //   if (this.checkExpectedImpacts1 != undefined && this.checkExpectedImpacts2 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   } else if (this.checkExpectedImpacts2 != undefined && this.checkExpectedImpacts3 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   } else if (this.checkExpectedImpacts1 != undefined && this.checkExpectedImpacts3 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   } else if (this.checkExpectedImpacts1 != undefined && this.checkExpectedImpacts2!= undefined && this.checkExpectedImpacts3 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   } else  {
    //   }

    // if (this.checkExpectedImpacts2 != undefined ) {
    //   if (this.checkExpectedImpacts2 != undefined && this.checkExpectedImpacts1 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   }

    //   if (this.checkExpectedImpacts2 != undefined && this.checkExpectedImpacts3 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   }
    //   this.isExpectedImpactsValid = false;
    // }

    // if (this.checkExpectedImpacts3 != undefined ) {
    //   if (this.checkExpectedImpacts3 != undefined && this.checkExpectedImpacts2 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   }

    //   if (this.checkExpectedImpacts3 != undefined && this.checkExpectedImpacts1 != undefined) {
    //     this.isExpectedImpactsValid = false;
    //   }
    //   this.isExpectedImpactsValid = false;
    // }
  }

  checkDataInTab() {
    if (
      this.projectProposal.teamMembers &&
      this.projectProposal.teamMembers.length > 0
    ) {
      this.isTeamDataValid = true;
    } else {
      this.isTeamDataValid = false;
    }

    // budget
    if (this.projectProposal.budget) {
      if (this.isDetailBudgetNeeded) {
        if (this.projectProposal.budget.budgetDetails && this.projectProposal.budget.budgetDetails.length > 0) {
          this.isBudgetValid = true;
        } else {
          this.isBudgetValid = false;
        }
        // if (
        //   this.isLongForm &&
        //   this.projectProposal.budget.budgetDetails &&
        //   this.projectProposal.budget.budgetDetails.length > 0
        // ) {
        //   this.isBudgetValid = true;
        // } else if (!this.isDetailBudgetNeeded) {
        //   this.isBudgetValid = true;
        // } else {
        //   this.isBudgetValid = true;
        // }
      } else {
        this.isBudgetValid = true;
      }
    } else if (!this.isDetailBudgetNeeded) {
      this.isBudgetValid = true;
    }
    //  else if (!this.isLongForm) {
    //   this.isBudgetValid = true;
    // }

    // objectives
    if (
      this.projectProposal.objectives &&
      this.projectProposal.objectives.length > 0
    ) {
      this.isObjectValid = true;
    } else {
      this.isObjectValid = false;
    }

    // keywords
    if (
      this.projectProposal.keywords &&
      this.projectProposal.keywords.length > 0
    ) {
      this.isKeywordValid = true;
    } else {
      this.isKeywordValid = false;
    }

    if (this.editorKewardAndProblem != null) {
      this.isKeyworAndProblemdValid = true;
    } else {
      this.isKeyworAndProblemdValid = false;
    }

    if (this.editorLiteratureReview != null) {
      this.isLiteratureReviewValid = true;
    } else {
      this.isLiteratureReviewValid = false;
    }

    // researchMethods
    if (
      this.projectProposal.researchMethods &&
      this.projectProposal.researchMethods.length > 0
    ) {
      this.isResearchMethodsValid = true;
    } else {
      this.isResearchMethodsValid = false;
    }

    // timeLine

    if (this.projectProposal.timeLine != null) {
      if (this.projectProposal.timeLine.timeLineDetails != null) {
        if (
          this.projectProposal.timeLine.timeLineDetails &&
          this.projectProposal.timeLine.timeLineDetails.length > 0
        ) {
          this.isTimeLineValid = true;
        } else {
          this.isTimeLineValid = false;
        }
      }
    }
    // expectedResults
    if (
      this.projectProposal.expectedResults &&
      this.projectProposal.expectedResults.length > 0
    ) {
      this.isExpectedResultsValid = true;
    } else {
      this.isExpectedResultsValid = false;
    }

    // durableArticles
    if (
      this.projectProposal.durableArticles &&
      this.projectProposal.durableArticles.length > 0
    ) {
      this.isDurableArticlesValid = true;
    } else {
      this.isDurableArticlesValid = false;
    }

    // referenceDocuments
    if (
      this.projectProposal.referenceDocuments &&
      this.projectProposal.referenceDocuments.length > 0
    ) {
      this.isReferenceDocumentsValid = true;
    } else {
      this.isReferenceDocumentsValid = false;
    }

    // expectedBenefits
    if (
      this.projectProposal.expectedBenefits &&
      this.projectProposal.expectedBenefits.length > 0
    ) {
      this.isExpectedBenefitsValid = true;
    } else {
      this.isExpectedBenefitsValid = false;
    }

    // expectedImpacts
    if (
      this.projectProposal.expectedImpacts &&
      this.projectProposal.expectedImpacts.length > 0
    ) {
      this.isExpectedImpactsValid = true;
    } else {
      this.isExpectedImpactsValid = false;
    }

    if (
      this.projectProposal.oecdId != null ||
      this.projectProposal.oecdSubId != null
    ) {
      this.isOecdValid = true;
    } else {
      this.isOecdValid = false;
    }

    // researchSiteHeadId

    if (this.projectProposal.researchSingleCenterName || this.projectProposal.researchNationalSiteName || this.projectProposal.researchCoInternaltionalSiteName) {
      this.isResearchSiteHeadIdValid = true;

    } else {
      this.isResearchSiteHeadIdValid = false;
    }

    // projectProposal.received

    if (this.projectProposal.received != null) {
      if (
        this.projectProposal.received.presentWork == true ||
        this.projectProposal.received.article == true ||
        this.projectProposal.received.patent == true ||
        this.projectProposal.received.commercialInnovation == true ||
        this.projectProposal.received.copyright == true ||
        this.projectProposal.received.other == true
      ) {
        this.isProductAndResultProjectValid = true;
      } else {
        this.isProductAndResultProjectValid = false;
      }
    }

    // researchDescriptionTypeId
    if (this.projectProposal.researchDescriptionTypeId != undefined) {
      if (this.projectProposal.researchDescriptionTypeId != 0) {
        this.isResearcherTypeValid = true;
      } else {
        this.isResearcherTypeValid = false;
      }
    }
  }

  onBudgetAllocationInRowChange() {
    this.totalFundAllocation = 0;
    for (let i = 0; i < this.projectBudgetAllocation.budgetReceivedDetails.length; i++
    ) {
      let tempbudgetReceived: string = this.projectBudgetAllocation.budgetReceivedDetails[i].budgetReceived.toString();
      this.totalFundAllocation += Number.parseFloat(tempbudgetReceived);
    }

    this.totalFundAllocationInString = formatNumber(
      this.totalFundAllocation,
      'en-US',
      '1.2-2'
    );
    this.onFundAllocartionTotalChange();
  }

  onCategoryAllocationChange(categoryID: any, rowID: any) {
    let budgetSubGroup: enumdata = this.budgetSubGroupList.find((x) => x.id == categoryID);

    this.projectBudgetAllocation.budgetReceivedDetails[rowID].budgetGroup = budgetSubGroup.groupName;
    this.projectBudgetAllocation.budgetReceivedDetails[rowID].budgetGroupId = this.budgetGroupList.find(x => x.description == budgetSubGroup.groupName).id;
    this.projectBudgetAllocation.budgetReceivedDetails[rowID].budgetCategory = budgetSubGroup.description;
    this.dataSourceBudgetAllocation.data = this.projectBudgetAllocation.budgetReceivedDetails;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  btnAddFundingAllocation() {
    let budgetAllocationRow: budgetdetailallocation =
      new budgetdetailallocation();
    budgetAllocationRow.projectTrackingBudgetHeadId =
      this.projectBudgetAllocation.id;
    if (this.projectBudgetAllocation.budgetReceivedDetails) {
      this.projectBudgetAllocation.budgetReceivedDetails.push(
        budgetAllocationRow
      );
    } else {
      this.projectBudgetAllocation.budgetReceivedDetails = [
        budgetAllocationRow,
      ];
    }
    this.dataSourceBudgetAllocation.data =
      this.projectBudgetAllocation.budgetReceivedDetails;
  }

  btnDeleteBusgetAllocation(rowid: number) {
    this.projectBudgetAllocation.budgetDetails.splice(rowid, 1);
    this.dataSourceBudgetAllocation.data =
      this.projectBudgetAllocation.budgetReceivedDetails;
  }

  resetMenu() {
    this.isAtDocumentReviewStep = false;
    this.isAddSpecialistStep = false;
    this.isAddSpecialistStepSubmitEnable = false;
    this.isShowBudgetBtn = false;
    this.isShowingProgressBtn = false;
    this.isShowingProgressReviewBtn = false;
    this.isShowingProjectCancel = false;
    this.isShowingProjectStatusUpdate = false;
    this.isApprovingStep = false;
    this.isShowingMessageButton = false;
    this.isShowingPublicationButton = false;
    this.isShowingPrintProposalButton = false;

    this.historyMode = false;

    this.isShowingMenu = false;
  }

  btnToggleChangeOwnerDialog(isShow: boolean) {
    this.newOwnerName = '';
    this.isShowOwnerChangeDialog = isShow;
  }

  btnChangeOwner() {
    if (!this.newOwnerName) {
      this.openDialog('ข้อมูลไม่ครบถ้วน', 'กรุณาใส่ชื่อนักวิจัย');
      return;
    }

    let newOnerRow = this.researcherSelectionList.find(x => (x.nameTH == this.newOwnerName));
    if (!newOnerRow) {
      this.openDialog('ข้อมูลไม่ครบถ้วน', 'ไม่พบนักวิจัยในระบบ');
      return;
    }
    let newOnerID = newOnerRow.id;
    let newOwner = { 'projectId': this.projectProposal.id, 'newResearcherId': newOnerID }
    console.log('newowner', JSON.stringify(newOwner));
    let url = "/Project/ChangeProjectOwner";
    this.blockScreen();
    this.brokerAPIService.post(url, newOwner).subscribe(
      values => {
        this.result = values;
        this.unBlockScreen();
        if (this.result.success) {
          this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          this.getProjectInfo(this.projectProposal.id);
          this.btnToggleChangeOwnerDialog(false);
        } else {
          this.openDialog("ไม่สามารถบันทึกข้อมูล", JSON.stringify(this.result.message));
        }
      },
      errors => {
        this.unBlockScreen();
        this.openDialog("ไม่สามารถบันทึกข้อมูล", JSON.stringify(errors));
      }
    )
  }
}
