<div fxLayout="column" class="components-container-gt-xy" fxLayoutGap="20px" style="padding-top: 0px; padding-right: 0px; padding-left: 0px; margin-bottom: 10px;">

    <div fxFlex class="table-component-holder mat-elevation-z4">
        <div class="table-container" style=" background-color: #FD7E14; color: #002F87; text-align: center; padding: 10px; padding-left: 15px; padding-right: 15px;">
            <table width=100%>
                <tr *ngIf="!showBudgetAllocation&&!showPublication">
                    <td width=35% style="text-align: left;">
                        <div class="font-light">
                            <button *ngIf="isShowingMenu" style="margin-left: 0px;" class="btn-Menu" mat-raised-button [matMenuTriggerFor]="menu">Menu </button>
                            <mat-menu #menu="matMenu" xPosition="before">
                                <button *ngIf="isAddSpecialistStep" class="font-light" mat-menu-item (click)="btnShowExpertInformation()">{{specialistBtnCaption}}</button>
                                <button *ngIf="isShowBudgetBtn" class="font-light" mat-menu-item (click)="onBudgetAllocationClicked()">{{budgetBtnCaption}}</button>
                                <button *ngIf="isShowingProgressBtn" class="font-light" mat-menu-item (click)="onTrackingClicked()">{{progressBtnCaption}}</button>
                                <button *ngIf="isAtDocumentReviewStep" class="font-light" mat-menu-item (click)="btnShowDocumentReview()">{{documentReviewCaption}}</button>
                                <button *ngIf="isShowingProjectStatusUpdate" class="font-light" mat-menu-item (click)="btnProjectStatusUpdate()">{{projectStatusUpdateBtnCaption}}</button>
                                <button *ngIf="isApprovingStep" class="font-light" mat-menu-item (click)="btnOpenApprovalDecision(true)">{{projectReviewBtnCaption}}</button>
                                <button *ngIf="isShowingProgressReviewBtn" class="font-light" mat-menu-item (click)="onTrackingClicked()">{{progressReviewBtnCaption}}</button>
                                <button *ngIf="isShowingConslusion" class="font-light" mat-menu-item (click)="btnOpenApprovalDecision(false)">{{conclusionCaption}}</button>
                                <button *ngIf="isShowingPublicationButton" class="font-light" mat-menu-item (click)="onPublicationClicked()">{{publicationBtnCaption}}</button>
                                <button *ngIf="isShowingPrintProposalButton" class="font-light" mat-menu-item (click)="btnReportProposal()">{{printProposalCaption}}</button>
                                <mat-divider *ngIf="isShowMenuDivider"></mat-divider>
                                <button *ngIf="isShowChangeProjectOwner" class="font-light" mat-menu-item (click)="btnToggleChangeOwnerDialog(true)">{{changeProjectOwnerCaption}}</button>
                                <button *ngIf="isShowingProjectCancel" class="font-light" mat-menu-item (click)="btnProjectCancellation()">{{projectCancelBtnCaption}}</button>
                            </mat-menu>

                        </div>
                    </td>
                    <td width=30% style="text-align: center;">
                        <label class="font-bold" style=" color: #002F87;">{{screenTitle}}</label>
                    </td>
                    <td width=35% style="text-align: right;">
                        <button *ngIf="isShowingMessageButton" mat-raised-button style="margin-right: 15px;" class="btn-mail" (click)="btnMessageDialogOpen()" matBadge={{messageCount}} matBadgePosition="after" matBadgeColor="warn"><img style="margin-right: 3px; height: 50%; width: 50%;" src="assets\Icon\unread.png"></button>
                        <button mat-raised-button class="btn-back" style=" font-family: chulabhornlikittext-light; font-size: small;" (click)="btnBack()">{{'ย้อนกลับ'}}</button>
                        <button *ngIf="isSubmitable" mat-raised-button class="btn-black" style="margin-left: 15px; font-family: chulabhornlikittext-light; font-size: small;" (click)="btnSend()">{{'ส่งคำขอ'}}</button>
                        <button *ngIf="isEditable" mat-raised-button class="btn-black" style="margin-left: 15px; font-family: chulabhornlikittext-light; font-size: small;" (click)="btnSave()">{{'บันทึก'}}</button>

                    </td>
                </tr>
                <tr *ngIf="showBudgetAllocation&&!showPublication">
                    <td width=35% style="text-align: left;">

                    </td>
                    <td width=30% style="text-align: center;">
                        <label class="font-bold" style=" color: #002F87;">{{screenTitle}}</label>
                    </td>
                    <td width=35% style="text-align: right;">
                        <button style="margin-left: 10px; font-family: chulabhornlikittext-light; font-size: small;" mat-raised-button class="btn-back" (click)="onBackToMain()">{{'ย้อนกลับ'}}</button>
                        <button style="margin-left: 10px; font-family: chulabhornlikittext-light; font-size: small;" mat-raised-button class="btn-black" (click)="btnSaveBudgetAllocation()">{{'บันทึก'}}</button>

                    </td>
                </tr>
                <tr *ngIf="!showBudgetAllocation&&showPublication">
                    <td width=35% style="text-align: left;">

                    </td>
                    <td width=30% style="text-align: center;">
                        <label class="font-bold" style=" color: #002F87;">{{screenTitle}}</label>
                    </td>
                    <td width=35% style="text-align: right;">
                        <button style="margin-left: 10px;  font-family: chulabhornlikittext-light; font-size: small;" mat-raised-button class="btn-back" (click)="onBackToMain()">{{'ย้อนกลับ'}}</button>
                        <button style="margin-left: 10px; font-family: chulabhornlikittext-light; font-size: small;" mat-raised-button class="btn-black" (click)="btnSavePublication()">{{'บันทึก'}}</button>

                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>

<div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin: 10px; font-family: chulabhornlikittext-bold;">
    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px; margin-bottom: 10px;">
        <div class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #b5d5f3; color: #002F87; text-align: center; padding-top: 10px; padding-bottom: 10px;">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%;">

                    </td>
                    <td style="width: 20%;" class="font-bold">
                        <label>{{'ชื่อโครงการวิจัย'}}</label>
                    </td>
                    <td style="width: 40%; text-align: right;">
                        <!-- <button style="margin-left: 10px;" mat-raised-button class="btn-send" (click)="btnReportProposal()">{{'พิมพ์ proposal'}}</button> -->
                    </td>
                </tr>
            </table>

        </div>


        <!-- </mat-toolbar> -->

        <div style="padding: 0px;" class="font-light">


            <div class="table-container" style="padding: 5px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="100%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input [readonly]='!isEditable' matInput maxlength="500" autocomplete="off" placeholder="{{'ชื่อโครงการภาษาไทย'}}" [(ngModel)]="projectProposal.projectNameTH">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                </div>
            </div>

            <div class="table-container" style="padding: 5px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="100%">
                        <mat-form-field class="font-light">
                            <div class="font-light">
                                <input [readonly]='!isEditable' matInput maxlength="500" autocomplete="off" placeholder="{{'ชื่อโครงการภาษาอังกฤษ'}}" [(ngModel)]="projectProposal.projectNameEN">
                            </div>

                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>

                </div>
            </div>
        </div>

    </div>


</div>

<!-- Dialog -->
<!--  message list -->
<mat-card *ngIf="isshowdialogMessage" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; width: 80%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'กล่องข้อความ' }}</label>
        </div>
        <div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="10px">
            <!-- <mat-table class="gridrow" [dataSource]="dataSource" matSort matSortDisableClear (matSortChange)="sortData($event)"> -->
            <mat-table class="gridrow" [dataSource]="dataSourceMessage" matSort matSortDisableClear>

                <ng-container matColumnDef="Control">
                    <mat-header-cell fxFlex="10" *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index" fxFlex="10">
                        <span class="font-medium" class="mobile-label"></span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                    <mat-cell fxFlex="10" *matCellDef="let element; let i = index">
                        <div fxFlex="10" class="font-light">{{i + 1}}</div>
                    </mat-cell>

                </ng-container>
                <ng-container matColumnDef="messageDate">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'วันที่'}}</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <div [ngStyle]="{'font-weight': objjob.isRead ? 'normal' : 'bold' }" class="font-light">{{ objjob.createDate |date:'dd MMM yyyy' }}</div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="subject">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หัวข้อ'}}</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <div [ngStyle]="{'font-weight': objjob.isRead ? 'normal' : 'bold' }" class="font-light">{{ objjob.subject }}</div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isRead">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สถานะ'}}</mat-header-cell>
                    <mat-cell *matCellDef="let objjob" fxFlex="10">
                        <img *ngIf="objjob.isRead" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\read.png">
                        <img *ngIf="!objjob.isRead" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\unread.png">
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsMessage"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsMessage;" (click)="onMessageRowClicked(row)"></mat-row>
            </mat-table>
            <!-- <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons aria-label="Select page of list">
            </mat-paginator> -->
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 30px; padding-bottom: 20px; ">
                <table style="width: 100%;">
                    <tr style="width: 100%; text-align: center; padding-left: 10px;">
                        <td style="width: 100%; text-align: center; padding-left: 10px;">
                            <button mat-raised-button (click)="btnMessageDialogClose()" style="font-family: chulabhornlikittext-light; font-size: small;" class="btn-black">{{'ย้อนกลับ' }}</button>
                        </td>
                    </tr>
                </table>
            </div>

        </div>
    </mat-card>
</mat-card>



<mat-card *ngIf="isshowdialogDocumentCheckRecordAdmin" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; padding: 0px; min-width: 300px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label class="font-bold">บันทึกการตรวจสอบเอกสาร</label>
        </div>
        <div class="table-container" style="padding: 0px;">
            <div *ngIf="!isDocumentReviewInfoReadOnly" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <div fxFlex="2"></div>

                <div fxFlex="33%">

                    <section fxLayout="row" class="font-light;">
                        <label class="font-bold" style=" color: #002F87; padding-right: 30px; margin-top: 15px;"></label>
                        <mat-radio-group fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="reviewDecision" (change)="onReviewDecisionChange()">
                            <mat-radio-button class="font-light" style=" color: #002F87; margin-right: 30px; padding-bottom: 10px;" value="1">{{'เอกสารครบถ้วน' }} </mat-radio-button>
                            <br>
                            <mat-radio-button class="font-light" style=" color: #002F87; padding-bottom: 10px;" value="2">{{'ปรับปรุงแก้ไขเอกสาร' }}</mat-radio-button>
                        </mat-radio-group>
                    </section>

                </div>
                <div fxFlex="67%"></div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <table style="width: 100%;">
                    <tr *ngIf="isShowDecisionDetail">
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td style="width: 50%; text-align: center;">
                            <input class="font-light" style="width: 800px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" placeholder="เรื่อง" type="text" [(ngModel)]="documentReviewInfo.subject">
                        </td>

                    </tr>
                    <tr *ngIf="isShowDecisionDetail">
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td fxFlex="100%">
                            <div fxFlex="100%">
                                <angular-editor *ngIf="!isDocumentReviewInfoReadOnly" class="font-light" [(ngModel)]="DocumentCheckRecord" [config]="editorConnfig">
                                    <textarea [disabled]="disabledTab"> </textarea>
                                </angular-editor>
                                <div class="custome-div" style="border-style: solid; border-width:thin; border-color: #000000;" *ngIf="isDocumentReviewInfoReadOnly" style="margin-top: 10px;" [innerHtml]="documentReviewInfo.message"> </div>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">


            </div>
            <div *ngIf="isShowDecisionDetail" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
                <table style="width: 100%;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;"></td>
                        <td style="width: 33%; text-align: left;">
                            <input hidden class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="file" #reviewattachment1 (change)="handleFileInput($event.target.files,'reviewCommentFile1')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx">

                            <input readonly class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="documentReviewInfo.attachment1.documentName">
                            <button mat-raised-button *ngIf="!isDocumentReviewInfoReadOnly" style=" font-family: chulabhornlikittext-light; font-size: small; margin-left: 3px" class="btn-UploadFile" (click)="reviewattachment1.click()">{{'แนบไฟล์' }}</button>
                        </td>

                        <td style="width: 33%; text-align: left;">
                            <input hidden class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="file" #reviewattachment2 (change)="handleFileInput($event.target.files,'reviewCommentFile2')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx">

                            <input readonly class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="documentReviewInfo.attachment2.documentName">
                            <button mat-raised-button *ngIf="!isDocumentReviewInfoReadOnly" style="font-family: chulabhornlikittext-light; font-size: small; margin-left: 3px" class="btn-UploadFile" (click)="reviewattachment2.click()">{{'แนบไฟล์' }}</button>

                        </td>
                        <td style="width: 33%; text-align: left;">
                            <input hidden class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="file" #reviewattachment3 (change)="handleFileInput($event.target.files,'reviewCommentFile3')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx">

                            <input readonly class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="documentReviewInfo.attachment3.documentName">
                            <button mat-raised-button *ngIf="!isDocumentReviewInfoReadOnly" style="font-family: chulabhornlikittext-light; font-size: small; margin-left: 3px;" class="btn-UploadFile" (click)="reviewattachment3.click()">{{'แนบไฟล์' }}</button>

                        </td>

                    </tr>
                </table>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 30px; padding-bottom: 20px; " fxLayoutAlign="center center">
                <button *ngIf="!isDocumentReviewInfoReadOnly" mat-raised-button class="btn-black" (click)="btnSendDocumentReview()" style="margin-right: 10px;margin-left: 10px;">{{'แจ้งผล' }}</button>

                <button mat-raised-button class="btn-back" (click)="btnCloseDocumentReview()" style="margin-right: 10px;margin-left: 10px;">{{'ย้อนกลับ' }}</button>

            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isshowdialogExpertNotes" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; width: 80%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'บันทึกผู้เชี่ยวชาญ' }}</label>
        </div>
        <div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="10px">
            <div *ngIf="!historyMode" style="padding: 10px; text-align: right;" class="font-light">
                <table style="width: 100%;">
                    <tr (click)="btnAddExpert()">
                        <td style="width: 69%;"></td>
                        <td style="width: 30%;">
                            <label style="color: #002F87;  padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มผู้เชี่ยวชาญ'}}</label>
                        </td>
                        <td style="width: 1%;">
                            <img style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\AddPersonal.png">
                        </td>

                    </tr>
                </table>
            </div>
            <mat-table [dataSource]="dataSourceExpert" matSort matSortActive="expPosition" matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="Control">
                    <mat-header-cell fxFlex="10" *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell class="font-light" *matCellDef="let row; let i = index" fxFlex="10">
                        <span class="font-medium" class="mobile-label"></span>
                        <button *ngIf="!row.isEdit" mat-icon-button focusable="false" color="accent">
                            <img (click)="btnDeleteExpert(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\PersonalRemove.png">
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                    <mat-cell fxFlex="10" style="color: #002F87; text-align: center;" class="font-light" *matCellDef="let element; let i = index">{{i + 1}}</mat-cell>

                </ng-container>
                <ng-container matColumnDef="specialistFullName">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ตำแหน่งทางวิชาการ ชื่อ-นามสกุล'}}</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.specialistFullName" autocomplete="off">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="specialistOrganization">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สังกัด'}}</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">

                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.specialistOrganization" autocomplete="off">
                            </div>
                        </mat-form-field>
                        <!-- <div style="color: #002F87;" class="font-light">{{ objjob.specialistOrganization }}</div> -->
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="specialistEmail">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'email'}}</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" type="email" matInput maxlength="100" [(ngModel)]="objjob.specialistEmail" autocomplete="off">
                            </div>
                        </mat-form-field>
                        <!-- <div style="color: #002F87;" class="font-light">{{ objjob.specialistEmail }}</div> -->
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="evaluateDate">
                    <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef>วันที่ส่งประเมิน</mat-header-cell>
                    <mat-cell fxFlex="15" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input readonly class="M-educationHistsEdit" min="0" step="1" matInput maxlength="100" value="objjob.evaluateDate|date:'dd MMM yyyy'" autocomplete="off" [matDatepicker]="pickerevaluateDate " [(ngModel)]="objjob.evaluateDate">
                            </div>
                            <mat-datepicker-toggle matSuffix [for]="pickerevaluateDate" *ngIf="!historyMode"></mat-datepicker-toggle>
                            <mat-datepicker #pickerevaluateDate></mat-datepicker>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="receiveDocDate">
                    <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef>วันที่ได้รับเอกสารคืน</mat-header-cell>
                    <mat-cell fxFlex="15" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input readonly class="M-educationHistsEdit" min="0" step="1" matInput maxlength="100" value="objjob.receiveDocDate|date:'dd MMM yyyy'" autocomplete="off" [matDatepicker]="pickerreceiveDocDate " [(ngModel)]="objjob.receiveDocDate">
                            </div>
                            <mat-datepicker-toggle matSuffix [for]="pickerreceiveDocDate" *ngIf="!historyMode"></mat-datepicker-toggle>
                            <mat-datepicker #pickerreceiveDocDate></mat-datepicker>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumnsExpert"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsExpert;"></mat-row>
            </mat-table>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 30px; padding-bottom: 20px; " fxLayoutAlign="center center">
                <button *ngIf="!historyMode" mat-raised-button (click)="btnSaveExpert()" class="btn-black" style="margin-left: 10px; margin-right: 10px;">{{'บันทึก' }}</button>
                <button *ngIf="isAddSpecialistStepSubmitEnable" mat-raised-button (click)="btnSendForConsideration()" class="btn-black" style="margin-left: 10px; margin-right: 10px;">{{'นำเสนอโครงการเพื่อพิจารณา' }}</button>
                <button mat-raised-button (click)="btnExpertDialogClose()" class="btn-back" style="margin-left: 10px; margin-right: 10px;">{{'ย้อนกลับ' }}</button>
            </div>

        </div>
    </mat-card>
</mat-card>


<mat-card *ngIf="isshowdialogRecordTheResults" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label *ngIf="isConclusion" class="font-bold">รายละเอียดการพิจารณา</label>
            <label *ngIf="!isConclusion" class="font-bold">บันทึกผลการอนุมัติโครงการ</label>
        </div>
        <div class="table-container" style="padding: 0px;">
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" *ngIf="!isConclusion">
                <div fxFlex="2"></div>

                <div fxFlex="33%">

                    <section fxLayout="row" class="font-light;">
                        <label class="font-bold" style=" color: #002F87; padding-right: 30px; margin-top: 15px;"></label>
                        <mat-radio-group [disabled]="isConclusion" fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="decisionAtCommiteeApproval" (change)="onCommotteDecisionChange()">
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px;" value="1">{{'อนุมัติโครงการ' }} </mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px;" value="2">{{'ปรับปรุงแก้ไขเอกสาร' }}</mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px;" value="3">{{'ไม่อนุมัติโครงการ' }}</mat-radio-button>
                        </mat-radio-group>
                    </section>

                </div>
                <div fxFlex="67%"></div>
            </div>
            <div *ngIf="isShowApprovedDate||isConclusion" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <table style="width: 100%;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td style="width: 90%; text-align: center;" class="font-light">
                            <mat-form-field class="font-light" style="width: 95%;">
                                <div class="font-light">
                                    <input readonly class="M-educationHistsEdit" min="0" step="1" matInput maxlength="100" autocomplete="off" [matDatepicker]="picker " [(ngModel)]="approvedDate" placeholder="{{approveDateLabel}}">
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="picker " *ngIf="!isConclusion"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </td>

                    </tr>
                </table>
                <div fxFlex="2"></div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <table style="width: 100%;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td style="width: 50%; text-align: center;" class="font-light">
                            <div class="font-light">
                                <input [readonly]="isConclusion" [(ngModel)]="documentApprovalInfo.subject" class="input-field" style="width: 900px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px; font-family: chulabhornlikittext-light;" placeholder="เรื่อง"
                                    type="text">
                            </div>
                        </td>

                    </tr>
                </table>

            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <div fxFlex="2" style="padding-right: 45px;"></div>

                <div fxFlex="100%">
                    <angular-editor *ngIf="!isConclusion" class="font-light" [(ngModel)]="RecordTheResults" [config]="editorConnfig">
                        <textarea [disabled]="disabledTab"> </textarea>
                    </angular-editor>
                    <div *ngIf="isConclusion" [innerHtml]="conclusionMessage" class="font-light" style="width: 900px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px; font-family: chulabhornlikittext-light; min-height: 200px; font-size: medium;"></div>
                </div>
                <div fxFlex="2"></div>
            </div>
            <div *ngIf="isShowDecisionDetail" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <div fxFlex="2"></div>

                <div fxFlex="33%">

                    <section fxLayout="row" class="font-light;">
                        <label class="font-bold" style=" color: #002F87; padding-right: 30px; margin-top: 15px;"></label>
                        <mat-radio-group fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="decisionOption" (change)="onCommotteDecisionChange()">
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px;" value="1">{{'อนุมัติในหลักการ แต่ให้ปรับปรุงแก้ไขก่อนเสนอลงนามอนุมัติ' }} </mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px;" value="2">{{'ปรับปรุงแก้ไขเอกสาร และนำเข้าพิจารณาในคณะกรรมการกลั่นกรองฯ อีกครั้ง' }}</mat-radio-button>
                            <br>
                            <mat-radio-button style="color: #002F87; padding-bottom: 10px;" value="3">{{'ปรับปรุงแก้ไขเอกสาร และส่งประเมินผู้เชี่ยวชาญก่อนนำเข้าพิจารณาคณะกรรมการกลั่นกรองฯ อีกครั้ง' }}</mat-radio-button>
                        </mat-radio-group>
                    </section>

                </div>
                <div fxFlex="67%"></div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
                <table style="width: 100%;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;"></td>
                        <td *ngIf="documentApprovalInfo.attachment1" style="width: 33%; text-align: left;" class="font-light">

                            <input hidden style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="file" #approveAttachment1 (change)="handleFileInput($event.target.files,'approveAttachment1')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx">
                            <input readonly class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="documentApprovalInfo.attachment1.documentName">
                            <button *ngIf="!isConclusion" mat-raised-button style=" margin-left: 3px;" class="btn-black" (click)="approveAttachment1.click()">{{'แนบไฟล์' }}</button>
                            <button *ngIf="isConclusion&&documentApprovalInfo.attachment1.documentName" mat-raised-button><img (click)="viewAttachDocument(documentApprovalInfo.attachment1.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                        </td>

                        <td *ngIf="documentApprovalInfo.attachment2" style="width: 33%; text-align: left;" class="font-light">
                            <input hidden style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="file" #approveAttachment2 (change)="handleFileInput($event.target.files,'approveAttachment2')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx">
                            <input readonly class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="documentApprovalInfo.attachment2.documentName">
                            <button *ngIf="!isConclusion" mat-raised-button style=" margin-left: 3px;" class="btn-black" (click)="approveAttachment2.click()">{{'แนบไฟล์' }}</button>
                            <button *ngIf="isConclusion&&documentApprovalInfo.attachment2.documentName" mat-raised-button><img (click)="viewAttachDocument(documentApprovalInfo.attachment2.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                        </td>
                        <td *ngIf="documentApprovalInfo.attachment3" style="width: 33%; text-align: left;" class="font-light">
                            <input hidden style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="file" #approveAttachment3 (change)="handleFileInput($event.target.files,'approveAttachment3')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx">
                            <input readonly class="font-light" style="width: 200px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="documentApprovalInfo.attachment3.documentName">
                            <button *ngIf="!isConclusion" mat-raised-button style=" margin-left: 3px;" class="btn-black" (click)="approveAttachment3.click()">{{'แนบไฟล์' }}</button>
                            <button *ngIf="isConclusion&&documentApprovalInfo.attachment3.documentName" mat-raised-button><img (click)="viewAttachDocument(documentApprovalInfo.attachment3.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                        </td>

                    </tr>
                </table>
            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 20px;" fxLayoutAlign="center center">
                <button mat-raised-button (click)="btnSubmitApprovalDecision()" class="btn-black" *ngIf="!isConclusion" style="margin-right: 10px;margin-left:  10px;">{{'แจ้งผล' }}</button>
                <button mat-raised-button (click)="btnCloseApprovalDecision()" class="btn-back" style="margin-right: 10px;margin-left:  10px;">{{'ย้อนกลับ' }}</button>
            </div>

        </div>

    </mat-card>

</mat-card>

<!-- ตรงนี้เป็นไฟล์ที่ถูกส่งมาจากAdmin มาที่นักวิจัย ให้เอาชื่อไฟล์ไปใส่ไว้เหมือนตัวอย่างด้านล่างครับ  -->

<mat-card *ngIf="isShowTextEditorDialog" class="DialogShow" style="width: 95%;height: 90%; background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="width: 100%;height: 100%; background-color: white; border-radius: 10px; padding: 0px; ">
        <div style="width: 100%; background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label class="font-bold">{{editBoxTitle}}</label>
        </div>
        <div>
            <table width="100%">
                <tr width="100%">
                    <td width="50%">
                      <label class="font-light" style="color: red; font-size: 13px;padding-left: 5px;">ระบุได้ไม่เกิน 10,000 ตัวอักษร</label>
                    </td>
                    <td width="50%" style="text-align: right; padding: 5px;" class="font-light">
                        <button class="btn-back" mat-raised-button (click)="closeTextEditorDialog()">{{'ปิด'}}</button>
                    </td>
                </tr>
            </table>
        </div>
        <div class="table-container" style="height: 80%; padding: 0px; width: 100%; overflow:scroll; padding: 5px;">
            <angular-editor *ngIf="isEditable" class="font-light" (ngModelChange)="onEditorResearchMethodDetail($event)" [(ngModel)]="htmlContent" [config]="editorConnfig">
            </angular-editor>
            <label *ngIf="isEditable" class="font-light" style="color: red; font-size: 13px; padding-left: 5px;">ระบุได้ไม่เกิน 10,000 ตัวอักษร</label>
            <div *ngIf="!isEditable" [innerHtml]="htmlContent"></div>
        </div>


    </mat-card>

</mat-card>

<mat-card *ngIf="isShowOwnerChangeDialog" class="DialogShow" style=" background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important; ">
    <mat-card style="width: 30%; background-color: white; border-radius: 10px; margin: 200px; padding: 0px;">
        <div style="width: 100%; background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label class="font-bold">{{changeProjectOwnerCaption}}</label>
        </div>
        <div fxLayout="row" style="width: 100%; padding-left: 20px; padding-right: 20px; padding-top: 20px;">
            <mat-form-field class="font-light">
                <div class="font-light">
                    <input placeholder="ชื่อนักวิจัย" class="font-light" type="text" aria-label="Country" matInput [matAutocomplete]="auto" [(ngModel)]="newOwnerName" (input)="onProjectOwnerInput(newOwnerName)">
                </div>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of researcherSelectionList " [value]="option.nameTH">
                        <div class="font-light-small">{{option.nameTH}}</div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; padding: 10px;">
            <button class="btn-black" style="margin-left: 15px; margin-right: 15px;" mat-raised-button (click)="btnChangeOwner()">{{'เปลี่ยน'}}</button>
            <button class="btn-back" style="margin-left: 15px; margin-right: 15px;" mat-raised-button (click)="btnToggleChangeOwnerDialog(false)">{{'ย้อนกลับ'}}</button>
        </div>

    </mat-card>

</mat-card>


<mat-card *ngIf="isshowdialogMessageContent" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label class="font-bold">{{'ข้อความ' }}</label>
        </div>

        <div class="table-container" style="padding: 0px; width: 800px;">
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 20px;">
                <div fxFlex="2" style="padding-right: 22.5px;"></div>

                <div fxFlex="96" class="custome-div1" style="border-style: solid; border-width:thin; border-color: #000000;">{{messageContentData.subject}} </div>
                <div fxFlex="2" style="padding-right: 22.5px;"></div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 20px;">
                <div fxFlex="2" style="padding-right: 22.5px;"></div>

                <div fxFlex="96" class="custome-div" style="border-style: solid; border-width:thin; border-color: #000000;" [innerHtml]="messageContentData.message"> </div>
                <div fxFlex="2" style="padding-right: 22.5px;"></div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
                <div fxFlex="2" style="padding-right: 22.5px;"></div>
                <div class="font-light">{{'เอกสารแนบ'}}</div>

            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;">
              <table style="width: 100%;">
                  <tr>
                      <td fxFlex="2" style="padding-right: 45px;"></td>
                      <td *ngIf="messageContentData.attachment1" style="width: 33%; text-align: left;" class="font-light">

                          <input readonly class="font-light" style="width: 150px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="messageContentData.attachment1.documentName">
                          <button  mat-raised-button><img (click)="viewAttachDocument(messageContentData.attachment1.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                      </td>

                      <td *ngIf="messageContentData.attachment2" style="width: 33%; text-align: left;" class="font-light">
                          <input readonly class="font-light" style="width: 150px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="messageContentData.attachment2.documentName">
                          <button  mat-raised-button><img (click)="viewAttachDocument(messageContentData.attachment2.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                      </td>
                      <td *ngIf="messageContentData.attachment3" style="width: 33%; text-align: left;" class="font-light">
                          <input readonly class="font-light" style="width: 150px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="messageContentData.attachment3.documentName">
                          <button  mat-raised-button><img (click)="viewAttachDocument(messageContentData.attachment3.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                      </td>

                  </tr>
              </table>
          </div>
          <!-- กรณีแนบไฟล์แล้วข้อมูลทับกัน aom weerapon 20240305 -->
            <!-- <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <table style="width: 100%;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 22.5px;"></td>
                        <td *ngIf="messageContentData.attachment1 != null" style="width: 33%; text-align: left;">
                            <button class="font-light" (click)="btnMessageAttachmentOpen(1)" *ngIf="messageContentData.attachment1!=null" mat-icon-button focusable="false" color="accent">
                                {{messageContentData.attachment1.documentName}}
                            </button>

                        </td>

                        </tr>
                        <tr>

                        <td fxFlex="2" style="padding-right: 22.5px;"></td>
                        <td *ngIf="messageContentData.attachment2 != null" style="width: 33%; text-align: left;">
                            <button class="font-light" (click)="btnMessageAttachmentOpen(2)" *ngIf="messageContentData.attachment1!=null" mat-icon-button focusable="false" color="accent">
                                {{messageContentData.attachment2.documentName}}
                            </button>


                        </td>
                        </tr>
                        <tr>
                        <td fxFlex="2" style="padding-right: 22.5px;"></td>
                        <td *ngIf="messageContentData.attachment3 != null" style="width: 33%; text-align: left;">
                            <button class="font-light" (click)="btnMessageAttachmentOpen(3)" *ngIf="messageContentData.attachment1!=null" mat-icon-button focusable="false" color="accent">
                                {{messageContentData.attachment3.documentName}}
                            </button>


                        </td>
                        <td fxFlex="2" style="padding-right: 22.5px;"></td>
                    </tr>
                </table>
            </div> -->

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 20px;">

                <table style="width: 100%;">
                    <tr>
                        <td style="width: 40%; text-align: right;">

                        </td>
                        <td style="width: 20%; text-align: center;">
                            <button mat-raised-button style="font-size: 13px; font-family: chulabhornlikittext-bold;" class="btn-black" (click)="onBtnMessageContentClose()">{{'ตกลง' }}</button>
                        </td>
                        <td style="width: 40%; text-align: left;">


                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isshowdialogSubmitCompletedRequest" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">



    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'ส่งคำร้องที่ปรับปรุงเสร็จสิ้น' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="2"></div>
                <!-- <div fxFlex="10%"></div> -->
                <div fxFlex="100%" style="text-align: center;  width: 240px;">

                    <label class="font-bold" style="color: #002F87;">โปรดยืนยันการส่งคำร้องที่ปรับปรุงเสร็จสิ้น</label>
                </div>
                <!-- <div fxFlex="10%"></div> -->
                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 50%; text-align: right; padding-right: 23px;" class="font-light">
                            <button mat-raised-button class="btn-black">{{'ยืนยัน' }}</button>
                        </td>
                        <!-- <td style="width: 20%; text-align: center;">

                        </td> -->
                        <td style="width: 50%; text-align: left; padding-left: 23px;">
                            <button mat-raised-button class="btn-back">{{'ย้อนกลับ' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isshowdialogProcessStatus " class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'บันทึกสถานะโครงการ' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="5%"></div>
                <div fxFlex="95%">

                    <mat-form-field class="font-light" style="width: 80%; ">
                        <mat-select class="font-light" placeholder="บันทึกสถานะการดำเนินการ " [(ngModel)]="projectStatusAfterApprove">
                            <div class="font-light-small">
                                <mat-option value=1>รอจัดสรรงบประมาณ</mat-option>
                                <mat-option value=2>อยู่ระหว่างดำเนินการ</mat-option>
                            </div>
                        </mat-select>

                    </mat-form-field>
                </div>

                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%; ">
                    <tr>
                        <td style="width: 50%; text-align: right; padding-right: 23px; " class="font-light">
                            <button mat-raised-button class="btn-black " (click)="btnSubmitProjectStatusUpdate()">{{'ยืนยัน' }}</button>
                        </td>
                        <!-- <td style="width: 20%; text-align: center; ">

                    </td> -->
                        <td style="width: 50%; text-align: left; padding-left: 23px; " class="font-light">
                            <button mat-raised-button (click)="btnCloseProjectStatusUpdate()" class="btn-back ">{{'ย้อนกลับ' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isshowdialogReasonForCancelingProject" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">
    <mat-card style="background-color: white; border-radius: 10px; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <label class="font-bold">{{dialogCancelTitle + ""}}</label>
        </div>
        <div class="table-container" style="padding: 0px;">
            <div *ngIf="!historyMode" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                <table style="width: 100%; margin-top: 15px;">
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td style="padding-bottom: 10px;">
                            <label class="font-light">โปรดระบุเหตุผลการยกเลิกโครงการ</label>
                        </td>
                    </tr>
                    <tr>
                        <td fxFlex="2" style="padding-right: 45px;">

                        </td>
                        <td fxFlex="100%">
                            <div fxFlex="100%">
                                <angular-editor class="font-light" [(ngModel)]="cancellationReason" [config]="editorConnfig">
                                </angular-editor>
                                <!-- <div class="custome-div" style="border-style: solid; border-width:thin; border-color: #000000;" style="margin-top: 10px;" [innerHtml]="documentReviewInfo.message"> </div> -->
                            </div>
                        </td>
                        <td fxFlex="2" style="padding-right: 45px;"></td>
                    </tr>
                </table>

            </div>

            <div *ngIf="historyMode" [innerHtml]="cancellationReason" style="width: 100%; margin-top: 15px;margin-bottom: 15px; margin-left: 50px; margin-right: 15px; font-family: chulabhornlikittext-light;
            font-size: small;">

            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">


            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px;" fxLayoutAlign="center center">
                <input hidden style="width: 950px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" #cancellationFile type="file" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx" (change)="handleFileCancellation($event.target.files)">

                <input readonly class="font-light" style="width: 450px; border-radius: 10px; border-style: solid; border-color: #002F87; padding: 10px;" type="text" [(ngModel)]="cancellationDocument.documentName">

                <button *ngIf="historyMode" mat-raised-button><img (click)="viewAttachDocument(cancellationDocument.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png"></button>
                <div class="font-light">
                    <button *ngIf="!historyMode" mat-raised-button style=" margin-left: 3px;" (click)="cancellationFile.click()" class="btn-UploadFile">{{'แนบไฟล์' }}</button>
                </div>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 20px;" fxLayoutAlign="center center">
                <button *ngIf="!historyMode" mat-raised-button class="btn-black" (click)="btnSubmitProjectCancellation()" style="margin-left: 10px;margin-right: 10px;">{{'บันทึก' }}</button>
                <button mat-raised-button class="btn-back" (click)="btnCloseProjectCancellation()" style="margin-left: 10px;margin-right: 10px;">{{'ย้อนกลับ' }}</button>

            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isShowKeyWordAndProblrm" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'แจ้งเตือนการกรอกข้อมูล' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="5%"></div>
                <div fxFlex="95%">
                    <div class="font-light">
                        <label style="font-size: 15px; color: red;">
                            คุณกรอกข้อมูล ความสำคัญและที่มาของปัญหา เกิน 10,000 ตัวอักษร
                        </label><br>
                        <label style="font-size: 15px;">
                            โปรดลบจำนวนที่เกิน {{ lengthOverrayKeyword }} ตัวอักษร
                        </label>
                    </div>
                </div>

                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%; ">
                    <tr>

                        <td style="width: 50%; text-align: left; padding-left: 23px; text-align: center;" class="font-light">
                            <button mat-raised-button (click)="btnCloseDialogKeyword()" class="btn-back ">{{'ตกลง' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>

<mat-card *ngIf="isShowLiteratureReview" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'แจ้งเตือนการกรอกข้อมูล' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="5%"></div>
                <div fxFlex="95%">
                    <div class="font-light">
                        <label style="font-size: 15px; color: red;">
                            คุณกรอกข้อมูล การทบทวนวรรณกรรม เกิน 10,000 ตัวอักษร
                        </label><br>
                        <label style="font-size: 15px;">
                            โปรดลบจำนวนที่เกิน {{ lengthOverrayLiteratureReview }} ตัวอักษร
                        </label>
                    </div>
                </div>

                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%; ">
                    <tr>

                        <td style="width: 50%; text-align: left; padding-left: 23px; text-align: center;" class="font-light">
                            <button mat-raised-button (click)="btnCloseDialogLiteratureReview()" class="btn-back ">{{'ตกลง' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>


<mat-card *ngIf="isShowBudget" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

  <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
      <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
          <label class="font-bold">{{'แจ้งเตือนการกรอกข้อมูล' }}</label>
      </div>
      <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

          <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
              <div fxFlex="5%"></div>
              <div fxFlex="95%">
                  <div class="font-light">
                      <label style="font-size: 15px; color: red;">
                          งบประมาณทั้งโครงการ จำนวนมากกว่าที่จะแปลงเงินเกิน 13 หลักได้ ตัวอย่างเช่น 9,000,000,000.00 (หลัก)
                      </label>
                      <br>
                      <!-- <label style="font-size: 15px;">
                          โปรดลบจำนวนที่เกิน {{ lengthOverrayLiteratureReview }} ตัวอักษร
                      </label> -->
                  </div>
              </div>

              <div fxFlex="2"></div>
          </div>

          <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
              <table style="width: 100%; ">
                  <tr>
                      <td style="width: 50%; text-align: left; padding-left: 23px; text-align: center;" class="font-light">
                          <button mat-raised-button (click)="btnCloseDialogBudket()" class="btn-back ">{{'ตกลง' }}</button>
                      </td>
                  </tr>
              </table>
          </div>

      </div>

  </mat-card>

</mat-card>

<mat-card *ngIf="isShowResearchMethodType" class="DialogShow" style="background-color: transparent;  border-style: solid; border-color: transparent; transition: none !important; box-shadow: none !important;">

    <mat-card style="background-color: white; border-radius: 10px; width: 50%; padding: 0px;">
        <div style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; padding-left: 30px; padding-right: 30px;">
            <label class="font-bold">{{'แจ้งเตือนการกรอกข้อมูล' }}</label>
        </div>
        <div class="table-container" style="padding: 0px; padding-left: 30px; padding-right: 30px;">

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 40px; padding-bottom:20px;">
                <div fxFlex="5%"></div>
                <div fxFlex="95%">
                    <div class="font-light">
                        <label style="font-size: 15px; color: red;">
                            คุณกรอกข้อมูล ระเบียบวิธีวิจัย เกิน 10,000 ตัวอักษร
                        </label><br>
                        <!-- <label style="font-size: 15px;">
                            โปรดลบจำนวนที่เกิน {{ lengthOverrayResearchMethodType }} ตัวอักษร

                        </label> -->
                        <div style="font-size: 15px;" *ngFor="let researchMethord of researchMethodDetail" >
                           หัวข้อที่ {{ researchMethord.RowID +1 }} โปรดลบจำนวนที่เกิน {{ researchMethord.OverrayLength }} ตัวอักษร
                        </div>
                    </div>
                </div>

                <div fxFlex="2"></div>
            </div>

            <div fxFlexFill class="container " fxLayout="row " fxLayout.xs="column " style="padding-top: 10px; padding-bottom: 20px; ">
                <table style="width: 100%; ">
                    <tr>

                        <td style="width: 50%; text-align: left; padding-left: 23px; text-align: center;" class="font-light">
                            <button mat-raised-button (click)="btnCloseDialogOverrayResearchMethodType()" class="btn-back ">{{'ตกลง' }}</button>

                        </td>
                    </tr>
                </table>
            </div>

        </div>

    </mat-card>

</mat-card>
<!-- // endDialog -->

<!-- เริ่มส่วน Tab -->
<!-- <div *ngIf="!showBudgetAllocation&&!showPublication" fxLayout="column" class="bottom-tab-area" fxLayoutGap="20px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 0px; margin-right: 0px;"> -->
<div *ngIf="!showBudgetAllocation&&!showPublication" fxLayout="column" class="components-container-gt-xy" fxLayoutGap="20px" style="margin-bottom: 10px; margin-top: 10px; margin-left: 10px;  margin-right: 10px;">
    <div fxFlex style="border-radius: 10px; margin-bottom: 0px;">

        <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);" class="font-light">
            <mat-tab label="{{'คณะผู้ทำวิจัย'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isTeamDataValid ? 'black' : 'red'  }">{{'คณะผู้ทำวิจัย'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-light">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 5px; padding-right: 3px;">
                            <label (click)="btnAddResearchTeam()" style="color: #002F87; ">{{'เพิ่มนักวิจัย'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddResearchTeam()" style=" height: 28px; width: 28px;" src="assets\Icon\AddPersonal.png">
                        </div>
                    </div>

                    <mat-table [dataSource]="dataSourceResearchTeam" matSort matSortActive="researcherType" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">
                                    <img (click)="ResearchTeamDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\PersonalRemove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="4" style="color: #002F87;" *matCellDef="let element; let i = index">
                                <div style="color: #002F87;" class="font-light">{{i + 1}}</div>
                            </mat-cell>

                        </ng-container>

                        <ng-container matColumnDef="isInternal" style="margin-left: 10px;">
                            <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'นักวิจัย'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob; let i = index">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <mat-select class="font-light" *ngIf="isEditable" [(ngModel)]="objjob.isIntenalResearch" (selectionChange)="onOECDSChanged()">
                                        <div class="font-light-small">
                                            <mat-option *ngFor="let type of employeeType" [value]=type.isInternal>
                                                {{ type.desc }}
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                    <div class="font-light">
                                        <input *ngIf="!isEditable" class="font-light" readonly matInput maxlength="100" [value]="transformResearchOrgType(objjob.isIntenalResearch)" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="researcherType">
                            <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ประเภทนักวิจัย'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob">
                                <div matInput style="color: #002F87;" class="font-light">{{ researcherType }}</div>
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <mat-select class="font-light" *ngIf="isEditable" [disabled]='!isEditable' [(ngModel)]="objjob.researcherType">
                                        <div class="font-light-small">
                                            <mat-option *ngFor="let type of researcherTypeList" [value]=type.id>
                                                {{ type.researcherTypeName }}
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                    <div class="font-light">
                                        <input *ngIf="!isEditable" readonly class="M-educationHistsEdit" matInput maxlength="100" [value]="transformResearchType(objjob.researcherType)" autocomplete="off">
                                    </div>

                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="researcherFullName">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ตำแหน่งทางวิชาการ ชื่อ-นามสกุล'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob,let i = index">
                                <mat-form-field class="font-light">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' type="text" aria-label="Country" matInput [matAutocomplete]="auto" [(ngModel)]="objjob.researcherFullName" (input)="onResearcherNameInput(i,objjob.researcherFullName)">
                                    </div>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onResearcherNameselected(i,$event.option.value)">
                                        <mat-option *ngFor="let option of researcherSelectionList " [value]="option.nameTH">
                                            <div class="font-light-small">{{option.nameTH}}</div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>

                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="nationalId">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมายเลขบัตรประชาชน'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' matInput maxlength="100" [(ngModel)]="objjob.nationalId" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="organizationName">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สังกัด'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="250" [(ngModel)]="objjob.organizationName" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="percentAssociate">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สัดส่วนร้อยละ'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" style="text-align: right;" type="number" min="0" step="1" matInput maxlength="100" [(ngModel)]="objjob.percentAssociate" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="eMail">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'อีเมล์'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" *ngIf="!objjob.isEdit" matInput maxlength="100" [(ngModel)]="objjob.eMail" autocomplete="off">
                                    </div>
                                </mat-form-field>
                                <div *ngIf="objjob.isEdit" style="color: #002F87;" class="font-light">{{ objjob.eMail }}</div>
                            </mat-cell>
                        </ng-container>


                        <mat-header-row *matHeaderRowDef="displayedColumnsResearchTeam"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsResearchTeam;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 95%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; " (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>
                <!-- </div> -->
            </mat-tab>

            <mat-tab label="{{'งบประมาณ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isBudgetValid ? 'black' : 'red'  }">{{'งบประมาณ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <div class="table-container" style="padding: 5px;">
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="2"></div>
                            <div fxFlex="15%">
                                <mat-form-field class="font-light">
                                    <mat-select *ngIf="isEditable" class="font-light" placeholder="{{'การขอรับทุนภายในราชวิทยาลัยจุฬาภรณ์'}}" [(ngModel)]="fundingRequest" (selectionChange)="onFundRequestChanged()">
                                        <!-- <div class="font-light-small"> -->
                                        <mat-option style=" font-family: chulabhornlikittext-light; font-size: smaller;" [value]=0 class="custom-selection2">
                                            ขอรับทุน
                                        </mat-option>
                                        <mat-option style=" font-family: chulabhornlikittext-light; font-size: smaller;" [value]=1 class="custom-selection2">
                                            ไม่ขอรับทุน
                                        </mat-option>
                                        <!-- </div> -->
                                    </mat-select>

                                    <input *ngIf="!isEditable" readonly class="font-light" placeholder="{{'การขอรับทุนภายในราชวิทยาลัยจุฬาภรณ์'}}" matInput maxlength="150" autocomplete="off" value="{{fundingRequest=='0'?'ขอรับทุน':'ไม่ขอรับทุน'}}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>

                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select *ngIf="isEditable" class="font-light" placeholder="{{'ประเภททุน'}}" [(ngModel)]="fundingTypeIDSelected" (selectionChange)="onFundRequestTypeChange()">
                                        <!-- <div class="font-light-small"> -->
                                        <mat-option style=" font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let type of fundingtypes" [value]=type.id class="custom-selection">
                                            {{ type.fundingName }}
                                        </mat-option>
                                        <!-- </div> -->
                                    </mat-select>

                                    <input *ngIf="!isEditable" readonly class="font-light" placeholder="{{'ประเภททุน'}}" matInput maxlength="150" autocomplete="off" value="{{fundingTypeName}}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="50%">
                                <!-- <mat-form-field *ngIf="fundingTypeRemark" style="color: black;" class="font-light">
                                    <div class="font-light">
                                        <input readonly class="font-light" placeholder="{{'เงื่อนไขการขอทุน'}}" matInput maxlength="150" autocomplete="off" [(ngModel)]="fundingTypeRemark">
                                    </div>
                                </mat-form-field> -->

                                <mat-form-field *ngIf="fundingTypeIDSelected==11||fundingTypeIDSelected==6||fundingTypeIDSelected==2||fundingTypeIDSelected==12" class="font-light" style="color: black;" class="font-light">
                                    <div class="font-light">
                                        <input placeholder="{{'โปรดระบุแหล่งทุน'}}" matInput maxlength="150" autocomplete="off" [(ngModel)]="fundingOther">
                                    </div>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="isDetailBudgetNeeded" fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="2"></div>
                            <div fxFlex="15%">
                                <mat-form-field style="color: black;" class="font-light">
                                    <div class="font-light">
                                        <input readonly placeholder="{{'งบประมาณทั้งโครงการ  (บาท)'}}" matInput maxlength="12" autocomplete="off" style="text-align: right;" type="text" min="0" step="1" matInput autocomplete="off" value={{fundingTotalDisplay}}>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>

                            <div fxFlex="50%">
                                <mat-form-field style="color: black;" class="font-light">
                                    <div class="font-light">
                                        <input readonly placeholder="{{'จำนวนเงิน'}}" matInput maxlength="150" autocomplete="off" value={{bahtThaiBudget}}>
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="35%">
                                <mat-form-field style="color: black;" class="font-light">
                                    <div class="font-light">
                                        <input readonly placeholder="{{'งบประมาณสรุป'}}" matInput maxlength="150" autocomplete="off" style="text-align: left;" [(ngModel)]="budgetDescriptionByYear" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>
                        </div>
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="2"></div>
                            <div fxFlex="30%">
                                <mat-form-field class="font-light">
                                    <mat-select *ngIf="isEditable" class="font-light" placeholder="{{'สถานะการอนุมัติ EC'}}" [(ngModel)]="projectProposal.ecStatus">
                                        <!-- <div class="font-light-small"> -->
                                        <mat-option style=" font-family: chulabhornlikittext-light; font-size: smaller;" [value]=1 class="custom-selection2">
                                            ไม่เกี่ยวข้อง
                                        </mat-option>
                                        <mat-option style=" font-family: chulabhornlikittext-light; font-size: smaller;" [value]=2 class="custom-selection2">
                                            อยู่ระหว่างการยื่นขอ
                                        </mat-option>
                                        <mat-option style=" font-family: chulabhornlikittext-light; font-size: smaller;" [value]=3 class="custom-selection2">
                                            ผ่านการอนุมัติ
                                        </mat-option>
                                        <!-- </div> -->
                                    </mat-select>

                                    <input *ngIf="!isEditable" readonly class="font-light" placeholder="{{'การขออนุมัติ'}}" matInput maxlength="150" autocomplete="off" value="{{ecApprove}}">
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="30%">
                                <mat-form-field *ngIf="projectProposal.ecStatus==2||projectProposal.ecStatus==3" style="color: black;" class="font-light">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' placeholder="{{'หมายเลข EC'}}" matInput maxlength="15" autocomplete="off" style="text-align: left;" [(ngModel)]="projectProposal.ecNo" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="30%">
                                <mat-form-field *ngIf="projectProposal.ecStatus==3" class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input readonly placeholder="{{'วันที่อนุมัติ EC'}}" class="M-educationHistsEdit" min="0" step="1" matInput maxlength="100" value="projectProposal.ecDate|date:'dd MMM yyyy '" autocomplete="off" [matDatepicker]="pickerEc " [(ngModel)]="projectProposal.ecDate">
                                    </div>
                                    <mat-datepicker-toggle matSuffix [for]="pickerEc" *ngIf="isEditable"></mat-datepicker-toggle>
                                    <mat-datepicker #pickerEc></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="2"></div>

                        </div>
                    </div>

                    <div *ngIf="isEditable&&isDetailBudgetNeeded" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label (click)="btnAddBudget()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มงบประมาณ'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddBudget()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>
                    <mat-table *ngIf="isDetailBudgetNeeded" [dataSource]="dataSourceBudget" matSort matSortActive="BudgetGroup" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button mat-icon-button focusable="false" color="accent">

                                    <img *ngIf="isEditable" (click)="BudgetDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>



                        <ng-container matColumnDef="budgetCategory">
                            <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณย่อย'}}</mat-header-cell>
                            <mat-cell fxFlex="15" *matCellDef="let objjob,let i = index">
                                <mat-select *ngIf="isEditable" class="font-light" [(ngModel)]="objjob.budgetCategoryId" (selectionChange)="onCategoryChange($event.value,i)">
                                    <div class="font-light-small">
                                        <mat-option *ngFor="let budgetSubGroup of budgetSubGroupList" [value]=budgetSubGroup.id>
                                            {{ budgetSubGroup.description }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                                <div *ngIf="!isEditable" class="font-light">{{objjob.budgetCategory}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="budgetGroup">
                            <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณ'}}</mat-header-cell>
                            <mat-cell fxFlex="15" *matCellDef="let objjob">
                                <div class="font-light">{{objjob.budgetGroup}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="budgetDescription">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'รายการ'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.budgetDescription" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="budget">
                            <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'งบประมาณ (รวม)'}}</mat-header-cell>
                            <mat-cell fxFlex="15" *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input localizedNumericInput [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="13" style="text-align: right;" type="text" autocomplete="off" [(ngModel)]="objjob.budget" (input)='onBudgetInRowChange()'>
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="fiscalYear">
                            <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีงบประมาณ'}}</mat-header-cell>
                            <mat-cell fxFlex="10" *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <mat-select class="font-light" [disabled]='!isEditable' [(ngModel)]="objjob.fiscalYear" (selectionChange)="onBudgetInRowChange()">
                                        <div class="font-light-small">
                                            <mat-option *ngFor="let year of fiscalYearList" [value]=year>
                                                {{ year }}
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsBudget"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsBudget;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

            <mat-tab *ngIf="isLongForm" class="font-light" label="{{'ประเภทการวิจัย'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isResearcherTypeValid ? 'black' : 'red'}">{{'ประเภทการวิจัย'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px; padding-top: 30px;">

                    <mat-radio-group [disabled]="!isEditable" fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="researchDescriptionTypeId" (change)="onProjectTypeChange()">
                        <div class="table-container" style="padding: 5px; ">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="20%"></div>
                                <div fxFlex="30%">
                                    <mat-radio-button class="font-light" style="color: #002F87;" value='1'>{{'วิจัยพื้นฐาน'}}</mat-radio-button>

                                </div>
                                <div fxFlex="10%"></div>

                                <div fxFlex="40%">
                                    <mat-radio-button class="font-light" style=" color: #002F87; " value='2'>{{'วิจัยประยุกต์'}}</mat-radio-button>
                                </div>


                                <div fxFlex="2"></div>
                            </div>
                        </div>

                        <div class="table-container" style="padding: 5px;">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="20%"></div>
                                <div fxFlex="30%">
                                    <mat-radio-button class="font-light" style=" color: #002F87; " value='3'>{{'วิจัยเพื่อพัฒนาและทดลอง'}}</mat-radio-button>

                                </div>
                                <div fxFlex="10%"></div>

                                <div fxFlex="40%">
                                    <mat-radio-button class="font-light" style=" color: #002F87;" value='4'>{{'วิจัยเชิงสังคม'}}</mat-radio-button>
                                </div>


                                <div fxFlex="2"></div>
                            </div>
                        </div>

                        <div class="table-container" style="padding: 5px;">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="20%"></div>
                                <div fxFlex="30%">
                                    <mat-radio-button class="font-light" style=" color: #002F87;" value='5'>{{'วิจัยแบบอื่นๆ'}}</mat-radio-button>

                                </div>
                                <div fxFlex="10%"></div>

                                <div fxFlex="40%">

                                </div>


                                <div fxFlex="2"></div>
                            </div>
                        </div>

                        <div class="table-container" style="padding-left: 5px;">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="20"></div>
                                <div fxFlex="60%">
                                    <mat-form-field *ngIf="showTypeOtherInformation" class="font-light" style="border: 0px;">
                                        <div class="font-light">
                                            <input matInput maxlength="50" style="width: 50%;" autocomplete="off" placeholder="{{'โปรดระบุ' }}" [(ngModel)]="projectProposal.otherResearchDescriptionType">
                                        </div>
                                    </mat-form-field>
                                </div>
                                <div fxFlex="20"></div>
                            </div>
                        </div>
                    </mat-radio-group>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>


            </mat-tab>

            <mat-tab *ngIf="isLongForm" class="font-light" label="{{'สาขาวิชา'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isOecdValid ? 'black' : 'red'}">{{'สาขาวิชา'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px; padding-top: 30px;">

                    <div class="table-container" style="padding: 5px; ">
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="30%"></div>
                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" [disabled]="!isEditable" placeholder="{{'สาขาวิชาหลัก (OECD)'}}" [(ngModel)]="projectProposal.oecdId" (selectionChange)="onOECDSChanged()">

                                        <div class="font-light-small">
                                            <mat-option *ngFor="let oecd of oecds" [value]=oecd.id class="mat-selection">
                                                {{ oecd.oecdDesc }}
                                            </mat-option>
                                        </div>
                                    </mat-select>

                                </mat-form-field>

                            </div>

                            <div fxFlex="35%">
                            </div>

                        </div>
                    </div>

                    <div class="table-container" style="padding: 5px; ">
                        <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                            <div fxFlex="30%"></div>
                            <div fxFlex="35%">
                                <mat-form-field class="font-light">
                                    <mat-select class="font-light" [disabled]="!isEditable" placeholder="{{'สาขาวิชาที่เกี่ยวข้อง (OECD)'}}" [(ngModel)]="projectProposal.oecdSubId">
                                        <div class="font-light-small">
                                            <mat-option *ngFor="let oecdsub of oecdsubs" [value]=oecdsub.id>
                                                {{ oecdsub.oecdSubDesc }}
                                            </mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>

                            </div>

                            <div fxFlex="35%">
                            </div>

                        </div>
                    </div>







                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>


            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'วัตถุประสงค์ของการทำวิจัย'}}">
                <!-- <ng-template mat-tab-label>
                    <span class="font-medium"  [ngStyle]="{'color': isBudgetValid ? 'black' : 'red'  }">{{'งบประมาณ'}}</span>
                </ng-template> -->
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isObjectValid ? 'black': 'red'}">{{'วัตถุประสงค์ของการทำวิจัย'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">

                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                            <label (click)="btnAddObjective()" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มวัตถุประสงค์'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddObjective()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>
                    <mat-table [dataSource]="dataSourceObjective" matSort matSortActive="Objective" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">

                                    <img (click)="ResearchObjectivesDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" style="color: #002F87;" *matCellDef="let element; let objjob ; let i = index">
                                <div class="font-light">{{i+1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="objective">
                            <mat-header-cell fxFlex="100%" style="color: #002F87; text-align: center;" class="font-light" *matHeaderCellDef>{{'วัตถุประสงค์'}}</mat-header-cell>
                            <mat-cell fxFlex="100%" *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="700" [(ngModel)]="objjob.objective" autocomplete="off">
                                    </div>
                                </mat-form-field>
                                <!-- <div *ngIf="!objjob.isEdit" style="color: #002F87; text-align: center;" class="font-light">{{ objjob.Objective }}</div> -->
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsObjective"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsObjective;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>
                <!-- </div> -->
            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'คำสำคัญ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isKeywordValid ? 'Black': 'Red'}">{{'คำสำคัญ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <!-- <div style="padding: 10px; text-align: right;" class="font-bold">
                        <tr style="width: 100%;">
                            <td style="width: 90%;"></td>
                            <td style="width: 10%;">
                                <label (click)="btnAddKeyword()" style="color: #002F87; font-size: 15px; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มคำสำคัญ'}}</label>
                            </td>
                            <td style="width: 1%;">
                                <img (click)="btnAddKeyword()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                            </td>

                        </tr>
                    </div> -->

                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;" class="font-light">
                            <label (click)="btnAddKeyword()" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มคำสำคัญ'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddKeyword()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>

                    <mat-table [dataSource]="dataSourceKeyword" matSort matSortActive="KeywordTH" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">

                                    <img (click)="KeywordDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell style="color: #002F87;" fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="keywordEN">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'คำสำคัญ (TH)'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.keywordEN" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="keywordTH">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'คำสำคัญ (EN)'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.keywordTH" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>




                        <mat-header-row *matHeaderRowDef="displayedColumnsKeyword"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsKeyword;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>
            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'ความสำคัญและที่มาของปัญหา'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isKeyworAndProblemdValid ? 'Black': 'Red'}">{{'ความสำคัญและที่มาของปัญหา'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <label *ngIf="isEditable" class="font-light" style="color: red; font-size: 13px;padding-left: 5px;">ระบุได้ไม่เกิน 10,000 ตัวอักษร</label>
                    <div>
                        <angular-editor class="font-light" *ngIf="isEditable" (ngModelChange)="onEditorKewardAndProblem($event)" (click)="onclickEditorKewardAndProblem(editorKewardAndProblem)" [(ngModel)]="editorKewardAndProblem" [config]="editorConnfig">
                        </angular-editor>

                    </div>
                    <label *ngIf="isEditable" class="font-light" style="color: red; font-size: 13px;padding-left: 5px;">ระบุได้ไม่เกิน 10,000 ตัวอักษร</label>

                    <div *ngIf="!isEditable" style="margin: 10px;" [innerHtml]="editorKewardAndProblem"> </div>

                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>

                </mat-card>
            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'การทบทวนวรรณกรรม'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isLiteratureReviewValid ? 'Black': 'Red'}">{{'การทบทวนวรรณกรรม'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <!-- <div style="padding: 10px; text-align: right;" class="font-bold">
                            <tr style="width: 100%;">
                                <td style="width: 91%;"></td>
                                <td style="width: 8%;">
                                </td>
                                <td style="width: 1%;">
                                </td>

                            </tr>
                        </div> -->
                      <label *ngIf="isEditable" class="font-light" style="color: red; font-size: 13px;padding-left: 5px;">ระบุได้ไม่เกิน 10,000 ตัวอักษร</label>
                    <angular-editor class="font-light" *ngIf="isEditable" (ngModelChange)="onEditorLiteratureReview($event)" (click)="onclickEditorLiteratureReview(editorLiteratureReview)" [(ngModel)]="editorLiteratureReview" [config]="editorConnfig">
                    </angular-editor>
                    <label *ngIf="isEditable" class="font-light" style="color: red; font-size: 13px;padding-left: 5px;">ระบุได้ไม่เกิน 10,000 ตัวอักษร</label>

                    <div *ngIf="!isEditable" style="margin: 10px;" [innerHtml]="editorLiteratureReview"> </div>
                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>

                </mat-card>
            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'ระเบียบวิธีวิจัย'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isResearchMethodsValid ? 'Black': 'Red'}">{{'ระเบียบวิธีวิจัย'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">


                    <mat-table [dataSource]="dataSourceResearchMethodology" matSort matSortActive="ResearchMethodologyType" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="5" style="color: #002F87;" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="researchMethodType">
                            <mat-header-cell fxFlex="50" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ประเภทระเบียบวิธีวิจัย'}}</mat-header-cell>
                            <mat-cell fxFlex="50" *matCellDef="let objjob">
                                <div class="font-light">
                                    {{objjob.researchMethodType}}
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="researchMethodDetail">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>
                                {{'รายละเอียดระเบียบวิธีวิจัย'}}&nbsp;&nbsp;
                                <a style="padding-bottom: 2px; margin-left: 1px;" title="คำอธิบายระเบียบวิธีวิจัย" download="คำอธิบายระเบียบวิธีวิจัย.pdf" target="_blank" href={{methodologyDoc}}>
                                    <img src="assets\Icon\pdf_2.png">
                                </a>

                            </mat-header-cell>
                            <mat-cell *matCellDef="let objjob;let i = index">
                                <!-- <mat-form-field class="font-light" style="width: 95%;"> -->
                                <div style="width: 100%; text-align: center;" (click)="openTextEditorDialog(i)">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\documentdetail.png">
                                </div>
                                <!-- <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.researchMethodDetail" autocomplete="off"> -->
                                <!-- </mat-form-field> -->
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumnsResearchMethodology"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsResearchMethodology;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>
                <!-- </div> -->
            </mat-tab>

            <mat-tab *ngIf="isLongForm" class="font-light" label="{{'สถานที่ทำวิจัย'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isResearchSiteHeadIdValid ? 'Black': 'Red'}">{{'สถานที่ทำวิจัย'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px; padding-top: 30px;">

                    <mat-radio-group [disabled]="!isEditable" fxLayoutGap=100px style="margin-top: 15px;" [(ngModel)]="researchSiteHeadId" (change)="onresearchSiteChange()">
                        <div class="table-container" style="padding: 5px; ">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="20%"></div>
                                <div fxFlex="10%">
                                    <mat-radio-button class="font-light" style=" color: #002F87;" value='1'>{{'Single center ระบุ'}}
                                        <mat-form-field class="font-light" style="color: black;">
                                            <div class="font-light">
                                                <input [disabled]="researchSiteHeadId!=1" matInput maxlength="300" autocomplete="off" [(ngModel)]="projectProposal.researchSingleCenterName" (input)="checkDataInTab()">
                                            </div>
                                        </mat-form-field>
                                    </mat-radio-button>
                                </div>
                                <div fxFlex="50%">

                                </div>

                                <div fxFlex="20%">

                                </div>
                            </div>

                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="20%"></div>
                                <div fxFlex="10%">
                                    <mat-radio-button class="font-light" style="color: #002F87;" value='2'>{{'Multiple centers'}}</mat-radio-button>
                                </div>
                                <div fxFlex="50%">

                                </div>

                                <div fxFlex="20%">

                                </div>
                            </div>
                        </div>
                    </mat-radio-group>
                    <div *ngIf="researchSiteHeadId==2" style="padding-left: 5px; " fxLayout="row">
                        <div fxFlex="25%"></div>
                        <div fxFlex="20%">
                            <mat-checkbox [disables]="!isEditable" class="font-light" style="color: #002F87; margin-bottom: 20px;" [(ngModel)]="projectProposal.isOnlyNationalSite">{{'เฉพาะประเทศไทย ระบุชื่อหน่วยงาน/สถาบัน'}}
                                <mat-form-field style="color: black;" class="font-light">
                                    <textarea class="font-light" [readonly]="!isEditable" rows="3" matInput maxlength="700" autocomplete="off" [(ngModel)]="projectProposal.researchNationalSiteName" (input)="checkDataInTab()"></textarea>
                                </mat-form-field>
                            </mat-checkbox>
                        </div>
                    </div>
                    <div *ngIf="researchSiteHeadId==2" style="padding-left: 5px; " fxLayout="row">
                        <div fxFlex="25%"></div>
                        <div fxFlex="20%">
                            <mat-checkbox [disables]="!isEditable" class="font-light" style="color: #002F87; margin-bottom: 20px;" [(ngModel)]="projectProposal.isCoInternationalSite">{{'ร่วมกับต่างประเทศ ระบุชื่อหน่วยงาน/สถาบัน'}}
                                <mat-form-field style="color: black;" class="font-light">
                                    <textarea class="font-light" [readonly]="!isEditable" rows="3" matInput maxlength="700" autocomplete="off" [(ngModel)]="projectProposal.researchCoInternaltionalSiteName" (input)="checkDataInTab()"></textarea>
                                </mat-form-field>
                            </mat-checkbox>
                        </div>
                    </div>


                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'ระยะเวลาดำเนินการ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isTimeLineValid ? 'Black': 'Red'}">{{'ระยะเวลาดำเนินการ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">

                    <div style="padding: 10px; text-align: right;" class="font-bold">

                        <div fxFlex="30%">
                            <label class="font-light" style="color: #002F87;">ระยะเวลาการดำเนินการวิจัย
                                <mat-form-field class="font-light" style="color: black; width: 40%;">
                                   <div class="font-light">
                                    <input [readonly]="!isEditable" type="number" min="0" step="1" matInput maxlength="10" autocomplete="off" [(ngModel)]="totalTime"  style="text-align: center;" >
                               </div>
                                </mat-form-field>
                                <label class="font-light" style="color: #002F87;">เดือน</label>
                            </label>

                        </div>
                        <div fxFlex="50%"></div>

                        <div *ngIf="isEditable" fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label (click)="btnAddProcessingTime()" class="font-light" style="color: #002F87;  padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มแผนงาน'}}</label>
                        </div>

                        <div *ngIf="isEditable" style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddProcessingTime()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>
                    <mat-table [dataSource]="dataSourceProcessingTime" matSort matSortActive="fromMonth" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>
                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">
                                    <img (click)="ProcessingTimeDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="fiscalYear">
                            <mat-header-cell fxFlex="5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีที่'}}</mat-header-cell>
                            <mat-cell fxFlex="5" *matCellDef="let objjob">
                                <mat-select class="font-light" [disabled]="!isEditable" [(ngModel)]="objjob.fiscalYear">
                                    <div class="font-light-small">
                                        <mat-option *ngFor="let year of fiscalYearListTwo" [value]=year>
                                            {{ year }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="activityName">
                            <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'กิจกรรมตามการดำเนินการวิจัย' }}</mat-header-cell>
                            <mat-cell fxFlex="15" *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.activityName" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="timeperiod">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ระยะเวลาดำเนินการ (เดือนเริ่มต้นถึงเดือนสิ้นสุด)'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob" class="custom-ngx-slider" style="padding-right: 10px;">
                                <ngx-slider [readonly]="!isEditable" class="font-light" [(value)]="objjob.fromMonth" [(highValue)]="objjob.toMonth" [options]="options"></ngx-slider>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="percentOfActivity">
                            <mat-header-cell fxFlex="8" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ร้อยละกิจกรรมต่อโครงการวิจัย'}} </mat-header-cell>
                            <mat-cell fxFlex="8" *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" type="number" class="M-numberField" matInput maxlength="100" [(ngModel)]="objjob.percentOfActivity" autocomplete="off" size="6" style="text-align: center;">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>



                        <mat-header-row *matHeaderRowDef="displayedColumnsProcessingTime"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsProcessingTime;"></mat-row>
                    </mat-table>

                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'ผลที่คาดว่าจะได้รับ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isExpectedResultsValid ? 'Black': 'Red'}">{{'ผลที่คาดว่าจะได้รับ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">

                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%" style="text-align: right;">
                        </div>
                        <!-- <div fxLayout="row" fxFlex="15%" style="text-align: right; margin-top: 4px; padding-right: 3px;">

                             <a style="margin-left: 1px;" title="ผลที่คาดว่าจะได้รับจากการวิจัย" download="ผลที่คาดว่าจะได้รับจากการวิจัย.pdf" target="_blank" href="/assets/files/guideline/expectedresult.pdf">
                                <img src="assets\Icon\pdf_1.png">
                            </a>
                            <label (click)="btnAddExpectedResult()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px; margin-left: 3px;">{{'เพิ่มกิจกรรม'}}</label>
                        </div> -->
                        <div fxFlex="14%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <a style="margin-left: 1px;" title="คำอธิบายผลที่คาดว่าจะได้รับ" download="คำอธิบายผลที่คาดว่าจะได้รับ.pdf" target="_blank" href={{expectedResultDoc}}>
                                <img src="assets\Icon\pdf_2.png">
                            </a>
                        </div>

                        <div fxFlex="6%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label (click)="btnAddExpectedResult()" class="font-light" style="color: #002F87;  padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มกิจกรรม'}}</label>
                        </div>

                        <div fxFlex="5%" fxLayout="row">
                            <img (click)="btnAddExpectedResult()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">

                        </div>
                    </div>

                    <mat-table [dataSource]="dataSourceExpectedResults" matSort matSortActive="fromMonth" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button mat-icon-button focusable="false" color="accent">

                                    <img *ngIf="isEditable" (click)="ExpectedResultsDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="fromMonth">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'เริ่มต้น'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob; let i = index">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" type="number" min="0" step="1" matInput maxlength="10" [(ngModel)]="objjob.fromMonth" autocomplete="off" (change)="checkIfGreaterThanTotalTime(objjob.fromMonth,'from',i)">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="toMonth">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สิ้นสุด'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob; let i = index">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]="!isEditable" class="M-educationHistsEdit" type="number" min="0" step="1" matInput maxlength="10" [(ngModel)]="objjob.toMonth" autocomplete="off" (change)="checkIfGreaterThanTotalTime(objjob.toMonth,'to',i)">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="activityName">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'กิจกรรม/ขั้นตอนการดำเนินงาน'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <!-- <input [readonly]="!isEditable" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.activityName" autocomplete="off"> -->
                                    <textarea [readonly]='!isEditable' class="font-light" matInput maxlength="300" rows="3" [(ngModel)]="objjob.activityName" autocomplete="off"></textarea>
                                </mat-form-field>

                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="expectedResult">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ผลที่คาดว่าจะได้รับ'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <textarea [readonly]='!isEditable' class="font-light" matInput maxlength="300" rows="3" [(ngModel)]="objjob.expectedResult" autocomplete="off"></textarea>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsExpectedResults"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsExpectedResults;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>



            <mat-tab *ngIf="isLongForm" label="{{'เหตุผลการซื้อครุภัณฑ์'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isDurableArticlesValid ? 'Black': 'Red'}">{{'เหตุผลการซื้อครุภัณฑ์'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label (click)="onclickReasonsForPurchasingEquipment()" class="font-light" style="color: #002F87;  padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มครุภัณฑ์'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="onclickReasonsForPurchasingEquipment()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>


                    <mat-table [dataSource]="dataSourceReasonforPurchasingEquipment" matSort matSortActive="NameOfEquipment" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">
                                    <img (click)="ReasonsForPurchasingEquipmentDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>


                        <ng-container matColumnDef="orderNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="durableArticleName">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ชื่อครุภัณฑ์'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' matInput maxlength="100" [(ngModel)]="objjob.durableArticleName" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="durableArticleStatus">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สถานภาพครุภัณฑ์'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <!-- <input [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.durableArticleStatus" autocomplete="off"> -->
                                    <mat-select class="font-light" [(ngModel)]="objjob.durableArticleStatus">
                                        <div class="font-light-small">
                                            <mat-option value="มี">มี</mat-option>
                                            <mat-option value="ไม่มี">ไม่มี</mat-option>
                                        </div>
                                    </mat-select>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="equivalentDurableArticle">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ครุภัณฑ์ใกล้เคียงที่ใช้'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.equivalentDurableArticle" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="presentStatus">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'สถานะภาพการใช้งานปัจจุบัน'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.presentStatus" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="usage">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลักษณะการใช้งานและความจำเป็น'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.usage" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsReasonforPurchasingEquipment"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsReasonforPurchasingEquipment;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'เอกสารอ้างอิง'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isReferenceDocumentsValid ? 'Black': 'Red'}">{{'เอกสารอ้างอิง'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">

                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label (click)="btnAddReference()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มเอกสาร'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddReference()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>

                    <mat-table [dataSource]="dataSourceReferenceDocuments" matSort matSortActive="ReferenceDocuments" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">
                                    <img (click)="ReferencesDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="referenceDocumentDetail">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'อ้างอิงเอกสารตามระบบแวนคูเวอร์'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!isEditable' class="M-educationHistsEdit" matInput maxlength="700" [(ngModel)]="objjob.referenceDocumentDetail" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsReferenceDocuments"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsReferenceDocuments;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'ประโยชน์ที่คาดว่าจะได้รับ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isExpectedBenefitsValid ? 'Black': 'Red'}">{{'ประโยชน์ที่คาดว่าจะได้รับ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <div *ngIf="isEditable" style="padding: 10px; text-align: right;" class="font-bold">
                        <div fxFlex="80%"></div>

                        <div fxFlex="18.5%" style="text-align: right; margin-top: 4px; padding-right: 3px;">
                            <label (click)="btnAddBenefits()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">{{'เพิ่มประโยชน์'}}</label>
                        </div>

                        <div style="padding-right: 12px; " fxFlex="1.5%">
                            <img (click)="btnAddBenefits()" style=" height: 28px; width: 28px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Add.png">
                        </div>
                    </div>

                    <mat-table [dataSource]="dataSourceExpectedBenefits" matSort matSortActive="Anticipation" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>

                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>

                                <button mat-icon-button focusable="false" color="accent">
                                    <img *ngIf="isEditable" (click)="ExpectedBenefitsDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="expectation">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'คำอธิบาย'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <textarea [readonly]='!isEditable' class="font-light" matInput maxlength="700" rows="3" [(ngModel)]="objjob.expectation" autocomplete="off"></textarea>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsExpectedBenefits"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsExpectedBenefits;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>
            <mat-tab *ngIf="isLongForm" class="font-light" label="{{'ผลผลิตและผลลัพธ์ที่คาดว่าจะได้รับ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isProductAndResultProjectValid ? 'Black': 'Red'}">{{'ผลผลิตและผลลัพธ์ที่คาดว่าจะได้รับ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px; padding-top: 30px;">

                    <section class="example-section">
                        <div class="table-container">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="20%"></div>
                                <div fxFlex="50%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.presentWork" class="font-light" style="color: #002F87;"><b>{{'นำเสนอผลงานในการประชุมวิชาการ จำนวน'}}</b>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input disabled style="text-align: right;" type="number" min="0" step="1" matInput maxlength="150" autocomplete="off" [(ngModel)]="projectProposal.received.presentWorkNumber">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><b>ครั้ง</b></label>
                                    </mat-checkbox>
                                </div>

                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <div class="table-container">
                            <!-- checkbox รอง -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="25%"></div>
                                <div fxFlex="45%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.presentWorkNational" class="font-light" style="color: #002F87;"><i>{{'ระดับชาติ จำนวน'}}</i>
                                        <mat-form-field style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input disabled style="text-align: right;" type="number" min="0" step="1" matInput maxlength="150" autocomplete="off" [(ngModel)]="projectProposal.received.presentWorkNationalNumber">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><i>ครั้ง</i></label>
                                    </mat-checkbox>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <div class="table-container">

                            <!-- input ลำดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="40%">
                                    <label class="font-light" style="color: #002F87;">{{'Oral Presentation จำนวน'}}
                                            <mat-form-field class="font-light" style="color: black; width: 20%;">
                                                <div class="font-light" >
                                                <input [readonly]='!isEditable' style="text-align: right;"  type="number" min="0" step="1" [(ngModel)]="projectProposal.received.presentWorkNationalOralNumber"  matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                         </div>
                                            </mat-form-field>
                                            <label class="font-light" style="color: #002F87;">ครั้ง</label>
                                    </label>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <div class="table-container">

                            <!-- input ลำดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="40%">
                                    <label class="font-light" style="color: #002F87;">{{'Poster Presentation จำนวน'}}
                                            <mat-form-field class="font-light" style="color: black; width: 20%;">
                                                <div class="font-light" >
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.presentWorkNationalPosterNumber"  matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                           </div>
                                            </mat-form-field>
                                            <label class="font-light" style="color: #002F87;">ครั้ง</label>
                                    </label>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <div class="table-container">

                            <!-- checkbox รอง -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="25%"></div>
                                <div fxFlex="45%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.presentWorkInternational" class="font-light" style="color: #002F87;"><i>{{'ระดับนานาชาติ จำนวน'}}</i>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input disabled style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.presentWorkInternationalNumber" matInput maxlength="150" autocomplete="off">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><i>ครั้ง</i></label></mat-checkbox>
                                </div>

                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <div class="table-container">

                            <!-- input ลำดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="40%">
                                    <label class="font-light" style="color: #002F87; ">{{'Oral Presentation จำนวน'}}
                                            <mat-form-field class="font-light" style="color: black; width: 20%;">
                                                <div class="font-light">
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.presentWorkInternationalOralNumber" matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                          </div>
                                            </mat-form-field>
                                            <label class="font-light" style="color: #002F87;">ครั้ง</label>
                                    </label>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <div class="table-container">

                            <!-- input ลำดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="40%">
                                    <label class="font-light" style="color: #002F87;">{{'Poster Presentation จำนวน'}}
                                            <mat-form-field class="font-light" style="color: black; width: 20%;">
                                                <div class="font-light">
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.presentWorkInternationalPosterNumber" matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                         </div>
                                            </mat-form-field>
                                            <label class="font-light" style="color: #002F87;">ครั้ง</label>
                                    </label>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>


                        <!-- checkbox หลัก -->
                        <div class="table-container">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="20%"></div>
                                <div fxFlex="50%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.article" class="font-light" style="color: #002F87;"><b>{{'ผลงานตีพิมพ์ จำนวน'}}</b>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input disabled style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.articleNumber" matInput maxlength="150" autocomplete="off">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><b>เรื่อง</b></label></mat-checkbox>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>

                            <!-- checkbox รอง -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="25%"></div>
                                <div fxFlex="45%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.journalNational" class="font-light" style="color: #002F87;"><i>{{'วารสารวิชาการระดับชาติ จำนวน'}}</i>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.journalNationalNumber" matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><i>เรื่อง ปีที่คาดว่าจะตีพิมพ์ พ.ศ.</i></label>
                                        <mat-form-field class="font-light" style="color: black; width: 15%; text-align: center;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable' type="number" min="0" step="1" [(ngModel)]="projectProposal.received.journalNationalPublishYear" matInput maxlength="4" minlength="4" autocomplete="off">
                                            </div>
                                        </mat-form-field>
                                    </mat-checkbox>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>

                            <!-- RadioButon ระดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="40%">
                                    <section fxLayout="row" class="font-light;">
                                        <label class="font-light" style=" color: #002F87; padding-right: 30px; margin-top: 15px;">{{'ระบบ TCI Tier' }}</label>
                                        <mat-radio-group [disabled]='!isEditable' fxLayoutGap=30px style="margin-top: 15px;" [(ngModel)]="tciTier">
                                            <mat-radio-button [disabled]='isEditableJournalNational' style="color: #002F87; margin-right: 30px;" value='1'>1</mat-radio-button>
                                            <mat-radio-button [disabled]='isEditableJournalNational' style="color: #002F87;" value='2'>2</mat-radio-button>
                                            <mat-radio-button [disabled]='isEditableJournalNational' style="color: #002F87;" value='3'>3</mat-radio-button>
                                        </mat-radio-group>
                                    </section>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>

                            <!-- RadioButon ระดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="25%"></div>
                                <div fxFlex="45%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.journalInternational" class="font-light" style="color: #002F87;"><i>{{'วารสารวิชาการระดับนานาชาติ จำนวน'}}</i>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.journalInternationalNumber" matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><i>เรื่อง ปีที่คาดว่าจะตีพิมพ์ พ.ศ.</i></label>
                                        <mat-form-field class="font-light" style="color: black; width: 15%; text-align: center;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable' type="number" min="0" step="1" [(ngModel)]="projectProposal.received.journalInternationalPublishYear" matInput maxlength="150" autocomplete="off">
                                            </div>
                                        </mat-form-field>
                                    </mat-checkbox>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>



                            <!-- RadioButon ระดับ3 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="50%">
                                    <section fxLayout="row" class="font-light;">
                                        <label class="font-light" style="color: #002F87; padding-right: 30px; margin-top: 15px;">{{'ฐานข้อมูล' }}</label>
                                        <mat-radio-group [disabled]='!isEditable || isDisableJournalInternational' fxLayoutGap=30px style="margin-top: 15px;" (change)="onchangjournalInternationalDatabase(journalInternationalDatabase)" [(ngModel)]="journalInternationalDatabase">
                                            <mat-radio-button class="font-light" style="color: #002F87; margin-right: 30px;" value=1>{{'Scopus'}}</mat-radio-button>
                                            <mat-radio-button class="font-light" style="color: #002F87;" value=2>{{'Web of science'}}</mat-radio-button>
                                            <mat-radio-button class="font-light" style="color: #002F87;" value=3>{{'อื่นๆ ระบุ'}}</mat-radio-button>
                                        </mat-radio-group>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input [readonly]=' isReadonlyJournalInternational' [(ngModel)]="projectProposal.received.journalInternationalOther" matInput maxlength="150" autocomplete="off">
                                            </div>
                                        </mat-form-field>
                                    </section>
                                </div>


                                <div fxFlex="20%"></div>

                                <div fxFlex="2"></div>

                            </div>

                            <!-- RadioButon ระดับ4 -->
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="30%"></div>
                                <div fxFlex="50%">
                                    <section fxLayout="row" class="font-light;">
                                        <label class="font-light" style="color: #002F87; padding-right: 30px; margin-top: 15px;">{{'ระดับควอไทล์' }}</label>
                                        <mat-radio-group [disabled]='!isEditable || isDisableJournalInternational' [(ngModel)]="quartile" fxLayoutGap=30px style="margin-top: 15px;">
                                            <mat-radio-button class="font-light" style="color: #002F87; margin-right: 30px;" value=1>Q1</mat-radio-button>
                                            <mat-radio-button class="font-light" style="color: #002F87;" value=2>Q2</mat-radio-button>
                                            <mat-radio-button class="font-light" style="color: #002F87;" value=3>Q3</mat-radio-button>
                                            <mat-radio-button class="font-light" style="color: #002F87;" value=4>Q4</mat-radio-button>
                                        </mat-radio-group>
                                    </section>
                                </div>


                                <div fxFlex="20%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <!-- mat-checkbox หลัก -->
                        <div class="table-container">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="20%"></div>
                                <div fxFlex="50%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.patent" class="font-light" style="color: #002F87;"><b>{{'สิทธิบัตร หรืออนุสิทธิบัตร จำนวน'}}</b>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.patentNumber" matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><b>เรื่อง / ชิ้นงาน / ผลงาน</b></label>
                                    </mat-checkbox>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <!-- mat-checkbox หลัก -->
                        <div class="table-container">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="20%"></div>
                                <div fxFlex="50%">
                                    <mat-checkbox (click)="false" [(ngModel)]="projectProposal.received.commercialInnovation" class="font-light" style="color: #002F87;"><b>{{'นวัตกรรมเชิงพานิชย์ จำนวน'}}</b>
                                        <mat-form-field class="font-light" style="color: black; width: 20%;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable' style="text-align: right;" type="number" min="0" step="1" [(ngModel)]="projectProposal.received.commercialInnovationNumber" matInput maxlength="150" autocomplete="off" (input)="onPresentationTabDataChange()">
                                            </div>
                                        </mat-form-field>
                                        <label class="font-light" style="color: #002F87;"><b>เรื่อง / ชิ้นงาน ระบุ</b></label>
                                        <mat-form-field class="font-light" style="color: black; width: 100%;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable || isReadonlyCommercialInnovation' [(ngModel)]="projectProposal.received.commercialInnovationWorkPiece" matInput maxlength="150" autocomplete="off">
                                            </div>
                                        </mat-form-field>
                                    </mat-checkbox>
                                </div>


                                <div fxFlex="30%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <!-- mat-checkbox หลัก -->
                        <div class="table-container">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="20%"></div>
                                <div fxFlex="15%">
                                    <mat-checkbox [disabled]='!isEditable' [(ngModel)]="projectProposal.received.copyright" (change)="onChangcopyright()" class="font-light" style="color: #002F87;"><b>{{'ทรัพย์สินทางปัญญา'}}</b></mat-checkbox>
                                </div>
                                <div fxFlex="2%">
                                </div>
                                <div fxFlex="3%">
                                </div>

                                <div fxFlex="60%"></div>

                                <div fxFlex="2"></div>

                            </div>
                        </div>

                        <!-- mat-checkbox หลัก -->
                        <div class="table-container">
                            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                                <div fxFlex="2"></div>
                                <div fxFlex="20%"></div>
                                <div fxFlex="80%">
                                    <mat-checkbox [disabled]='!isEditable' [(ngModel)]="projectProposal.received.other" (change)="onChangReceivedOthrt()" class="font-light" style="color: #002F87;"><b>{{'อื่นๆ ระบุ'}}</b>
                                        <mat-form-field class="font-light" style="color: black; width: 400%;">
                                            <div class="font-light">
                                                <input [readonly]='!isEditable || isReadonlyReceivedOther' [(ngModel)]="projectProposal.received.otherDetail" matInput maxlength="500" autocomplete="off">
                                            </div>
                                        </mat-form-field>

                                    </mat-checkbox>
                                </div>


                                <div fxFlex="2"></div>

                            </div>
                        </div>
                    </section>





                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

            <mat-tab *ngIf="isLongForm" label="{{'ผลกระทบที่คาดว่าจะได้รับ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': isExpectedImpactsValid ? 'Black': 'Red'}">{{'ผลกระทบที่คาดว่าจะได้รับ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">

                    <mat-table [dataSource]="dataSourceExpectedImpact" matSort matSortActive="DetailsOfTheExpectedImpact" matSortDirection="asc" matSortDisableClear>


                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="4" *matCellDef="let element; let i = index">
                                <div class="font-light">{{i + 1}}
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="canBeUtilized">
                            <mat-header-cell fxFlex="6" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'การนำไปใช้'}}</mat-header-cell>
                            <mat-cell fxFlex="6" *matCellDef="let objjob let i = index">
                                <mat-checkbox [(ngModel)]="objjob.canBeUtilized" (change)="oncanBeUtilized(objjob.canBeUtilized,i)" (change)="objjob.comment=''"></mat-checkbox>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="expectedImpact">
                            <mat-header-cell fxFlex="30" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'รายละเอียดผลกระทบที่คาดว่าจะได้รับ'}}</mat-header-cell>
                            <mat-cell fxFlex="30" *matCellDef="let objjob">
                                <div class="font-light">
                                    {{objjob.expectedImpact}}
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="comment">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'โปรดระบุ'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <mat-form-field class="font-light" style="width: 95%;">
                                    <div class="font-light">
                                        <input [readonly]='!objjob.canBeUtilized' class="M-educationHistsEdit" matInput maxlength="1000" [(ngModel)]="objjob.comment" autocomplete="off">
                                    </div>
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>






                        <mat-header-row *matHeaderRowDef="displayedColumnsExpectedImpact"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsExpectedImpact;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label class="font-light" style="font-size: 14px;color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">
                                <button mat-raised-button class="font-light" style="padding-left: 10px; padding-right: 5px; color: white;
                                  background-color: #002F87; border-radius: 10px; font-size: 14px;" (click)="btnNext()">{{'ถัดไป'}}
                                  <a style="padding-right: 15px;"></a>
                                  <!-- <mat-icon style="margin-left: 10px;" class="material-icons" style=" color: white;">
                                      arrow_forward
                                  </mat-icon> -->
                                      <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleRight.png">

                              </button>

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>


            <mat-tab label="{{'เอกสารแนบ'}}">
                <ng-template mat-tab-label>
                    <span class="font-medium" [ngStyle]="{'color': 'Black'}">{{'เอกสารแนบ'}}</span>
                </ng-template>
                <mat-card style="border-radius: 10px;  border-style: solid; border-color: #d9d9d9; margin: 10px;">
                    <mat-card>
                      <!-- // ยังไม่ขึ้น CR Phase2 -->
                        <!-- <div *ngIf="isEditable || isEditAdmin || isEditResearcher" class="container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFill> -->
                        <div *ngIf="isEditable" class="container" fxLayout="row" fxLayout.xs="column" fxLayoutGap="5px" fxFill>
                            <div fxFlexFill fxLayout="row" style="padding-top: 10px;">

                                <div fxFlex="2"></div>
                                <div fxFlex="35%">
                                    <mat-form-field class="font-light">
                                      <!-- // ยังไม่ขึ้น CR Phase2 -->
                                        <!-- <input [readonly]='!checkReadonlyInAttachment' class="font-light" type="text" aria-label="Country" matInput [matAutocomplete]="auto" [(ngModel)]="attachedFileType" (input)="onDocumentTypeInput(attachedFileType)" placeholder="{{'ประเภทเอกสาร'}}"> -->
                                        <input [readonly]='!isEditable' class="font-light" type="text" aria-label="Country" matInput [matAutocomplete]="auto" [(ngModel)]="attachedFileType" (input)="onDocumentTypeInput(attachedFileType)" placeholder="{{'ประเภทเอกสาร'}}">
                                        <mat-autocomplete #auto="matAutocomplete" class="font-light" (optionSelected)="onAttachedFileTypeSelected($event.option.value)">
                                            <!-- <div class="font-light-small"> -->
                                            <mat-option style="font-family: chulabhornlikittext-light; font-size: smaller;" *ngFor="let option of projectAttachFileTypeListToShow " [value]="option.description">
                                                {{option.description}}
                                            </mat-option>
                                            <!-- </div> -->
                                        </mat-autocomplete>

                                    </mat-form-field>

                                </div>
                                <div fxFlex="2"></div>
                                <div fxFlex="36%">
                                    <mat-form-field class="font-light">
                                        <input matInput class="font-light" readonly maxlength="100" [(ngModel)]="attachedFileName" autocomplete="off" placeholder="{{'ชื่อไฟล์'}}">
                                    </mat-form-field>

                                </div>

                                <div fxFlex="2"></div>
                                <div fxFlex="10%">
                                    <button style="width: 100%;" class="font-light" mat-raised-button color="primary" (click)="imgFileInput.click()">{{'เลือกไฟล์'}}</button>
                                    <input hidden type="file" #imgFileInput (change)="handleFileInput($event.target.files,'projectAttacFile')" accept=".txt,.gif,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.pdf,.ppt,.pptx" />
                                </div>
                                <div fxFlex="2"></div>
                                <div fxFlex="10%">
                                  <button style="width: 100%; background-color: #002F87; color: white;" class="font-light" mat-raised-button  (click)="btnSaveattachDocuments()">{{'แนบไฟล์'}}</button>
                              </div>
                              <div fxFlex="2"></div>
                              <div *ngIf="isSaveAttachment" fxFlex="10%">
                                <button style="width: 100%; background-color: #b9f3f3; color: #002F87;" class="font-light" mat-raised-button  (click)="btnSave()">{{'บันทึก'}}</button>
                            </div>
                            </div>

                        </div>


                    </mat-card><br>

                    <mat-table [dataSource]="dataSourceAttachment" matSort matSortActive="documentType" matSortDirection="asc" matSortDisableClear>

                        <ng-container matColumnDef="Control">
                            <mat-header-cell fxFlex="5" *matHeaderCellDef>
                            </mat-header-cell>
                            <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                                <span class="font-medium" class="mobile-label"></span>
                                <!-- // ยังไม่ขึ้น CR Phase2 -->
                                <!-- <button *ngIf="CheckRemoveAttachment" mat-icon-button focusable="false" color="accent"> -->
                                <button *ngIf="isEditable" mat-icon-button focusable="false" color="accent">
                                    <img (click)="attachDocumentsDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                                </button>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="lineNo">
                            <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                            <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                                <div style="color: #002F87;" class="font-light">{{i + 1}}</div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="documentType">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ประเภทเอกสาร'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                                <div class="font-light">
                                  <!-- // ยังไม่ขึ้น CR Phase2 -->
                                    <!-- <input [readonly]='checkReadonlyInAttachment' class="font-light" matInput maxlength="100" [(ngModel)]="objjob.attachDocument.documentType" autocomplete="off"> -->
                                    <input [readonly]='isEditable' class="font-light" matInput maxlength="100" [(ngModel)]="objjob.attachDocument.documentType" autocomplete="off">
                                </div>
                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="documentName">
                            <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ชื่อไฟล์'}}</mat-header-cell>
                            <mat-cell *matCellDef="let objjob">
                              <!-- // ยังไม่ขึ้น CR Phase2 -->
                                <!-- <button mat-icon-button focusable="false" color="accent" class="font-light"> -->
                                <button *ngIf="!isEditable" mat-icon-button focusable="false" color="accent" class="font-light">
                                    <img (click)="viewAttachDocument(objjob.attachDocument.id)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png">
                                </button>
                                <div style="color: #002F87;" class="font-light">{{ objjob.attachDocument.documentName }}</div>

                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumnsAttachment"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumnsAttachment;"></mat-row>
                    </mat-table>



                    <div class="container" style="padding: 10px; margin-top: 30px; text-align: right;" class="font-light">
                        <tr style="width: 100%;">
                            <td style="width: 5%;">
                                <button mat-raised-button style="padding-left: 5px; padding-right: 5px; color: white;
                                background-color: #002F87; border-radius: 10px;" (click)="btnPrevious()">
                                    <img style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ArrowCircleLeft.png">
                                    <a style="padding-right: 15px;"></a>
                                    <label  class="font-light" style=" color: white; padding-right: 10px;">{{'ก่อนหน้า'}}</label>

                            </button>
                            </td>
                            <td style="width: 90%;"></td>
                            <td style="width: 5%;">

                            </td>

                        </tr>
                    </div>
                </mat-card>

            </mat-tab>

        </mat-tab-group>

    </div>
    <!-- <footer class="footer">
        <mat-label style="color: white; text-align: center; margin-top: 10px;">© ๒๐๒๑ by Chulabhorn. All Rights Reserved</mat-label>
    </footer> -->
</div>
<div *ngIf="showBudgetAllocation&&!showPublication">
    <div class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #b5d5f3; color: #002F87; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-left: 10px; margin-right: 10px;">
        <table style="width: 100%;">
            <tr>
                <td style="width: 40%;">

                </td>
                <td style="width: 20%;">
                    <label class="font-bold">{{'งบประมาณและแหล่งทุนในการวิจัย'}}</label>
                </td>
                <td style="width: 40%;">

                </td>
            </tr>
        </table>

    </div>
    <div>
        <mat-card style="border-radius: 0px 0px 8px 8px;  border-style: solid; border-color: #d9d9d9; margin-left: 10px; margin-right: 10px;">


            <!-- แหล่งเงินทุน -->
            <div class="table-container" style="padding: 5px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="15%">
                        <mat-form-field class="font-light" style="color: black;">
                            <div class="font-light">
                                <input readonly placeholder="{{'งบประมาณทั้งโครงการ (บาท)'}}" matInput maxlength="12" autocomplete="off" style="text-align: right;" matInput autocomplete="off" value={{totalFundAllocationInString}}>
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="40%">
                        <mat-form-field class="font-light" style="color: black;">
                            <div class="font-light">
                                <input readonly placeholder="{{'จำนวนเงิน'}}" matInput maxlength="150" autocomplete="off" [(ngModel)]="projectFundInThai">
                            </div>
                        </mat-form-field>
                    </div>
                    <!-- <div class="font-light" style="color: black;" class="font-light"> {{bahtThaiBudget}})</div> -->
                    <div fxFlex="35%"></div>
                    <div fxFlex="20%" style="text-align: right;">

                        <div fxFlex="90%" style="text-align: right; margin-top: 5px; padding-right: 3px;">
                            <label (click)="btnAddFunding()" class="font-light" style="color: #002F87;">{{'เพิ่มแหล่งเงิน'}}</label>
                        </div>

                        <div style="padding-right: 5px; " fxFlex="1.5%">
                            <img (click)="btnAddFunding()" style=" height: 28px; width: 28px;" src="assets\Icon\Add.png">
                        </div>
                    </div>
                </div>
            </div>
            <mat-table [dataSource]="dataSourceSourceOfFund" matSort matSortDisableClear>

                <ng-container matColumnDef="Control">
                    <mat-header-cell fxFlex="5" *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                        <span class="font-medium" class="mobile-label"></span>

                        <button mat-icon-button focusable="false" color="accent">
                        <img (click)="btnDeleteFunding(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                    </button>
                    </mat-cell>
                    <mat-footer-cell fxFlex="5" *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                    <mat-cell fxFlex="4" style="color: #002F87;" *matCellDef="let element; let i = index">
                        <div style="text-align: center; width: 100%;" class="font-light">{{i + 1}}</div>
                    </mat-cell>
                    <mat-footer-cell fxFlex="4" *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="fiscalYear" style="margin-left: 10px;">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีงบประมาณ'}}</mat-header-cell>
                    <mat-cell fxFlex="10" *matCellDef="let objjob; let i = index">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input [(ngModel)]="objjob.fiscalYear" type="number" min="0" step="1" class="M-educationHistsEdit" matInput maxlength="4" autocomplete="off" style="text-align: center;">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                    <mat-footer-cell fxFlex="50" class="font-light" *matFooterCellDef>รวม</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="budgetReceived">
                    <mat-header-cell fxFlex="20" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'จำนวนเงิน(บาท)'}}</mat-header-cell>
                    <mat-cell fxFlex="20" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input localizedNumericInput class="M-educationHistsEdit" style="text-align: right; " type="text" matInput maxlength="15" autocomplete="off" (input)="onFundingInRowChange()" [(ngModel)]="objjob.budgetReceived">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                    <mat-footer-cell fxFlex="20" *matFooterCellDef>
                        <div class="font-light">
                            <input class="M-educationHistsEdit" readonly style="text-align: right; width: 95%;" type="string" min="0" step="1" matInput maxlength="100" autocomplete="off" value="{{projectFundTotal}}">
                        </div>
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="budgetCode">
                    <mat-header-cell fxFlex="40" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'แหล่งเงิน(รหัสหน่่วยงาน)'}}</mat-header-cell>
                    <mat-cell fxFlex="40" *matCellDef="let objjob,let i = index">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.budgetCode" autocomplete="off">
                            </div>
                        </mat-form-field>

                    </mat-cell>
                    <mat-footer-cell fxFlex="40" *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="budgetAreaCode">
                    <mat-header-cell fxFlex="20" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'รหัสกิจกรรม'}}</mat-header-cell>
                    <mat-cell fxFlex="20" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.budgetAreaCode" autocomplete="off">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                    <mat-footer-cell fxFlex="20" *matFooterCellDef></mat-footer-cell>
                </ng-container>


                <mat-header-row *matHeaderRowDef="displayedColumnSourceOfFund"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnSourceOfFund;"></mat-row>
                <mat-footer-row *matFooterRowDef="displayedColumnSourceOfFund"></mat-footer-row>
            </mat-table>
            <!-- งวดเงิน -->
            <div class="table-container" style="padding: 5px; margin-top: 20px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="2"></div>
                    <div fxFlex="15%">
                        <mat-form-field class="font-light" style="color: black;">
                            <div class="font-light">
                                <input [readonly]='!isEditable' placeholder="{{'จำนวนเดือน'}}" matInput maxlength="2" autocomplete="off" style="text-align: right;" type="number" min="0" step="1" matInput autocomplete="off" [(ngModel)]="totalProjectMonth">
                            </div>
                        </mat-form-field>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="15%">
                        <mat-form-field class="font-light" style="color: black;">
                            <div class="font-light">
                                <input readonly placeholder="{{'จำนวนงวด'}}" matInput maxlength="2" autocomplete="off" style="text-align: right;" type="number" min="0" step="1" matInput autocomplete="off" [(ngModel)]="budgetPaymentTerm">
                            </div>
                        </mat-form-field>
                    </div>
                    <!-- <div class="font-light" style="color: black;" class="font-light"> {{bahtThaiBudget}})</div> -->
                    <div fxFlex="60%"></div>
                    <div fxFlex="20%" style="text-align: right;">

                        <div fxFlex="90%" style="text-align: right; margin-top: 5px; padding-right: 3px;">
                            <label (click)="btnAddPaymentTerm()" class="font-light" style="color: #002F87;">{{'เพิ่มงวด'}}</label>
                        </div>

                        <div style="padding-right: 5px; " fxFlex="1.5%">
                            <img (click)="btnAddPaymentTerm()" style=" height: 28px; width: 28px;" src="assets\Icon\Add.png">
                        </div>
                    </div>
                </div>
            </div>
            <mat-table [dataSource]="dataSourceBudgetTerm" matSort matSortDisableClear>

                <ng-container matColumnDef="Control">
                    <mat-header-cell fxFlex="5" *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                        <span class="font-medium" class="mobile-label"></span>

                        <button mat-icon-button focusable="false" color="accent">
                        <img (click)="btnDeletePaymentTerm(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                    </button>
                    </mat-cell>
                    <mat-footer-cell fxFlex="5" *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                    <mat-cell fxFlex="4" style="color: #002F87;" *matCellDef="let element; let i = index">
                        <div class="font-light" style="text-align: center; width: 100%;">{{i + 1}}</div>
                    </mat-cell>
                    <mat-footer-cell fxFlex="4" *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="termNo" style="margin-left: 10px;">
                    <mat-header-cell fxFlex="50" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'งวดที่'}}</mat-header-cell>
                    <mat-cell fxFlex="50" *matCellDef="let objjob; let i = index">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" type="number" min="0" step="1" matInput maxlength="100" autocomplete="off" [(ngModel)]="objjob.termNo" style="text-align: center;">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                    <mat-footer-cell fxFlex="50" class="font-light" *matFooterCellDef>รวม</mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="budgetReceived">
                    <mat-header-cell fxFlex="50" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'จำนวนเงิน(บาท)'}}</mat-header-cell>
                    <mat-cell fxFlex="50" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input localizedNumericInput class="M-educationHistsEdit" style="text-align: right;" type="text" matInput maxlength="15" autocomplete="off" (input)="onPaymentTermAmountRowChange()" [(ngModel)]="objjob.budgetReceived">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                    <mat-footer-cell fxFlex="50" *matFooterCellDef>
                        <div class="font-light" style="width: 100%;">
                            <input class="M-educationHistsEdit" readonly style="text-align: right; width: 95%;" matInput value="{{projectPaymentByTermTotal}}">
                        </div>
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnBudgetTerm"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnBudgetTerm;"></mat-row>
                <mat-footer-row *matFooterRowDef="displayedColumnBudgetTerm"></mat-footer-row>
            </mat-table>
            <!-- งบประมาณจัดสรรตามหมวด -->

            <div class="table-container" style="padding: 5px;">
                <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
                    <div fxFlex="85%">

                    </div>
                    <!-- <div class="font-light" style="color: black;" class="font-light"> {{bahtThaiBudget}})</div> -->

                    <div fxFlex="20%" style="text-align: right;">

                        <div fxFlex="90%" style="text-align: right; margin-top: 5px; padding-right: 3px;">
                            <label (click)="btnAddFundingAllocation()" class="font-light" style="color: #002F87;">{{'เพิ่มงบประมาณ'}}</label>
                        </div>

                        <div style="padding-right: 5px; " fxFlex="1.5%">
                            <img (click)="btnAddFundingAllocation()" style=" height: 28px; width: 28px;" src="assets\Icon\Add.png">
                        </div>
                    </div>
                </div>
            </div>
            <mat-table [dataSource]="dataSourceBudgetAllocation" matSort matSortDisableClear style="margin-top: 10px;">

                <ng-container matColumnDef="Control">
                    <mat-header-cell fxFlex="5" *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                        <span class="font-medium" class="mobile-label"></span>

                        <button mat-icon-button focusable="false" color="accent">
                        <img (click)="btnDeleteBusgetAllocation(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\Remove.png">
                    </button>
                    </mat-cell>
                    <mat-footer-cell fxFlex="5" *matFooterCellDef></mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="2.5" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ'}}</mat-header-cell>
                    <mat-cell fxFlex="2.5" *matCellDef="let element; let i = index">
                        <div style="text-align: center; width: 100%;" class="font-light">{{i + 1}}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fiscalYear">
                    <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ปีงบประมาณ'}}</mat-header-cell>
                    <mat-cell fxFlex="7" *matCellDef="let objjob">
                        <!-- <div class="font-light"> {{objjob.fiscalYear}} </div> -->
                        <div class="font-light">
                            <input matInput maxlength="10" (keypress)="numberOnly($event)" style="text-align: center;" type="text" matInput autocomplete="off" [(ngModel)]="objjob.fiscalYear" autocomplete="off">
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="budgetCategory">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณย่อย'}}</mat-header-cell>
                    <!-- <div class="font-light"> {{objjob.budgetCategory}}</div> -->
                    <mat-cell fxFlex="10" *matCellDef="let objjob,let i = index">
                        <mat-select class="font-light" [(ngModel)]="objjob.budgetCategoryId" (selectionChange)="onCategoryAllocationChange($event.value,i)" style="text-align: center; width: 100%;">
                            <mat-option *ngFor="let budgetSubGroup of budgetSubGroupList" [value]=budgetSubGroup.id>

                                <div class="font-light-small">
                                    {{ budgetSubGroup.description }}

                                </div>
                            </mat-option>
                        </mat-select>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="budgetGroup">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'หมวดงบประมาณ'}}</mat-header-cell>
                    <mat-cell fxFlex="10" *matCellDef="let objjob">
                        <div class="font-light" style="text-align: center; width: 100%;">{{objjob.budgetGroup}} </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="budgetDescription">
                    <mat-header-cell fxFlex="30" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'รายการ'}}</mat-header-cell>
                    <mat-cell fxFlex="30" *matCellDef="let objjob">
                        <!-- <div class="font-light">{{objjob.budgetDescription}} </div> -->

                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input matInput maxlength="300" [(ngModel)]="objjob.budgetDescription">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="budget" style="text-align: right;">
                    <mat-header-cell fxFlex="20" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'งบประมาณที่เสนอขอ'}}</mat-header-cell>
                    <mat-cell fxFlex="20" *matCellDef="let objjob" style="text-align: right;">
                        <!-- <div class="font-light"> {{objjob.budgetRequest}} </div> -->

                        <mat-form-field class="font-light" style="width: 95%;">

                            <div class="font-light">
                                <input localizedNumericInput matInput maxlength="20" style="text-align: right;" type="text" matInput autocomplete="off" [(ngModel)]="objjob.budgetRequest">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="budgetReceived">
                    <mat-header-cell fxFlex="20" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'งบประมาณที่จัดสรร(บาท)'}}</mat-header-cell>
                    <mat-cell fxFlex="20" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input localizedNumericInput matInput maxlength="20" style="text-align: right;" type="text" matInput autocomplete="off" [(ngModel)]="objjob.budgetReceived" autocomplete="off" (input)='onBudgetAllocationInRowChange()'>
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumnsBudgetAllocation"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsBudgetAllocation;"></mat-row>
            </mat-table>
        </mat-card>
    </div>
</div>
<div *ngIf="!showBudgetAllocation&&showPublication">
    <div class="font-bold" style="border-radius: 8px 8px 0px 0px; background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; margin-left: 10px; margin-right: 10px;">
        <table style="width: 100%;">
            <tr>
                <td style="width: 40%;">

                </td>
                <td style="width: 20%;">
                    <label class="font-bold">{{'ผลผลิตและการตีพิมพ์ '}}</label>
                </td>
                <td style="width: 40%;">

                </td>
            </tr>
        </table>

    </div>
    <div>
        <mat-card style="border-radius: 0px 0px 8px 8px;  border-style: solid; border-color: #d9d9d9; margin-left: 10px; margin-right: 10px;">

            <div style="padding: 10px; text-align: right;" class="font-bold">
                <tr *ngIf="!ishowtabSeminarHists" style="width: 100%;">
                    <td style="width: 70%;">

                    </td>
                    <td style="width: 14%;">
                        <label (click)="onclickAddPublications()" class="font-light" style="color: #002F87; padding-bottom: 30px; padding-right: 5px;">เพิ่มผลงานตีพิมพ์</label>
                    </td>
                    <td style="width: 1%;">
                        <img (click)="onclickAddPublications()" style=" height: 25px; width: 25px; padding-top: 10px;" src="assets\Icon\Add.png">
                    </td>

                </tr>
            </div>



            <mat-table [dataSource]="dataSourcePublications" matSort matSortDirection="asc" matSortDisableClear>

                <ng-container matColumnDef="Control">
                    <mat-header-cell fxFlex="5" *matHeaderCellDef>
                    </mat-header-cell>

                    <mat-cell *matCellDef="let row; let i = index" fxFlex="5">
                        <span class="font-medium" class="mobile-label"></span>

                        <button *ngIf="!row.isEdit" mat-icon-button focusable="false" color="accent">
                        <img (click)="btnPublicationDelete(i)" style=" height: 32px; width: 32px; padding-bottom: 2px; " src="assets\Icon\Remove.png">
                    </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="3" style="color: #002F87;" class="font-light" *matHeaderCellDef>{{'ลำดับ '}}</mat-header-cell>
                    <mat-cell fxFlex="3" style="color: #002F87;" *matCellDef="let element; let i = index">
                        <div class="font-light">{{i + 1}}</div>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="publishDate">
                    <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-light" *matHeaderCellDef>วันที่ตีพิมพ์</mat-header-cell>
                    <mat-cell fxFlex="7" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input readonly class="M-educationHistsEdit" min="0" step="1" matInput maxlength="100" value="objjob.publishDate|date:'dd MMM yyyy '" autocomplete="off" [matDatepicker]="picker1 " [(ngModel)]="objjob.publishDate">
                            </div>
                            <mat-datepicker-toggle matSuffix [for]="picker1 "></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="publishWorkName">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่อผลงานตีพิมพ์</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.publishWorkName" autocomplete="off">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="journalName">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ชื่อวารสาร/ฉบับที่ตีพิมพ์</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.journalName" autocomplete="off">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="journalLevel">
                    <mat-header-cell fxFlex="7" style="color: #002F87;" class="font-light" *matHeaderCellDef>ประเภทวารสาร</mat-header-cell>
                    <mat-cell fxFlex="7" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <mat-select class="font-light" [(ngModel)]="objjob.journalLevel" (selectionChange)="objjob.quartileTCI_Index=0">
                                <div class="font-light-small">
                                    <mat-option *ngFor="let level of journalLeveList" [value]=level.id>
                                        {{ level.description }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="isDb_Scopus">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>Scopus</mat-header-cell>
                    <mat-cell fxFlex="4" *matCellDef="let objjob">
                        <div style="width: 100%;align-items: center;text-align: center;" class="font-light">
                            <mat-checkbox [(ngModel)]="objjob.isDb_Scopus"></mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isDb_WebOfScience">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>Web of Science</mat-header-cell>
                    <mat-cell fxFlex="4" *matCellDef="let objjob">
                        <div style="width: 100%;align-items: center;text-align: center;" class="font-light">
                            <mat-checkbox [(ngModel)]="objjob.isDb_WebOfScience"></mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isDb_PubMed">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>PubMed</mat-header-cell>
                    <mat-cell fxFlex="4" *matCellDef="let objjob">
                        <div style="width: 100%;align-items: center;text-align: center;" class="font-light">
                            <mat-checkbox [(ngModel)]="objjob.isDb_PubMed"></mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isDb_Other">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>ฐานข้อมูลอื่น</mat-header-cell>
                    <mat-cell fxFlex="4" *matCellDef="let objjob">
                        <div style="width: 100%;align-items: center;text-align: center;" class="font-light">
                            <mat-checkbox [(ngModel)]="objjob.isDb_Other"></mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="otherDbName">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef>ฐานข้อมูล</mat-header-cell>
                    <mat-cell *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input [readonly]="!objjob.isDb_Other" class="M-educationHistsEdit" matInput maxlength="100" [(ngModel)]="objjob.otherDbName" autocomplete="off">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="impactFactor">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>Impact factor</mat-header-cell>
                    <mat-cell fxFlex="4" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div class="font-light">
                                <input type="number" min="0" matInput maxlength="6" class="M-educationHistsEdit" matInput maxlength="100" style="text-align:center;" [(ngModel)]="objjob.impactFactor" autocomplete="off">
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="quartileTCI_Index">
                    <mat-header-cell fxFlex="4" style="color: #002F87;" class="font-light" *matHeaderCellDef>Quartile/ Indexed in TCI</mat-header-cell>
                    <mat-cell fxFlex="4" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <div *ngIf="objjob.journalLevel==2">
                                <mat-select class="font-light" [(ngModel)]="objjob.quartileTCI_Index">
                                    <div class="font-light-small">
                                        <mat-option *ngFor="let type of quartileList" [value]=type.id>
                                            {{ type.description }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                            <div *ngIf="objjob.journalLevel==1">
                                <mat-select class="font-light" [(ngModel)]="objjob.quartileTCI_Index">
                                    <div class="font-light-small">
                                        <mat-option *ngFor="let type of tciTierList" [value]=type.id>
                                            {{ type.description }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </div>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="paticipation">
                    <mat-header-cell fxFlex="8" style="color: #002F87;" class="font-light" *matHeaderCellDef>การมีส่วนร่วม</mat-header-cell>
                    <mat-cell fxFlex="8" *matCellDef="let objjob">
                        <mat-form-field class="font-light" style="width: 95%;">
                            <mat-select class="font-light" [(ngModel)]="objjob.paticipation">
                                <div class="font-light-small">
                                    <mat-option *ngFor="let type of participationList" [value]=type.id>
                                        {{ type.description }}
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="attachDocument">
                    <mat-header-cell fxFlex="10" style="color: #002F87;" class="font-light" *matHeaderCellDef>ไฟล์แนบ</mat-header-cell>
                    <mat-cell fxFlex="10" *matCellDef="let objjob,let i=index">
                        <button class="font-light" *ngIf="objjob.attachDocument.documentName=='new'" [readonly]="!isEditable" style="width: 100%;" mat-raised-button color="primary" (click)="publicationFile.click()">เลือกไฟล์</button>

                        <input hidden type="file" #publicationFile (change)="handleFilePublication($event.target.files,i)" />
                        <button class="font-light" *ngIf="objjob.attachDocument.documentName!='new'" mat-icon-button focusable="false" color="accent">
                        <img *ngIf="objjob.attachDocument.documentName!='new'"  (click)="viewAttachDocument(objjob.attachDocumentId)" style=" height: 32px; width: 32px; padding-bottom: 2px; margin-left: 1px;" src="assets\Icon\ViewFile.png">
                    </button>
                        <mat-label class="font-light" *ngIf="objjob.attachDocument.documentName!='new'">{{objjob.attachDocument?objjob.attachDocument.documentName:""}}</mat-label>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumnsPublications"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumnsPublications;"></mat-row>
            </mat-table>
        </mat-card>
    </div>
</div>
<div id="overlay">
    <span class="font-medium" id="nav" *ngIf="saveInProgress">
        <div class="spinner" style="display: block; position: fixed">
        </div>
    </span>
    <span class="font-medium" id="nav" *ngIf="saveInProgress">
        <div class="submit-message" style="display: block; color: #FFFFFF; position: fixed; left: 49.7%; top: 54.5%;">
            Saving...
        </div>
    </span>
</div>
