import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { SDUtilityService } from 'src/app/services/sdutility.service';
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};
@Component({
  selector: 'app-report-from-data-export',
  templateUrl: './report-from-data-export.component.html',
  styleUrls: ['./report-from-data-export.component.scss']
})
export class ReportFromDataExportComponent implements OnInit {
  @Input() objFromDataExport: any = {};
  Year: number;
  AmountResearchProject: any = [];
  dataProjectNobudget: any = [];
  //field selectio
  fiscalYear: boolean;
  projectCode: boolean;
  projectNameTH:boolean;
  selectedColumn:Array<boolean>;
  columnHeadData:Map<string,boolean>;
  fiscalYearListFrom: [number];


  dataSourceProjectDataExport = new MatTableDataSource();
  displayedColumnsProjectDataExport = ['lineNo','fiscalYear','projectCode','projectNameTH',  'projectNameEN','prefixTH', 'projectOwerName','projectOwnerFullName','organizationName','groupProjectID',
  'totalProjectBudget','ecno','specialistName1','specialistName2','specialistName3','evaluateStatus','approveNote','meetingNo1','noteResult1','approveNote1','meetingNo2','noteResult2','approveNote2','evaluateNote',
  'projectApproveDate','projectStatusDesc','projectRemark'];

  columnHeaderTitle:string[]=["ลำดับ","ปีงบประมาณ","รหัสโครงการ","ชื่อโครงการ (ไทย)","ชื่อโครงการ (อังกฤษ)","คำนำหน้านาม","ชื่อหัวหน้าโครงการ","ชื่อเต็ม","สังกัด","งบประมาณที่ขอ","เลข EC","ผู้ทรงคุณวุฒิท่าน 1","ผู้ทรงคุณวุฒิท่าน 2","ผู้ทรงคุณวุฒิท่าน 3","สถานะการประเมิน","บันทึกข้อความขออนุมัติ","ประชุมครั้งที่ 1","บันทึกข้อความแจ้งผล","บันทึกข้อความขออนุมัติ","ประชุมครั้งที่ 2","บันทึกข้อความแจ้งผล","บันทึกข้อความขออนุมัติ","ใบแจ้งผลการพิจารณา","วันที่อนุมัติ (วัน/เดือน/พ.ศ.)","สถานะโครงการ","หมายเหตุ"];
  
  constructor(private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    public sdUtilityService: SDUtilityService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.columnHeadData = new Map();
    this.getFromYear();
    console.log(this.objFromDataExport);
    this.Year = this.objFromDataExport.year
    this.GetFromDataExport()
    this.selectedColumn = Array(this.displayedColumnsProjectDataExport.length);
    for(let i =0 ; i<this.displayedColumnsProjectDataExport.length;i++){
      this.selectedColumn[i]=false;
      this.columnHeadData.set(this.displayedColumnsProjectDataExport[i],false);
    }
    console.log("showhash", this.columnHeadData);
  }

  GetFromDataExport() {

    let callMethod: string = '/Report/GetProjectDataExport/'  + this.Year;
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        console.log(values);
        this.dataSourceProjectDataExport.data = values;
      });

      
  }

  getFromYear() {

    let currentTime = new Date();
    let year = currentTime.getFullYear() -4
    if (year < 2500) {
      year = year + 543;
    }


    
    this.fiscalYearListFrom = [year];

    
    for (let i = 0; i < 8; i++) {
      this.fiscalYearListFrom.push(year + i + 1 );
    }
    console.log(this.fiscalYearListFrom);
    this.Year = this.fiscalYearListFrom[0];
    
  
  }


  onChangeFromDataReport(){
    this.GetFromDataExport();
  }

  downloadButtonPush() {
    let headerLabel:string="";
    let rowNo:number=0;
    for( var keyName of this.columnHeadData.keys()){
      if( this.columnHeadData.get(keyName)){
        if (headerLabel != ''){
          headerLabel += ','+this.columnHeaderTitle[rowNo];
        }
        else {
          headerLabel += this.columnHeaderTitle[rowNo];
        }
      }
      rowNo++;
    }
    var csvData = "\ufeff" + headerLabel + this.ConvertToCSV();

    // ,เบอร์โทรติดต่อ

    var blob = new Blob([csvData], { type: 'text/csv;' });
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.href = url;
    a.download = 'ProjectDataExport' + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  ConvertToCSV() {

    let exportColumn:string[]=[];
    exportColumn.push("lineNo");
    if(this.fiscalYear){
      exportColumn.push("fiscalYear");
    }
    if(this.projectCode){
      exportColumn.push("projectCode");
    }
    if(this.projectNameTH){
      exportColumn.push("projectNameEN");
    }
   

    let arrData = JSON.parse(JSON.stringify(this.dataSourceProjectDataExport.data, ["fiscalYear","projectCode","projectNameTH",  "projectNameEN","prefixTH", "projectOwerName","projectOwnerFullName","organizationName",
    "totalProjectBudget","ecno","specialistName1","specialistName2","specialistName3","evaluateStatus","approveNote","meetingNo1","noteResult1","approveNote1","meetingNo2","noteResult2","approveNote2","evaluateNote",
    "projectApproveDate","projectStatusDesc","projectRemark"]));
    console.log("array", arrData);
    var str = '';
    var row = "";

    for (var index in arrData[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += '\r\n';  //str += row + '\r\n'; ลบหัวคอลัมออกเพื่อใช้เป็นภาษาไทย
    console.log("showsselection",this.columnHeadData);
    for (var i = 0; i < arrData.length; i++) {
  
      var line = '';
     
      for (var index in arrData[i]) {
        console.log(index);
        if(this.columnHeadData.get(index)){
          let fieldData:string;
          if(arrData[i][index]){
            fieldData = arrData[i][index];
          } else {
            fieldData = "";
          }
          if (line != ''){
            line += ','+fieldData;
          }
          else {
            line += fieldData;
          }
        }
      }
      str += line + '\r\n';
    }
    return str;
  }
  onHeaderClick(columnIndex:number){
    this.selectedColumn[columnIndex] =!this.selectedColumn[columnIndex];
    this.columnHeadData.set(this.displayedColumnsProjectDataExport[columnIndex],!this.columnHeadData.get(this.displayedColumnsProjectDataExport[columnIndex]));
  }

  getSelectedStatus(){
    return true;
  }
}
