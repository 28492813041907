import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { budgetUsageByOrg, totalProjectByYearReport } from 'src/app/datamodel/chartdatamodel';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { transformYearToGovernementFiscalYear } from 'src/app/services/general-function';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  Reportdatas: any = [];
  fiscalYearListFrom: [number];
  fiscalYearListTo: [number];
  fromYear: number;
  toYear: number;
  isNoCriteria: boolean;
  isShowSelectOneYear: boolean;
  isShowBudgetYear:boolean;
  isShowCriteriaFromDataExport: boolean;
  year: number;
  budgetyear:number;
  yearFromDataExport: number;
  isShowReportResearcherPublished: boolean;
  isShowReportProjectMeetingProgress: boolean;
  isShowReportAmountResearchProject: boolean;
  isShowReportFromDataExport: boolean;
  budgetUsageByOrgs:budgetUsageByOrg[];
  dataPublishedWork: any = [];
  Year: number;
  AmountResearchProject:totalProjectByYearReport[];
  hasData:boolean;
  dataProjectTracking: any = [];
  errorMessage:string;

  isShowPrint: boolean
  isShowExport: boolean
  // Year: number;
  // AmountResearchProject: any = [];
  dataProjectNobudget: any = [];
  //field selectio
  fiscalYear: boolean;
  projectCode: boolean;
  projectNameTH:boolean;
  selectedColumn:Array<boolean>;
  columnHeadData:Map<string,boolean>;

  dataSourceProjectDataExport = new MatTableDataSource();
  displayedColumnsProjectDataExport = ['lineNo','fiscalYear','projectCode','projectNameTH',  'projectNameEN','prefixTH', 'projectOwerName','projectOwnerFullName','organizationName','groupProjectID',
  'totalProjectBudget','ecno','specialistName1','specialistName2','specialistName3','evaluateStatus','approveNote','meetingNo1','noteResult1','approveNote1','meetingNo2','noteResult2','approveNote2','evaluateNote',
  'projectApproveDate','projectStatusDesc','projectRemark'];

  columnHeaderTitle:string[]=["ลำดับ","ปีงบประมาณ","รหัสโครงการ","ชื่อโครงการ (ไทย)","ชื่อโครงการ (อังกฤษ)","คำนำหน้านาม","ชื่อหัวหน้าโครงการ","ชื่อเต็ม","สังกัด",'กลุ่มรหัสโครงการ',
  "งบประมาณที่ขอใช้","เลข EC","ผู้ทรงคุณวุฒิท่าน 1","ผู้ทรงคุณวุฒิท่าน 2","ผู้ทรงคุณวุฒิท่าน 3","สถานะการประเมิน","บันทึกข้อความขออนุมัติ","ประชุมครั้งที่ 1","บันทึกข้อความแจ้งผล","บันทึกข้อความขออนุมัติ","ประชุมครั้งที่ 2","บันทึกข้อความแจ้งผล","บันทึกข้อความขออนุมัติ","ใบแจ้งผลการพิจารณา","วันที่อนุมัติ (วัน/เดือน/พ.ศ.)","สถานะโครงการ","หมายเหตุ","xx"];

  constructor(
    public dialog: MatDialog,
    private brokerAPIService:BrokerAPIService,
    private currencyPipe: CurrencyPipe,) { }

  ngOnInit(): void {
    this.getFromYear();
    this.getToYear();

    this.FiscalYear();
    this.resetMenu();

  }

  FiscalYear() {
    let date = transformYearToGovernementFiscalYear(new Date)
    console.log(date);
    this.budgetyear = date;
    this.fromYear = date;
    this.toYear = date;
    this.year = date;
    this.yearFromDataExport = date;

  }




  onclickProjectMeetingProgress(){
    this.resetMenu()
    this.isShowPrint = true;
    this.isShowReportProjectMeetingProgress = true;
    this.isNoCriteria = true;
    let callMethod:string='/Report/GetProjectTrackingCloseToDueDateReport' ;
    this.brokerAPIService.get(callMethod).subscribe(
      values =>{
        console.log(values);
        this.dataProjectTracking = values;
        // this.dataSourceSysuserRoll.data = values;
        // values.forEach(objGetUser => {
        //   console.log(objGetUser);

        //   // this.dataSysuserRoll.push({
        //   //   email:objGetUser.email
        //   })

        // });

        // this.dataSourceSysuserRoll.data = this.dataSysuserRoll;


        // console.log(this.dataSourceSysuserRoll.data);

      },
      error =>{
         this.errorMessage = <any>error;
         console.log('ProjectList:'+JSON.stringify(this.errorMessage));
      });

  }



  onclickFromDataExport() {

    this.resetMenu()
    this.isShowCriteriaFromDataExport = true;

  }

  onClickForBudgetReport(budgetYear:number){

    this.resetMenu();
    this.isShowBudgetYear = true;
    this.isShowPrint = true;
    let url = "/Report/GetBudgetUsageByOrgReport/"+budgetYear;
    this.brokerAPIService.get(url).subscribe(
      values=>{
        console.log("data",JSON.stringify(values));
        if(values){
          this.budgetUsageByOrgs = values;
        }
      }
    )

  }

  onBudgetYearChange(){
    this.onClickForBudgetReport(this.budgetyear);
  }

  resetMenu(){
    this.isShowCriteriaFromDataExport = false;
    this.isShowSelectOneYear = false;
    this.isShowBudgetYear = false;
    this.isShowReportProjectMeetingProgress = false;
    this.isShowReportResearcherPublished = false;
    this.isNoCriteria = true;
    // this.toYear = undefined;
    // this.fromYear = undefined;
    // this.yearFromDataExport  = undefined;
    // this.year = undefined;
    this.isShowExport = false;
    this.isShowPrint = false;
    this.isShowReportAmountResearchProject = false;
    this.isShowReportFromDataExport = false;

  }

  onChangedate() {
    // console.log("datefrom", this.fromYear, "dateto", this.toYear);

    // if (this.fromYear != undefined  && this.toYear != undefined) {

    //   if (this.fromYear < this.toYear  ) {

    //   let data = {
    //     datefrom: this.fromYear,
    //     dateto: this.toYear
    //   }
    //   this.Reportdatas = data;
    //   console.log(this.Reportdatas );

    //   this.isShowReportResearcherPublished = true;
    // } else {
    //   this.openDialog("แจ้งเตือน","toYear ต้องมากว่า fromYear");
    // }

    // }

    this.onclickReportResearchPublised(this.fromYear,this.toYear);
  }

  onclickReportResearchPublised(fromYear:number, toYear: number){


    this.resetMenu()
    console.log("fromYear", fromYear,"toyear",toYear );
    this.isShowPrint = true;
    this.isShowReportResearcherPublished = true;
    let url = '/Report/GetPublishedWorkReport/' + fromYear + "," + toYear;
    this.brokerAPIService.get(url).subscribe(
      values=>{
        console.log("data",JSON.stringify(values));
        if(values){
          this.dataPublishedWork = values;
        }
      }
    )

  }

  onChangeAmuntResearchProject() {
    // if (this.year != undefined) {
    //   let dataaAmuntResearchProject = {
    //     year: this.year
    //   }
    //   this.Reportdatas = dataaAmuntResearchProject;
    //   // if ()
    //   this.isShowReportAmountResearchProject = false;

    //   this.isShowReportAmountResearchProject = true;
    // }
    this.onclickAmuntResearchProject(this.year)
  }

  onclickAmuntResearchProject(year: number) {
    this.resetMenu()
    console.log("year", year );
    this.isShowPrint = true;
    this.isShowSelectOneYear = true;
    let callMethod: string = '/Report/GetTotalProjectByYearReport/'  + year;
    let groupSum0=0;
    let groupSum1=0;
    let groupSum2=0;
    let groupSum3=0;
    let groupSum4=0;
    let groupSum5=0;
    let netSum0=0;
    let netSum1=0;
    let netSum2=0;
    let netSum3=0;
    let netSum4=0;
    let netSum5=0;

    this.brokerAPIService.get(callMethod).subscribe(
      values => {
         console.log(values);
        let results:totalProjectByYearReport[] = values;
        this.AmountResearchProject = [];
        if(values){
          this.hasData = true;
          let projectTypeTitle:string ="";
          let organizationTitle:string ="";
          let affiliationTitle:string ="";
          for(let i = 0 ; i< results.length;i++){
            if(results[i].fundingDesc==projectTypeTitle){
              results[i].fundingDesc = "";
            } else {
              projectTypeTitle = results[i].fundingDesc;
              if(i!=0){
                let sumLine = new totalProjectByYearReport(
                  -99,
                  'ยอดรวม',
                  'aaaaa',
                  '',
                  groupSum0,
                  groupSum1,
                  groupSum2,
                  groupSum3,
                  groupSum4,
                  groupSum5,
                );
                this.createRow(sumLine);
                groupSum0=0;
                groupSum1=0;
                groupSum2=0;
                groupSum3=0;
                groupSum4=0;
                groupSum5=0;
              }

            }
            if(results[i].organizationName==organizationTitle){
              results[i].organizationName = "";
            } else {
              organizationTitle = results[i].organizationName;
            }
            if(results[i].affiliation==affiliationTitle){
              results[i].affiliation = "";
            } else {
              affiliationTitle = results[i].affiliation;
            }
            this.createRow(results[i]);
            groupSum0=groupSum0+results[i].newProjectCount;
            groupSum1=groupSum1+results[i].contProjectCount;
            groupSum2=groupSum2+results[i].totalProjectCount;
            groupSum3=groupSum3+results[i].newProjectBudget;
            groupSum4=groupSum4+results[i].contProjectBudget;
            groupSum5=groupSum5+results[i].totalProjectBudget;
            netSum0=netSum0+results[i].newProjectCount;
            netSum1=netSum1+results[i].contProjectCount;
            netSum2=netSum2+results[i].totalProjectCount;
            netSum3=netSum3+results[i].newProjectBudget;
            netSum4=netSum4+results[i].contProjectBudget;
            netSum5=netSum5+results[i].totalProjectBudget;
          }
          let sumLine = new totalProjectByYearReport(
            -99,
            'ยอดรวม',
            'aaaaa',
            '',
            groupSum0,
            groupSum1,
            groupSum2,
            groupSum3,
            groupSum4,
            groupSum5,
          );
          this.createRow(sumLine);
          sumLine = new totalProjectByYearReport(
            -99,
            'ยอดรวมทั้งหมด',
            'aaaaa',
            '',
            netSum0,
            netSum1,
            netSum2,
            netSum3,
            netSum4,
            netSum5,
          );
          this.createRow(sumLine);
          // console.log("data",JSON.stringify(this.AmountResearchProject));


        }
        else {
          this.AmountResearchProject = [];
          this.hasData = false;
        }




      });




  }


  createRow(dataRow:totalProjectByYearReport){
    let newRow = new totalProjectByYearReport(
      dataRow.fundingType,
      dataRow.fundingDesc,
      dataRow.affiliation,
      dataRow.organizationName,
      dataRow.newProjectCount,
      dataRow.contProjectCount,
      dataRow.totalProjectCount,
      dataRow.newProjectBudget,
      dataRow.contProjectBudget,
      dataRow.totalProjectBudget
    );
    console.log("newRow",newRow);
    console.log("AmountResearchProject",this.AmountResearchProject);

    if(!this.AmountResearchProject){
      this.AmountResearchProject = [newRow];
    } else {
      this.AmountResearchProject.push(newRow);
    }
  }


  onclickFromDataReport(yearFromDataExport:number) {

    console.log(yearFromDataExport);

    this.resetMenu();
    this.isShowExport = true;
    this.isShowReportFromDataExport = true;
    this.columnHeadData = new Map();

    let callMethod: string = '/Report/GetProjectDataExport/'  + yearFromDataExport;
    this.brokerAPIService.get(callMethod).subscribe(
      values => {
        console.log(values);
        this.dataSourceProjectDataExport.data = values;
      });


      this.selectedColumn = Array(this.displayedColumnsProjectDataExport.length);
      for(let i =0 ; i<this.displayedColumnsProjectDataExport.length;i++){
        this.selectedColumn[i]=false;
        this.columnHeadData.set(this.displayedColumnsProjectDataExport[i],false);
      }
      console.log("showhash", this.columnHeadData);
  }



  onChangeFromDataReport() {
    this.onclickFromDataReport(this.yearFromDataExport);

  }

  downloadButtonPush() {
    let headerLabel:string="";
    let rowNo:number=0;
    for( var keyName of this.columnHeadData.keys()){
      if( this.columnHeadData.get(keyName)){
        if (headerLabel != ''){
          headerLabel += ','+this.columnHeaderTitle[rowNo];
        }
        else {
          headerLabel += this.columnHeaderTitle[rowNo];
        }
      }
      rowNo++;
    }
    var csvData = "\ufeff" + headerLabel + this.ConvertToCSV();

    // ,เบอร์โทรติดต่อ

    var blob = new Blob([csvData], { type: 'text/csv;' });
    var url = window.URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.href = url;
    a.download = 'ProjectDataExport' + '.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  ConvertToCSV() {

    let exportColumn:string[]=[];
    exportColumn.push("lineNo");
    if(this.fiscalYear){
      exportColumn.push("fiscalYear");
    }
    if(this.projectCode){
      exportColumn.push("projectCode");
    }
    if(this.projectNameTH){
      exportColumn.push("projectNameEN");
    }


    let arrData = JSON.parse(JSON.stringify(this.dataSourceProjectDataExport.data, ["fiscalYear","projectCode","projectNameTH",  "projectNameEN","prefixTH", "projectOwerName","projectOwnerFullName","organizationName",
    "totalProjectBudget","ecno","specialistName1","specialistName2","specialistName3","evaluateStatus","approveNote","meetingNo1","noteResult1","approveNote1","meetingNo2","noteResult2","approveNote2","evaluateNote",
    "projectApproveDate","projectStatusDesc","projectRemark"]));
    console.log("array", arrData);

    var str = '';
    var row = "";

    for (var index in arrData[0]) {
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += '\r\n';  //str += row + '\r\n'; ลบหัวคอลัมออกเพื่อใช้เป็นภาษาไทย
    console.log("showsselection",this.columnHeadData);

    for (var i = 0; i < arrData.length; i++) {

      // arrData[i].projectNameEN = arrData[i].projectNameEN.replace(',', '|');
      // // console.log("array projectNameEN",  arrData[i].projectNameEN);
      // console.log("test", arrData[i]);


      var line = '';

      console.log("columnHeadData",this.columnHeadData);

      for (var index in arrData[i]) {
        // console.log('index in arrData[i]',index);
        if(this.columnHeadData.get(index)){
// console.log("arrData>>>",arrData[i][index]);

          if (index == "projectNameEN") {
          if (arrData[i][index] != null) {
          // if (arrData[3][index]) {
            arrData[i][index] = arrData[i].projectNameEN.replace(',', '|').replace(',', '|').replace(',', '|').replace(',', '|');
            // console.log("arrData=======>",arrData[i][index]);
          // }
        }
          }

          // console.log("arrData>>>",arrData[i][index]);
          let fieldData:string;


          if(arrData[i][index]){
            fieldData = arrData[i][index];

          } else {


            fieldData = "";
          }
          if (line != ''){
            line += ','+fieldData;
          }
          else {
            line += fieldData;
          }


        }


      }
      str += line + '\r\n';
    }

    return str;
  }

  onHeaderClick(columnIndex:number){
    this.selectedColumn[columnIndex] =!this.selectedColumn[columnIndex];
    this.columnHeadData.set(this.displayedColumnsProjectDataExport[columnIndex],!this.columnHeadData.get(this.displayedColumnsProjectDataExport[columnIndex]));
  }


  getToYear() {

      // let currentTime = new Date();
      // var year = currentTime.getFullYear() -4;
      // console.log("Year",year);

      // if (year < 2500) {
      //   year = year + 543;
      // }
      // this.fiscalYearListTo = [year];
      // for (let i = 0; i < 8; i++) {
      //   this.fiscalYearListTo.push(year + i + 1);
      // }
      // console.log(this.fiscalYearListTo);
      // this.toYear = this.fiscalYearListTo[0 + 5].toString();
      let currentTime = new Date();
      let year = currentTime.getFullYear();
      if (year < 2500) {
        year = year + 544;
      }
      this.fiscalYearListTo = [year];
      for (let i = 0; i < 20; i++) {
        this.fiscalYearListTo.push(year + i * -1 - 1);
      }
    // this.fromYear = this.fiscalYearListTo[0];


  }

  getFromYear() {

    // let currentTime = new Date();
    // let year = currentTime.getFullYear() -4
    // if (year < 2500) {
    //   year = year + 543;
    // }

    // this.fiscalYearListFrom = [year];


    // for (let i = 0; i < 8; i++) {
    //   this.fiscalYearListFrom.push(year + i + 1 );
    // }
    // console.log(this.fiscalYearListFrom);
    let currentTime = new Date();
    let year = currentTime.getFullYear();
    if (year < 2500) {
      year = year + 544;
    }
    this.fiscalYearListFrom = [year];
    for (let i = 0; i < 20; i++) {
      this.fiscalYearListFrom.push(year + i * -1 - 1);
    }

  }


  captureScreen() {
    let WindowPrt = window.open('', 'PRINT', 'height=600,width=900');

    WindowPrt.document.write('<html><head><title>' + document.title + '</title>');
    WindowPrt.document.write('<style> .Mat-lbl-text {font-size: 15px;padding-bottom: 10px;padding-top: 10px;color: black;font-style: normal;width: 100%;}');
    WindowPrt.document.write(' @font-face {font-family: chulabhorn;src: url(/assets/fonts/chulabhornLikit/chulabhornlikittext-light.woff);} table {font-family: chulabhorn;}')
    WindowPrt.document.write(' .Mat-lbl-data {font-size: 13px;padding-bottom: 10px;padding-top: 10px;color: rgb(2, 2, 2);font-style: normal;}');
    WindowPrt.document.write(' @page {size: A4;margin: 10mm;} ');
    WindowPrt.document.write(' @media print { html, body {width: 210mm;height: 297mm;} .page {  margin: 0; border: initial;  border-radius: initial; width: initial; min-height: initial; box-shadow: initial; background: initial; page-break-after: always; } } ');
    WindowPrt.document.write(' body {width: 100%;height: 100%;margin: 0mm;padding: 0;} ');
    WindowPrt.document.write(' .page {width: 210mm; min-height: 297mm;padding-top: 0mm;');

    WindowPrt.document.write(' .subpage {height: 297mm; padding: 1cm;} ');
    WindowPrt.document.write(' </style>');
    WindowPrt.document.write('</head><body >');
    WindowPrt.document.write(document.getElementById("contentToConvert").innerHTML);
    WindowPrt.document.write('</body></html>');

    setTimeout(() => {
      WindowPrt.print();
    }, 5000);
    // WindowPrt.print();
    // WindowPrt.close();
  }

  openDialog(dialogTitle:string,dialogMessage:string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      data: {title: dialogTitle, message: dialogMessage},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed',result);
          });
  }

  transformAmount(Amount: number): string {
    return this.currencyPipe.transform(Amount, '฿').replace('฿', '');

  }



}
