import { attachdocument } from "./attachdocument";

export class researcherattachdocument {
    id: number;
    researcherId: number;
    orderNo: number;
    attachDocumentId: number;
    attachDocument: attachdocument;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated: boolean;
}