<table style="width: 100%;">
  <tr>
      <td style="width: 33.33%; text-align: left;" class="btn-left">
          <button mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold; " class="btn-close" (click)="btnClose()">{{ 'ย้อนกลับ' }}</button>
      </td>
      <!-- <td *ngIf="!isShowCreate" style="width: 33.33%; text-align: center;" class="btn-center">
        <button mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold; " class="btn-delete" (click)="btnRemve()">{{ 'ลบข้อมูล' }}</button>

      </td> -->
      <td  style="width: 33.33%; text-align: center;" class="btn-center"></td>
      <td style="width: 33.33%; text-align: right;" class="btn-right">
          <button mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold; " class="btn-save" (click)="btnSave()">{{ 'บันทึก' }}</button>

      </td>
  </tr>
</table>


<div class="table-container" style="padding: 5px; margin-top: 40px;">
  <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="2"></div>
      <div fxFlex="30%" >
        <mat-form-field class="font-light" >
            <input style="font-size: 13px;" matInput [disabled]="isDisabledOrderNo"  maxlength="100" [(ngModel)]="dataRow.orderNo" autocomplete="off" placeholder="{{'ลำดับหน่วยงาน'}}">
        </mat-form-field>
    </div>
      <div fxFlex="2"></div>
      <div fxFlex="70%" >
        <mat-form-field class="font-light" >
            <input matInput style="font-size: 13px;" maxlength="100" [(ngModel)]="dataRow.organizationName" autocomplete="off" placeholder="{{'หน่วยงาน'}}">
        </mat-form-field>
    </div>
    <div fxFlex="2"></div>
  </div>
</div>

<div class="table-container" style="padding: 5px; margin-top: 20px;">
 <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
    <div fxFlex="2"></div>
    <div fxFlex="50%" >
        <mat-form-field class="font-light" >
            <input matInput style="font-size: 13px;" maxlength="50" [(ngModel)]="dataRow.organizationShortName" autocomplete="off" placeholder="{{'ชื่อย่อหน่วยงาน'}}">
        </mat-form-field>
    </div>
    <div fxFlex="2"></div>

    <div fxFlex="50%" >
      <mat-form-field class="font-light" >
         <input matInput style="font-size: 13px;"  maxlength="100" [(ngModel)]="dataRow.affiliation" autocomplete="off" placeholder="{{'สังกัด'}}">
          <!-- <mat-select class="font-light"  [(ngModel)]="dataRow.id" (selectionChange)="onSelectAffiation()" placeholder="{{'สังกัด'}}" >
              <div class="font-light">
                  <mat-option *ngFor="let objAffiation of dataAffiation" [value]=objAffiation.id>
                      {{ objAffiation.affiliation }}
                  </mat-option>
              </div>
          </mat-select> -->
        </mat-form-field>
  </div>
  <div fxFlex="2"></div>

 </div>
</div>


  <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="2"></div>
      <div fxFlex="25%" >
          <mat-checkbox class="font-light" [(ngModel)]="dataRow.inActivated">ไม่ใช้งาน</mat-checkbox>
      </div>
      <div fxFlex="2"></div>
  </div>

  <div *ngIf="!isShowCreate" class="table-container" style="padding: 5px; margin-top: 20px;">
    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
      <div fxFlex="2"></div>
      <div fxFlex="50%" >
          <mat-form-field class="font-light" >
              <input matInput style="font-size: 13px;" disabled maxlength="50" [(ngModel)]="dataRow.updateBy" autocomplete="off" placeholder="{{'บันทึกโดย'}}">
          </mat-form-field>
      </div>
      <div fxFlex="2"></div>
      <div fxFlex="50%" >
        <mat-form-field class="font-light">
            <input matInput style="font-size: 13px;" disabled maxlength="100" [(ngModel)]="dataRow.updateDate" autocomplete="off" placeholder="{{'วันเวลาบันทึก'}}">
        </mat-form-field>
      </div>
      <div fxFlex="2"></div>
    </div>
  </div>

  <div id="overlay">
    <span class="font-medium" id="nav" *ngIf="saveInProgress">
        <div class="spinner" style="display: block; position: fixed">
        </div>
    </span>
    <span class="font-medium" id="nav" *ngIf="saveInProgress">
        <div class="submit-message" style="display: block; color: #FFFFFF; position: fixed; left: 49.7%; top: 54.5%;">
            Saving...
        </div>
    </span>
</div>
