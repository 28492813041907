<table class="report-container" id="getHight">
  <div style="text-align: center; padding: 0px; border: 0px; margin: 0px;">
    <img src="../../../assets/images/logo/RoyalAcademy_ standart.png">
  </div>



  <thead class="report-header" style="padding: 0px; border: 0px; margin: 0px; height: 2px;">
    <tr>
      <th class="report-header-cell" style="height: 2px;">
        <div class="header-info" style="color: transparent; height: 2px;">

          .
        </div>
      </th>
    </tr>
  </thead>
  <tfoot class="report-footer">
    <tr>
      <td class="report-footer-cell">
        <div class="footer-info">
          <div class="HideContent" id="content">

            <div style="color: transparent; margin-top: 20px; ">
              พิมพ์จากระบบวันที่ {{ dates | date: "dd/MM/yyyy"}}
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tfoot>

  <tbody class="report-content">
    <tr>
      <td class="report-content-cell">
        <div class="main">


          <table style="width: 100%;padding: 0px;margin: 0px;">

            <tr>
              <td style="text-align: center;">
                <h3 style="margin-top: 0px;">แบบเสนอโครงร่างการวิจัย (Research Proposal)</h3>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 100%;">
                <label style="font-weight: bold;">1. ชื่อโครงการวิจัย</label>
                <table style="width: 100%;padding: 0px;margin: 0px; padding-left: 9px;">
                  <tr>
                    <td style="width: 16%;">
                      <label style="font-weight: bold;">(ภาษาไทย)</label>
                    </td>
                    <td colspan="2" style="width: 84%;">
                      <label>{{ projectProposal.projectNameTH }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                  <tr>
                    <td style="width: 16%;">
                      <label style="font-weight: bold;">(ภาษาอังกฤษ)</label>
                    </td>
                    <td colspan="2" style="width: 84%;">
                      <label>{{ projectProposal.projectNameEN }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 100%;">
                <div style="padding-top: 8px;">
                  <label style="font-weight: bold;">2. ที่ปรึกษาโครงการวิจัย</label>
                </div>
              </td>
            </tr>

            <tr *ngFor="let objProjectConsultant of dataResearcherType2 let i = index">
              <td colspan="4" style="width: 100%;">

                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>

                    <td style="width: 45%; padding-left: 14px;">
                      <label style="font-weight: bold;">ตำแหน่งทางวิชาการ ชื่อ - นามสกุล</label>
                    </td>
                    <td colspan="2" style="width: 55%;">
                      <label>{{ objProjectConsultant.researcherFullName }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 12%; padding-left: 14px;">
                      <label style="font-weight: bold;">สังกัด</label>
                    </td>
                    <td colspan="2" style="width: 88%;">
                      <label>{{ objProjectConsultant.organizationName }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>

                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 12%; padding-left: 14px;">
                      <label style="font-weight: bold;">email</label>
                    </td>
                    <td colspan="2" style="width: 88%;">
                      <label>{{ objProjectConsultant.eMail }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>

                  </tr>

                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 100%;">
                <div style="padding-top: 8px;">
                  <label style="font-weight: bold;">3. คณะผู้ทำวิจัย</label>
                </div>
              </td>
            </tr>

            <tr *ngFor="let objteamMembers of dataResearcherType1 let i = index">
              <td colspan="4" style="width: 100%;">
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 30%; padding-left: 15px;">
                      <label style="font-weight: bold;">{{ objteamMembers.textResearcher
                        }}</label>
                    </td>
                    <td colspan="2" style="width: 70%;"></td>
                    <td style="min-width: 100px;"></td>
                  </tr>

                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 45%; padding-left: 38px;">
                      <label style="font-weight: bold;">ตำแหน่งทางวิชาการ ชื่อ - นามสกุล</label>
                    </td>
                    <td colspan="2" style="width: 55%;">
                      <label>{{ objteamMembers.researcherFullName }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 12%; padding-left: 38px;">
                      <label style="font-weight: bold;">สังกัด</label>
                    </td>
                    <td colspan="2" style="width: 88%;">
                      <label>{{ objteamMembers.organizationName }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 34%; padding-left: 38px;">
                      <label style="font-weight: bold;">สัดส่วนในการทำวิจัยร้อยละ</label>
                    </td>
                    <td colspan="2" style="width: 66%;">
                      <label>{{ objteamMembers.percentAssociate }}</label>
                    </td>
                    <td style="min-width: 90px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 12%; padding-left: 38px;">
                      <label style="font-weight: bold;">email</label>
                    </td>
                    <td colspan="2" style="width: 88%;">
                      <label>{{ objteamMembers.eMail }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>

                  </tr>

                </table>
              </td>
            </tr>

            <tr *ngFor="let objteamMembers3 of dataResearcherType3 let i = index">
              <td colspan="4" style="width: 100%;">
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 30%; padding-left: 15px;">
                      <label style="font-weight: bold;">{{ objteamMembers3.textResearcher
                        }}</label>
                    </td>
                    <td colspan="2" style="width: 70%;"></td>
                    <td style="min-width: 100px;"></td>
                  </tr>

                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 45%; padding-left: 38px;">
                      <label style="font-weight: bold;">ตำแหน่งทางวิชาการ ชื่อ - นามสกุล</label>
                    </td>
                    <td colspan="2" style="width: 55%;">
                      <label>{{ objteamMembers3.researcherFullName }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 12%; padding-left: 38px;">
                      <label style="font-weight: bold;">สังกัด</label>
                    </td>
                    <td colspan="2" style="width: 88%;">
                      <label>{{ objteamMembers3.organizationName }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 34%; padding-left: 38px;">
                      <label style="font-weight: bold;">สัดส่วนในการทำวิจัยร้อยละ</label>
                    </td>
                    <td colspan="2" style="width: 66%;">
                      <label>{{ objteamMembers3.percentAssociate }}</label>
                    </td>
                    <td style="min-width: 90px;">

                    </td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 12%; padding-left: 38px;">
                      <label style="font-weight: bold;">email</label>
                    </td>
                    <td colspan="2" style="width: 88%;">
                      <label>{{ objteamMembers3.eMail }}</label>
                    </td>
                    <td style="min-width: 100px;">

                    </td>

                  </tr>

                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 100%;">
                <label style="font-weight: bold;">4. ประเภทการวิจัย</label>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 100%; padding-left: 15px;">
                      <label>{{ researcherType }}</label>
                      <label *ngIf="isshowResearcherType5" style="padding-left: 5px; padding-right: 5px;">{{
                        projectProposal.otherResearchDescriptionType}}</label>
                    </td>

                  </tr>
                </table>

              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 100%;">
                <label style="font-weight: bold;">5. สาขาวิชาหลัก
                  และสาขาวิชาที่เกี่ยวข้องทั้งหมดที่ทำวิจัย</label>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 30%; padding-left: 15px;">
                      <label style="font-weight: bold;">5.1 สาขาวิชาหลัก (OCED)</label>
                    </td>
                    <td colspan="2" style="width: 70%;">
                      <label *ngIf="projectProposal.oecd">{{
                        projectProposal.oecd.oecdDesc}}</label>
                    </td>
                    <td style="min-width: 100px;"></td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px;">
                  <tr>
                    <td style="width: 38%; padding-left: 15px;">
                      <label style="font-weight: bold;">5.2 สาขาวิชาที่เกี่ยวข้อง (OCED)</label>
                    </td>
                    <td colspan="2" style="width: 62%;">
                      <label *ngIf="projectProposal.oecdSub">{{
                        projectProposal.oecdSub.oecdSubDesc}}</label>
                    </td>
                    <td style="min-width: 100px;"></td>
                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4" style="width: 100%;">
                <label style="font-weight: bold;">6. วัตถุประสงค์ของการวิจัย (Objectives)</label>
                <table *ngFor="let objobjectives of projectProposal.objectives let i = index"
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px;">
                  <tr>
                    <td style="width: 250px; text-align: left; padding-left: 54px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; ">
                        {{ i + 1 + "."}} {{ objobjectives.objective }}
                      </label>
                    </td>
                  </tr>

                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">7. คำสำคัญ (Keywords)</label>
                <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">

                  <tr>
                    <td style="width: 100%; padding-left: 15px;">
                      {{ datakeywordTH }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 100%; padding-left: 15px;">
                      {{ datakeywordEN }}
                    </td>
                  </tr>

                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">8. ความสำคัญและที่มาของปัญหาการวิจัย (Background and
                  rationale)</label>
                <table style="width: 100%;padding: 0px;margin: 0px; ">
                  <tr>
                    <td style="width: 250px; padding-left: 15px;">
                      <label style="text-align: justify;  word-break: break-all;  " class="font-light"
                        [innerHtml]="transformHTML(projectProposal.importantOfProblem)"></label>
                    </td>


                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">9. การทบทวนวรรณกรรม ทฤษฎี แนวคิด และงานวิจัยที่เกี่ยวข้อง (Review of
                  related literatures)</label>
                <table style="width: 100%;padding: 0px;margin: 0px; ">
                  <tr>
                    <td style="width: 250px; padding-left: 15px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; "
                        [innerHtml]="projectProposal.literatureReview"></label>
                    </td>


                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">10. ระเบียบวิธีวิจัย (Research methodology)</label>
                <table style="width: 100%;padding: 0px;margin: 0px; ">
                  <tr>
                    <td style="width: 100%; padding-left: 22px;">
                      <label style="font-weight: bold; ">10.1 รูปแบบการวิจัย</label>
                    </td>


                  </tr>

                  <tr>
                    <td *ngIf="projectProposal.researchMethods.length > 0"
                      style="width: 250px; padding-bottom: 5px; padding-left: 56px; text-align: justify;">
                      <label style="text-align: justify; word-break: break-all;"
                        [innerHtml]="transformHTML(projectProposal.researchMethods[0].researchMethodDetail)"></label>
                    </td>
                  </tr>


                  <tr>
                    <td style="width: 100%; padding-left: 22px;">
                      <label style="font-weight: bold; ">10.2
                        กลุ่มประชากรและกลุ่มตัวอย่างที่ศึกษา</label>
                    </td>

                  </tr>
                  <tr>
                    <td *ngIf="projectProposal.researchMethods.length > 0"
                      style="width: 250px; padding-bottom: 5px; padding-left: 56px; text-align: justify;">
                      <label style="text-align: justify; word-break: break-all;"
                        [innerHtml]="transformHTML(projectProposal.researchMethods[1].researchMethodDetail)"></label>
                    </td>
                  </tr>


                  <tr>
                    <td style="width: 100%; padding-left: 22px;">
                      <label style="font-weight: bold; ">10.3 วิธีการสุ่มตัวอย่าง</label>
                    </td>


                  </tr>
                  <tr>
                    <td *ngIf="projectProposal.researchMethods.length > 0"
                      style="width: 250px; padding-bottom: 5px; padding-left: 56px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; "
                        [innerHtml]="transformHTML(projectProposal.researchMethods[2].researchMethodDetail)"></label>
                    </td>
                  </tr>


                  <tr>
                    <td style="width: 50%; padding-left: 22px;">
                      <label style="font-weight: bold; ">10.4
                        วิธีการเก็บข้อมูลหรือวิธีการดำเนินงาน</label>
                    </td>

                  </tr>
                  <tr>
                    <td *ngIf="projectProposal.researchMethods.length > 0"
                      style="width: 250px; padding-bottom: 5px; padding-left: 56px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; "
                        [innerHtml]="transformHTML(projectProposal.researchMethods[3].researchMethodDetail)"></label>
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 100%; padding-left: 22px;">
                      <label style="font-weight: bold; ">10.5
                        การวิเคราะห์ข้อมูลและสถิติที่ใช้</label>
                    </td>

                  </tr>
                  <tr>
                    <td *ngIf="projectProposal.researchMethods.length > 0"
                      style="width: 250px; padding-bottom: 5px; padding-left: 56px; text-align: justify;">
                      <label style="text-align: justify; word-break: break-all; "
                        [innerHtml]="transformHTML(projectProposal.researchMethods[4].researchMethodDetail)"></label>
                    </td>
                  </tr>



                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">11. สถานที่ทำการวิจัย (Place)</label><br>


                <table style="width: 100%;padding: 0px;margin: 0px;">

                  <tr>
                    <td div style="width: 100%; padding-left: 18px;">
                      <label> {{ researcherSite }}</label>&nbsp;
                      <label *ngIf="isshowSinglecenter">{{
                        projectProposal.researchSingleCenterName }}</label>
                    </td>
                  </tr>
                  <tr *ngIf="nationalSite">
                    <td div style="width: 100%; padding-left: 18px;">
                      <label> {{ siteNational }}</label>&nbsp;
                      <label>{{ projectProposal.researchNationalSiteName }}</label>

                    </td>
                  </tr>
                  <tr *ngIf="internationalSite">
                    <td div style="width: 100%; padding-left: 18px;">
                      <label>{{ siteInternational }}</label>&nbsp;
                      <label>{{ projectProposal.researchCoInternaltionalSiteName }}</label>


                    </td>
                  </tr>


                </table>

              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">12. ระยะเวลาดำเนินการวิจัยและแผนการดำเนินงาน (Gantt
                  chart)</label><br>
                <table
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; page-break-inside: avoid !important; ">
                  <tr>
                    <td style="padding-left: 22px;">
                      <label class="font-light ">ระยะเวลาดำเนินการวิจัย
                        <Label *ngIf="projectProposal.timeLine != null" style="width: 49px; ">
                          {{ projectProposal.timeLine.totalTimeLineInMonth }}
                          เดือน</Label>
                      </label>
                    </td>
                  </tr>
                </table>


                <table style="width: 100%;padding: 0px;margin: 0px; page-break-inside: avoid !important;">
                  <tr>
                    <td colspan="4 " style="width: 100%; ">
                      <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                        <tr>
                          <td rowspan="2" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                            กิจกรรมตามการดำเนินการวิจัย
                          </td>

                          <td colspan="12" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                            ระยะเวลาดำเนินการ (เดือนที่/ปี)
                          </td>
                          <td rowspan="2" style="border: 1px solid black; font-weight: bold; text-align: center; ">

                            ร้อยละกิจกรรมต่อโครงการวิจัย
                          </td>

                        </tr>

                        <tr>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            1
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            2
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            3
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            4
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            5
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            6
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            7
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            8
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            9
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            10
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px">

                            11
                          </td>
                          <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 30px;">

                            12
                          </td>


                        </tr>

                        <tr *ngFor="let objtimeLine of dataProposalReport let i = index">

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: left; font-size: 12px; background-color: gray; color: white;">
                            {{ "ปีงบประมาณ" + objtimeLine.activityName }}
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99"
                            style="border: 1px solid black; text-align: left; font-size: 12px;">
                            {{ objtimeLine.activityName }}
                          </td>
                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 1 && objtimeLine.toMonth >= 1"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth1"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 2 && objtimeLine.toMonth >= 2"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth2"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 3 && objtimeLine.toMonth >= 3"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth3"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 4 && objtimeLine.toMonth >= 4"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth4"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 5 && objtimeLine.toMonth >= 5"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth5"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 6 && objtimeLine.toMonth >= 6"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth6"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 7 && objtimeLine.toMonth >= 7"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth7"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 8 && objtimeLine.toMonth >= 8"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth8"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 9 && objtimeLine.toMonth >= 9"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth9"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 10 && objtimeLine.toMonth >= 10"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth10"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 11 && objtimeLine.toMonth >= 11"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth11"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                          </td>

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td
                            *ngIf="objtimeLine.activityType != -99 && objtimeLine.fromMonth <= 12 && objtimeLine.toMonth >= 12"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            x
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99 && !objtimeLine.isShowMonth12"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">

                          <td *ngIf="objtimeLine.activityType == -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px; background-color: gray; color: white; text-align: left;">
                          </td>
                          <td *ngIf="objtimeLine.activityType != -99"
                            style="border: 1px solid black; text-align: center; font-size: 12px;">
                            {{ objtimeLine.percentOfActivity }}
                          </td>

                        </tr>

                      </table>
                    </td>
                  </tr>
                </table>



              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">13. ผลที่คาดว่าจะได้รับจากการวิจัย (Outputs)</label>

                <table class="tableContent"
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; page-break-inside: avoid !important;">
                  <tr>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center; width: 57px; word-break: break-all">

                      ลำดับ
                    </td>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center; width: 57px; word-break: break-all">

                      เดือนที่
                    </td>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center; width: 286px; word-break: break-all">

                      กิจกรรม/ขั้นตอนการดำเนินงาน
                    </td>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 286px; word-break: break-all">

                      ผลที่คาดว่าจะได้รับ
                    </td>


                  </tr>
                  <tr *ngFor="let objexpectedResults of projectProposal.expectedResults let i = index ">
                    <td
                      style="border: 1px solid black; text-align: center; vertical-align: top; width: 57px; word-break: break-all">
                      {{ i + 1 }}
                    </td>
                    <td
                      style="border: 1px solid black; text-align: center; vertical-align: top; width: 57px; word-break: break-all">
                      {{ objexpectedResults.fromMonth}} - {{ objexpectedResults.toMonth}}
                    </td>
                    <td
                      style="border: 1px solid black; text-align: left; vertical-align: top; width: 286px; word-break: break-all">
                      <span style="white-space: pre-line">{{
                        objexpectedResults.activityName}}</span>

                    </td>

                    <td
                      style="border: 1px solid black; text-align: left; vertical-align: top;  width: 286px; word-break: break-all">
                      <span style="white-space: pre-line">{{
                        objexpectedResults.expectedResult}}</span>
                    </td>

                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">14. งบประมาณและแหล่งทุนในการวิจัย (Budgets)</label>
                <table
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; page-break-inside: avoid !important;">
                  <tr>
                    <td style="padding-left: 25px;">
                      <label class="font-light ">งบประมาณทั้งโครงการ {{ totalFundAllocationTwo }}
                        บาท ({{
                        projectFundInThai }})
                      </label>
                    </td>
                  </tr>
                </table>
                <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">

                  <tr>
                    <td style="width: 26%; padding-left: 25px;">
                      <label style="font-weight: bold; ">ประเภททุนที่เสนอขอ</label>
                    </td>
                    <td colspan="2 " style="width: 74%; ">
                      <label *ngIf="projectProposal.budget != null">{{
                        projectProposal.budget.fundingType.fundingName}}</label>
                    </td>
                    <td style="min-width: 100px; ">

                    </td>
                  </tr>
                  <tr>
                    <td style="width: 26%; padding-left: 25px;">
                      <label style="font-weight: bold; ">เงินรวมประมาณการ</label>
                    </td>
                    <td colspan="2 " style="width: 74%; ">
                      <label>{{ forcastedBudget }} </label>
                      <label style="font-weight: bold; ">บาท</label>
                    </td>
                    <td style="min-width: 100px; ">

                    </td>
                  </tr>

                </table>
                <table
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; page-break-inside: avoid !important;">

                  <tr>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center; width: 270px; word-break: break-all">

                      รายการ
                    </td>

                    <td style="border: 1px solid black; font-weight: bold; text-align: center; width: 98px;">

                      งบประมาณ (รวม)
                    </td>

                    <td *ngIf="keepYear.length >= 1"
                      style="border: 1px solid black; font-weight: bold; text-align: center; ">

                      ปี {{ keeyMonth1 }}
                    </td>
                    <td *ngIf="keepYear.length >= 2"
                      style="border: 1px solid black; font-weight: bold; text-align: center; ">

                      ปี {{ keeyMonth2 }}
                    </td>
                    <td *ngIf="keepYear.length >= 3"
                      style="border: 1px solid black; font-weight: bold; text-align: center; ">

                      ปี {{ keeyMonth3 }}
                    </td>
                    <td *ngIf="keepYear.length >= 4"
                      style="border: 1px solid black; font-weight: bold; text-align: center; ">

                      ปี {{ keeyMonth4 }}
                    </td>
                    <td *ngIf="keepYear.length >= 5"
                      style="border: 1px solid black; font-weight: bold; text-align: center; ">

                      ปี {{ keeyMonth5 }}
                    </td>
                  </tr>
                  <tr *ngFor="let objbudget of budgetByYearReport let i = index">
                    <td [ngStyle]=" objbudget.budgetTotal ? {} : {'background-color':'lightgray','font-weight':'bold'}"
                      style="border: 1px solid black; font-size: 12px; text-align: left; padding-left: 3px; vertical-align: top; width: 270px; word-break: break-all">
                      {{objbudget.budgetDesc}}

                    </td>

                    <td [ngStyle]="{'background-color': objbudget.budgetTotal ? 'white' : 'lightgray' }"
                      style="border: 1px solid black; font-size: 12px; text-align: right; width: 98px;">
                      <div *ngIf="objbudget.budgetTotal">
                        {{objbudget.budgetTotal|number:'1.2-2'}}
                      </div>

                    </td>

                    <td [ngStyle]="{'background-color': objbudget.budgetTotal ? 'white' : 'lightgray' }"
                      *ngIf="keepYear.length >= 1"
                      style="border: 1px solid black;font-size: 12px;  text-align: right; ">
                      <div *ngIf="objbudget.budgetAmountByYear">
                        {{objbudget.budgetAmountByYear[keeyMonth1]|number:'1.2-2'}}</div>

                    </td>
                    <td [ngStyle]="{'background-color': objbudget.budgetTotal ? 'white' : 'lightgray' }"
                      *ngIf="keepYear.length >= 2"
                      style="border: 1px solid black;font-size: 12px;  text-align: right; ">
                      <div *ngIf="objbudget.budgetAmountByYear">
                        {{objbudget.budgetAmountByYear[keeyMonth2]|number:'1.2-2'}}</div>

                    </td>
                    <td [ngStyle]="{'background-color': objbudget.budgetTotal ? 'white' : 'lightgray' }"
                      *ngIf="keepYear.length >= 3"
                      style="border: 1px solid black;font-size: 12px;  text-align: right; ">
                      <div *ngIf="objbudget.budgetAmountByYear">
                        {{objbudget.budgetAmountByYear[keeyMonth3]|number:'1.2-2'}}</div>

                    </td>
                    <td [ngStyle]="{'background-color': objbudget.budgetTotal ? 'white' : 'lightgray' }"
                      *ngIf="keepYear.length >= 4"
                      style="border: 1px solid black;font-size: 12px;  text-align: right; ">
                      <div *ngIf="objbudget.budgetAmountByYear">
                        {{objbudget.budgetAmountByYear[keeyMonth4]|number:'1.2-2'}}</div>

                    </td>
                    <td [ngStyle]="{'background-color': objbudget.budgetTotal ? 'white' : 'lightgray' }"
                      *ngIf="keepYear.length >= 5"
                      style="border: 1px solid black;font-size: 12px;  text-align: right; ">
                      <div *ngIf="objbudget.budgetAmountByYear">
                        {{objbudget.budgetAmountByYear[keeyMonth5]|number:'1.2-2'}}</div>

                    </td>
                  </tr>

                  <tr>
                    <td
                      style="border: 1px solid black; font-size: 12px; background-color: lightgray;text-align: left; padding-left: 3px; font-weight: bold; width: 270px; word-break: break-all">
                      รวมงบประมาณที่เสนอขอ
                    </td>


                    <td
                      style="border: 1px solid black;  font-size: 12px; background-color: lightgray;  text-align: right; width: 98px;">
                      {{ forcastedBudget }}
                    </td>

                    <td *ngIf="keepYear.length >= 1"
                      style="border: 1px solid black; font-size: 12px; background-color: lightgray;  text-align: right;">
                      {{footerTotal[keeyMonth1]|number:'1.2-2'}}
                    </td>
                    <td *ngIf="keepYear.length >= 2"
                      style="border: 1px solid black; font-size: 12px; background-color: lightgray; text-align: right;">
                      {{footerTotal[keeyMonth2]|number:'1.2-2'}}
                    </td>
                    <td *ngIf="keepYear.length >= 3"
                      style="border: 1px solid black; font-size: 12px; background-color: lightgray;  text-align: right;">
                      {{footerTotal[keeyMonth3]|number:'1.2-2'}}
                    </td>
                    <td *ngIf="keepYear.length >= 4"
                      style="border: 1px solid black; font-size: 12px; background-color: lightgray;  text-align: right;">
                      {{footerTotal[keeyMonth4]|number:'1.2-2'}}
                    </td>
                    <td *ngIf="keepYear.length >= 5"
                      style="border: 1px solid black;  font-size: 12px; background-color: lightgray;  text-align: right;">
                      {{footerTotal[keeyMonth5]|number:'1.2-2'}}
                    </td>
                  </tr>


                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">15. เหตุผลความจำเป็นในการจัดซื้อครุภัณฑ์
                  (*สำหรับทุนประเภทที่ 3
                  ทุนวิจัยระดับแนวหน้า และทุนประเภทที่ 4 ทุนสมทบโครงการวิจัย)</label><br>

                <table class="tableContent"
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; page-break-inside: avoid !important;">
                  <tr>
                    <td rowspan="2"
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 35px; word-break: break-all">

                      ลำดับ
                    </td>
                    <td rowspan="2"
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 90px; word-break: break-all">

                      ชื่อครุภัณฑ์
                    </td>
                    <td colspan="3"
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 379px; word-break: break-all">

                      ครุภัณท์ที่ขอสนับสนุน
                    </td>

                    <td rowspan="2"
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 137px; word-break: break-all">

                      ลักษณะการใช้งานและความจะเป็น ต้องระบุ
                    </td>


                  </tr>

                  <tr>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 35px; word-break: break-all">

                      สถานะภาพ (มี/ไม่มี)
                    </td>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 154.5px; word-break: break-all">

                      ครุภัณฑ์ที่ใกล้เคียงที่ใช้ ณ ปัจุบัน (ถ้ามี)
                    </td>
                    <td
                      style="border: 1px solid black; font-weight: bold; text-align: center;  width: 154.5px; word-break: break-all">

                      สถานะภาพการใช้งาน ณ ปัจจุบัน (ถ้ามี)
                    </td>
                  </tr>
                  <tr *ngFor="let objdurableArticles of projectProposal.durableArticles let i = index">
                    <td
                      style="border: 1px solid black; text-align: center;  vertical-align: top; width: 35px; word-break: break-all">
                      {{ i + 1 }}
                    </td>
                    <td
                      style="border: 1px solid black; text-align: left;  vertical-align: top; width: 90px; word-break: break-all">
                      {{ objdurableArticles.durableArticleName }}
                    </td>
                    <td
                      style="border: 1px solid black; text-align: left;  vertical-align: top;  width: 35px; word-break: break-all">
                      {{ objdurableArticles.durableArticleStatus }}
                    </td>

                    <td
                      style="border: 1px solid black; text-align: left;  vertical-align: top; width: 154.5px; word-break: break-all">
                      {{ objdurableArticles.equivalentDurableArticle }}
                    </td>
                    <td
                      style="border: 1px solid black; text-align: left;  vertical-align: top; width: 154.5px; word-break: break-all">
                      {{ objdurableArticles.presentStatus }}
                    </td>

                    <td
                      style="border: 1px solid black; text-align: left;  vertical-align: top; width: 137px; word-break: break-all">
                      {{ objdurableArticles.usage }}
                    </td>

                  </tr>
                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">16. เอกสารอ้างอิง (References)</label><br>

                <table *ngFor="let objreferenceDocuments of projectProposal.referenceDocuments let i = index"
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                  <tr>

                    <td style="width: 250px; text-align: left; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; ">
                        {{ i + 1 + "."}} {{ objreferenceDocuments.referenceDocumentDetail }}
                      </label>
                    </td>
                  </tr>

                </table>
              </td>
            </tr>

            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">17. ประโยชน์ที่คาดว่าจะได้รับจากการวิจัย (Expected
                  benefits)</label><br>

                <table *ngFor="let objexpectedBenefits of projectProposal.expectedBenefits let i = index"
                  style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                  <tr>

                    <td style="width: 250px; text-align: left; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; ">
                        {{ i + 1 + "."}} {{ objexpectedBenefits.expectation }}
                      </label>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">18. ผลผลิตและผลลัพธ์ที่คาดว่าจะได้รับจากการวิจัย
                  (Outputs/
                  Outcome)</label><br>
                <table style="width: 100%;padding: 0px;margin: 0px;">

                  <tr *ngIf="isShowPresentationMeeting">
                    <td style="width: 100%;padding-left: 22px;">
                      <label>การนำเสนอผลงานในการประชุมวิชาการ {{
                        projectProposal.received.presentWorkNumber }}&nbsp;ครั้ง&nbsp;
                      </label>

                    </td>
                  </tr>

                  <tr *ngIf="isShowNationalPresention">
                    <td style="width: 100%; padding-left: 36px;">
                      <label>ระดับชาติ จำนวน &nbsp; {{
                        projectProposal.received.presentWorkNationalNumber }}
                        &nbsp;ครั้ง&nbsp; </label>

                  </tr>
                  <tr *ngIf="isShowNationalPresention">
                    <td style="width: 25%; padding-left: 56px;">
                      <label>Oral Presentation จำนวน &nbsp; {{
                        projectProposal.received.presentWorkNationalOralNumber
                        }}&nbsp;ครั้ง&nbsp; </label>
                    </td>
                  </tr>
                  <tr *ngIf="isShowNationalPresention">
                    <td style="width: 25%; padding-left: 56px;">
                      <label>poster Presentation จำนวน &nbsp; {{
                        projectProposal.received.presentWorkNationalPosterNumber
                        }}&nbsp;ครั้ง&nbsp;
                      </label>
                    </td>
                  </tr>

                  <tr *ngIf="isShowInternationalPresention">
                    <td style="width: 100%; padding-left: 36px;">
                      <label>ระดับนานาชาติ จำนวน &nbsp; {{
                        projectProposal.received.presentWorkInternationalNumber
                        }}&nbsp;ครั้ง&nbsp; </label>

                    </td>
                  </tr>
                  <tr *ngIf="isShowInternationalPresention">
                    <td style="width: 25%; padding-left: 56px;">
                      <label>Oral Presentation จำนวน &nbsp;{{
                        projectProposal.received.presentWorkInternationalOralNumber
                        }}&nbsp;ครั้ง&nbsp;
                      </label>
                    </td>
                  </tr>
                  <tr *ngIf="isShowInternationalPresention">
                    <td style="width: 25%; padding-left: 56px;">
                      <label>poster Presentation จำนวน &nbsp;{{
                        projectProposal.received.presentWorkInternationalPosterNumber
                        }}&nbsp;ครั้ง&nbsp;
                      </label>
                    </td>
                  </tr>

                  <tr *ngIf="isShowArticle">
                    <td style="width: 100%; padding-left: 23px;">
                      <label>บทความทางวิชาการ จำนวน &nbsp; {{
                        projectProposal.received.articleNumber
                        }}&nbsp;เรื่อง </label>

                    </td>
                  </tr>
                  <tr *ngIf="isShowArticleNational">
                    <td style="width: 100%; padding-left: 36px;">
                      <label>วารสารวิชาการระดับชาติ จำนวน &nbsp;{{
                        projectProposal.received.journalNationalNumber
                        }}&nbsp;เรื่อง ปีที่คาดว่าจะตีพิมพ์ &nbsp; {{
                        projectProposal.received.journalNationalPublishYear }}</label>

                    </td>
                  </tr>
                  <table style="width: 100%;padding: 0px;margin: 0px; padding-left: 56px;">
                    <tr *ngIf="valuetciTier != undefined">
                      <td style="width: 20%;">
                        <label>ระดับ TCI Tier&nbsp; {{ valuetciTier }} </label>
                      </td>
                    </tr>
                  </table>
                  <tr *ngIf="isShowArticleInternational">
                    <td style="width: 100%; padding-left: 36px;">
                      <label>วารสารวิชาการระดับนานาชาติ จำนวน&nbsp; {{
                        projectProposal.received.journalInternationalNumber }}&nbsp;เรื่อง
                        ปีที่คาดว่าจะตีพิมพ์&nbsp;{{
                        projectProposal.received.journalInternationalPublishYear
                        }}</label>

                    </td>
                  </tr>
                  <table style="width: 100%;padding: 0px;margin: 0px; padding-left: 56px;">
                    <tr *ngIf="valuejournalInternationalDatabase != undefined">
                      <td style="width: 20%;">
                        <label>ฐานข้อมูล&nbsp; {{ valuejournalInternationalDatabase }}</label>
                        <label *ngIf="isShowDatabase3">{{
                          projectProposal.received.journalInternationalOther
                          }}</label>
                      </td>
                    </tr>
                  </table>
                  <table style="width: 100%;padding: 0px;margin: 0px; padding-left: 56px;">
                    <tr *ngIf="valuequartile != undefined">
                      <td style="width: 20%;">
                        <label>ระดับควอไทล์ &nbsp; {{ valuequartile }}</label>
                      </td>
                    </tr>
                  </table>
                  <tr *ngIf="isSowPatent">
                    <td style="width: 100%;padding-left: 21px;">
                      <label>สิทธิบัตร หรืออนุสิทธิบัตร &nbsp;{{
                        projectProposal.received.patentNumber }}
                        &nbsp;เรื่อง/ชิ้นงาน ระบุ</label>

                    </td>
                  </tr>

                  <tr *ngIf="isShowCommercialInnovation">
                    <td style="width: 100%; padding-left: 21px;">
                      <label>นวัตกรรมเชิงพานิชย์ จำนวน &nbsp;{{
                        projectProposal.received.commercialInnovationNumber }}
                        &nbsp;เรื่อง/ชิ้นงาน ระบุ
                        &nbsp;{{ projectProposal.received.commercialInnovationWorkPiece
                        }}</label>

                    </td>
                  </tr>
                  <tr *ngIf="isShowCopyright">
                    <td style="width: 100%; padding-left: 21px;">
                      <label>ทรัพย์สินทางปัญญา</label>

                    </td>
                  </tr>

                  <tr *ngIf="isShowOther">
                    <td style="width: 100%; padding-left: 21px;">
                      <label>อื่นๆ ระบุ &nbsp;{{ projectProposal.received.otherDetail }}</label>

                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="4 " style="width: 100%; ">
                <label style="font-weight: bold; ">19. ผลกระทบที่คาดว่าจะได้รับจากการวิจัย
                  (Impact)</label>
                <table style="width: 100%;padding: 0px;margin: 0px; ">
                  <tr>
                    <td style="width: 100%; padding-left: 21px;">
                      <label style="font-weight: bold; ">19.1
                        ผลงานวิจัยสามารถนำไปใช้ประโยชน์ในการแก้ปัญหาของสังคมและชุมชนได้หรือไม่*</label>
                    </td>

                    <td style="min-width: 100px; "></td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px; ">


                  <tr>
                    <td style="width: 250px; text-align: left; padding-left: 54px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; ">
                        {{ commentOne }}
                      </label>
                    </td>
                  </tr>

                </table>

                <table style="width: 100%;padding: 0px;margin: 0px; ">

                  <tr>
                    <td style="width: 100%; padding-left: 21px;">
                      <label style="font-weight: bold; ">19.2 ผลงานวิจัย
                        สามารถนำไปใช้ในการวางนโยบายในการพัฒนาประเทศได้หรือไม่*</label>
                    </td>
                    <td style="min-width: 100px; "></td>
                  </tr>

                </table>
                <table style="width: 100%;padding: 0px;margin: 0px; ">

                  <tr>
                    <td style="width: 250px; text-align: left; padding-left: 54px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; ">
                        {{ commentTwo }}
                      </label>
                    </td>
                  </tr>
                </table>

                <table style="width: 100%;padding: 0px;margin: 0px; ">

                  <tr>
                    <td style="width: 100%; padding-left: 21px;">
                      <label style="font-weight: bold; ">19.3 อื่นๆ </label>
                    </td>

                    <td style="min-width: 100px; "></td>
                  </tr>
                </table>
                <table style="width: 100%;padding: 0px;margin: 0px; ">

                  <tr>
                    <td style="width: 250px; text-align: left; padding-left: 54px; text-align: justify;">
                      <label style="text-align: justify;  word-break: break-all; ">
                        {{ commentTree }}
                      </label>
                    </td>
                  </tr>

                </table>
              </td>
            </tr>
          </table>
        </div>
      </td>
  </tbody>
</table>
