
import { CurrencyPipe,formatNumber,Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { attachdocument } from 'src/app/datamodel/attachdocument';
import { budgetdetailallocation } from 'src/app/datamodel/budgetdetailallocation';
import { enumdata } from 'src/app/datamodel/enumdata';
import { messagecontent } from 'src/app/datamodel/messagecontent';
import { projectcancellation } from 'src/app/datamodel/projectcancellation';
import { projectobjective } from 'src/app/datamodel/projectobjective';
import { projectproposal } from 'src/app/datamodel/projectproposal';
import { projectProposalControlData } from 'src/app/datamodel/projectProposalControlData';
import { projecttracking } from 'src/app/datamodel/projecttracking';
import { trackingattachdocument } from 'src/app/datamodel/trackingattachdocument';
import { trackingcommitteedecision } from 'src/app/datamodel/trackingcommitteedecision';
import { MessageDialogComponent } from 'src/app/dialogs/message-dialog/message-dialog.component';
import { IAPIResponse } from 'src/app/interfaces/apiResponse';
import { BrokerAPIService } from 'src/app/services/brokerapi.service';
import { sortingArrayList,transformDateToBE,base64ToBlob, offsetTimeZone, moneyToThai,getEditorConfig, transformYearToGovernementFiscalYear  } from '../../services/general-function';

@Component({
  selector: 'app-project-progress-detail',
  templateUrl: './project-progress-detail.component.html',
  styleUrls: ['./project-progress-detail.component.scss'],
})
export class ProjectProgressDetailComponent implements OnInit {
  editorConnfig: AngularEditorConfig = getEditorConfig();
  selectedTabIndex: any;
  eventselectedTabIndex: any;
  arrfileName: string[];
  isShowPresentations: boolean = false;
  attachedFileName: string;
  dataBase64: any;
  attachedFileType: string;
  result: IAPIResponse;
  projectAttachFileTypeList: enumdata[];
  projectAttachFileTypeListToShow: enumdata[];
  private errorMessage: string;
  appInfo: any;
  isshowdialogSentReport: boolean;
  isshowdialogRecordResolutions: boolean;
  proposeExtendMonth: boolean;
  proposeProjectCancel: boolean;
  saveInProgress: boolean;
  budgetGroupList: enumdata[];
  budgetSubGroupList: enumdata[];
  totalProjectBudget: string;
  totalProjectBudgetInThai: string;
  projectOwnerFullname: string = '';
  projectApprovalDate: string = '';
  organizationName: string = '';
  mobielPhone: string = '';
  email: string = '';
  budgetPaymentTerm: number;
  totalProjectMonth: number;
  proposeDecision: string;
  closeSnackbarMessage: string = 'Close';
  projectProposalControl: projectProposalControlData;
  totalFundingAllocation: number = 0;
  totalTermAmount: number = 0;
  projectWorkingMonth: number;
  trackingCommitteeSelection: string;
  trackingCommitteeExtendedMonthEnable: boolean = false;
  trackingCommitteeExtendedMonth: number = 0;
  trackingCommitteeTopic: string;
  trackingCommitteeMessage: string;
  isTrackingCommitteeAttachfile: boolean;
  isStopingProject: boolean;

  isBudgetetailNeeded:boolean;

  isLongForm: boolean;

  isShowingTrackingEntry: boolean;
  trackingResultCaption: string;
  isShowingTrackingResultEntry: boolean;
  trackingResultEntryCaption: string;

  isDataEditable: boolean;

  trackingTime: number;
  trackingDate: Date;

  projectTrackingData: projecttracking;
  onPlanCode: string;

  committeeDecision: messagecontent;
  committeeDecisionFileName1: string;
  committeeDecisionFileName2: string;
  committeeDecisionFileName3: string;
  //วัตถุประสงค์ของโครงการวิจัย

  dataSourceObjective = new MatTableDataSource();
  displayedColumnsObjective = ['lineNo', 'objective'];

  //ผลการดำเนินงาน
  dataSourceExpectedResults = new MatTableDataSource();
  displayedColumnsExpectedResults = [
    'lineNo',
    'fromMonth',
    'activityName',
    'expectedResult',
    'percentAssociate',
  ];

  //คำชี้แจงเกี่ยวกับปัญหาหรืออุปสรรค
  Statement: string;

  //ผลการดำเนินงาน
  dataSourceAttachment = new MatTableDataSource();
  displayedColumnsAttachment = [
    'Control',
    'lineNo',
    'documentType',
    'documentName',
  ];

  operationList: any = [
    {
      description: 'การเตรียมเครื่องมือในการวิจัยหรือการทบทวนวรรณกรรม',
      status: '1',
    },
    {
      description:
        'การดำเนินงานวิจัย เช่น การเก็บข้อมูลแบบสอบถาม,การเก็บข้อมูลคนไข้,การเก็บข้อมูลทางห้องปฏิบัติการ,การลงพื้นที่ชุมชน ฯลฯ',
      status: '1',
    },
    {
      description:
        'การวิเคราะห์ข้อมูล เช่น ผลการวิเคราะห์ข้อมูล,สรุปผลการทดลอง ฯลฯ',
      status: '1',
    },
    {
      description:
        'จัดทำรายงานและเผยแพร่ เช่น ร่างรายงานการวิจัยฉบับสมบุรณ์,Manuscript,Reprint ฯลฯ',
      status: '1',
    },
  ];
  dataSourceOperationProcedure = new MatTableDataSource();
  displayedColumnsOperationProcedure = [
    'lineNo',
    'operationProcedure',
    'operationStatus',
  ];

  //รายงานการใช้จ่ายเงินงบประมาณ

  dataSourceSourceOfFund = new MatTableDataSource();
  displayedColumnSourceOfFund = [
    'lineNo',
    'fiscalYear',
    'budgetReceived',
    'budgetCode',
    'budgetAreaCode',
  ];

  dataSourceBudgetTerm = new MatTableDataSource();
  displayedColumnBudgetTerm = ['termNo', 'budgetReceived'];

  dataSourceBudgetSpending = new MatTableDataSource();
  displayedColumnsBudgetSpending = [
    'Control',
    'lineNo',
    'fiscalYear',
    'budgetCategory',
    'budgetGroup',
    'budgetDescription',
    'withdrawDate',
    'budgetRequest',
    'budgetReceived',
    'budgetUsagePlan',
  ];

  dataSourceBudgetBalance = new MatTableDataSource();
  displayedColumnsBudgetBalance = [
    'lineNo',
    'fiscalYear',
    'budgetCategory',
    'budgetGroup',
    'budgetReceived',
    'withdrawBudget',
    'actualWithdrawBudget',
    'balance',
  ];
  totalBudget: number = 0;
  totalRequestWithdraw: number = 0;
  totalActualWithdraw: number = 0;
  totalBalance: number = 0;

  spendingSummarybyGroup: spendingBygroup[];
  isObjectivesValid: boolean = false;
  isOperationListValid: boolean = false;
  isTrackingBudgetHeadValid: boolean = false;
  isStatementValid: boolean = false;
  isProposeDecisionValid: boolean = false;
  isUseBudgetValid: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private brokerAPIService: BrokerAPIService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private locationService: Location,
    private currencyPipe: CurrencyPipe,
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog
  ) {
    this.appInfo = JSON.parse(this.route.snapshot.paramMap.get('dataobj'));
    if(this.appInfo.fundingType==1){
      this.isBudgetetailNeeded = true;
    } else {
      this.isBudgetetailNeeded = false;
    }
    this.dateAdapter.setLocale('th-TH');
    this.getProjectAttachFileTypeList();
    this.projectTrackingData = new projecttracking();
    this.projectTrackingData.project = new projectproposal();
    this.getBudgetGroupList();
  }

  ngOnInit(): void {
    //showDialog
    this.isshowdialogSentReport = false;
    this.isshowdialogRecordResolutions = false;
    this.getProjectTrackingData(this.appInfo['projectid']);
    this.dataSourceOperationProcedure.data = this.operationList;
  }

  onTabChanged($event) {
    let clickedIndex = $event.index;
    this.eventselectedTabIndex = $event.index;
  }

  btnNext() {
    this.selectedTabIndex = 1;

    if (this.eventselectedTabIndex == 1) {
      this.selectedTabIndex = 2;
    } else if (this.eventselectedTabIndex == 2) {
      this.selectedTabIndex = 3;
    } else if (this.eventselectedTabIndex == 3) {
      this.selectedTabIndex = 4;
    } else if (this.eventselectedTabIndex == 4) {
      this.selectedTabIndex = 5;
    } else if (this.eventselectedTabIndex == 5) {
      this.selectedTabIndex = 6;
    } else if (this.eventselectedTabIndex == 6) {
      this.selectedTabIndex = 7;
    } else if (this.eventselectedTabIndex == 7) {
      this.selectedTabIndex = 8;
    } else if (this.eventselectedTabIndex == 8) {
      this.selectedTabIndex = 9;
    } else if (this.eventselectedTabIndex == 9) {
      this.selectedTabIndex = 10;
    } else if (this.eventselectedTabIndex == 10) {
      this.selectedTabIndex = 11;
    } else if (this.eventselectedTabIndex == 11) {
      this.selectedTabIndex = 12;
    } else if (this.eventselectedTabIndex == 12) {
      this.selectedTabIndex = 13;
    } else if (this.eventselectedTabIndex == 13) {
      this.selectedTabIndex = 14;
    } else if (this.eventselectedTabIndex == 14) {
      this.selectedTabIndex = 15;
    } else if (this.eventselectedTabIndex == 15) {
      this.selectedTabIndex = 16;
    } else if (this.eventselectedTabIndex == 16) {
      this.selectedTabIndex = 17;
    } else if (this.eventselectedTabIndex == 17) {
      this.selectedTabIndex = 18;
    }
  }

  btnPrevious() {
    this.selectedTabIndex = this.eventselectedTabIndex - 1;
  }

  // Objectives
  btnAddObjective() {
    let objectiveRow = new projectobjective();
    objectiveRow.projectId = this.projectTrackingData.projectId;

    if (this.projectTrackingData.objectives) {
      this.projectTrackingData.objectives.push(objectiveRow);
    } else {
      this.projectTrackingData.objectives = [objectiveRow];
    }
    this.dataSourceObjective.data = this.projectTrackingData.objectives;
  }

  btnAddExpectedResult() {}

  onBudgetTotalChange() {}

  onDocumentTypeInput(searchName: string) {
    this._filterAttachedFileType(searchName);
  }

  private _filterAttachedFileType(value: string) {
    if (!value) {
      this.projectAttachFileTypeListToShow = this.projectAttachFileTypeList;
      return;
    }
    const filterValue = value.toLowerCase();
    let typeList: [enumdata];
    // for(let i=0 ; i<this.projectAttachFileTypeList.length;i++){
    //   if(!this.projectAttachFileTypeList[i].description.includes(value)){
    //     this.projectAttachFileTypeList.splice(i,1);
    //   }
    // }
    let tempList: [enumdata];
    for (let i = 0; i < this.projectAttachFileTypeList.length; i++) {
      if (this.projectAttachFileTypeList[i].description.includes(filterValue)) {
        let tempRow = new enumdata();
        tempRow.id = this.projectAttachFileTypeList[i].id;
        tempRow.description = this.projectAttachFileTypeList[i].description;
        if (tempList) {
          tempList.push(tempRow);
        } else {
          tempList = [tempRow];
        }
      }
    }

    this.projectAttachFileTypeListToShow = tempList;
    // return Array.from(this.projectAttachFileTypeList.values()).filter(option => option['description'].toLowerCase().includes(filterValue)||option['description'].toLowerCase().includes(filterValue));
  }

  onAttachedFileTypeSelected(description: string) {
    this.attachedFileType = description;
  }

  btnSaveattachDocuments() {
    let errorMessage: string = '';
    if (this.attachedFileType == null || this.attachedFileType == '') {
      errorMessage = 'ประเภทเอกสาร';
    }
    if (this.attachedFileName == null || this.attachedFileName == '') {
      if (errorMessage == '') {
        errorMessage = 'ไฟล์ที่ต้องการแนบ';
      } else {
        errorMessage = errorMessage + 'และไฟล์ที่ต้องการแนบ';
      }
    }
    if (errorMessage != '') {
      this.openSnackBar('กรุณาใส่' + errorMessage, '');
      errorMessage = '';
      return;
    }
    let attachedDocument = new attachdocument();
    attachedDocument.documentType = this.attachedFileType;
    attachedDocument.documentName = this.attachedFileName;
    this.attachedFileType = '';
    this.attachedFileName = '';
    attachedDocument.documentContent = this.dataBase64;
    attachedDocument.documentExtention =
      attachedDocument.documentName.substring(
        attachedDocument.documentName.indexOf('.') + 1,
        attachedDocument.documentName.length
      );
    this.PostFileData(attachedDocument);
  }

  PostFileData(docToUpload: attachdocument) {
    this.brokerAPIService
      .post('/DocumentUpload/UploadDocument', JSON.stringify(docToUpload))
      .subscribe(
        (values) => {
          this.result = values;
          docToUpload.id = Number.parseInt(this.result.data);
          console.log('imageid<' + JSON.stringify(docToUpload));
          let projectAttachdocumentRow = new trackingattachdocument();
          projectAttachdocumentRow.projectTrackingId =
            this.projectTrackingData.id;
          projectAttachdocumentRow.attachDocumentId = Number.parseInt(
            this.result.data
          );
          docToUpload.documentContent = null;
          projectAttachdocumentRow.attachDocument = docToUpload;
          if (this.projectTrackingData.trackingAttachDocuments) {
            this.projectTrackingData.trackingAttachDocuments.push(
              projectAttachdocumentRow
            );
          } else {
            this.projectTrackingData.trackingAttachDocuments = [
              projectAttachdocumentRow,
            ];
          }
          this.dataSourceAttachment.data =
            this.projectTrackingData.trackingAttachDocuments;
          console.log(
            'attach',
            JSON.stringify(this.projectTrackingData.trackingAttachDocuments)
          );
        },
        (error) => {
          this.errorMessage = <any>error;
          console.log('errorMessage:' + JSON.stringify(this.errorMessage));
        }
      );
  }

  async getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  async handleFileInput(files: FileList) {
    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      this.attachedFileName = file.name;
      const result = await this.getBase64(file).catch((e) => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        return;
      }
      this.dataBase64 = result.toString();
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 4000,
    });
  }

  btnSave() {
    this.blockScreen();
    this.projectTrackingData.problemNotice = this.Statement;
    // if(this.totalBalance<0){
    //   this.openDialog('ไม่สามารถบันทึกข้อมูลได้','ยอดใช้จ่ายเกินยอดงบประมาณ');
    //   this.unBlockScreen();
    //   return;
    // }

    // this.projectTrackingData.trackingBudgetHead.totalProjectBudget = this.totalProjectBudget ;

    for (let i = 0; i < this.dataSourceOperationProcedure.data.length; i++) {
      switch (i) {
        case 0: {
          this.projectTrackingData.progressStep1 = Number.parseInt(
            this.dataSourceOperationProcedure.data[i]['status']
          );
          break;
        }
        case 1: {
          this.projectTrackingData.progressStep2 = Number.parseInt(
            this.dataSourceOperationProcedure.data[i]['status']
          );
          break;
        }
        case 2: {
          this.projectTrackingData.progressStep3 = Number.parseInt(
            this.dataSourceOperationProcedure.data[i]['status']
          );
          break;
        }
        case 3: {
          this.projectTrackingData.progressStep4 = Number.parseInt(
            this.dataSourceOperationProcedure.data[i]['status']
          );
          break;
        }
      }
    }

    if (this.proposeDecision) {
      this.projectTrackingData.nextStepPlan = Number.parseInt(
        this.proposeDecision
      );
    }
    if (this.onPlanCode == '1') {
      this.projectTrackingData.isProjetOnPlan = true;
    } else {
      this.projectTrackingData.isProjetOnPlan = false;
    }
    this.projectTrackingData.trackingActualResults = this.updateOrderNo(
      this.projectTrackingData.trackingActualResults
    );
    this.projectTrackingData.trackingBudgetHead.budgetDetails =
      this.updateOrderNo(
        this.projectTrackingData.trackingBudgetHead.budgetDetails
      );
    // for(let i=0;i<this.projectTrackingData.trackingActualResults.length;i++){
    //   this.projectTrackingData.trackingActualResults[i].orderNo = i;
    // }
    for (
      let i = 0;
      i < this.projectTrackingData.trackingBudgetHead.budgetDetails.length;
      i++
    ) {
      this.projectTrackingData.trackingBudgetHead.budgetDetails[
        i
      ].withdrawDate = offsetTimeZone(
        this.projectTrackingData.trackingBudgetHead.budgetDetails[i]
          .withdrawDate
      );
    }
    let url = '/ProjectTracking/SaveProjectTracking/';
    this.brokerAPIService
      .post(url, JSON.stringify(this.projectTrackingData))
      .subscribe(
        (valuse) => {
          this.unBlockScreen();
          this.result = valuse;
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          } else {
            this.openDialog(
              'ไม่สามารถบันทึกข้อมูลได้',
              JSON.stringify(this.result.message)
            );
          }
          console.log('savedata:', JSON.stringify(this.result));
        },
        (error) => {
          this.unBlockScreen();
          this.openDialog('ไม่สามารถบันทึกข้อมูลได้', JSON.stringify(error));
          console.log('tracking error', JSON.stringify(error));
        }
      );
  }

  updateOrderNo(dataObj: any) {
    for (let i = 0; i < dataObj.length; i++) {
      dataObj[i]['orderNo'] = i;
    }
    return dataObj;
  }

  getProjectTrackingData(projectID: number) {
    let url: string = '/ProjectTracking/GetProjectTracking/' + projectID;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.projectTrackingData = values;
        let callMethod: string =
          '/Auth/IsEditableProjectTracking/' +
          this.projectTrackingData.projectId;
        this.brokerAPIService.get(callMethod).subscribe((values) => {
          this.projectProposalControl = values;
          this.isDataEditable = this.projectProposalControl.isEditable;
          for (
            let i = 0;
            i < this.projectProposalControl.dropdownMenu.length;
            i++
          ) {
            if (
              this.projectProposalControl.dropdownMenu[i].linkName ==
              'TrackingResultEntry'
            ) {
              this.isShowingTrackingResultEntry = true;
              this.trackingResultEntryCaption =
                this.projectProposalControl.dropdownMenu[i].caption;
            } else if (
              this.projectProposalControl.dropdownMenu[i].linkName ==
              'SubmitProjectProgress'
            ) {
              this.isShowingTrackingEntry = true;
              this.trackingResultCaption =
                this.projectProposalControl.dropdownMenu[i].caption;
            }
          }
          for (
            let i = 0;
            i <
            this.projectTrackingData.trackingBudgetHead.budgetDetails.length;
            i++
          ) {
            if (
              this.projectTrackingData.trackingBudgetHead.budgetDetails[
                i
              ].withdrawDate.toString() == '0001-01-01T00:00:00'
            ) {
              this.projectTrackingData.trackingBudgetHead.budgetDetails[
                i
              ].withdrawDate = new Date();
            }
          }
        });
        console.log('track', JSON.stringify(this.projectTrackingData));

        // console.log("budgetsummary",JSON.stringify(this.projectTrackingData.trackingBudgetHead.budgetReceivedDetails));
        if (this.projectTrackingData.objectives&&this.projectTrackingData.objectives.length>0) {
          this.isLongForm = true;
          this.dataSourceObjective.data = this.projectTrackingData.objectives;
        }
        this.projectOwnerFullname =
          this.projectTrackingData.project.projectOwner.prefixTH +
          ' ' +
          this.projectTrackingData.project.projectOwner.firstNameTH +
          ' ' +
          this.projectTrackingData.project.projectOwner.lastNameTH;
        this.projectApprovalDate = this.transformDate(
          this.projectTrackingData.project.projectApproveDate
        );
        this.organizationName =
          this.projectTrackingData.project.researchForOrg.organizationName;
        this.mobielPhone =
          this.projectTrackingData.project.projectOwner.mobilePhone;
        this.email = this.projectTrackingData.project.projectOwner.emailAddress;
        if (this.projectTrackingData.trackingActualResults.length > 0){
          this.dataSourceExpectedResults.data = sortingArrayList(
            this.projectTrackingData.trackingActualResults,
            'fromMonth',
            'asc'
          );

        }
        console.log("dataSourceExpectedResults",this.dataSourceExpectedResults.data);
        
          // this.projectTrackingData.trackingActualResults;
        this.dataSourceSourceOfFund.data =
          this.projectTrackingData.trackingBudgetHead.budgetReceiveds;
        this.dataSourceBudgetTerm.data =
          this.projectTrackingData.trackingBudgetHead.budgetTerms;
        this.dataSourceBudgetSpending.data =
          this.projectTrackingData.trackingBudgetHead.budgetDetails;
        this.budgetPaymentTerm =
          this.projectTrackingData.trackingBudgetHead.budgetTerms.length;
        this.totalProjectMonth =
          this.projectTrackingData.trackingBudgetHead.totalProjectMonth;
        this.Statement = this.projectTrackingData.problemNotice;
        this.totalFundingAllocation = 0;
        if (this.projectTrackingData.nextStepPlan) {
          this.proposeDecision =
            this.projectTrackingData.nextStepPlan.toString();
          this.onProposeDecisionChange({ value: this.proposeDecision });
        }
        this.operationList[0]['status'] =
          '' + this.projectTrackingData.progressStep1;
        this.operationList[1]['status'] =
          '' + this.projectTrackingData.progressStep2;
        this.operationList[2]['status'] =
          '' + this.projectTrackingData.progressStep3;
        this.operationList[3]['status'] =
          '' + this.projectTrackingData.progressStep4;
        if (this.projectTrackingData.isProjetOnPlan) {
          this.onPlanCode = '1';
        } else {
          this.onPlanCode = '2';
        }
        for (
          let i = 0;
          i <
          this.projectTrackingData.trackingBudgetHead.budgetReceiveds.length;
          i++
        ) {
          this.totalFundingAllocation =
            this.totalFundingAllocation +
            this.projectTrackingData.trackingBudgetHead.budgetReceiveds[i]
              .budgetReceived;
        }
        this.totalTermAmount = 0;
        for (
          let i = 0;
          i < this.projectTrackingData.trackingBudgetHead.budgetTerms.length;
          i++
        ) {
          this.totalTermAmount =
            this.totalTermAmount +
            this.projectTrackingData.trackingBudgetHead.budgetTerms[i]
              .budgetReceived;
        }
        this.dataSourceAttachment.data =
          this.projectTrackingData.trackingAttachDocuments;
        this.totalProjectBudget = this.transformAmount(
          this.projectTrackingData.trackingBudgetHead.totalProjectBudget
        );
        this.totalProjectBudgetInThai = moneyToThai(
          this.projectTrackingData.trackingBudgetHead.totalProjectBudget
        );
        this.trackingTime = this.projectTrackingData.trackingTime;
        this.trackingDate = this.projectTrackingData.trackingDate;
        if (this.projectTrackingData.trackingBudgetHead) {
          this.projectWorkingMonth =
            this.projectTrackingData.trackingBudgetHead.totalProjectMonth;
        }
        this.checkDataInTab();
        this.summarySpendingByCategory();
      },
      (error) => { }
    );
  }

  getProjectAttachFileTypeList() {
    //category
    let url: string = '/Master/GetProjectAttachTrackingFileTypeList';
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.projectAttachFileTypeList = values;
        this.projectAttachFileTypeListToShow = values;
      },
      (error) => {}
    );
  }

  btnDeleteAttachFile(rowID: number) {
    this.projectTrackingData.trackingAttachDocuments.splice(rowID, 1);
    this.dataSourceAttachment.data =
      this.projectTrackingData.trackingAttachDocuments;
  }

  checkDataInTab() {
    if (
      this.projectTrackingData.objectives &&
      this.projectTrackingData.objectives.length > 0
    ) {
      this.isObjectivesValid = true;
    } else {
      this.isObjectivesValid = false;
    }

    if ((this.dataSourceBudgetSpending.data &&this.dataSourceBudgetSpending.data.length > 0)
      || !this.isBudgetetailNeeded) {
      this.isUseBudgetValid = true;
    } else {
      this.isUseBudgetValid = false;
    }

    if (this.operationList && this.operationList.length > 0) {
      this.isOperationListValid = true;
    } else {
      this.isOperationListValid = false;
    }

    if (
      this.projectTrackingData.trackingBudgetHead.budgetReceiveds &&
      this.projectTrackingData.trackingBudgetHead.budgetReceiveds.length > 0
    ) {
      this.isTrackingBudgetHeadValid = true;
    } else {
      this.isTrackingBudgetHeadValid = false;
    }

    if (
      this.projectTrackingData.trackingBudgetHead.budgetTerms &&
      this.projectTrackingData.trackingBudgetHead.budgetTerms.length > 0
    ) {
      this.isTrackingBudgetHeadValid = true;
    } else {
      this.isTrackingBudgetHeadValid = false;
    }

    if (
      this.projectTrackingData.trackingBudgetHead.budgetDetails &&
      this.projectTrackingData.trackingBudgetHead.budgetDetails.length > 0
    ) {
      this.isTrackingBudgetHeadValid = true;
    } else {
      this.isTrackingBudgetHeadValid = false;
    }

    if (this.Statement != null) {
      this.isStatementValid = true;
    } else {
      this.isStatementValid = false;
    }

    if (this.proposeDecision == '0' || !this.proposeDecision) {
      this.isProposeDecisionValid = false;
    } else {
      this.isProposeDecisionValid = true;
    }
  }

  onChangeOperationList(event: any) {
    console.log('saasa', event);

    if (event.value > 0) {
      this.isOperationListValid = true;
    }
  }

  onEditorStatement() {
    console.log(this.Statement);

    if (this.Statement == undefined) {
    } else if (this.Statement != undefined) {
      this.isStatementValid = true;
    }
  }

  onclickEditorStatement(Statement: string) {
    console.log(Statement);
    if (Statement == '') {
      this.isStatementValid = false;
    }
  }

  transformDate(dateValue): string {
    return transformDateToBE(dateValue);
  }
  transformAmount(Amount: number): string {
    return this.currencyPipe.transform(Amount, '฿').replace('฿', '');
  }
  getBudgetGroupList() {
    let url: string = '/Master/GetBudgetGroupList';
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        this.budgetGroupList = values;
        for (let i = 0; i < this.budgetGroupList.length; i++) {
          this.getBudgetSubGroupList(this.budgetGroupList[i]);
        }
      },
      (error) => {}
    );
  }

  getBudgetSubGroupList(budgetGroup: enumdata) {
    //category
    let url: string = '/Master/GetBudgetCategoryList/' + budgetGroup.id;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        let categoryList: enumdata[] = values;

        for (let i = 0; i < categoryList.length; i++) {
          let budgetSubGroupRow: enumdata = new enumdata();
          budgetSubGroupRow.id = categoryList[i].id;
          budgetSubGroupRow.description = categoryList[i].description;
          budgetSubGroupRow.groupName = budgetGroup.description;
          if (this.budgetSubGroupList == null) {
            this.budgetSubGroupList = [budgetSubGroupRow];
          } else {
            this.budgetSubGroupList.push(budgetSubGroupRow);
          }
        }
        // this.dataSourceBudgetSpending.data  =  this.projectTrackingData.trackingBudgetHead.budgetDetails;
      },
      (error) => {}
    );
  }

  onCategoryChange(categoryID: any, rowID: any) {
    // console.log(categoryID+"<>"+rowID);
    // console.log(JSON.stringify(this.budgetSubGroupList));
    // console.log(JSON.stringify(this.budgetSubGroupList.find(x => x.id == categoryID)));
    let budgetSubGroup: enumdata = this.budgetSubGroupList.find(
      (x) => x.id == categoryID
    );
    this.projectTrackingData.trackingBudgetHead.budgetDetails[
      rowID
    ].budgetGroup = budgetSubGroup.groupName;
    this.projectTrackingData.trackingBudgetHead.budgetDetails[
      rowID
    ].budgetCategory = budgetSubGroup.description;
    this.dataSourceBudgetSpending.data =
      this.projectTrackingData.trackingBudgetHead.budgetDetails;
    this.summarySpendingByCategory();
  }

  btnAddPayment() {
    let budgetSpengingRow = new budgetdetailallocation();
    budgetSpengingRow.projectTrackingBudgetHeadId =
      this.projectTrackingData.trackingBudgetHead.id;
    budgetSpengingRow.withdrawDate = new Date();
    if (this.projectTrackingData.trackingBudgetHead.budgetDetails) {
      this.projectTrackingData.trackingBudgetHead.budgetDetails.push(
        budgetSpengingRow
      );
    } else {
      this.projectTrackingData.trackingBudgetHead.budgetDetails = [
        budgetSpengingRow,
      ];
    }
    this.dataSourceBudgetSpending.data =
      this.projectTrackingData.trackingBudgetHead.budgetDetails;
    this.checkDataInTab();
  }

  btnDeletePayment(rowID: number) {
    this.projectTrackingData.trackingBudgetHead.budgetDetails.splice(rowID, 1);
    this.dataSourceBudgetSpending.data =
      this.projectTrackingData.trackingBudgetHead.budgetDetails;
    this.checkDataInTab();
    this.summarySpendingByCategory();
  }

  onProposeDecisionChange(event: any) {
    if (this.proposeDecision == '2') {
      this.proposeProjectCancel = false;
      this.proposeExtendMonth = true;
    } else if (this.proposeDecision == '3') {
      this.proposeProjectCancel = true;
      this.proposeExtendMonth = false;
      this.projectTrackingData.projectExtendInMonth = 0;
      this.projectTrackingData.projectExtendReason = '';
    } else {
      this.proposeProjectCancel = false;
      this.proposeExtendMonth = false;
      this.projectTrackingData.projectCancelReason = '';
      this.projectTrackingData.projectExtendInMonth = 0;
      this.projectTrackingData.projectExtendReason = '';
    }

    // if (event.value > 0 ) {
    //   this.isProposeDecisionValid = true
    // } else {
    //    this.isProposeDecisionValid = false
    // }
    this.checkDataInTab();
  }

  blockScreen() {
    this.saveInProgress = true;
    document.getElementById('overlay').style.display = 'block';
  }

  unBlockScreen() {
    this.saveInProgress = false;
    document.getElementById('overlay').style.display = 'none';
  }

  btnBack() {
    this.locationService.back();
    // this.router.navigate(['/projectsearchlist']);
  }

  openProgressDialog() {
    this.isshowdialogSentReport = true;
  }

  closeProgressDialog() {
    this.isshowdialogSentReport = false;
  }
  submitProgress() {
    this.blockScreen;
    let url = '/ProjectTracking/SubmitProjectTracking';
    this.brokerAPIService
      .post(url, JSON.stringify(this.projectTrackingData))
      .subscribe(
        (values) => {
          this.result = values;
          this.unBlockScreen();
          console.log('submit', JSON.stringify(this.result));
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
            this.locationService.back();
          } else {
            this.openDialog('ไม่สามารถบันทึกข้อมูลได้', this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
          this.openSnackBar(error, this.closeSnackbarMessage);
        }
      );
  }

  openProgressReivewDialog() {
    this.isshowdialogRecordResolutions = true;
    this.committeeDecision = new messagecontent();
    this.committeeDecision.projectId = this.projectTrackingData.id;
    this.trackingCommitteeTopic = '';
    this.trackingCommitteeMessage = '';
    this.trackingCommitteeExtendedMonth = 0;
    this.trackingCommitteeSelection = '0';
    this.committeeDecisionFileName1 = '';
    this.committeeDecisionFileName2 = '';
    this.committeeDecisionFileName3 = '';
  }

  closenProgressReivewDialog() {
    this.isshowdialogRecordResolutions = false;
  }

  submitProgressReivewDialog() {
    this.blockScreen();
    this.isshowdialogRecordResolutions = false;
    if (this.trackingCommitteeSelection == '4') {
      this.committeeDecision.projectId = this.projectTrackingData.projectId;
      this.committeeDecision.subject = this.trackingCommitteeTopic;
      this.committeeDecision.message = this.trackingCommitteeMessage;
      let url = '/ProjectTracking/SendReviseTrackingToResearcher';
      this.brokerAPIService
        .post(url, JSON.stringify(this.committeeDecision))
        .subscribe(
          (values) => {
            this.result = values;
            this.unBlockScreen();
            this.locationService.back();
            console.log('submitresult', JSON.stringify(this.result));
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          },
          (error) => {
            this.unBlockScreen();
            this.openSnackBar(JSON.stringify(error), this.closeSnackbarMessage);
            console.log('submitresulterror', JSON.stringify(error));
          }
        );
    }
    if (this.trackingCommitteeSelection == '5') {
      this.cancelProject();
    } else {
      let committeeDecisionSubmission = new trackingcommitteedecision();
      committeeDecisionSubmission.projectId =
        this.projectTrackingData.projectId;
      committeeDecisionSubmission.approveOption = Number.parseInt(
        this.trackingCommitteeSelection
      );
      committeeDecisionSubmission.extendMonth =
        this.trackingCommitteeExtendedMonth;
      let url = '/ProjectTracking/ApproveProjectTracking';
      this.brokerAPIService
        .post(url, JSON.stringify(committeeDecisionSubmission))
        .subscribe(
          (values) => {
            this.result = values;
            this.unBlockScreen();
            this.locationService.back();
            console.log('submitresult', JSON.stringify(this.result));
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
          },
          (error) => {
            this.unBlockScreen();
            this.openSnackBar(JSON.stringify(error), this.closeSnackbarMessage);
            console.log('submitresulterror', JSON.stringify(error));
          }
        );
    }
  }

  cancelProject() {
    this.blockScreen();
    let projectCancellation: projectcancellation = new projectcancellation();
    projectCancellation.projectId = this.projectTrackingData.projectId;
    projectCancellation.cancelReason = this.trackingCommitteeMessage;
    projectCancellation.cancelDocument = this.committeeDecision.attachment1;
    projectCancellation.cancelDocumentId = this.committeeDecision.attachment1Id;

    console.log('projectstop', JSON.stringify(projectCancellation));
    let url = '/Project/CancelProject';
    this.brokerAPIService
      .post(url, JSON.stringify(projectCancellation))
      .subscribe(
        (values) => {
          this.result = values;
          this.unBlockScreen();
          if (this.result.success) {
            this.openSnackBar(this.result.message, this.closeSnackbarMessage);
            this.btnBack();
          } else {
            this.openDialog('ไม่สามารถบันทึกข้อมูลได้', this.result.message);
          }
        },
        (error) => {
          this.unBlockScreen();
          this.openDialog('ไม่สามารถบันทึกข้อมูลได้', JSON.stringify(error));
        }
      );
  }

  async handleFileInputCommittee(files: FileList, attachNumber: number) {
    console.log('attachNumber>>', files, attachNumber);

    if (files && files.length > 0) {
      let file: File = files.item(0);
      const result = await this.getBase64(file).catch((e) => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        return;
      }
      let attachFile = new attachdocument();
      attachFile.documentName = file.name;
      attachFile.mimeType = file.type;
      attachFile.documentContent = result.toString();
      let returnResult: any = await this.PostCommitteeFileData(attachFile);
      console.log('IAPIResponse 11', JSON.stringify(returnResult));
      this.result = returnResult;
      if (this.result.success) {
        attachFile.id = Number.parseInt(this.result.data);
        switch (attachNumber) {
          case 1: {
            this.committeeDecisionFileName1 = file.name;
            this.committeeDecision.attachment1Id = attachFile.id;
            break;
          }
          case 2: {
            this.committeeDecisionFileName2 = file.name;
            this.committeeDecision.attachment2Id = attachFile.id;
            break;
          }
          case 3: {
            this.committeeDecisionFileName3 = file.name;
            this.committeeDecision.attachment3Id = attachFile.id;
            break;
          }
        }
      }
    }
  }

  PostCommitteeFileData(docToUpload: attachdocument) {
    return new Promise((resolve, reject) => {
      this.brokerAPIService
        .post('/DocumentUpload/UploadDocument', JSON.stringify(docToUpload))
        .subscribe(
          (values) => {
            resolve(values);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  onComitteeDecisionChange() {
    if (this.trackingCommitteeSelection == '2') {
      this.trackingCommitteeExtendedMonthEnable = true;
    } else {
      this.trackingCommitteeExtendedMonthEnable = false;
      this.trackingCommitteeExtendedMonth = 0;
    }
    if (this.trackingCommitteeSelection == '4') {
      this.isTrackingCommitteeAttachfile = true;
    } else {
      this.isTrackingCommitteeAttachfile = false;
    }
    if (this.trackingCommitteeSelection == '5') {
      this.isStopingProject = true;
    } else {
      this.isStopingProject = false;
    }
  }

  async handleCommitteDecisionFileInput(files: FileList, fileNumber: number) {
    console.log(files);
    if (files && files.length > 0) {
      let file: File = files.item(0);
      this.attachedFileName = file.name;
      let attachmentFileRow = new attachdocument();

      const result = await this.getBase64(file).catch((e) => e);
      if (result instanceof Error) {
        console.log('Error: ', result.message);
        return;
      }
    }
  }

  viewAttachDocument(attachmentID: Number) {
    console.log('viewAttachDocument', attachmentID);
    let url: string = '/DocumentUpload/GetDocument/' + attachmentID;
    this.brokerAPIService.get(url).subscribe(
      (values) => {
        try {
          let docData: attachdocument = values;
          console.log('viewAttachDocumentvall', JSON.stringify(docData));
          if (docData.documentContent) {
            let blob: Blob;
            blob = base64ToBlob(docData.documentContent, docData.mimeType);
            if (blob) {
              let link = document.createElement('a');
              const url = window.URL.createObjectURL(blob);
              link.href = url;
              link.setAttribute('download', docData.documentName);
              link.click();
            }
          }
        } catch (error) {
          this.openSnackBar(
            'error preview:' + error,
            this.closeSnackbarMessage
          );
        }
      },
      (error) => {}
    );
  }

  summarySpendingByCategory() {
    this.spendingSummarybyGroup = [];
    this.dataSourceBudgetBalance = new MatTableDataSource();
    // console.log("budgetsummary",JSON.stringify(this.projectTrackingData.trackingBudgetHead.budgetReceivedDetails));
    if (this.projectTrackingData.trackingBudgetHead.budgetReceivedDetails) {
      for (
        let i = 0;
        i <
        this.projectTrackingData.trackingBudgetHead.budgetReceivedDetails
          .length;
        i++
      ) {
        let budgetRow: budgetdetailallocation = this.projectTrackingData.trackingBudgetHead.budgetReceivedDetails[i];
        let combineCode: string = budgetRow.fiscalYear.toString() +  budgetRow.budgetCategoryId.toString();
        let spendingSummaryRow: spendingBygroup = this.spendingSummarybyGroup.find((x) => x.budgetGroupName == combineCode);

        if (spendingSummaryRow) {
          spendingSummaryRow.budgetReceived += budgetRow.budgetReceived;
        } else {
          let spendingNewRow: spendingBygroup = new spendingBygroup();
          spendingNewRow.fiscalYear = budgetRow.fiscalYear;
          spendingNewRow.budgetGroupName = combineCode;
          spendingNewRow.budgetGroup = budgetRow.budgetGroup;
          spendingNewRow.budgetGroupId = budgetRow.budgetGroupId;
          spendingNewRow.budgetCategory = budgetRow.budgetCategory;
          spendingNewRow.budgetCategoryId = budgetRow.budgetCategoryId;
          spendingNewRow.budgetGroup = budgetRow.budgetGroup;
          spendingNewRow.budgetReceived = budgetRow.budgetReceived;
          spendingNewRow.balance = budgetRow.budgetReceived;
          spendingNewRow.withdrawBudget = 0;
          spendingNewRow.actualWithdrawBudget = 0;
          this.spendingSummarybyGroup.push(spendingNewRow);
        }
      }
    }

    // this.dataSourceBudgetBalance.data = this.spendingSummarybyGroup;
    this.calculateBudgetBalance();
  }

  calculateBudgetBalance() {
    this.totalBudget = 0;
    this.totalRequestWithdraw = 0;
    this.totalActualWithdraw = 0;
    this.totalBalance = 0;
    if (this.projectTrackingData.trackingBudgetHead.budgetDetails) {
      for (
        let i = 0;
        i < this.projectTrackingData.trackingBudgetHead.budgetDetails.length;
        i++
      ) {
        let budgetRow: budgetdetailallocation =
          this.projectTrackingData.trackingBudgetHead.budgetDetails[i];
        let combineCode: string="";
        if(this.projectTrackingData.trackingBudgetHead.budgetDetails[i].fiscalYear){
          if(this.projectTrackingData.trackingBudgetHead.budgetDetails[i].budgetCategoryId){
            combineCode = this.projectTrackingData.trackingBudgetHead.budgetDetails[i].fiscalYear.toString() +this.projectTrackingData.trackingBudgetHead.budgetDetails[i].budgetCategoryId;
          } else {
            combineCode = this.projectTrackingData.trackingBudgetHead.budgetDetails[i].fiscalYear.toString();
          }
        } else {
          if(this.projectTrackingData.trackingBudgetHead.budgetDetails[i].budgetCategoryId){
            combineCode = this.projectTrackingData.trackingBudgetHead.budgetDetails[i].budgetCategoryId.toString();
          }
        }
        let spendingSummaryRow: spendingBygroup =
          this.spendingSummarybyGroup.find(
            (x) => x.budgetGroupName == combineCode
          );
       
        if (spendingSummaryRow) {
          if (spendingSummaryRow.withdrawBudget) {
            if(budgetRow.withdrawBudget){
              let tempWithdrawBudget:string =budgetRow.withdrawBudget.toString();
              let tempWithdrawBudgetOld:string =spendingSummaryRow.withdrawBudget.toString();
              spendingSummaryRow.withdrawBudget =Number.parseFloat(tempWithdrawBudgetOld) + Number.parseFloat(tempWithdrawBudget);
            }
          } else {
            spendingSummaryRow.withdrawBudget = budgetRow.withdrawBudget;
          }

          if (spendingSummaryRow.actualWithdrawBudget) {
            if (budgetRow.actualWithdrawBudget) {
              let tempActualWithdrawBudget = budgetRow.actualWithdrawBudget.toString();
              let tempActualWithdrawBudgetOld = spendingSummaryRow.actualWithdrawBudget .toString();
              spendingSummaryRow.actualWithdrawBudget =  Number.parseFloat(tempActualWithdrawBudgetOld)+ Number.parseFloat(tempActualWithdrawBudget);
            }
          } else {
            if (budgetRow.actualWithdrawBudget) {
              spendingSummaryRow.actualWithdrawBudget = budgetRow.actualWithdrawBudget;
            }
          }
          let tempBalance:string="0";
          if(spendingSummaryRow.balance){
            tempBalance= spendingSummaryRow.balance.toString();
          }
          if (budgetRow.actualWithdrawBudget) {
            let tempActualWithdrawBudget:string = "0";
            if( budgetRow.actualWithdrawBudget){
              tempActualWithdrawBudget = budgetRow.actualWithdrawBudget.toString();
            }
            spendingSummaryRow.balance = Number.parseFloat(tempBalance) - Number.parseFloat(tempActualWithdrawBudget);
          } else {
            let tempwithdrawBudget:string = "0";
            if(budgetRow.withdrawBudget){
              tempwithdrawBudget = budgetRow.withdrawBudget.toString();
            }
            spendingSummaryRow.balance = Number.parseFloat(tempBalance) - Number.parseFloat(tempwithdrawBudget) ;
          }
        } else {
          let spendingNewRow: spendingBygroup = new spendingBygroup();
          spendingNewRow.fiscalYear = budgetRow.fiscalYear;
          spendingNewRow.budgetGroupName = combineCode;
          spendingNewRow.budgetGroup = budgetRow.budgetGroup;
          spendingNewRow.budgetGroupId = budgetRow.budgetGroupId;
          spendingNewRow.budgetCategory = budgetRow.budgetCategory;
          spendingNewRow.budgetCategoryId = budgetRow.budgetCategoryId;
          spendingNewRow.budgetGroup = budgetRow.budgetGroup;
          if (budgetRow.withdrawBudget) {
            spendingNewRow.withdrawBudget = budgetRow.withdrawBudget;
          } else {
            spendingNewRow.withdrawBudget = 0;
          }
          if (budgetRow.actualWithdrawBudget) {
            spendingNewRow.actualWithdrawBudget =
              budgetRow.actualWithdrawBudget;
          } else {
            spendingNewRow.actualWithdrawBudget = 0;
          }

          if (budgetRow.actualWithdrawBudget) {
            spendingNewRow.balance = 0 - budgetRow.actualWithdrawBudget;
          } else {
            spendingNewRow.balance = 0 - budgetRow.withdrawBudget;
          }
          this.spendingSummarybyGroup.push(spendingNewRow);
        }
      }
    }
    // console.log("budgetsummary>>>",JSON.stringify(this.spendingSummarybyGroup));
    if (this.spendingSummarybyGroup) {
      for (let i = 0; i < this.spendingSummarybyGroup.length; i++) {
        let dataRow: spendingBygroup = this.spendingSummarybyGroup[i];
        let tempWithdrawBudget = dataRow.withdrawBudget.toString();
        let tempActualWithdrawBudget = dataRow.actualWithdrawBudget.toString();
        let tempBalance = dataRow.balance.toString();
        if (dataRow.budgetReceived) {
          this.totalBudget += dataRow.budgetReceived;
        }
        if (dataRow.withdrawBudget) {
          this.totalRequestWithdraw += Number.parseFloat(tempWithdrawBudget);
        }
        if (dataRow.actualWithdrawBudget) {
          this.totalActualWithdraw += Number.parseFloat(tempActualWithdrawBudget);
        }
        if (dataRow.balance) {
          this.totalBalance += Number.parseFloat(tempBalance);
        }
      }
    }

    // this.dataSourceBudgetBalance.data = this.spendingSummarybyGroup;
    // console.log('showsummary',JSON.stringify(this.spendingSummarybyGroup));
    if (this.spendingSummarybyGroup && this.spendingSummarybyGroup.length > 0) {
      this.dataSourceBudgetBalance.data = sortingArrayList(
        this.spendingSummarybyGroup,
        'budgetGroupName',
        'asc'
      );
    }
  }

  transformAmountHTMLInput(element, Amount: number) {
    element.target.value = formatNumber(Amount, 'th-TH', '1.0-2');
  }

  openDialog(dialogTitle: string, dialogMessage: string): void {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '250px',
      data: { title: dialogTitle, message: dialogMessage },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });
  }
  CommaFormatted(event) {
    // skip for arrow keys
    if (event.which >= 37 && event.which <= 40) return;

    // format number
    // if (this.product_price) {
    //  this.product_price = this.product_price.replace(/\D/g, "")
    //    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  }

  numberCheck(args) {
    if (args.key === 'e' || args.key === '+' || args.key === '-') {
      return false;
    } else {
      return true;
    }
  }
}

export class spendingBygroup{
  budgetGroupName: string;//fiscalyear+budgetCategoryId
  fiscalYear: number;
  budgetGroupId: number;
  budgetGroup: string;
  budgetCategoryId: number;
  budgetCategory: string;
  budgetDescription: string;
  budgetReceived: number;
  withdrawBudget: number;
  actualWithdrawBudget: number;
  balance:number;
}
