import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { projectNumberByYear } from '../datamodel/chartdatamodel';
import { BrokerAPIService } from '../services/brokerapi.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { transformYearToGovernementFiscalYear } from '../services/general-function';
@Component({
  selector: 'app-chart-portal',
  templateUrl: './chart-portal.component.html',
  styleUrls: ['./chart-portal.component.scss'],
})
export class ChartPortalComponent implements OnInit {
  isShowingOrg: boolean;
  isShowingResearch: boolean;
  isShowingProgress: boolean;
  isShowingOrgAndYear: boolean;
  isShowingResearcherGrowth: boolean;
  isShowingMainChart: boolean;
  isShowingBudgetByFunding: boolean;
  isShowingBudgetByYear: boolean;
  isShowingProjectByFundingSourceAndDepartment: boolean;
  lineChart: any; //ประกาศตัวแปรเก็บค่า
  lineChartResearcherInOrg: any;
  lineChartResearcherInOrg2: any;
  lineChartResearcherInOrg3: any;
  lineChartResearcherInOrg4: any;
  budgetByFundingChart: any;
  mainDashBoardCaption: string = 'แผนภูมิแสดงจำนวนโครงการตามรายปีงบประมาณ'; //6.2.1
  projectByOrganizationCaption: string =
    'แผนภูมิแสดงจำนวนโครงการตามรายปีงบประมาณเปรียบเทียบแยกตามสังกัด'; //6.2.2
  projectByFundSourceAndDepartment: string =
    'แผนภูมิแสดงจำนวนโครงการตามแหล่งทุนและสังกัดนักวิจัย'; //6.2.3
  budgetByFundingSource: string =
    'แผนภูมิแสดงจำนวนงบประมาณจำแนกตามประเภทแหล่งทุน'; //6.2.4
  projectByFundSourceAndFiscalYear: string =
    'แผนภูมิแสดงจำนวนโครงการแยกตามแหล่งทุนและปีงบประมาณ'; //6.2.6
  progressComparisonCaption: string = 'แผนภูมิแสดงยอดรวมตามสถานะโครงการ';
  projectByOrganizationandCaption: string =
    'แผนภูมิแสดงจำนวนโครงการแยกตามสังกัด';
  researchByOrganizationCaption: string =
    'แผนภูมินักวิจัยจำแนกตามสังกัด'; //6.2.5
  researchGrowthRateCaption: string =
    'แผนภูมิแสดงแนวโน้มจำนวนนักวิจัยที่เพิ่มขึ้น';
  colorArray: any = [
    'rgb(229, 112, 147)',
    'rgb(127, 179, 213)',
    'rgb(115, 198, 182)',
    'rgb(175, 122, 197)',
    'rgb(203, 67, 53)',
    'rgb(50, 75, 200)',
    'rgb(223, 230, 0)',
    'rgb(255, 140, 0)',
    'rgb(11, 102, 35)',
    'rgb(52, 152, 219)',
  ];
  yearList: number[];
  startFromYear: number;
  endAtYear: number;
  showStartYear: boolean;
  showEndYear: boolean;
  startYearDesc: string;
  constructor(private brokerApiService: BrokerAPIService) {}
  ngOnInit(): void {
    this.setFiscalYearSelection();
    this.mainDashboard(this.yearList[0]);
  }

  projectByOrgchart() {
    this.hideDateSelection();
    this.resetButton();
    this.isShowingOrg = true;
    let url = '/Report/GetProjectInOrganizationReport';
    this.resetChartObject();
    this.brokerApiService.get(url).subscribe((values) => {
      let chartDataRow: projectByOrganization[] = values;
      let chartLabel: string[];
      let chartData: number[];
      let colorList = [this.colorArray[0]];
      let dataSets: any[];
      for (let i = 0; i < chartDataRow.length; i++) {
        if (i == 0) {
          chartLabel = [chartDataRow[i].organizationName];
          chartData = [chartDataRow[i].projectCount];
          colorList = [this.colorArray[i]];
          // dataSets = [{
          //    label:  [chartDataRow[i].organizationName],
          //    data: chartData,
          //    fill: false,
          //    lineTension: .5,
          //    borderColor: "orange", // สีของเส้น
          //    borderWidth: 1
          // }]
        } else {
          chartLabel.push(chartDataRow[i].organizationName);
          chartData.push(chartDataRow[i].projectCount);
          colorList.push(this.colorArray[i]);
        }
      }
      this.lineChart = new Chart('lineChart', {
        // สร้าง object และใช้ชื่อ id lineChart ในการอ้างอิงเพื่อนำมาเเสดงผล
        plugins: [ChartDataLabels],
        type: 'bar', // ใช้ชนิดแผนภูมิแบบเส้นสามารถเปลี่ยนชิดได้
        data: {
          // ข้อมูลภายในแผนภูมิแบบเส้น
          labels: chartLabel, // ชื่อของข้อมูลในแนวแกน x
          datasets: [
            {
              // กำหนดค่าข้อมูลภายในแผนภูมิแบบเส้น
              label: 'สังกัด',
              data: chartData,
              fill: false,
              lineTension: 0.5,
              borderColor: 'orange', // สีของเส้น
              backgroundColor: 'blue',
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            // ข้อความที่อยู่ด้านบนของแผนภูมิ
            text: this.projectByOrganizationandCaption,
            display: true,
          },
          plugins: {
            datalabels: {
              color: 'white',
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
              font: {
                weight: 'bold',
              },
              formatter: Math.round,
            },
          },
          tooltips: {
            callbacks: {
                  label: function(tooltipItem, data) {
                      var value = data.datasets[0].data[tooltipItem.index];
                      if(value >= 1000){
                                 return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                 return  value.toString();
                              }
                  }
            } // end callbacks:
          }, 
          
        },
      });
    });
  }

  reserachByOrgChart() {
    this.hideDateSelection();
    this.resetButton();
    this.isShowingResearch = true;
    this.resetChartObject();
    let url = '/Report/GetResearcherInOrganizationReport';
    this.brokerApiService.get(url).subscribe((values) => {
      let chartDataRow: [researcherByOrganization] = values;
      let chartLabel: string[];
      let chartData: number[];
      for (let i = 0; i < chartDataRow.length; i++) {
        if (i == 0) {
          chartLabel = [chartDataRow[i].organizationName];
          chartData = [chartDataRow[i].researcherCount];
        } else {
          chartLabel.push(chartDataRow[i].organizationName);
          chartData.push(chartDataRow[i].researcherCount);
        }
      }
      let colorList = [this.colorArray[0]];
      for (let i = 0; i < chartData.length - 1; i++) {
        colorList.push(this.colorArray[1 + i]);
      }
      this.lineChartResearcherInOrg = new Chart('researchInOrgChart', {
        plugins: [ChartDataLabels],
        type: 'pie', // ใช้ชนิดแผนภูมิแบบเส้นสามารถเปลี่ยนชิดได้
        data: {
          // ข้อมูลภายในแผนภูมิแบบเส้น
          labels: chartLabel, // ชื่อของข้อมูลในแนวแกน x
          datasets: [
            {
              // กำหนดค่าข้อมูลภายในแผนภูมิแบบเส้น
              label: 'นักวิจัย',
              data: chartData,
              backgroundColor: colorList,
              fill: false,
              lineTension: 1,
              borderColor: 'white', // สีของเส้น
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            // ข้อความที่อยู่ด้านบนของแผนภูมิ
            text: this.researchByOrganizationCaption,
            display: true,
          },
          plugins: {
            datalabels: {
              color: 'white',
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
              font: {
                weight: 'bold',
              },
              formatter: Math.round,
            },
          },
          tooltips: {
            callbacks: {
                  label: function(tooltipItem, data) {
                      var value = data.datasets[0].data[tooltipItem.index];
                      if(value >= 1000){
                                 return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                 return  value.toString();
                              }
                  }
            } // end callbacks:
          }, 
          
        },
      });
    });
  }

  projectProgrssCountReport() {
    this.hideDateSelection();
    this.resetButton();
    this.isShowingProgress = true;
    this.resetChartObject();
    let url = '/Report/GetProjectProgressCountReport';
    this.brokerApiService.get(url).subscribe((values) => {
      console.log('data', JSON.stringify(values));
      this.lineChartResearcherInOrg = new Chart('progressSummaryChart', {
        plugins: [ChartDataLabels],
        type: 'doughnut', //line,pine,bar,doughnut
        data: {
          labels: [
            'เสร็จสิ้น',
            'อยู่ระหว่างดำเนินงานเป็นไปตามแผนงาน',
            'อยู่ระหว่างดำเนินงานแต่ไม่เป็นไปตามแผนงาน',
            'ยุติ',
          ], // ชื่อของข้อมูลในแนวแกน x
          datasets: [
            {
              label: 'โครงการ',
              data: [
                values['projectFinished'],
                values['projectProgressingOnPlan'],
                values['projectProgressingDelay'],
                values['projectTerminated'],
              ],
              // data: [1,2,3,4],
              backgroundColor: [
                this.colorArray[6],
                this.colorArray[2],
                this.colorArray[4],
                this.colorArray[5],
              ],
              fill: false,
              lineTension: 1,
              borderColor: 'white', // สีของเส้น
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            // ข้อความที่อยู่ด้านบนของแผนภูมิ
            text: this.progressComparisonCaption,
            display: true,
          },
          plugins: {
            datalabels: {
              color: 'white',
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
              font: {
                weight: 'bold',
              },
              formatter: Math.round,
            },
            tooltips: {
              callbacks: {
                    label: function(tooltipItem, data) {
                        var value = data.datasets[0].data[tooltipItem.index];
                        if(value >= 1000){
                                   return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                   return  value.toString();
                                }
                    }
              } // end callbacks:
            }, 
          },
        },
      });
    });
  }

  projectByOrgchartandYear(fromYear: number) {
    this.hideDateSelection();
    if (!fromYear) {
      fromYear = this.yearList[4];
      this.startFromYear = fromYear;
    }
    this.showStartYear = true;
    this.startYearDesc = 'เริ่มจากปี';
    this.resetButton();
    this.isShowingOrgAndYear = true;
    this.resetChartObject();
    let url = '/Report/GetProjectCountByOrgAndYearReport/' + fromYear;
    console.log('showdata', url);
    this.brokerApiService.get(url).subscribe((values) => {
      console.log('showdata', JSON.stringify(values));
      let rawDataRow: projectCountByOrgByYear[] = values;
      let labelX: string[];
      let chartDataSets: any[];
      for (let i = 0; i < rawDataRow.length; i++) {
        let countYear: number[];
        for (let j = 0; j < rawDataRow[i].projectInYear.length; j++) {
          if (i == 0) {
            if (labelX) {
              labelX.push(rawDataRow[i].projectInYear[j].year.toString());
            } else {
              labelX = [rawDataRow[i].projectInYear[j].year.toString()];
            }
          }
          if (j == 0) {
            countYear = [rawDataRow[i].projectInYear[j].projectCount];
          } else {
            countYear.push(rawDataRow[i].projectInYear[j].projectCount);
          }
        }
        if (!chartDataSets) {
          chartDataSets = [
            {
              label: rawDataRow[i].organizationName,
              data: countYear,
              fill: false,
              lineTension: 0.5,
              borderColor: this.colorArray[i], // สีของเส้น
              borderWidth: 1,
            },
          ];
        } else {
          chartDataSets.push({
            label: rawDataRow[i].organizationName,
            data: countYear,
            fill: false,
            lineTension: 0.5,
            borderColor: this.colorArray[i], // สีของเส้น
            borderWidth: 1,
          });
        }
      }
      console.log('shochartdate', JSON.stringify(chartDataSets));
      this.lineChart = new Chart('lineChartOrgYear', {
        plugins: [ChartDataLabels],
        type: 'line',
        data: {
          labels: labelX,
          datasets: chartDataSets,
        },
        options: {
          title: {
            text: this.projectByOrganizationCaption,
            display: true,
          },
          plugins: {
            datalabels: {
              color: 'black',
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
              font: {
                weight: 'bold',
              },
              formatter: Math.round,
            },
          },
          tooltips: {
            callbacks: {
                  label: function(tooltipItem, data) {
                      var value = data.datasets[0].data[tooltipItem.index];
                      if(value >= 1000){
                                 return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                 return  value.toString();
                              }
                  }
            } // end callbacks:
          }, 
        },
      });
    }),
      (error) => {
        console.log('showdata', JSON.stringify(error));
      };
  }

  researcherGrowthRate(fromYear: number) {
    this.hideDateSelection();
    this.showStartYear = true;
    if (!fromYear) {
      fromYear = this.yearList[4];
      this.startFromYear = fromYear;
    }
    this.startYearDesc = 'ปี';
    this.resetButton();
    this.isShowingResearcherGrowth = true;
    let url = '/Report/GetResearcherGrowthReport/' + fromYear;
    this.resetChartObject();
    this.brokerApiService.get(url).subscribe((values) => {
      let chartDataRow: researchGrowth[] = values;
      let chartLabel: string[];
      let chartData: number[];
      for (let i = 0; i < chartDataRow.length; i++) {
        if (i == 0) {
          chartLabel = [
            chartDataRow[i].year.toString() +
              ' ' +
              chartDataRow[i].percentGrowth +
              '%',
          ];
          chartData = [chartDataRow[i].researcherCount];
        } else {
          chartLabel.push(
            chartDataRow[i].year.toString() +
              ' ' +
              chartDataRow[i].percentGrowth +
              '%'
          );
          chartData.push(chartDataRow[i].researcherCount);
        }
      }
      this.lineChart = new Chart('growthRateChart', {
        plugins: [ChartDataLabels],
        type: 'line',
        data: {
          labels: chartLabel,
          datasets: [
            {
              label: 'นักวิจัย',
              data: chartData,
              fill: false,
              lineTension: 0.5,
              borderColor: 'blue',
              borderWidth: 1,
            },
          ],
        },
        options: {
          title: {
            text: this.researchGrowthRateCaption,
            display: true,
          },
          plugins: {
            datalabels: {
              color: 'black',
              display: function (context) {
                return context.dataset.data[context.dataIndex] > 0;
              },
              font: {
                weight: 'bold',
              },
              formatter: Math.round,
            },
          },
          tooltips: {
            callbacks: {
                  label: function(tooltipItem, data) {
                      var value = data.datasets[0].data[tooltipItem.index];
                      if(value >= 1000){
                                 return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                 return  value.toString();
                              }
                  }
            } // end callbacks:
          }, 
        },
      });
    });
  }

  mainDashboard(selectedYear: number) {
    this.hideDateSelection();
    if (!selectedYear) {
      selectedYear = this.yearList[0];
      this.startFromYear = selectedYear;
    }
    this.resetButton();
    this.startYearDesc = 'ปี';
    this.showStartYear = true;
    this.isShowingMainChart = true;
    let url = '/Report/GetMainDashboard/' + selectedYear;
    this.resetChartObject();

    this.brokerApiService.get(url).subscribe(
      (values) => {
        let chartData: projectNumberByYear = values;
        this.lineChartResearcherInOrg = new Chart('projectTypeChart', {
          plugins: [ChartDataLabels],
          type: 'pie',
          data: {
            labels: ['โครงการวิจัยต่อเนื่อง', 'โครงการวิจัยใหม่'],
            datasets: [
              {
                label: 'นักวิจัย',
                data: [
                  chartData.totalProjectInFiscalYear.contProjectCount,
                  chartData.totalProjectInFiscalYear.newProjectCount,
                ],
                backgroundColor: [this.colorArray[0], this.colorArray[2]],
                fill: false,
                lineTension: 1,
                borderColor: 'white',
                borderWidth: 1,
              },
            ],
          },
          options: {
            title: {
              text: 'โครงการที่ขอทำวิจัย',
              display: true,
            },
            plugins: {
              datalabels: {
                color: 'white',
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                  weight: 'bold',
                },
                formatter: Math.round,
              },
            },
            tooltips: {
              callbacks: {
                    label: function(tooltipItem, data) {
                        var value = data.datasets[0].data[tooltipItem.index];
                        if(value >= 1000){
                                   return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                } else {
                                   return  value.toString();
                                }
                    }
              } // end callbacks:
            }, 
          },
        });

        let totalFunding =
          chartData.totalProjectByFundType.noGrantFunding +
          chartData.totalProjectByFundType.privateFunding +
          chartData.totalProjectByFundType.governmentFunding +
          chartData.totalProjectByFundType.internalFunding;
        this.lineChartResearcherInOrg2 = new Chart(
          'fundingSourceComparisonChart',
          {
            plugins: [ChartDataLabels],
            type: 'doughnut',
            data: {
              labels: [
                'ไม่ขอรับทุนวิจัย ',
                'แหล่งทุนภายนอกหน่วยงานภาคเอกชน',
                'แหล่งทุนภายนอกหน่วยงานภาครัฐ/ภายใต้กำกับของรัฐ',
                'ได้รับทุนวิจัยจากหล่งทุนภายในราชวิทยาลัยจุฬาภรณ์',
              ],
              datasets: [
                {
                  label: 'นักวิจัย',
                  data: [
                    chartData.totalProjectByFundType.noGrantFunding,
                    chartData.totalProjectByFundType.privateFunding,
                    chartData.totalProjectByFundType.governmentFunding,
                    chartData.totalProjectByFundType.internalFunding,
                  ],
                  backgroundColor: [
                    this.colorArray[0],
                    this.colorArray[2],
                    this.colorArray[4],
                    this.colorArray[5],
                  ],
                  fill: false,
                  lineTension: 1,
                  borderColor: 'white',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              title: {
                text: 'โครงการแยกตามประเทภขอทุน',
                display: true,
              },
              plugins: {
                datalabels: {
                  color: 'white',
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                  },
                  font: {
                    weight: 'bold',
                  },
                  formatter: Math.round,
                },
              },
              tooltips: {
                callbacks: {
                      label: function(tooltipItem, data) {
                          var value = data.datasets[0].data[tooltipItem.index];
                          if(value >= 1000){
                                     return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  } else {
                                     return  value.toString();
                                  }
                      }
                } // end callbacks:
              }, 
            },
          }
        );

        this.lineChartResearcherInOrg3 = new Chart('newProjectFundingChart', {
          plugins: [ChartDataLabels],
          type: 'bar',
          data: {
            labels:[''],
            datasets: [
              {
                label: 'ไม่ขอรับทุนวิจัย',
                data: [chartData.newProjectByFundType.noGrantFunding],
                fill: false,
                lineTension: 0.5,
                backgroundColor: [this.colorArray[0]],
                borderColor: ['white'],
                borderWidth: 1,
              },
              {
                label: 'ได้รับทุนวิจัยจากหล่งทุนภายในราชวิทยาลัยจุฬาภรณ์',
                data: [chartData.newProjectByFundType.internalFunding],
                fill: false,
                lineTension: 0.5,
                backgroundColor: [this.colorArray[3]],
                borderColor: ['white'],
                borderWidth: 1,
              },
              {
                label: 'ได้รับทุนวิจัยจากหล่งทุนภายนอกราชวิทยาลัยจุฬาภรณ์',
                data: [chartData.newProjectByFundType.externalFunding],
                fill: false,
                lineTension: 0.5,
                backgroundColor: [this.colorArray[5]],
                borderColor: ['white'],
                borderWidth: 1,
              },
            ],
          },
          options: {
            title: {
              text: 'โครงการวิจัยใหม่',
              display: true,
            },
            plugins: {
              datalabels: {
                color: 'white',
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                  weight: 'bold',
                },
                formatter: Math.round,
              },
            },
            // tooltips: {
            //   callbacks: {
            //         label: function(tooltipItem, data) {
            //             var value = data.datasets[0].data[tooltipItem.index];
            //             if(value >= 1000){
            //                        return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            //                     } else {
            //                        return  value.toString();
            //                     }
            //         }
            //   } // end callbacks:
            // }, 
          },
        });

        this.lineChartResearcherInOrg4 = new Chart(
          'continueProjectFundingChart',
          {
            plugins: [ChartDataLabels],
            type: 'bar',
            data: {
              
              labels:[''],
              datasets: [
                {
                  label: 'ไม่ขอรับทุนวิจัย',
                  data: [chartData.contProjectByFundType.noGrantFunding],
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: [this.colorArray[0]],
                  borderColor: ['white'],
                  borderWidth: 1,
                },
                {
                  label: 'ได้รับทุนวิจัยจากหล่งทุนภายในราชวิทยาลัยจุฬาภรณ์',
                  data: [chartData.contProjectByFundType.internalFunding],
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: [this.colorArray[3]],
                  borderColor: ['white'],
                  borderWidth: 1,
                },
                {
                  label: 'ได้รับทุนวิจัยจากหล่งทุนภายนอกราชวิทยาลัยจุฬาภรณ์',
                  data: [chartData.contProjectByFundType.externalFunding],
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: [this.colorArray[5]],
                  borderColor: ['white'],
                  borderWidth: 1,
                },
              ],
            },
            options: {
              title: {
                text: 'โครงการวิจัยต่อเนื่อง',
                display: true,
              },
              plugins: {
                datalabels: {
                  color: 'white',
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                  },
                  font: {
                    weight: 'bold',
                  },
                  formatter: Math.round,
                },
              },
              // tooltips: {
              //   callbacks: {
              //         label: function(tooltipItem, data) {
              //             var value = data.datasets[0].data[tooltipItem.index];
              //             if(value >= 1000){
              //                        return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              //                     } else {
              //                        return  value.toString();
              //                     }
              //         }
              //   } // end callbacks:
              // }, 
            },
          }
        );
      },
      (error) => {}
    );
  }

  projectByFundingSourceAndDepartment(fromYear: number, toYear: number) {
    this.hideDateSelection();
    this.showStartYear = true;
    this.showEndYear = true;
    this.resetButton();
    this.startYearDesc = 'เริ่มจากปี';
    if (!fromYear) {
      fromYear = this.yearList[4];
      this.startFromYear = fromYear;
    }
    if (!toYear) {
      toYear = this.yearList[0];
      this.endAtYear = toYear;
    }
    this.isShowingProjectByFundingSourceAndDepartment = true;
    this.resetChartObject();
    let url =
      '/Report/GetProjectByFundTypeAndOrgReport/' + fromYear + ',' + toYear;
    this.brokerApiService.get(url).subscribe(
      (values) => {
        let chartLabel: string[];
        let internalFunding: number[];
        let governmentFunding: number[];
        let privateFunding: number[];
        if (values) {
          for (let i = 0; i < values.length; i++) {
            if (i == 0) {
              chartLabel = [values[i].organizationName];
              internalFunding = [values[i].internalFunding];
              governmentFunding = [values[i].governmentFunding];
              privateFunding = [values[i].privateFunding];
            } else {
              chartLabel.push(values[i].organizationName);
              internalFunding.push(values[i].internalFunding);
              governmentFunding.push(values[i].governmentFunding);
              privateFunding.push(values[i].privateFunding);
            }
          }
          console.log(
            'showdata',
            internalFunding,
            governmentFunding,
            privateFunding
          );
          new Chart('projectByFundingSourceAndDepartment', {
            plugins: [ChartDataLabels],
            type: 'bar',
            data: {
              labels: chartLabel,
              datasets: [
                {
                  label: 'แหล่งทุนภายใน',
                  data: internalFunding,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'blue',
                  borderColor: 'white',
                  borderWidth: 1,
                },
                {
                  label: 'แหล่งทุนภายนอก',
                  data: governmentFunding,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'red',
                  borderColor: 'white',
                  borderWidth: 1,
                },
                {
                  label: 'แหล่งทุนเอกขน',
                  data: privateFunding,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'green',
                  borderColor: 'white',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              title: {
                text: this.projectByFundSourceAndDepartment,
                display: true,
              },

              plugins: {
                datalabels: {
                  color: 'white',
                  display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                  },
                  font: {
                    weight: 'bold',
                  },
                  formatter: Math.round,
                },
              },
              tooltips: {
                callbacks: {
                      label: function(tooltipItem, data) {
                          var value = data.datasets[0].data[tooltipItem.index];
                          if(value >= 1000){
                                     return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  } else {
                                     return  value.toString();
                                  }
                      }
                } // end callbacks:
              }, 
            },
          });
        }
      },
      (error) => {}
    );
  }

  budgetByFunding(fromYear: number, toYear: number) {
    this.hideDateSelection();
    this.resetButton();
    this.isShowingBudgetByFunding = true;
    this.showStartYear = true;
    this.showEndYear = true;
    if (!fromYear) {
      fromYear = this.yearList[4];
      this.startFromYear = fromYear;
    }
    if (!toYear) {
      toYear = this.yearList[0];
      this.endAtYear = toYear;
    }
    this.startYearDesc = 'เริ่มจากปี';
    let url = '/Report/GetBudgetReceivedReport/' + fromYear + ',' + toYear;
    this.resetChartObject();
    this.brokerApiService.get(url).subscribe(
      (values) => {
        console.log('shewdate', JSON.stringify(values));
        let chartLabel: string[];
        let budget: number[];
        let backgroundColor: any[];
        if (values) {
          for (let i = 0; i < values.length; i++) {
            if (i == 0) {
              chartLabel = [values[i].fundingTypeName];
              budget = [values[i].budget];
              backgroundColor = [this.colorArray[i]];
            } else {
              chartLabel.push(values[i].fundingTypeName);
              budget.push(values[i].budget);
              backgroundColor.push(this.colorArray[i]);
            }
          }

          this.budgetByFundingChart = this.budgetByFundingChart = new Chart(
            'budgetByFunding',
            {
              plugins: [ChartDataLabels],
              type: 'doughnut',
              data: {
                labels: chartLabel,
                datasets: [
                  {
                    label: 'โครงการ',
                    data: budget,
                    // data: [1,2,3,4],
                    backgroundColor: backgroundColor,
                    fill: false,
                    lineTension: 1,
                    borderColor: 'white', // สีของเส้น
                    borderWidth: 1,
                  },
                ],
              },
              options: {
                title: {
                  // ข้อความที่อยู่ด้านบนของแผนภูมิ
                  text: this.budgetByFundingSource,
                  display: true,
                },
                plugins: {
                  datalabels: {
                    color: 'white',
                    display: function (context) {
                      return context.dataset.data[context.dataIndex] > 0;
                    },
                    font: {
                      weight: 'bold',
                    },
                    formatter: Math.round,
                  },
                },
                tooltips: {
                  callbacks: {
                        label: function(tooltipItem, data) {
                            var value = data.datasets[0].data[tooltipItem.index];
                            if(value >= 1000){
                                       return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    } else {
                                       return  value.toString();
                                    }
                        }
                  } // end callbacks:
                }, 
               
              },
            }
          );
        } else {
          this.isShowingBudgetByFunding = false;
        }
      },
      (error) => {}
    );
  }

  budgetReceivedByYearReport(fromYear: number, toYear: number) {
    this.hideDateSelection();
    this.resetButton();
    this.isShowingBudgetByYear = true;
    this.showStartYear = true;
    this.showEndYear = true;
    if (!fromYear) {
      fromYear = this.yearList[4];
      this.startFromYear = fromYear;
    }
    if (!toYear) {
      toYear = this.yearList[0];
      this.endAtYear = toYear;
    }
    this.startYearDesc = 'เริ่มจากปี';
    this.resetChartObject();
    let url =
      '/Report/GetBudgetReceivedByYearReport/' + fromYear + ',' + toYear;
    this.brokerApiService.get(url).subscribe(
      (values) => {
        let chartLabel: string[];
        let internalFund: number[];
        let externalFund: number[];
        let governmentFund: number[];
        if (values) {
          for (let i = 0; i < values.length; i++) {
            if (i == 0) {
              chartLabel = [values[i].year];
              internalFund = [values[i].internalFund];
              externalFund = [values[i].externalFund];
              governmentFund = [values[i].governmentFund];
            } else {
              chartLabel.push(values[i].year);
              internalFund.push(values[i].internalFund);
              externalFund.push(values[i].externalFund);
              governmentFund.push(values[i].governmentFund);
            }
          }
          this.lineChart = new Chart('budgetByYear', {
            type: 'bar',
            data: {
              labels: chartLabel,
              datasets: [
                {
                  label: 'แหล่งทุนภายใน',
                  data: internalFund,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'orange',
                  borderColor: 'white',
                  borderWidth: 1,
                },               
                {
                  label: 'แหล่งทุนภายนอกหน่วยงานภาครัฐ/ภายใต้กำกับของรัฐ',
                  data: governmentFund,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'green',
                  borderColor: 'white',
                  borderWidth: 1,
                },
                {
                  label: 'แหล่งทุนภายนอกหน่วยงานภาคเอกชน',
                  data: externalFund,
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'blue',
                  borderColor: 'white',
                  borderWidth: 1,
                },
              ],
            },
            options: {
              title: {
                text: this.projectByFundSourceAndFiscalYear,
                display: true,
              },
              animation: {
                onComplete: function () {
                  var chartInstance = this.chart;
                  var ctx = chartInstance.ctx;
                  console.log(chartInstance);
                  var height = chartInstance.controller.boxes[0].bottom;
                  ctx.textAlign = 'center';
                  Chart.helpers.each(
                    this.data.datasets.forEach(function (dataset, i) {
                      var meta = chartInstance.controller.getDatasetMeta(i);
                      Chart.helpers.each(
                        meta.data.forEach(function (bar, index) {
                          if (dataset.data[index] != 0) {
                            ctx.fillText(
                              dataset.data[index].toLocaleString('en-us', {
                                minimumFractionDigits: 0,
                              }),
                              bar._model.x,
                              height - (height - bar._model.y) - 10
                            );
                          }
                        }),
                        this
                      );
                    }),
                    this
                  );
                },
              },
              tooltips: {
                callbacks: {
                      label: function(tooltipItem, data) {
                          var value = data.datasets[0].data[tooltipItem.index];
                          if(value >= 1000){
                                     return  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                  } else {
                                     return  value.toString();
                                  }
                      }
                } // end callbacks:
              }, 
            },
          });
        }
      },
      (error) => {}
    );
  }

  resetButton() {
    this.isShowingOrg = false;
    this.isShowingResearch = false;
    this.isShowingProgress = false;
    this.isShowingOrgAndYear = false;
    this.isShowingResearcherGrowth = false;
    this.isShowingMainChart = false;
    this.isShowingProjectByFundingSourceAndDepartment = false;
    this.isShowingBudgetByFunding = false;
    this.isShowingBudgetByYear = false;
  }

  setFiscalYearSelection() {
    let year =  transformYearToGovernementFiscalYear(new Date());
    
    this.yearList = [year];
    for (let i = 0; i < 20; i++) {
      this.yearList.push(year + i * -1 - 1);
    }
    this.startFromYear = this.yearList[0];
    this.endAtYear = this.yearList[0];
  }

  fromYearChange() {
    if (this.isShowingOrgAndYear) {
      this.projectByOrgchartandYear(this.startFromYear);
    } else if (this.isShowingMainChart) {
      this.mainDashboard(this.startFromYear);
    } else if (this.isShowingResearcherGrowth) {
      this.researcherGrowthRate(this.startFromYear);
    } else if (this.isShowingProjectByFundingSourceAndDepartment) {
      if (this.endAtYear < this.startFromYear) {
        this.endAtYear = this.startFromYear;
      }
      this.projectByFundingSourceAndDepartment(
        this.startFromYear,
        this.endAtYear
      );
    } else if (this.isShowingBudgetByFunding) {
      if (this.endAtYear < this.startFromYear) {
        this.endAtYear = this.startFromYear;
      }
      this.budgetByFunding(this.startFromYear, this.endAtYear);
    } else if (this.isShowingBudgetByYear) {
      if (this.endAtYear < this.startFromYear) {
        this.endAtYear = this.startFromYear;
      }
      this.budgetReceivedByYearReport(this.startFromYear, this.endAtYear);
    }
  }
  toYearChange() {
    if (this.isShowingProjectByFundingSourceAndDepartment) {
      if (this.endAtYear < this.startFromYear) {
        this.startFromYear = this.endAtYear;
      }
      this.projectByFundingSourceAndDepartment(
        this.startFromYear,
        this.endAtYear
      );
    } else if (this.isShowingBudgetByFunding) {
      if (this.endAtYear < this.startFromYear) {
        this.startFromYear = this.endAtYear;
      }
      this.budgetByFunding(this.startFromYear, this.endAtYear);
    } else if (this.isShowingBudgetByYear) {
      if (this.endAtYear < this.startFromYear) {
        this.startFromYear = this.endAtYear;
      }
      this.budgetReceivedByYearReport(this.startFromYear, this.endAtYear);
    }
  }

  hideDateSelection() {
    this.showStartYear = false;
    this.showEndYear = false;
  }

  resetChartObject() {
    if (this.budgetByFundingChart) {
      this.budgetByFundingChart.destroy();
    }
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    if (this.lineChartResearcherInOrg) {
      this.lineChartResearcherInOrg.destroy();
    }
    if (this.lineChartResearcherInOrg2) {
      this.lineChartResearcherInOrg2.destroy();
    }
    if (this.lineChartResearcherInOrg3) {
      this.lineChartResearcherInOrg3.destroy();
    }
    if (this.lineChartResearcherInOrg4) {
      this.lineChartResearcherInOrg4.destroy();
    }
  }
}

export class projectByOrganization {
  organizationName: string;
  projectCount: number;
}

export class researcherByOrganization {
  organizationName: string;
  researcherCount: number;
}

export class researchGrowth {
  year: number;
  researcherCount: number;
  percentGrowth: number;
}

export class projectCountbyYear {
  year: number;
  projectCount: number;
}

export class projectCountByOrgByYear {
  organizationName: string;
  projectInYear: projectCountbyYear[];
}
