<table>
    <table style="width: 100%; font-size: 14px; padding-left: 30px;" >
        <tr>
            <td colspan="2" style="width: 100%; text-align: center;">
                <h3>แบบประวัติผู้วิจัย</h3>
            </td>
        </tr>

        <tr>
            <td colspan="2">
                <table style="width: 100%;padding: 0px;margin: 0px;">
                    <tr>
                        <td style="width: 30%;">
                            <mat-label style="font-weight: bold;">ชื่อ-สกุล</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{ researcherData.prefixTH + researcherData.firstNameTH }} {{ researcherData.lastNameTH }}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td style="width: 30%;">
                            <mat-label style="font-weight: bold;">อีเมล</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{ researcherData.emailAddress }}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td style="width: 30%;">
                            <mat-label style="font-weight: bold;">ที่อยู่</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{researcherData.currentAddress }}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td style="width: 30%;">
                            <mat-label style="font-weight: bold;">เบอร์โทรศัพท์</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{ researcherData.mobilePhone}}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td style="width: 30%;">
                            <mat-label style="font-weight: bold;">ตำแหน่งงาน</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{ researcherData.currentPosition }}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td style="width: 30%;">
                            <mat-label style="font-weight: bold;">สังกัด</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{ department }}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">ประวัติการศึกษา</mat-label>
                        </td>
                    </tr>

                    <tr *ngFor="let dataEducationHists of researcherData.educationHists ">
                        <td style="width: 30%; vertical-align: initial; position: initial; z-index: 5;">
                            <mat-label>{{dataEducationHists.beginEduYear}}-{{dataEducationHists.endEduYear}}</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{dataEducationHists.qualification}}, {{dataEducationHists.fieldOfStudy}}, {{dataEducationHists.eduInstitution}}, {{dataEducationHists.eduCountry}}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">ประสบการณ์ทำงาน</mat-label>
                        </td>
                    </tr>

                    <tr *ngFor="let dataworkExpHists of researcherData.workExpHists ">
                        <td style="width: 30%; vertical-align: initial; position: initial; z-index: 5;">
                            <mat-label>{{dataworkExpHists.strBeginWorkYear}}-{{dataworkExpHists.strEndWorkYear}}</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{dataworkExpHists.workPosition}}, {{dataworkExpHists.workPlace}}, {{dataworkExpHists.workCountry}}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>


                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">ประวัติการฝึกอบรม/ดูงาน/สัมมนา</mat-label>
                        </td>
                    </tr>


                    <tr *ngFor="let dataSeminarHists of researcherData.seminarHists ">
                        <td style="width: 30%; vertical-align: initial; position: initial; z-index: 5;">
                            <mat-label>{{dataSeminarHists.year}}</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{dataSeminarHists.seminarSubject}}, {{dataSeminarHists.organizerName}}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">รางวัล</mat-label>
                            <table style="width: 100%;padding: 0px;margin: 0px; ">
                                <tr>
                                    <td style="width: 100%; ">
                                        <label style="font-weight: bold; " [innerHtml]="researcherData.reward"></label>
                                    </td>

                                    <td style="min-width: 100px; "></td>
                                </tr>
                            </table>
                        </td>
                    </tr>



                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">สมาชิกองค์กรวิชาชีพ</mat-label>
                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <label style="font-weight: bold; " [innerHtml]="researcherData.organizationMember"></label>

                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">การนำเสนอผลงาน</mat-label>
                        </td>
                    </tr>

                    <tr *ngFor="let dataPresentWorks of researcherData.presentWorks ">
                        <td style="width: 30%; vertical-align: initial; position: initial; z-index: 5;">
                            <mat-label>{{ dataPresentWorks.presentDate }}</mat-label>
                        </td>
                        <td colspan="2" style="width: 70%;">
                            <mat-label>{{ dataPresentWorks.presentWorkName }}, {{ dataPresentWorks.eventName }}, {{ dataPresentWorks.presentLevel == 1 ? 'ภายในประเทศ' : 'ต่างประเทศ' }}, {{ dataPresentWorks.presentLocation }}</mat-label>
                        </td>
                        <td style="min-width: 100px;">

                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" style="width: 100%;">
                            <mat-label style="font-weight: bold;">ผลงานตีพิมพ์</mat-label>
                        </td>
                    </tr>

                    <tr *ngFor="let dataExternalPublishedWorks of researcherData.externalPublishedWorks ">
                        <td colspan="4" style="width: 100%;">

                            <mat-label>{{dataExternalPublishedWorks.orderNo + 1}}. {{ dataExternalPublishedWorks.publishWorkName }}, {{ dataExternalPublishedWorks.journalName }}, {{ dataExternalPublishedWorks.publishYear }}.</mat-label>


                        </td>
                    </tr>


                </table>
            </td>
        </tr>
    </table>

</table>
