<!-- <div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="10px" style="margin-bottom: 10px; margin-top: 10px; ">

    <div fxFlex class="table-component-holder mat-elevation-z4" style="border-radius: 10px;"> -->
<!-- 
        <div class="font-bold" style="background-color: #002F87; color: white; text-align: center; padding-top: 10px; padding-bottom: 10px; border-radius: 10px 10px 0px 0px; ">
            <table style="width: 100%;">
                <tr>
                    <td style="width: 40%;">

                    </td>
                    <td style="width: 20%; text-align: center;">
                        <label style="font-size: 18px; " class="font-light">{{'หน้ากำหนดสิทธิ์ผู้ใช้งานระบบ' }}</label>
                    </td>
                    <td style="width: 30%;">

                    </td>
                    <td style="width: 10%;">

                    </td>
                </tr>
            </table>

        </div> -->

<table style="width: 100%;">
    <tr>
        <td style="width: 40%; text-align: left;" class="btn-left">
            <button mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold; " class="btn-back" (click)="btnClose()">{{'ย้อนกลับ' }}</button>
        </td>
        <td style="width: 20%; text-align: center;">

        </td>
        <td style="width: 40%; text-align: right;" class="btn-right">
            <button mat-raised-button style="font-size: 16px; font-family: chulabhornlikittext-bold; " class="btn-black" (click)="btnSave()">{{'บันทึก' }}</button>

        </td>
    </tr>
</table>


<div class="table-container" style="padding: 5px;margin-top: 40px;">
    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
        <div fxFlex="2"></div>
        <div fxFlex="50%" class="font-light">
            <mat-form-field class="font-light">
                <input matInput readonly maxlength="50" [(ngModel)]="dataRow.email" autocomplete="off" placeholder="{{'User'}}">
            </mat-form-field>
        </div>
    </div>
    <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column">
        <div fxFlex="2"></div>
        <div fxFlex="25%" class="font-light">
            <mat-checkbox [(ngModel)]="dataRow.isAdmin">Admin</mat-checkbox>
        </div>
        <div fxFlex="2"></div>

        <div fxFlex="25%" class="font-light">
            <mat-checkbox [(ngModel)]="dataRow.isManagement">Management</mat-checkbox>
        </div>
        <div fxFlex="2"></div>

        <div fxFlex="25%" class="font-light">
            <mat-checkbox [(ngModel)]="dataRow.isHightLevelManagement">High Level Management</mat-checkbox>
        </div>

        <div fxFlex="2"></div>

        <div fxFlex="25%" class="font-light">
            <mat-checkbox [(ngModel)]="dataRow.inActivated">Inactive</mat-checkbox>
        </div>

        <div fxFlex="2"></div>

    </div>

    <!-- Organization -->
    <br>
    <div fxLayout="column" class="components-container-gt-xs" fxLayoutGap="20px" style="margin-bottom: 30px; margin-top: 5px; margin-left: 10px; margin-right: 10px;">
        <mat-card style="border-radius: 10px; margin: 10px;  border-style: solid; border-color: #d9d9d9;">
            <mat-table class="gridrow" [dataSource]="dataSourceAuthenticationList" matSort matSortDisableClear>
                <ng-container matColumnDef="lineNo">
                    <mat-header-cell fxFlex="5" style="color: #002F87;" class="font-light" *matHeaderCellDef mat-sort-header>{{'ลำดับ'}}</mat-header-cell>
                    <mat-cell class="font-light" fxFlex="5" style="color: #002F87;" *matCellDef="let element; let i = index">{{i + 1 }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="isSelected">
                    <mat-header-cell fxFlex="15" style="color: #002F87;" class="font-light" *matHeaderCellDef mat-sort-header>{{'อนุญาต' }}</mat-header-cell>
                    <mat-cell fxFlex="15" *matCellDef="let dataRow">
                        <!-- <div style="color: #002F87;" class="font-light">{{ objjob.isSelected }}</div> -->
                        <div style="text-align: center; width: 100%;">
                            <mat-checkbox class="" [(ngModel)]="dataRow.isSelected"></mat-checkbox>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="organizationName">
                    <mat-header-cell style="color: #002F87;" class="font-light" *matHeaderCellDef mat-sort-header>{{'หน่วยงาน' }}</mat-header-cell>
                    <mat-cell *matCellDef="let dataRow">
                        <div style="color: #002F87;" class="font-light">{{ dataRow.organizationName }}</div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </mat-card>
    </div>

    <!-- <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 30px; padding-left: 25px; padding-bottom: 15px;">
                <label class="font-light" style="font-size: 16px; ">Organization
                </label>
            </div>
            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 5px;">
                <div fxFlex="2"></div>
                <div fxFlex="50%" class="font-light">
                    <mat-checkbox class="" [(ngModel)]="dataRow.Organization01">ราชวิทยาลัยจุฬาภรณ์</mat-checkbox>
                </div>
                <div fxFlex="2"></div>

                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization02">ศูนย์การเรียนรู้และวิจัยเฉลิมพระเกียรติ 60 พรรษาเจ้าฟ้าจุฬาภรณ์</mat-checkbox>
                </div>

                <div fxFlex="2"></div>

            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 5px;">
                <div fxFlex="2"></div>
                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization03">คณะแพทยศาสตร์และการสาธารณสุข</mat-checkbox>
                </div>
                <div fxFlex="2"></div>

                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization04">สังกัดวิทยาลัยวิทยาศาสตร์การแพทย์เจ้าฟ้าจุฬาภรณ์</mat-checkbox>
                </div>

                <div fxFlex="2"></div>

            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 5px;">
                <div fxFlex="2"></div>
                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization05">คณะเทคโนโลยีวิทยาศาสตร์สุขภาพ</mat-checkbox>
                </div>
                <div fxFlex="2"></div>

                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization06">คณะสัตวแพทยศาสตร์และสัตววิทยาประยุกต์</mat-checkbox>
                </div>

                <div fxFlex="2"></div>

            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 5px;">
                <div fxFlex="2"></div>
                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization07">สำนักงานอธิการบดีวิทยาลัยวิทยาศาสตร์การแพทย์เจ้าฟ้าจุฬาภรณ์</mat-checkbox>
                </div>
                <div fxFlex="2"></div>

                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization08">โรงพยาบาลจุฬาภรณ์</mat-checkbox>
                </div>

                <div fxFlex="2"></div>

            </div>

            <div fxFlexFill class="container" fxLayout="row" fxLayout.xs="column" style="padding-top: 10px; padding-bottom: 5px;">
                <div fxFlex="2"></div>
                <div fxFlex="50%" class="font-light">
                    <mat-checkbox [(ngModel)]="dataRow.Organization09">อื่น ๆ</mat-checkbox>
                </div>
                <div fxFlex="2"></div>

                <div fxFlex="50%" class="font-light">

                </div>

                <div fxFlex="2"></div>

            </div> -->


</div>

<!-- </div>
</div> -->