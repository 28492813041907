<div class="table-container">

    <table style="width: 100%; font-size: 14px;" class="font-light">
        <tr>
            <td colspan="2" style="width: 100%; text-align: center;">
                <h3>ผลงานวิจัยตีพิมพ์</h3>
            </td>
        </tr>
        <tr>

            <td class="" colspan="2" style="width: 100%; text-align: center;">
                <label style="font-weight: bold;">จากปี </label>{{ fromYear }}&nbsp;&nbsp;
                <label style="font-weight: bold;">ถึงปี </label>{{ toYear }}
            </td>

        </tr>
        <tr>
            <td colspan="2">
                <table style="width: 100%;padding: 0px;margin: 0px;">
                    <tr>
                        <td colspan="4 " style="width: 100%; ">
                            <table style="width: 100%; border-collapse: collapse; margin-bottom: 10px; margin-top: 10px; ">
                                <tr>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ลำดับ
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ชื่อผลงานตีพิมพ์เผยแพร่
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        Pl/Co Pl
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        สังกัด
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ชื่อวารสาร/ฉบับที่ตีพิมพ์
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        impact factor
                                    </td>
                                    <td style="border: 1px solid black; font-weight: bold; text-align: center; ">

                                        ประเภท
                                    </td>


                                </tr>
                                <tr *ngFor="let objPublishedWork of dataPublishedWork let i =index">
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ i + 1 }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objPublishedWork.publishWorkName }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objPublishedWork.paticipation }}
                                    </td>

                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objPublishedWork.organizationName }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objPublishedWork.journalName }}
                                    </td>

                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objPublishedWork.impactFactor }}
                                    </td>
                                    <td style="border: 1px solid black; text-align: center; font-size: 12px;">
                                        {{ objPublishedWork.journalLevel }}
                                    </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>

    </table>
</div>