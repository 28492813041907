import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { ProjectsearchRoutingModule } from './projectsearch-routing.module';
import { SearchpageComponent } from './searchpage/searchpage.component';
import { MaterialModule } from '../module/material/material.module';
import { ProjectsearchListComponent } from './projectsearch-list/projectsearch-list.component';
import { ProjectsearchDetailComponent } from './projectsearch-detail/projectsearch-detail.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ProjectProgressDetailComponent } from './project-progress-detail/project-progress-detail.component';
import { LocalizedNumericInputDirective } from '../services/LocalizedNumericInputDirective';

@NgModule({
  declarations: [SearchpageComponent, 
    ProjectsearchListComponent, 
    ProjectsearchDetailComponent,
    ProjectProgressDetailComponent,
    LocalizedNumericInputDirective],
  imports: [
    CommonModule,
    ProjectsearchRoutingModule,
    MaterialModule,
    NgxSliderModule,
  ],
  providers: [CurrencyPipe]
})
export class ProjectsearchModule { }
