export class researchmethod{
	id: number;
	projectId: number;
    orderNo: number;
    researchMethodType: string;
    researchMethodDetail: string;
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}