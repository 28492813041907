import { timelinedetail } from "./timelinedetail";

export class projecttimeline{
    id: number;
    projectId: number;
    totalTimeLineInMonth: number;
    timeLineDetails: [timelinedetail];
    createBy: string;
    createDate: Date;
    updateBy: string;
    updateDate: Date;
    inActivated:  boolean;
}